import { AudioService } from "src/app/services/audio.service";
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService, MenuEvents } from "src/app/services/global.service";
import { PlayTTSService } from "src/app/services/play-tts.service";
import { environment } from "src/environments/environment";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser/ngx";
import { ConnectionStatus, NetworkService } from "src/app/services/network.service";
import { ModalsService } from "src/app/services/modals.service";
import { AppLanguage } from "src/app/models/enums/enum-list";

export enum MenuTag {
	home = 1,
	codeclass = 2,
	activityParticipants = 3,
	activities = 4,
	videos = 5,
	account = 6
}
@Component({
	selector: "app-left-menu",
	templateUrl: "./left-menu.component.html",
	styleUrls: ["./left-menu.component.scss"]
})
export class LeftMenuComponent implements OnInit, OnDestroy {
	public environment: { production: boolean; activityVersion: number; kidaia: boolean; ose: boolean };
	public changeD: ChangeDetectorRef;
	@Input() changeDetector: ChangeDetectorRef;
	@Input() menuStatus: boolean;
	// @ViewChild("bodyContainer", { static: false }) bodyContainer: ElementRef;
	// @ViewChild("footerContainer", { static: false }) footerContainer: ElementRef;
	menuOpenSub: Subscription;
	windowResizedSubscription: Subscription;
	// tslint:disable-next-line: max-line-length
	appPages: Array<{
		title: string;
		icon: string;
		prod: boolean;
		loading: boolean;
		tag: MenuTag;
		hide: boolean;
		extUrl?: string;
		url?: string;
		premium?:boolean
	}>;
	scroll: boolean;
	networkStatus: ConnectionStatus;
	networkSub: Subscription;
	constructor(
		public ttsService: PlayTTSService,
		public cabriService: CabriDataService,
		public globalService: GlobalService,
		public accountImplService: AccountService,
		private router: Router,
		private inAppBrowser: InAppBrowser,
		public networkService: NetworkService,
		public modalService: ModalsService,
		public audioService: AudioService
	) {
		this.environment = environment;
		this.subscribeToMenuOpen();
		this.initWindowResizeEventSubscription();
		if (environment.kidaia) {
			if (environment.ose) {
				this.appPages = [
					// OSE:
					{
						title: $localize`Accueil`,
						url: "/choix-activites",
						icon: "home",
						prod: true,
						loading: true,
						hide: false,
						tag: MenuTag.home
					},
					{
						title: $localize`Joueurs`,
						url: "/activity-participants",
						icon: "people",
						prod: true,
						loading: true,
						hide: false,
						tag: MenuTag.activityParticipants
					},
					{
						title: $localize`Vidéos`,
						extUrl: "https://kidaia.com/app",
						icon: "film",
						prod: true,
						loading: true,
						hide: false,
						tag: MenuTag.videos
					}
					// ,
					// {
					// 	title: $localize`Compte`,
					// 	extUrl: "https://kidaia.com/profile",
					// 	icon: "person",
					// 	prod: true,
					// 	loading: true,
					// 	hide: false,
					// 	tag: MenuTag.account
					// }
				];
			} else {
				this.appPages = [
					// KIDAIA:
					{
						title: $localize`Accueil`,
						url: (this.globalService.locale === AppLanguage.EN)?"/accueil":"/landing",
						icon: "home",
						prod: true,
						loading: true,
						hide: false,
						tag: MenuTag.home
					},
					{
						title: $localize`Vidéos`,
						extUrl: "https://kidaia.com/app",
						icon: "film",
						prod: true,
						loading: true,
						hide: false,
						tag: MenuTag.videos,
						premium: true
					},
					{
						title: $localize`Compte`,
						extUrl: `https://kidaia.com${this.globalService.locale === AppLanguage.EN ? '/my-account' : '/profile'}`,
						icon: "person",
						prod: true,
						loading: true,
						hide: false,
						tag: MenuTag.account
					}
					// {
					//   title: "Politique de confidentialité",
					//   extUrl: "politique",
					//   icon: "book"
					// },
					// {
					//   title: "Conditions générales",
					//   extUrl: "cgu",
					//   icon: "book"
					// },
					// {
					//   title: "Mentions légales",
					//   extUrl: "legal",
					//   icon: "book"
					// },
					// {
					//   title: "Gestion des joueurs",
					//   url: "/activity-participants",
					//   icon: "people"
					// }
				];
			}
		} else {
			// MATHIA
			this.appPages = [
				{
					title: $localize`Accueil`,
					url: "/accueil",
					icon: "home",
					prod: true,
					loading: true,
					hide: false,
					tag: MenuTag.home
				},
				{
					title: $localize`Activités`,
					url: "/gabarits",
					icon: "rocket",
					prod: true,
					loading: true,
					hide: false,
					tag: MenuTag.activities
				},
				{
					title: $localize`Joueurs`,
					url: "/activity-participants",
					icon: "people",
					prod: true,
					loading: true,
					hide: false,
					tag: MenuTag.activityParticipants
				},
				{
					title: $localize`Code classe`,
					url: "/class-code-entry",
					icon: "school",
					prod: true,
					loading: false,
					hide: false,
					tag: MenuTag.codeclass
				}
				// {
				//   title: "Politique de confidentialité",
				//   extUrl: "rgpd",
				//   icon: "link"
				// },

				// DEV

				// {
				//   title: "STT",
				//   url: "/test-stt",
				//   icon: "mic",
				//   prod: false
				// },
				// {
				//   title: "TTS",
				//   url: "/test-tts",
				//   icon: "chatbubbles",
				//   prod: false
				// },
				// {
				//   title: "OCR",
				//   url: "/ocr",
				//   icon: "home",
				//   prod: false
				// },
				// {
				//   title: "Calcul",
				//   url: "/cabri",
				//   icon: "calculator",
				//   loading: true
				// },
				// {
				//   title: "Furet",
				//   url: "/jeudufuret",
				//   icon: "rocket",
				//   loading: true
				// },
				// {
				//   title: "Kim",
				//   url: "/jeu-de-kim",
				//   icon: "cube",
				//   loading: true
				// },
				// {
				//   title: "Heure",
				//   url: "/heure",
				//   icon: "time",
				//   loading: true
				// },
				// {
				//   title: "Galaxie",
				//   url: "/galaxiedescalculs",
				//   icon: "planet",
				//   loading: true
				// },
				// {
				//   title: "Découverte solides",
				//   url: "/solides",
				//   icon: "cube",
				//   loading: true
				// },
				// {
				//   title: "Controle à distance",
				//   url: "/remote-control",
				//   icon: "game-controller",
				// },
				// {
				//   title: "Mon enregistrement",
				//   url: "/record",
				//   icon: "contact"
				// },
				// {
				//   title: "Ressources",
				//   url: "/ressources",
				//   icon: "globe"
				// },
				// {
				//   title: "Actualités",
				//   url: "/news",
				//   icon: "clipboard"
				// },
				// {
				//   title: "Newsletter",
				//   url: "/newsletter",
				//   icon: "paper"
				// },
				// {
				//   title: "Donner son avis",
				//   url: "/feedback",
				//   icon: "chatboxes"
				// },
				// {
				//   title: "Parents",
				//   url: "/parents",
				//   icon: "person"
				// },
				// {
				//   title: "Déconnexion",
				//   url: "/logout",
				//   icon: "key"
				// }
				// {
				//   title: "Gérer classe",
				//   url: "/manage-class",
				//   icon: "build"
				// },
				// {
				//   title: "Mon profil",
				//   url: "/profile",
				//   icon: "contact"
				// },
			];
		}

		this.hideMenuElements();
	}

	hideMenuElements() {
		if (this.globalService.locale === AppLanguage.EN) {
			this.appPages.forEach(menu => {
				if (menu.tag === MenuTag.videos) {
					menu.hide = true;
				}
			});
		}
	}

	ngOnInit() {
		this.networkSub = this.networkService.status.subscribe(value => {
			this.networkStatus = value;
		});
	}

	unsubscribeNetworkStatus() {
		this.networkSub.unsubscribe();
	}

	setScroll(value: boolean) {
		this.scroll = value;
	}

	closeMenu(): Promise<void> {
		return new Promise(resolve => {
			this.globalService.menuOpenEvent.next({ open: false, target: MenuEvents.Burger, redirecting: true });
			this.globalService.leftMenuStatus = false;
			setTimeout(() => {
				resolve();
			}, 200);
		});
	}

	async btnClick(url?: string, loading?: boolean, extUrl?: string, title?: string) {
		if (url && this.router.url === url) {
			await this.closeMenu();
		}
		if (url && this.router.url !== url) {
			if (
				!(title === "Vidéos" && !this.globalService.isIos && !this.globalService.isTablet && !this.globalService.lowPerformanceMode)
			) {
				await this.closeMenu();
			}

			if ((url === "/accueil" || url === "/gabarits" || url === "/activity-participants") && this.accountImplService.team.length === 0) {
				// TODO modal / guard to say "choose a player first" ?
				if (this.accountImplService.user.codeClasse || environment.kidaia) {
					url = "/activity-participants";
				} else {
					url = "/class-code-entry";
				}
				loading = false;
			} else if (url === "/accueil" && (!this.accountImplService.isRealClassConnected || this.accountImplService.team.length > 1)) {
				if (this.accountImplService.user.codeClasse || environment.kidaia) {
					url = "/activity-participants";
				} else {
					url = "/class-code-entry";
				}
				loading = false;
			}
			if (loading) {
				this.globalService.setGlobalLoading(true);
			}
			this.router.navigateByUrl(url, { replaceUrl: true });
		} else if (title === "Vidéos" && !environment.ose && !this.globalService.isPageOse && !this.globalService.isIos  && !this.globalService.lowPerformanceMode) {
			// open video modal in place of redirection to kidaia/video if not on iPads
			this.accountImplService.canAccessPremium('video', ()=>{
				this.audioService.toggleMusicPlayback(false);
				this.modalService.popVideoModal().then(() => {
					this.audioService.toggleMusicPlayback(true);
				});
			})
		} else if (title === "Vidéos") {
			url = "/videos";
			this.closeMenu();
			this.accountImplService.canAccessPremium('video',()=>{
				this.globalService
				.simpleAlert($localize`Quitter la page ?`, [
					{ text: $localize`Non`, value: false, cssButton: "redButtonCustomAlert" },
					{ text: $localize`Oui`, value: true, cssButton: "greenButtonCustomAlert" }					
				],"customAlert")
				.then(result => {
					if (result) {
						this.router.navigateByUrl(url, { replaceUrl: true });
					}
				});
			});

		} else if (extUrl) {
			if (loading) {
				this.globalService.setGlobalLoading(true);
			}
			// ?
			this.inAppBrowser.create(extUrl, "_self", "location=false");
		}
	}

	onWindowResized() {
		this.setMenuHeight();
	}

	setMenuHeight() {
		return;
		// const windowHeight = window.innerHeight;
		// const availableHeight = windowHeight - this.globalService.toolbarHeight;
		// const availableForBody = availableHeight - 741;
		// let footerHeight: number;
		// let bodyHeight: number;
		// if (availableForBody >= 0) {
		//   bodyHeight = availableForBody;
		//   footerHeight = 741;
		// } else if (availableForBody > -320) {
		//   bodyHeight = 0;
		//   footerHeight = 741 - availableForBody;
		// } else {
		//   bodyHeight = 0;
		//   footerHeight = 421;
		// }
		// console.error("bodyHeight = ", bodyHeight);
		// console.error("footerHeight = ", footerHeight);
		// this.footerContainer.nativeElement.style.setProperty("height", footerHeight + "px");
		// this.bodyContainer.nativeElement.style.setProperty("height", bodyHeight + "px");
	}

	isSet(obj: any) {
		return typeof obj !== "undefined";
	}

	ngOnDestroy() {
		this.unsubscribeWindowResizeEventSubscription();
		this.unsubscribeNetworkStatus();
		this.unsubscribeToMenuOpen();
	}

	// subscriptions:
	initWindowResizeEventSubscription() {
		console.log("%cinitWindowResizeEventSubscription", "background: yellow; color: black");
		this.unsubscribeWindowResizeEventSubscription();
		this.windowResizedSubscription = this.globalService.windowResizedEvent.subscribe({
			next: () => {
				console.log("resize event");
				// this.onWindowResized();
			}
		});
	}
	unsubscribeWindowResizeEventSubscription() {
		if (this.windowResizedSubscription) {
			this.windowResizedSubscription.unsubscribe();
			this.windowResizedSubscription = null;
		}
	}

	subscribeToMenuOpen() {
		this.unsubscribeToMenuOpen();
		this.menuOpenSub = this.globalService.menuOpenEvent.subscribe(status => {
			if (status.target === MenuEvents.Burger && status.open) {
				this.setMenuHeight();
			}
		});
	}
	unsubscribeToMenuOpen() {
		if (this.menuOpenSub) {
			this.menuOpenSub.unsubscribe();
			this.menuOpenSub = null;
		}
	}
}
