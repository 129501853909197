import { environment } from "./../../../environments/environment";
import { ScenarioOseBubble } from "./../../models/scenario-ose-bubble";
import { AccountImplService } from "./../../services/account.Impl.service";
import { Router } from "@angular/router";
import { ChangeDetectorRef, Renderer2 } from "@angular/core";
import { PlayTTSService } from "src/app/services/play-tts.service";
import { Component, Input, OnInit } from "@angular/core";
import { GlobalService } from "src/app/services/global.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { AccountService } from "src/app/services/account.service";

@Component({
	selector: "app-speech-bubble",
	templateUrl: "./speech-bubble.component.html",
	styleUrls: ["./speech-bubble.component.scss"]
})
export class SpeechBubbleComponent implements OnInit {
	@Input() bubbleTextContent: string;
	public scenario: ScenarioOseBubble;
	public displayBubble: boolean;
	environment: {
		production: boolean;
		activityVersion: number;
		storyVersion: number;
		kidaia: boolean;
		bearerToken: string;
		ose: boolean;
		autolog: boolean;
		login: any;
		password: any;
	};
	constructor(
		public globalService: GlobalService,
		public ttsService: PlayTTSService,
		public cd: ChangeDetectorRef,
		public router: Router,
		public accountService: AccountService,
		public renderer: Renderer2,
		public oseJourneyService: OseJourneyService
	) {
		this.globalService.speechBubbleComponent = this;
		// security -> no need to create a scenario beacause it's mde by page in order to have one instance of it
		this.scenario = new ScenarioOseBubble(accountService, globalService, oseJourneyService, cd, this.ttsService);
		this.environment = environment;
	}

	ngOnInit() {}

	async displayGlobalBubble(value: boolean) {
		if (!value && this.ttsService.protectedTTSisPlaying) {
			await this.scenario.skipMathiaSpeechSequence(true);
		}
		this.displayBubble = value;
		this.cd.detectChanges();
	}

	injectTextToBubble(phrase?: string) {
		this.bubbleTextContent = phrase;
		this.cd.detectChanges();
	}

	async skip() {
		await this.scenario.skipMathiaSpeechSequence(true);
	}

	async ngOnDestroy() {
		await this.scenario.skipMathiaSpeechSequence(true);
	}
}
