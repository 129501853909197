import { Injectable } from "@angular/core";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { ToastController, Platform, AlertController } from "@ionic/angular";
import { CabriDataService } from "./cabri-data.service";
import { Router } from "@angular/router";
import { Storage } from "@ionic/storage-angular";
import { LrsService } from "./lrs.service";
import { environment } from "src/environments/environment";
import { LmsService } from "./lms.service";
import { NetworkService } from "./network.service";
import { LocalStorageService } from "./local-storage-service";
import { AccountService } from "./account.service";
import { GlobalService } from "./global.service";

export enum ConnectionStatus {
	Online,
	Offline
}

@Injectable({
	providedIn: "root"
})
export class ConnectionStatusUpdaterService {
	// Autorization to store data
	static needStoreAllJourneys = true;
	static needStoreAssignations = true;
	toast: any;
	public rgpdUpdated = false;
	private alreadyConnected = false;
	public alreadyDeconnected = false;
	private firstTime: boolean;
	private appComponent: any;
	public permissionWrite: BehaviorSubject<any> = new BehaviorSubject(false);
	environment: any;
	public connectedStatus: boolean;

	constructor(
		private network: Network,
		private router: Router,
		private toastController: ToastController,
		private plt: Platform,
		public alertController: AlertController,
		public networkService: NetworkService,
		private accountService: AccountService,
		public lrsService: LrsService,
		public lmsService: LmsService,
		public cabriDataService: CabriDataService,
		public globalService: GlobalService
	) {
		this.environment = environment;
		this.plt.ready().then(() => {
			this.subscribeNetworkEvent();
		});
	}

	public subscribeNetworkEvent() {
		this.networkService.status.subscribe((connectionStatus: ConnectionStatus) => {
			if (connectionStatus === ConnectionStatus.Offline) {
				this.alreadyDeconnected = true;
				this.actionsWithoutConnexion();
				this.globalService.loadOcrModel().subscribe(model => {
					console.log("Ocr model Loaded");
				});
			} else {
				this.actionsWithConnexion();
				this.alreadyConnected = true;
				if (this.toast) {
					this.toastController.dismiss();
				}
			}
			if (!this.firstTime) {
				this.firstTime = true;
			} else {
				this.accountService.recoverStudentsInfos();
			}
		});
	}

	actionsWithConnexion() {
		if (this.alreadyDeconnected) {
			this.lmsService.allActivitiesLoaded = new ReplaySubject(1);
			this.lmsService.modalStudentJourneys = false;
			this.cabriDataService.getAllActivities();
		}
		this.lmsService.storedJourneyStatementLoad = false;
		this.lrsService.sendOfflineStatements();
	}

	actionsWithoutConnexion() {
		this.lmsService.storedJourneyStatementLoad = false;
		this.cabriDataService.getAllActivities();
		if (this.router.url === "/lecture" || this.router.url.startsWith("/record")) {
			this.router.navigateByUrl("/gabarits");
		}
	}
}
