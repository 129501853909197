import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { Chapter, systemName } from "src/app/models/chapter";
import { Story } from "src/app/models/story";
import { AccountService } from "src/app/services/account.service";
import { AudioService } from "src/app/services/audio.service";
import { GlobalService } from "src/app/services/global.service";
import { NetworkService } from "src/app/services/network.service";
import { StoryService } from "src/app/services/story.service";

export enum svgTags {
	planet = "eachPlanet",
	padlock = "padlock",
	path = "path"
}

@Component({
	selector: "app-galaxy-map",
	templateUrl: "./galaxy-map.component.html",
	styleUrls: ["./galaxy-map.component.scss"]
})
export class GalaxyMapComponent implements OnInit {
	@Input() storys: Story[];
	currentChapter: Chapter;
	worldMaps = {};
	displayContent = false;
	currentUserWorld: { index: number; systemName: systemName; currentChapter: Chapter[] };
	unlockedPlanetsAnimHandler: Array<Animation> = new Array();
	allPlanetsUnlocked = false;

	@HostListener("window:keyup", ["$event"])
	keyEvent(event: KeyboardEvent) {
		// Change map
		if (event.key === "ArrowRight") {
			this.goRight();
		} else if (event.key === "ArrowLeft") {
			this.goLeft();
		} else if (event.key === "Enter") {
			this.redirectToMap();
		}
	}

	constructor(
		public renderer: Renderer2,
		public el: ElementRef,
		public router: Router,
		public globalService: GlobalService,
		public madalController: ModalController,
		public accountService: AccountService,
		public storyService: StoryService,
		public networkService: NetworkService,
		public audioService: AudioService
	) {}

	async ngOnInit() {
		this.currentChapter = this.accountService.user.currentStory.getCurrentChapter();
		this.displayContent = false;
		this.displayContent = true;
		this.worldMaps = this.getAllWorldsMaps();
		this.currentUserWorld = this.getCurrentUserWorld(this.currentChapter?.systemName);
		this.allPlanetsUnlocked = this.checkStoryCompleted();
		this.unlockChapters();
	}

	/**
	 * Check if story is completed in order to determine if pulse animation is needed to be displayed too the current planet
	 */
	checkStoryCompleted() {
		let allUnlocked = false;
		this.accountService.user.storys.forEach(story => {
			allUnlocked = story.chapters.every(chapter => {
				return chapter.unlocked;
			});
			if (allUnlocked) {
				allUnlocked = this.currentChapter.maxExercices === this.currentChapter.exercicesChapterHistory?.length;
			}
		});
		return allUnlocked;
	}

	/**
	 * Unlock planets with padlock according if chapter had been unlocked
	 */
	async unlockChapters() {
		await this.timeOut(200);
		const currentPlanet = Array.from(
			this.el.nativeElement.querySelectorAll(`.${this.currentUserWorld.systemName} [data-item="${svgTags.planet}"`)
		);

		let allPadlings = Array.from(
			this.el.nativeElement.querySelectorAll(`.${this.currentUserWorld.systemName} [data-item="${svgTags.padlock}"`)
		);
		if (this.currentUserWorld.systemName === systemName.tellurique) {
			allPadlings = allPadlings.reverse();
		}

		const unlockedMaps = this.worldMaps[this.currentUserWorld.systemName].filter((chapter: Chapter) => {
			return chapter.unlocked;
		});

		// current selecte planet
		const currentChapterSelected = currentPlanet.find((planet: HTMLBaseElement) => {
			return planet.classList.contains("currentChapter");
		}) as HTMLBaseElement;

		if (currentChapterSelected && !currentChapterSelected.getAttribute("unlocked")) {
			currentChapterSelected.classList.remove("currentChapter");
		}
		// planet to be selected
		const currentChapterPlanet = currentPlanet.find((planet: HTMLBaseElement) => {
			return planet.dataset.planet === this.currentChapter?.planet;
		}) as HTMLBaseElement;
		const items = currentPlanet.filter((planet: HTMLBaseElement) => {
			return unlockedMaps.some(unlocked => {
				return planet.dataset.planet === unlocked.planet;
			});
		});

		for (let index = 0; index <= items.length - 1; index++) {
			const planet = items[index] as HTMLBaseElement;
			if (planet.getAttribute("unlocked")) {
				continue;
			}

			if (!planet.hasAttribute("no-clickable")) {
				// add click events on each map
				this.redirectToMapHandler(planet);
				if (planet.tagName === "image") {
					// movement of all planets
					this.manageUnlockedPlanetsMovement(planet);
				}
			}

			if (!planet.hasAttribute("sync-anim")) {
				await this.timeOut(350);
			}
			if (allPadlings[index]) {
				// remove paddlings if unlocked
				(allPadlings[index] as HTMLBaseElement).setAttribute("data-visible", "false");
			}

			planet.setAttribute("filter", "");
			if (index === items.length - 1 && !this.allPlanetsUnlocked) {
				if (this.globalService.isIos) {
					if (currentChapterPlanet) {
						// current chapter animate with highlighting ios (filter property not supported)
						currentChapterPlanet.setAttribute("filter", "url(#dropShadow)");
					}
				} else {
					// current chapter animate with highlighting
					if (currentChapterPlanet && !currentChapterPlanet.classList.contains("currentChapter")) {
						currentChapterPlanet.classList.add("currentChapter");
						await this.timeOut(50);
						currentChapterPlanet.classList.add("selectedPlanet");
					}
				}
			}
		}
	}

	/**
	 * Add click element on each map
	 * @param planet SVG OR PNG
	 */
	redirectToMapHandler(planet: HTMLBaseElement) {
		planet.removeEventListener("click", this.redirectToMap.bind(this));

		if (planet.getAttribute("listener") !== "true") {
			planet.addEventListener("click", this.redirectToMap.bind(this));
		}
	}

	/**
	 * All unlocked planets animated
	 * P.S Not animated from css in order to not erase transform property of svg planets
	 */
	manageUnlockedPlanetsMovement(planet: HTMLBaseElement) {
		const getTransformValue = planet.getAttribute("transform") as any;
		const transformValue = getTransformValue ? getTransformValue.replaceAll(" ", ", ") : "";
		const attributeUnlocked = document.createAttribute("unlocked");
		attributeUnlocked.value = "true";
		planet.setAttributeNode(attributeUnlocked);
		const currPlanetAnim = planet.animate(
			[
				{ transform: `${transformValue}translateY(0px)` },
				{ transform: `${transformValue}translateY(25px)` },
				{ transform: `${transformValue}translateY(0px)` }
			],
			{
				duration: 5700,
				iterations: Infinity
			}
		);
		this.unlockedPlanetsAnimHandler.push(currPlanetAnim);
	}

	async redirectToMap(event?: Event) {
		let redirectUrl;
		let state: { key: string; value: string };
		let targettedPlanet;
		if (event) {
			targettedPlanet = (event.target as HTMLBaseElement).dataset.planet;
		} else {
			targettedPlanet = this.currentChapter?.planet;
		}

		this.unlockedPlanetsAnimHandler.forEach((eachAnim: Animation) => {
			eachAnim.pause();
		});
		if (this.networkService.connectedStatus) {
			if (this.checkCurrentChapterPlanetSelected(targettedPlanet)) {
				const chapter = this.accountService.user.currentStory.getCurrentChapterByPlanetName(targettedPlanet);
				this.accountService.user.currentChapter = chapter;
				redirectUrl = "narration";
				state = { key: "narration", value: chapter ? chapter.introduction : null };
			} else {
				redirectUrl = "map";
				this.accountService.user.currentChapter =
					this.accountService.user.currentStory.getCurrentChapterByPlanetName(targettedPlanet);
			}
			this.audioService.toggleMusicPlayback(false);
			this.audioService.playLaunchSound();
			this.router
				.navigateByUrl(`/${redirectUrl}`, {
					state
				})
				.then(() => {
					this.globalService.setGlobalLoading(true);
					if (this.madalController) {
						this.madalController.dismiss();
					}
				});
		} else {
			if (this.madalController) {
				this.madalController.dismiss();
			}
		}
	}

	/**
	 * Verify if the planet selected is the user last planet
	 */
	public checkCurrentChapterPlanetSelected(targettedPlanet): boolean {
		return this.currentChapter?.planet === targettedPlanet;
	}

	/**
	 * Get user current world index
	 */
	getCurrentUserWorld(currentSystemName: string) {
		let currentWorld;
		if (currentSystemName === systemName.tellurique) {
			currentWorld = this.getCurrentUserWorldInfos(0, systemName.tellurique);
		} else if (currentSystemName === systemName.cassiopee) {
			currentWorld = this.getCurrentUserWorldInfos(1, systemName.cassiopee);
		} else if (currentSystemName === systemName.enfant) {
			currentWorld = this.getCurrentUserWorldInfos(2, systemName.enfant);
		} else if (currentSystemName === systemName.olympe) {
			currentWorld = this.getCurrentUserWorldInfos(3, systemName.olympe);
		} else if (currentSystemName === systemName.aeriens) {
			currentWorld = this.getCurrentUserWorldInfos(4, systemName.aeriens);
		} else if (currentSystemName === systemName.galaxie7) {
			currentWorld = this.getCurrentUserWorldInfos(5, systemName.galaxie7);
		} else if (currentSystemName === systemName.etape3) {
			currentWorld = this.getCurrentUserWorldInfos(6, systemName.etape3);
		} else if (currentSystemName === systemName.galaxie8) {
			currentWorld = this.getCurrentUserWorldInfos(7, systemName.galaxie8);
		} else if (currentSystemName === systemName.etape4) {
			currentWorld = this.getCurrentUserWorldInfos(8, systemName.etape4);
		} else if (currentSystemName === systemName.galaxie9) {
			currentWorld = this.getCurrentUserWorldInfos(9, systemName.galaxie9);
		} else if (currentSystemName === systemName.etape5) {
			currentWorld = this.getCurrentUserWorldInfos(10, systemName.etape5);
		} else if (currentSystemName === systemName.galaxie10) {
			currentWorld = this.getCurrentUserWorldInfos(11, systemName.galaxie10);
		} else {
			currentWorld = this.getCurrentUserWorldInfos(0, systemName.tellurique);
		}

		return currentWorld;
	}

	/**
	 * Total worlds indexes
	 */
	get totalIndex() {
		return Object.keys(this.worldMaps).length - 1;
	}

	/**
	 * Get world maps
	 */
	getAllWorldsMaps() {
		const worldMaps = new Array();
		this.storys.forEach((story: Story) => {
			story.chapters.forEach((chapter: Chapter) => {
				if (chapter.systemName) {
					if (!worldMaps.hasOwnProperty(chapter.systemName)) {
						worldMaps[chapter.systemName] = [chapter];
					} else {
						worldMaps[chapter.systemName].push(chapter);
					}
				}
			});
		});

		return worldMaps;
	}

	/**
	 * Access from HTML
	 */
	get systemName() {
		return systemName;
	}

	goRight() {
		this.getCurrentWorld(this.currentUserWorld.index + 1, true);
		this.unlockChapters();
	}

	goLeft() {
		this.getCurrentWorld(this.currentUserWorld.index - 1, false);
		this.unlockChapters();
	}

	/**
	 * Get current system
	 */
	getCurrentWorld(nextIndexWorld: number, next: boolean) {
		if (next) {
			if (nextIndexWorld > this.totalIndex) {
				nextIndexWorld = 0;
			}
		} else {
			if (nextIndexWorld < 0) {
				nextIndexWorld = this.totalIndex;
			}
		}

		const getNextKey = Object.keys(this.worldMaps)[nextIndexWorld];
		if (getNextKey === systemName.tellurique) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(0, systemName.tellurique);
		} else if (getNextKey === systemName.cassiopee) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(1, systemName.cassiopee);
		} else if (getNextKey === systemName.enfant) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(2, systemName.enfant);
		} else if (getNextKey === systemName.olympe) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(3, systemName.olympe);
		} else if (getNextKey === systemName.aeriens) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(4, systemName.aeriens);
		} else if (getNextKey === systemName.galaxie7) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(5, systemName.galaxie7);
		} else if (getNextKey === systemName.etape3) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(6, systemName.etape3);
		} else if (getNextKey === systemName.galaxie8) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(7, systemName.galaxie8);
		} else if (getNextKey === systemName.etape4) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(8, systemName.etape4);
		} else if (getNextKey === systemName.galaxie9) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(9, systemName.galaxie9);
		} else if (getNextKey === systemName.etape5) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(10, systemName.etape5);
		} else if (getNextKey === systemName.galaxie10) {
			this.currentUserWorld = this.getCurrentUserWorldInfos(11, systemName.galaxie10);
		}
	}

	getCurrentUserWorldInfos(index: number, currentSystemName: systemName) {
		let title = "";
		if (currentSystemName === systemName.tellurique) {
			title = $localize`Les mondes Telluriques`;
		} else if (currentSystemName === systemName.cassiopee || currentSystemName === systemName.olympe) {
			title = $localize`Cassiopée`;
		} else if (currentSystemName === systemName.aeriens) {
			title = $localize`Les mondes Aériens`;
		} else if (currentSystemName === systemName.enfant) {
			title = $localize`Les mondes de Cosinus`;
		} else if (currentSystemName === systemName.galaxie7) {
			title = $localize`Monde`;
		} else if (currentSystemName === systemName.etape3) {
			title = $localize`Monde`;
		} else if (currentSystemName === systemName.galaxie8) {
			title = $localize`Monde`;
		} else if (currentSystemName === systemName.etape4) {
			title = $localize`Monde`;
		} else if (currentSystemName === systemName.galaxie9) {
			title = $localize`Monde`;
		} else if (currentSystemName === systemName.etape5) {
			title = $localize`Monde`;
		} else if (currentSystemName === systemName.galaxie10) {
			title = $localize`Monde`;
		}
		return { index, systemName: currentSystemName, currentChapter: this.worldMaps[currentSystemName], title };
	}

	/**
	 * Custom async setTimeOut for waiting end of exe
	 */
	async timeOut(ms): Promise<void> {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, ms);
		});
	}
}
