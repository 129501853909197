import { AppUtils } from './../app-utils';
import { Platform } from "@ionic/angular";
import { CabriDataService } from "../services/cabri-data.service";
import { get } from "scriptjs";
import { AppComponent } from "../app.component";
import postal from "postal";
import { environment } from "src/environments/environment";
import { Engine } from "babylon4.1";
import { GlobalService } from "../services/global.service";

declare var window: {
	store: any;
	document: any;
	weareready: any;
	postal: any;
	cabriTS: any;
	Globals: { FileToLoad: string; Debug: any; Holo: any; HoloFaces: any };
	runtimeInitialized: any;
	params: any;
};

export class CabriLoader {
	private engine: Engine;

	private scriptsCabri = [
		"assets/js/opencv4.js",
		"assets/cabri2/lib/jquery-3.1.1/jquery-3.5.1.min.js",
		//   not necessary?:
		"assets/cabri2/lib/jquery-ui-1.12.0.custom/jquery-ui.min.js",
		//   not necessary?:
		"assets/cabri2/lib/jquery.ui.touch-punch.js",
		"assets/cabri2/lib/algebrite.bundle-for-browser.js",
		"assets/cabri2/lib/encoding.js",
		"assets/cabri2/lib/encoding-indexes.js",
		//   not necessary?:
		"assets/cabri2/lib/fastclick.min.js",
		"assets/cabri2/lib/jszip.js",
		//   not necessary?:
		"assets/cabri2/lib/pep.js",
		//   not necessary?:
		"assets/cabri2/lib/simple-keyboard-layouts.js",
		"assets/cabri2/lib/tincan-min.js",
		//   not necessary?:
		"assets/cabri2/lib/web-animations.min.js",
		"assets/cabri2/custom1.js",
		"assets/cabri2/cabriassets.js",
		"assets/cabri2/emcc-out/cdk.js",
		"assets/cabri2/globals.js",
		"assets/cabri2/js/chunk-vendors" + (this.platform.is("ios") ? "-ios" : "") + ".js"
	];

	public loadingEnded = false;
	postalSub: any;
	constructor(
		public cabriService: CabriDataService,
		public platform: Platform,
		public appComponent: AppComponent,
		public globalService: GlobalService
	) {
		window.postal = postal;
	}
	private resolveBabylonJS;
	loadBabylonJS() {
		return new Promise<void>(resolve => {
			this.resolveBabylonJS = resolve;
			if(!environment.ose){
				const index = 0;
				this.recurringLoadScript(index);
			} else {
				window.document.querySelector("#cabriHidden").appendChild(window.document.querySelector("#CabriMainWrapper"));
				this.loadingEnded = true;
				this.platform.ready().then(() => {
					resolve();
				});
			}
		});
	}
	recurringLoadScript(index: number) {
		get(this.scriptsCabri[index], () => {
			const nbScripts = this.scriptsCabri.length;
			index++;
			if (index < nbScripts) {
				this.appComponent.tempProgress = (index / (nbScripts - 1)) * 100; // progressbar
				setTimeout(() => {
					this.recurringLoadScript(index); // load next script
				}, 100);
			} else {
				// missing cabri variable
				window.Globals.Debug = new Array();
				//
				// end of loading script
				//
				this.postalSub = postal.subscribe({
					channel: "electron",
					topic: "babylone.ready",
					callback: async (data, envelope) => {
						this.postalSub.unsubscribe();
						this.postalSub = null;
						this.endOfLoading();
					}
				});

				// wait webpack end of loading for slow device
				const waitWebPack = setInterval(() => {
					console.log("wait runtime initialized");
					if (window.runtimeInitialized) {
						clearInterval(waitWebPack);
						// load cabri APP
						get("assets/cabri2/js/app.js", () => {
							// function "weareready" are not directly accessible
							const waitCabri = setInterval(() => {
								try {
									if (typeof window.weareready !== "undefined") {
										clearInterval(waitCabri);
										let holoMode = localStorage.getItem("holoMode");
										const savedActivity = JSON.parse(localStorage.getItem("savedActivity"));
										if (!holoMode && history?.state?.navigationId === 1 && this.globalService.onActivityPage) {
											// when not passed from gabarits (reload page)
											if (savedActivity?._params) {
												savedActivity._params.find(item => {
													if (item.name === "holo") {
														holoMode = item.value;
													}
												});
											}
										}

										if (savedActivity && savedActivity.id) {
											if (savedActivity.id === "8") {
												// solides vue différentes sur les quatre faces
												window.Globals.HoloFaces = 4;
											} else {
												window.Globals.HoloFaces = 1;
											}
										}

										localStorage.removeItem("holoMode");
										if (holoMode === "1") {
											window.Globals.Holo = 1;
											window.params.append("holo", "1");
										} else if (holoMode === "-1") {
											window.Globals.Holo = -1;
											window.params.append("holo", "-1");
										} else {
											// window.Globals.Holo = 0;
											window.params.append("holo", "0");
										}
										window.Globals.FileToLoad = "assets/cabri2/default.clmc";
										window.params.append("clmc", "assets/cabri2/default.clmc");
										window.weareready();
									}
								} catch (error) {
									// console.error(error);
									throw error;
								}
							}, 300);
						});
					}
				}, 300);
			}
		});
	}
	endOfLoading() {
		window.store.getters.cabri.Cps.setActiveState(false);
		this.globalService.webGl1 = window.store.getters.cabri.Engine.webGLVersion === 1;
		if (this.globalService.webGl1) {
			this.globalService._lowPerformanceMode = true;
			this.globalService.lowPerformanceModeSetStorageValue();
		}
		// AppUtils.debug("window.store.getters.cabri.engine.webGLVersion = ", window.store.getters.cabri.Engine.webGLVersion);
		this.stopRender(true);
		this.cabriService.savedDomMirrorMode = false;
		this.cabriService.savedDomHoloMode = "0";
		this.cabriService.currentSavedActivityId = "default";
		window.document.querySelector("#cabriHidden").appendChild(window.document.querySelector("#CabriMainWrapper"));
		this.loadingEnded = true;
		environment.kidaia ? (window.document.title = "Kidaia") : (window.document.title = "Mathia");
		this.resolveBabylonJS();
	}
	stopRender(stop = null) {
		window.store.getters.cabri.Engine.stopRenderLoop();
	}
	waitEndOfLoading(): Promise<void> {
		return new Promise(async resolve => {
			await Promise.all([this.waitCabri(), this.waitPlatform()]);
			resolve();
		});
	}
	waitCabri(): Promise<void> {
		return new Promise(resolve => {
			const waitEndOfLoading = setInterval(() => {
				// console.error(this.loadingEnded);
				if (this.loadingEnded) {
					clearInterval(waitEndOfLoading);
					resolve();
				}
			}, 100);
		});
	}
	waitPlatform(): Promise<void> {
		return new Promise(resolve => {
			this.platform.ready().then(() => {
				resolve();
			});
		});
	}
}
