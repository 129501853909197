export class OseActivity {
	id: number;
	title: string;
	type: string;
	icon?:string
	completed? = false; // current session
	alreadyCompleted? = false; // completed at least one time
	selected? = false;
	hasvideo?;
	name?:string;
	typeNameFr?:string;
	isworkshop?:string;
	isevaluation?:string;
	isEtape?:boolean;
	titre_sommaire?:string;
	displayInModal?:boolean;
	subpart?:{hasVideo:boolean, hasGame:boolean, hasQuiz:boolean};
	hasSubpart? = false;
}
