<div class="toolbarContainerGhost" *ngIf="ghost"></div>

<div class="toolbarContainer"
    [ngClass]="{'toolbarContainerHolo' : (cabriService.holoMode === '-1' || cabriService.holoMode === '1') && globalService.onActivityPage && !globalService.isKidaia,  'opak' : opacity, 'toolbarContainerOse' : globalService.isOse}">

    <div class="menuContainer">

        <!-- ICON CONTAINER LEFT -->
        <div class="leftIconsContainer"
            [ngClass]="{'leftIconsContainerMobile' : globalService.isMobile && !globalService.isKidaia, 'leftIconsContainerKidaia' : globalService.isKidaia}">

            <!-- Menu V2 Mathia -->
            <div *ngIf="!globalService.inIframe && !globalService.isKidaia" class="iconSlotMenu pointer"
                [ngClass]="globalService.isMobile? 'iconSlotMenuMobile' : ''" (click)="displayLeftMenu()">
                <ion-icon name="menu" class="menuIcon" color="secondary"></ion-icon>
            </div>

            <!-- Menu V2 Kidaia -->
            <div class="pointer" *ngIf="globalService.isKidaia"
                [ngClass]="[progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar iconSlotCenterMarginKidaia' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar']"
                (click)="displayLeftMenu()">
                <div class="homeIconKidaia iconDefault iconOnTop"
                    [ngClass]="[progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot']"></div>
            </div>

            
            <div class="closeJourney" (click)="oseJourneyService.quitJourney()" *ngIf="(globalService.isOse || globalService.isPageOse) && oseJourneyService.isPlaying"
            [ngClass]="[globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar iconSlotCenterMarginKidaia']">
                <ion-icon name="arrow-back-outline"></ion-icon>
           </div>

            <!-- KIDAIA title-->
            <div class="pointer" *ngIf="globalService.isKidaia"
                [ngClass]="[progressBar || globalService.smallHeightScreen || globalService.isMobile ? 'titleSlotKidaiaProgressBar' : 'titleSlotKidaiaNoPbar']"
                (click)="displayLeftMenu()">
                <div class="kidaiaTitle iconDefault"></div>
            </div>

            <!-- Back button -->
            <div *ngIf="!globalService.isMobile && !globalService.inIframe && !globalService.isKidaia"
                class="iconSlotBack">
                <div class="backIcon iconDefault pointer" (click)="back()"></div>
            </div>


            <!-- Back button for Android Mobiles -->
            <div *ngIf="globalService.isMobile && !globalService.isIos && !globalService.inIframe && !globalService.isKidaia"
                class="iconSlotBackAndroidMobile" (click)="back()">
                <div class="backIcon iconDefault"></div>
            </div>

            <!-- Back button for iPhones -->
            <div *ngIf="globalService.isMobile && globalService.isIos && !globalService.inIframe && !globalService.isKidaia"
                class="iconSlotBackIphones" (click)="back()">
                <div class="backIcon iconDefault"></div>
            </div>

            <!-- Home Button -->
            <div *ngIf="!globalService.isMobile && !globalService.onActivityParticipantsPage && !globalService.onAccueilPage && !globalService.onActivityPage && !globalService.isKidaia && !globalService.isOse"
                class="iconSlot pointer" (click)="home()">
                <div class="homeIcon iconDefault"></div>
            </div>

            <!-- Remote Button -->
            <div *ngIf="remote && accountImplService.user.codeClasse" (click)="switchRemoteHost()" class="iconSlot">
                <div class="iconDefault pointer"
                    [ngClass]="[remoteService.remoteHostActivate ? 'remoteIconON' : 'remoteIconOFF']"></div>
            </div>


            <div *ngIf="remoteService.remoteHostActivate && (cabriService.holoMode !== '-1' && cabriService.holoMode !== '1')"
                class="iconSlot">
                <app-avatar-bot [seed]="remoteService.uuid" class="iconDefault"></app-avatar-bot>
            </div>

            <div *ngIf="remoteService.remoteHostActivate && cabriService.holoMode !== '-1' && cabriService.holoMode !== '1'"
                class="hostText">
                {{remoteService.code}}
            </div>

            <div class="refreshIconContainer" *ngIf="!globalService.isKidaia && !globalService.isBeneylu">
                <ion-icon name="refresh-outline" (click)="refreshPage()" class="ion-icon-refresh pointer"></ion-icon>
            </div>

            <div class="iconInput iconNoWifiMathia" *ngIf="!globalService.isKidaia && !networkService.connectedStatus">
            </div>

            <!-- Git Data Button -->
            <div *ngIf="!environment.production || this.globalService.allowedUrl" class="git-data-slot">
                {{versions.revision}}@{{versions.branch}}:{{versions.version}}
            </div>
            <!-- Git Data Button -->
            <div *ngIf="!environment.production || this.globalService.allowedUrl" class="log-html-slot">
                <ion-icon name="document-text-outline"
                    (click)="window.document.querySelector('#log-html').style.display = 'block';"></ion-icon>
                <div id="log-html"
                    style="display: none; position: absolute; top:0; left:0; width:100vw;height:100vh;z-index:100000000;background-color:grey;overflow-y:scroll;text-align:left;">
                    <button onclick="window.document.querySelector('#log-html').style.display = 'none';"
                        style="">FERMER</button>
                </div>
            </div>
        </div>


        <!-- ICON CONTAINER CENTER -->
        <div [ngClass]="[globalService.isKidaia ? 'centerIconContainerKidaia' : 'centerIconContainer', globalService.displayVoiceSettingsStatus || displayVolume ? 'disableInputMethods' : '', !globalService.isKidaia && (globalService.smallHeightScreen || globalService.isMobile) ? 'centerIconContainerMathiaMobile' : '']"
            *ngIf="((activityName !== 'jeujustepoint' && (cabriService.holoMode !== '-1' && cabriService.holoMode !== '1')) || globalService.devActivateHoloToolbar)">
            <!-- Numpad Mathia -->

        <ng-container *ngIf="!globalService.isPageOse">

            <div class="iconSlotInputNumpad pointer"
                *ngIf="!globalService.isKidaia && !globalService.isOse && globalService.onActivityPage && cabriService.numpadAllowed"
                [ngClass]="globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotInputNumpadMarginMobile' : 'iconSlotInputNumpadMarginBrowser'"
                (click)="toggleCabriInputMethod('numpad')">
                <div class="iconInput" [ngClass]="[cabriService.cabriInputNumpadStatus || globalService.activateNumpadIcon? 'ModeNumpadIconOn' : 'ModeNumpadIconOff', globalService.smallHeightScreen || globalService.isMobile ? 'ModeNumpadIconOffMarginTopMobile' : 'ModeNumpadIconOffMarginTopBrowser',
                    !cabriService.numpadAllowed ? 'opacityNone' : '']">
                </div>
            </div>

            <!-- Numpad Kidaia -->
            <div *ngIf="globalService.isKidaia && !globalService.isOse && globalService.onActivityPage && cabriService.numpadAllowed"
                class="pointer"
                [ngClass]="[progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile iconSlotCenterMarginKidaia' : 'iconSlotKidaiaProgressBar iconSlotCenterMarginKidaia' : globalService.smallHeightScreen || globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar']"
                (click)="toggleCabriInputMethod('numpad')">
                <div
                    [ngClass]="[progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot', cabriService.cabriInputNumpadStatus || globalService.activateNumpadIcon? 'ModeNumpadIconOnKidaia' : 'ModeNumpadIconOffKidaia',!cabriService.numpadAllowed ? 'opacityNone' : '']">
                </div>
            </div>

            <!-- Mic Mathia -->
            <div class="iconSlotInputMic pointer"
                *ngIf="!globalService.isKidaia && !globalService.isOse && globalService.onActivityPage && cabriService.sttAllowed"
                [ngClass]="globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotInputMicMarginTopMobile' : 'iconSlotInputMicMarginTopBrowser'"
                (click)="toggleCabriInputMethod('stt')">
                <div *ngIf="(sttService.state.recording || globalService.fakeMicOn) && !this.globalService.micCheckRunning"
                    class="pulsating-circle" style="z-index: 2001"></div>
                <div *ngIf="(sttService.state.recording || globalService.fakeMicOn) && !this.globalService.micCheckRunning"
                    [ngClass]="globalService.smallHeightScreen || globalService.isMobile ? 'speakIconOnMobile' : 'speakIconOn'"
                    style="z-index: 2000"></div>
                <div *ngIf="(!sttService.state.recording && !globalService.fakeMicOn) || this.globalService.micCheckRunning"
                    [ngClass]="globalService.smallHeightScreen || globalService.isMobile ? 'speakIconOffMobile' : 'speakIconOff'">
                </div>
            </div>

            <!-- Mic Kidaia -->
            <div class="pointer"
                [ngClass]="[progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile iconSlotCenterMarginKidaia' : 'iconSlotKidaiaProgressBar iconSlotCenterMarginKidaia' : globalService.smallHeightScreen || globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar']"
                *ngIf="globalService.isKidaia && !globalService.isOse && globalService.onActivityPage && cabriService.sttAllowed"
                (click)="toggleCabriInputMethod('stt')">
                <div *ngIf="(sttService.state.recording || globalService.fakeMicOn) && !this.globalService.micCheckRunning"
                    class="pulsating-circle" style="z-index: 2001"></div>
                <div class="speakIconOnKidaia"
                    *ngIf="(sttService.state.recording || globalService.fakeMicOn) && !this.globalService.micCheckRunning"
                    [ngClass]="progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot'"
                    style="z-index: 2000"></div>
                <div class="speakIconOffKidaia"
                    *ngIf="(!sttService.state.recording && !globalService.fakeMicOn) || this.globalService.micCheckRunning"
                    [ngClass]="progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot'"></div>
            </div>

            <!-- Draw Mathia -->
            <div class="iconSlotInputDraw pointer"
                *ngIf="!globalService.isKidaia && !globalService.isOse && globalService.onActivityPage && cabriService.drawingAllowed"
                [ngClass]="globalService.smallHeightScreen || globalService.isMobile? 'iconSlotInputDrawMarginMobile' : 'iconSlotInputDrawMarginBrowser'"
                (click)="toggleCabriInputMethod('drawing')">
                <div class="iconInput"
                    [ngClass]="[cabriService.cabriInputDrawStatus? 'ModeDrawingIconOn' : 'ModeDrawingIconOff', globalService.smallHeightScreen || globalService.isMobile? 'ModeNumpadIconOffMarginTopMobile' : 'ModeNumpadIconOffMarginTopBrowser', activityName === 'jeudekim' || activityName === 'heure' || !cabriService.drawingAllowed ? 'opacityNone' : '']">
                </div>
            </div>

            <!-- Draw Kidaia -->
            <div class="pointer"
                *ngIf="globalService.isKidaia && !globalService.isOse && globalService.onActivityPage && cabriService.drawingAllowed"
                [ngClass]="[progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile iconSlotCenterMarginKidaia' : 'iconSlotKidaiaProgressBar iconSlotCenterMarginKidaia' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar']"
                (click)="toggleCabriInputMethod('drawing')">
                <div
                    [ngClass]="[progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot', cabriService.cabriInputDrawStatus? 'ModeDrawingIconOnKidaia' : 'ModeDrawingIconOffKidaia', activityName === 'jeudekim' || activityName === 'heure' ? 'opacityNone' : '']">
                </div>
            </div>
        </ng-container>

            <!-- Sounds ose -->
            <ng-container *ngIf="(environment.ose || globalService.isPageOse) || (environment.kidaia && !globalService.isPageOse && !globalService.onActivityPage) || (!environment.kidaia && !environment.ose && !globalService.onActivityPage)">
            <div class="iconOnTop"
                *ngIf="!(globalService.isMobile && !globalService.isLandscape)"
                [ngClass]="[globalService.isOse || globalService.isKidaia ? 'isKidaia' : 'isMathia',globalService.isKidaia ? progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar' : 'iconSlotRight']">
                <div class="iconOnTop pointer musicIconEmptyFrameOse iconDefault" id="musicIcon"
                    [ngClass]="[ressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot', !globalService.muteSounds ? 'soundsOnIcon' : 'soundsOffIcon']"
                    (click)="globalService.toggleMuteSounds(!globalService.muteSounds)">
                </div>
            </div>

            <!-- Music ose -->
            <div class="iconOnTop"
                *ngIf="!(globalService.isMobile && !globalService.isLandscape)"
                [ngClass]="[globalService.isOse || globalService.isKidaia ? 'isKidaia' : 'isMathia',globalService.isKidaia ? progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar' : 'iconSlotRight']">
                <div class="iconOnTop pointer musicIconEmptyFrameOse iconDefault" id="musicIcon"
                    [ngClass]="[progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot', !globalService.muteMusicVolume && !audioService.audioLocked && audioService.music ? 'musicOnIcon' : 'musicOffIcon']"
                    (click)="audioService.toggleMuteMusic()">
                </div>
            </div>
        </ng-container>

        <!-- </ng-container> -->
        
        </div>

        <!-- ICON CONTAINER RIGHT -->
        <div
            [ngClass]="globalService.smallHeightScreen || globalService.isMobile? 'rightIconsContainerMobile' : !globalService.isLandscape? 'rightIconsContainerTabletPortrait' : 'rightIconsContainer'">

            <!-- Remote Connect -->

            <ng-container *ngIf="!globalService.isKidaia">

                <div class="iconSlotRight iconOnTop"
                    *ngIf="cabriService.holoMode !== '-1' && cabriService.holoMode !== '1' && remoteService.listRemoteHost && remoteService.listRemoteHost.length > 0 && !remoteService.remoteHostActivate">
                    <app-remote-connect></app-remote-connect>
                </div>

                <div *ngIf="remoteService.remoteHostActivate && (cabriService.holoMode === '-1' || cabriService.holoMode === '1')"
                    class="iconSlot">
                    <app-avatar-bot [seed]="remoteService.uuid" class="iconDefault"></app-avatar-bot>
                </div>
            </ng-container>

            <!-- KIDAIA playerName -> switch Player-->
            <ng-container *ngIf="globalService.isKidaia && !globalService.onActivityParticipantsPage">
                <div class="switchUserContainer pointer" (click)="displayUserSettings()">
                    <div class="studentDropDownContainer">
                        <div class="studentNameContainer"
                            *ngIf="studentsList && studentsList.length > 0 && accountService.team && accountService.team.length > 0 && !(globalService.smallHeightScreen || globalService.isMobile && !globalService.isMobileLandscape)">
                            <div
                                [ngClass]="[progressBar || globalService.smallHeightScreen || globalService.isMobile ? 'titleSlotKidaiaProgressBar playerNameSlotKidaiaFlexEnd' : 'titleSlotKidaiaNoPbar playerNameSlotKidaiaFlexEnd']">
                                <div class="kidaiaPlayerName iconDefault"
                                    [ngClass]="{'kidaiaPlayerNameMobile' : globalService.smallHeightScreen || globalService.isMobile}">
                                    {{accountService.team && accountService.team.length > 0 ?
                                    accountService.team[0].name :
                                    ''}}
                                </div>
                            </div>

                            <div class="dropDownStudentsList" *ngIf="studentsList && studentsList.length >  1">
                                <ion-icon name="chevron-forward-outline" class="iconDropDownStudentsList"
                                    [ngClass]="{'iconDropDownStudentsListOpened' : globalService.studentsMenuStatus}">
                                </ion-icon>
                            </div>
                        </div>

                        <div class="dropdownContainer"
                            [ngClass]="{'dropdownContainerMobileLandscape' : globalService.isMobile && globalService.isMobileLandscape}">
                            <div class="dropdownContent"
                                *ngIf="globalService.studentsMenuStatus && ((studentsList && studentsList.length > 0) || (!studentsList || studentsList && studentsList.length === 0))"
                                [ngClass]="{'dropdownContentMobile' : globalService.isMobile, 'dropdownContentMobilePortrait' : globalService.isMobile && !globalService.isLandscape}">
                                <ng-container
                                    *ngIf="studentsList && studentsList.length > 0 && accountService.team && accountService.team.length > 0">
                                    <ng-container *ngFor="let student of studentsList">
                                        <p class="dropdownEachStudentName pointer"
                                            (click)="globalService.onActivityPage ? alertKidaiaSwitchStudent(student, $event) : accountImplService.switchKidaiaCurrentUser(student, $event)">
                                            {{student.name}}</p>
                                    </ng-container>
                                </ng-container>
                                <p class="dropdownEachStudentName borderTop pointer"
                                    (click)="goActivityParticipants($event)" i18n>
                                    Gestion des joueurs</p>
                            </div>
                        </div>
                    </div>
                    <!-- HELMET ICON -> goTo Activity-participants -->
                    <div class="iconOnTop"
                        [ngClass]="[progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar']">
                        <div class="iconDefault helmetIcon iconOnTop"
                            [ngClass]="[progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot']"></div>
                    </div>
                </div>
            </ng-container>

            <!-- Fullscreen browser Mathia / Kidaia -->
            <div *ngIf="globalService.isBrowser  && !(globalService.isIos && !globalService.isDesktop) && !globalService.isBeneylu"
                class="iconOnTop pointer"
                [ngClass]="[globalService.isKidaia ? progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar' : 'iconSlotRight']"
                (click)="browserFullscreen()">
                <div class="iconOnTop "
                    [ngClass]="[globalService.isKidaia ? progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot' : globalService.isFullscreen ? 'iconDefault closeIconMathia' : 'iconDefault fullscreenIconMathia', 
                    globalService.isKidaia && globalService.isFullscreen ? 'closeIconKidaia' : globalService.isKidaia && !globalService.isFullscreen? 'fullscreenIconKidaia' : '']">
                </div>
            </div>

            <!-- toggles old native global volume slider
            NOT USED ANYMORE -> TODO REMOVE ? -->
            <!-- App Volume Mobile Android -->
            <!--
            <div *ngIf="!globalService.isIos && globalService.isCordova && !globalService.isDesktop && globalService.isMobile && (!remoteService.remoteHostActivate && cabriService.holoMode !== '-1' && cabriService.holoMode !== '1')"
                class="iconSlotVolumeMobileAndroid iconOnTop" (click)="displayVolumeSettings()">

                <div *ngIf="!globalService.displayVoiceSettingsStatus" class="iconDefault VolumeIcon iconOnTop"></div>

                <div *ngIf="displayVolume" class="VolumeContainer VolumeContainerMobile">
                    <ion-icon name="remove" size="large" class="volumeIconMinus iconOnTop"></ion-icon>
                    <input type="range" class="customSlider" [(ngModel)]="appVolume" (change)="setVolume()" min="0"
                        max="1" step="0.1" />
                    <ion-icon name="add" size="large" slot="icon-only" class="volumeIconPlus iconOnTop"></ion-icon>
                </div>
            </div>
            -->
            <!-- App Volume Tablet Android -->
            <!--
            <div class="iconSlotRight iconOnTop"
                *ngIf="!globalService.isMobile && !globalService.isIos && globalService.isTablet && globalService.isCordova && !(remoteService.remoteHostActivate && (cabriService.holoMode === '-1' || cabriService.holoMode === '1'))"
                (click)="displayVolumeSettings()">

                <div *ngIf="!globalService.displayVoiceSettingsStatus" class="iconDefault VolumeIcon iconOnTop"></div>

                <div *ngIf="displayVolume" class="VolumeContainer">
                    <ion-icon name="remove" size="large" class="volumeIconMinusTablet iconOnTop"></ion-icon>
                    <input type="range" class="customSlider" [ngClass]="{'customSliderTablet' : globalService.isTablet}"
                        [(ngModel)]="appVolume" (change)="setVolume()" min="0" max="1" step="0.1" />
                    <ion-icon name="add" size="large" slot="icon-only" class="volumeIconPlusTablet iconOnTop">
                    </ion-icon>
                </div>
            </div>
            -->
            <!-- SETTINGS Mathia / Kidaia -->
            <div class="iconOnTop"
                [ngClass]="[globalService.isKidaia ? progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar' : 'iconSlotRight']">
                <div *ngIf="!globalService.isKidaia" class="iconOnTop pointer settingsMenuIconMathia iconDefault"
                    id="settingsMenuIconMathia" (click)="displaySettingsMenu()">
                </div>
                <div *ngIf="globalService.isKidaia" class="iconOnTop pointer settingsMenuIconKidaia iconDefault"
                    id="settingsMenuIconKidaia"
                    [ngClass]="[progressBar ? 'kidaiaBigIconSlotProgressBar' : 'kidaiaBigIconSlot']"
                    (click)="displaySettingsMenu()">
                </div>
            </div>



            <!-- deprecated -> in settings component -->
            <div *ngIf="false && !globalService.isKidaia && accountImplService.user.codeClasse && cabriService.holoMode !== '-1' && cabriService.holoMode !== '1'"
                class="iconOnTop"
                [ngClass]="[globalService.isKidaia ? progressBar ? globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaProgressBarMobile' : 'iconSlotKidaiaProgressBar' : globalService.smallHeightScreen || globalService.isMobile ? 'iconSlotKidaiaNoPbarMobile' : 'iconSlotKidaiaNoPbar' : 'iconSlotRight']">
                <!-- Logout -->
                <div class="logoutContainer" (click)="accountImplService.disconnectUser()">
                    <div class="logoutWrapper">
                        <ion-icon name="log-out-outline" class="ion-icon-logout"></ion-icon>
                    </div>
                </div>
            </div>

        </div>

        <!-- Availbale only during activity-->
        <app-progressbar-activity *ngIf="progressBar && cabriService.holoMode !== '-1' && cabriService.holoMode !== '1'"
            class="progressBarExercices" [ngClass]="{'progressBarExercicesMathia': !globalService.isKidaia}"
            [totalAward]="currentUser?currentUser.awardsCurrent.total:null" [currentUser]="currentUser"
            [minQuestionVariable]="cabriService.currentActivity?.variables?.minQuestions" [finalSummary]="finalSummary"
            [mode]="cabriService && cabriService.currentActivity && cabriService.currentActivity.variables ? cabriService.currentActivity.variables : null "
            [changeDetector]="changeDetector" [barLength]="progressBarLength"
            [currentActivity]="cabriService.currentActivity">
        </app-progressbar-activity>
    </div>

    <div *ngIf="globalService.fullPageConfirmationAlert">
        <app-custom-alert (alertMessageAnswer)="alertSwitchUser($event)" [selectedStudent]="selectedStudent"
            [alertAction]="userAlertActions.switchStudent" (globalService.fullPageConfirmationAlert)="modalState()"
            [alertTitle]="alertTitle"></app-custom-alert>
    </div>

    <div class="quitDiv" *ngIf="displayVolume || globalService.studentsMenuStatus || globalService.leftMenuStatus"
        [ngClass]="{'leftMenuQuitDiv': globalService.leftMenuStatus}" (click)="quitDiv()"></div>

</div>

<app-left-menu [ngClass]="{'settingsHidden': !globalService.leftMenuStatus && globalService.resizeEventRunning}"
    [menuStatus]="globalService.leftMenuStatus" [changeDetector]="changeDetector"></app-left-menu>