import { ProposedActivity, Status } from "./proposed-activity";
import { LmsService } from "../services/lms.service";
import { environment } from "src/environments/environment";
import { CabriDataService } from "../services/cabri-data.service";
import { LrsUtils } from "./lrs/lrsUtils";
import { CabriActivity } from "./cabri-activity";

export enum JourneyMode {
	bilan = "Bilan",
	entraînement = "Entraînement",
	entrainement = "Entrainement",
	remediation = "Remédiation"
}

export enum journeyStatus {
	// index 1
	inProgress = "Les parcours en cours",
	// index 2
	toDo = "Mes parcours à faire",
	// index 3
	completed = "Les parcours terminés",
	// index 4
	offlineJourneysByAssignation = "Mes parcours",
	// index 5
	allJourneys = "Tous les parcours"
}

export class JourneysByStatus {
	[status: string]: { opened: boolean; items: Journey[] };
}

export enum JourneyDiagnosticAward {
	maxAward = 5,
	maxTotalAwards = 10
}
/**
 * model for api.mathia recommendation
 */
export class JourneyRecommendation {
	confidence: number;
	erreur_type: number;
	journey: number;
	journeyDifficulty: JourneyMode;
	journeyTitle: string;
	recoType: string;
	skill: string;
	student: string;
}

export class Journey {
	id: number;
	idSession?: string;
	assignationId: number;
	kidaia = false;
	level: any;
	parcoursId: number;
	title: string;
	type: string;
	difficulty: Array<{ id: string; label: string }> | string;
	bilan = false;
	isAdventureBilan = false;
	recommandation = false;
	remediation = false;
	completed: boolean;
	exercises: ProposedActivity[];
	nextActivityProposed: ProposedActivity;
	firstActivityDone = false;
	resume: boolean;
	reload = false;
	start: boolean;
	allAskedQuestions: Array<any>;
	next = false;
	farthest = false;
	assignation = false;
	correctAnswers?: number;
	content: {
		source: object;
		difficulty: Array<any>;
		exercises: Array<any>;
		educationalLevel: { id: string; label: string }[];
		id: number;
		title: string;
	};
	learner: { uid: number };
	model: boolean;
	uid: string | number;
	duration = 0;
	dates;
	journeyStatus: string | number;
	educationalLevel: Array<{ id: string; label: string }>;
	// id Session from lrs
	_id?: string;
	public environment: { production: boolean; activityVersion: number; kidaia: boolean };

	cabriService: CabriDataService;
	lmsService: LmsService;

	constructor(
		cabriService: CabriDataService,
		lmsService: LmsService,
		id: number,
		educationalLevel: Array<{ id: string; label: string }>,
		assignationId: number | null,
		title: string,
		difficulty: Array<{ id: string; label: string }> | string,
		resume: boolean,
		assignation: boolean,
		exercises?: ProposedActivity[],
		allAskedQuestions?: Array<any>
	) {
		this.id = id;
		this.cabriService = cabriService;
		this.lmsService = lmsService;
		this.environment = environment;

		if (assignationId) {
			this.assignationId = assignationId;
		}
		this.title = title;

		if (educationalLevel && Array.isArray(educationalLevel) && educationalLevel.length > 0) {
			this.level = educationalLevel[0].label;
		} else if (educationalLevel) {
			this.level = educationalLevel;
		}

		this.assignation = assignation;
		this.difficulty = difficulty;
		if (this.difficulty && typeof this.difficulty === "string") {
			if (
				this.difficulty === JourneyMode.remediation ||
				this.difficulty === JourneyMode.entraînement ||
				this.difficulty === JourneyMode.entrainement
			) {
				this.bilan = false;
			} else {
				this.bilan = true;
				this.isAdventureBilan = this.title.toLowerCase().startsWith("da");
			}
		}
		if (this.difficulty && Array.isArray(this.difficulty) && this.difficulty.length > 0) {
			if (
				this.difficulty[0].label === JourneyMode.remediation ||
				this.difficulty[0].label === JourneyMode.entraînement ||
				this.difficulty[0].label === JourneyMode.entrainement
			) {
				this.bilan = false;
			} else {
				this.bilan = true;
			}
		}

		if (!this.completed) {
			this.completed = false;
		}
		this.exercises = new Array<ProposedActivity>();
		if (exercises) {
			this.exercises = exercises;
		}
		resume = false;
		if (resume) {
			this.resume = resume;
		}
		this.allAskedQuestions = new Array();
		if (this.allAskedQuestions.length > 0) {
			this.allAskedQuestions = allAskedQuestions;
		}
	}

	/**
	 * Construction of current journey in diagnostic mod
	 */

	getCurrentJourneyDiagnosticMod = () => {
		let currentUserJourney: Journey;
		let journey: Journey;
		if (this) {
			if (!this.environment.kidaia) {
				// mathia journey
				// select journey from user journey
				journey = this.lmsService.userJourneysByAssignation.find(j => {
					return j.id === this.id;
				});

				if (!journey) {
					// select journey from all journeys
					journey = this.lmsService.allJourneys.find(j => {
						return j.id === this.lmsService.userJourneyFromParams.id;
					});
				}
			} else {
				// kidaia journey
				journey = this.lmsService.userJourneyFromParams;
			}

			currentUserJourney = journey;
			currentUserJourney.start = true;
			if (currentUserJourney.idSession) {
				currentUserJourney.idSession = null;
			}
			currentUserJourney.exercises.forEach(data => {
				data.status = Status.notDone;
			});
			currentUserJourney.allAskedQuestions = new Array();
			return currentUserJourney;
		}
	};

	/**
	 * selection of journey exercise to be executed
	 */

	selectJourneyExercise = (exercises: ProposedActivity[], resume: boolean) => {
		let currentExercise: ProposedActivity;
		if (resume) {
			currentExercise = exercises.find(exercise => {
				return exercise.status === Status.notDone;
			});
			if (!currentExercise) {
				currentExercise = exercises.find(exercise => {
					return exercise.status === Status.done;
				});
			}
		} else {
			currentExercise = exercises[0];
		}

		return currentExercise;
	};

	getStartedCurrentJourneyTrainingMod = () => {
		let journey: Journey;
		if (!this.environment.kidaia) {
			journey = this.lmsService.userJourneysByAssignation.find(j => {
				return j.id === this.id;
			});
			if (!journey) {
				journey = this.lmsService.allJourneys.find(j => {
					return j.id === this.id;
				});
			}
		} else {
			journey = this.lmsService.userJourneyFromParams;
		}

		journey.completed = false;
		if (journey.idSession) {
			journey.idSession = null;
		}
		journey.start = true;
		journey.allAskedQuestions = new Array();
		journey.exercises.forEach((data: ProposedActivity) => {
			data.status = Status.notDone;
		});

		return journey;
	};

	/**
	 * get current journey when the journey is resumed in training mod
	 */

	getResumedCurrentJourneyTrainingMod = (journey: Journey): Promise<Journey> => {
		let currentUserJourney: Journey;
		return new Promise<Journey>(async (resolve, reject) => {
			// create copy in order to not modify original object
			const journeyCopy = this._journeyCopy(journey);
			// Reprendre un parcours
			let posInd = -1;
			let currentSessionAskedQuestions = new Array();
			if (this.resumeExerciseProcessing(journeyCopy)) {
				// Reprendre un parcours lorsqu'un exercice est en cours d'être joué
				const lastQuestion = journeyCopy.allAskedQuestions[journeyCopy.allAskedQuestions.length - 1];
				posInd = journeyCopy.exercises.findIndex(exercise => {
					return exercise.step === lastQuestion.step;
				});

				const idSession = lastQuestion.id_session;
				currentSessionAskedQuestions = journeyCopy.allAskedQuestions.filter(allAskedQuestions => {
					return allAskedQuestions.id_session === idSession;
				});
				if (posInd === -1) {
					// take first element of array if changes made to journey
					posInd = 0;
				}
			} else {
				// Reprendre un parcours lorsqu'un exercice a été terminé (Passage à l'exercice suivant)
				posInd = journeyCopy.exercises.findIndex(exercise => {
					return exercise.status === Status.notDone;
				});

				currentSessionAskedQuestions = journeyCopy.allAskedQuestions.filter(allAskedQuestions => {
					return allAskedQuestions.step === journeyCopy.exercises[posInd].step;
				});
			}

			if (posInd > -1) {
				try {
					await this.cabriService.setActivityId(journeyCopy.exercises[posInd].exerciseId);
					let currJ: Journey;
					if (!this.environment.kidaia) {
						currJ = this.lmsService.userJourneysByAssignation.find(j => {
							return j.id === journeyCopy.id;
						});
						if (!currJ) {
							currJ = this.lmsService.allJourneys.find(j => {
								return j.id === journeyCopy.id;
							});
						}
					} else {
						currJ = this.lmsService.userJourneyFromParams;
					}

					currentUserJourney = journeyCopy;
					currentUserJourney.title = currJ.title;
					currentUserJourney.assignationId = currJ.assignationId;
					currentUserJourney.type = currJ.type;
					currentUserJourney.idSession = journeyCopy.idSession;
					currentUserJourney.resume = true;

					if (currentSessionAskedQuestions && currentSessionAskedQuestions.length > 0) {
						// if there are more than one question asked during the session so connect it to journey property
						currentUserJourney.allAskedQuestions = currentSessionAskedQuestions;
						LrsUtils.idsession = currentUserJourney.allAskedQuestions[0].id_session;
					} else {
						// user is about to start new exercise among journey exercises so initilize an empty array
						currentUserJourney.allAskedQuestions = new Array();
					}
				} catch (err) {
					console.error("not exercise found", err);
					resolve(currentUserJourney);
				}
			}

			resolve(currentUserJourney);
		});
	};
	/**
	 * Copy journey object
	 */
	private _journeyCopy(journey: Journey) {
		const lmsService = journey.lmsService;
		const cabriService = journey.cabriService;
		journey.lmsService = journey.cabriService = null;
		const journeyCopy = JSON.parse(JSON.stringify(journey));
		journeyCopy.lmsService = lmsService;
		journeyCopy.cabriService = cabriService;
		return journeyCopy;
	}

	/**
	 * Resume an activity exercise when an exercise of journey is still playing
	 */

	resumeExerciseProcessing = (journey: Journey) => {
		return (
			journey.allAskedQuestions &&
			journey.allAskedQuestions.length > 0 &&
			journey.allAskedQuestions[journey.allAskedQuestions.length - 1].completed === 0
		);
	};

	/**
	 * Define journey selected Activity with the exercise that must be played
	 */
	defineJourneySelectedActivity = (journeyExercise: ProposedActivity) => {
		let selectedActivity: CabriActivity;
		selectedActivity = this.cabriService.activities.find(activity => {
			return Number(activity.id) === Number(journeyExercise.activityId);
		});

		const exercise = this.cabriService.exercices.getExercise(journeyExercise.exerciseId);

		if (selectedActivity) {
			selectedActivity.paramHidden("v-cursor-mod", { 3: false });
			this.cabriService.setSelectedActivity(selectedActivity);
			selectedActivity.params.forEach(param => {
				if (param.exercices) {
					param.defaultValue = exercise;
					param.value = exercise;
				}
			});
		}
		this.cabriService.defineExercice(selectedActivity);
		// define exercise default value
		if (exercise) {
			selectedActivity.setParamValue("ex", exercise);
		}

		return selectedActivity;
	};

	// Sécurité pour commencer le premier exercice du parcours en cas de parcours terminé
	selectDiagnosticAsSimpleExercise = async () => {
		return new Promise<void>(async resolve => {
			await this.cabriService.setActivityId(this.lmsService.currentUserJourney.exercises[0].exerciseId);
			resolve();
		});
	};

	/**
	 * Get next diagnostic activity if it's exist
	 */
	checkNextDiagnosticExercise = async (nextExercise: ProposedActivity) => {
		return new Promise(async (resolve, reject) => {
			if (!nextExercise) {
				reject(true);
				return;
			}

			if (!this.lmsService.currentUserJourney.exercises[nextExercise.step]) {
				reject(true);
				return;
			}
			try {
				// Prochain exercice
				await this.cabriService.setActivityId(this.lmsService.currentUserJourney.exercises[nextExercise.step].exerciseId);
				const exercise = this.cabriService.exercices.getExercise(
					this.lmsService.currentUserJourney.exercises[nextExercise.step].exerciseId
				);
				if (exercise && this.cabriService.currentActivity) {
					this.cabriService.currentActivity.setParamValue("ex", exercise);
				}

				// définir step (assignation + index) pour lrs
				resolve(true);
			} catch (err) {
				reject();
			}
		});
	};
}
