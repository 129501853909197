import { CabriDataService } from "../services/cabri-data.service";
import { LmsService } from "../services/lms.service";
import { CabriActivity } from "./cabri-activity";
import { Chapter } from "./chapter";
import { ExerciseStatistics } from "./gamification-lrs";
export class StoryStatistics {
	[planetName: string]: {
		[position: string]: {
			idExercice: number;
			nameActivity: string;
			thumbnailActivity: string;
			nameExercice: string;
			shooting?: number;
			star?: number;
			moon?: number;
			averageGoodAnswer?: number;
			competencies : { [exerciseId: string]: string; };
		};
	};
}
export class Story {
	id: string;
	name: string;
	order: number;
	category: string;
	background: string;
	chapters: Chapter[];

	/**
	 * return the last unlocked Chapter
	 */
	getCurrentChapter(): Chapter {
		return this.chapters
			.slice()
			.reverse()
			.find(chapter => chapter.unlocked);
	}

	getCurrentChapterByPlanetName(planet: string) {
		return this.chapters.find(chapter => {
			return chapter.planet === planet;
		});
	}

	getCurrentChapterById(chapterId: string | number) {
		return this.chapters.find(chapter => {
			return chapter.id == chapterId;
		});
	}

	/**
	 * get the next Chapter and return her value
	 */
	getNextChapter(currentChapter: Chapter): Chapter {
		const indexCurrent = this.chapters.indexOf(currentChapter);
		return indexCurrent + 1 < this.chapters.length ? this.chapters[indexCurrent + 1] : this.chapters[indexCurrent];
	}

	/**
	 * check if last chapter - returns boolean
	 */
	isLastChapter(currentChapter: Chapter): boolean {
		const indexCurrent = this.chapters.indexOf(currentChapter);
		return indexCurrent + 1 >= this.chapters.length;
	}

	/**
	 * get the previous Chapter and return her value
	 */
	getPreviousChapter(currentChapter: Chapter): Chapter {
		const indexCurrent = this.chapters.indexOf(currentChapter);
		return indexCurrent > 0 ? this.chapters[indexCurrent - 1] : this.chapters[indexCurrent];
	}

	haveNextChapterUnlocked(currentChapter: Chapter): boolean {
		const indexCurrent = this.chapters.indexOf(currentChapter);
		return indexCurrent + 1 < this.chapters.length && this.chapters[indexCurrent + 1].unlocked;
	}

	havePreviousChapterUnlocked(currentChapter: Chapter): boolean {
		const indexCurrent = this.chapters.indexOf(currentChapter);
		return indexCurrent > 0 && this.chapters[indexCurrent - 1].unlocked;
	}

	/**
	 * Get history of all story statistics / keeping the best session result based on exercise
	 */
	getTotalStatistics(cabriService: CabriDataService, currentPosition: number, lmsService: LmsService): StoryStatistics {
		const statistics: StoryStatistics = {};
		let exerciseNameExtracted: string, activity: CabriActivity, competency : { [exerciseId: string]: string; };

		const bestResultByExercise = lmsService.exerciseStatisticsDetails.getStoryStatistics();

		// history of all statistics except current journey that should be done
		this.chapters.forEach(chapter => {
			if (!statistics.hasOwnProperty(chapter.planet) && chapter.exercicesChapterHistory?.length > 0) {
				statistics[chapter.planet] = {};
			}
			chapter.exercicesChapterHistory.forEach(chapterHistory => {
				competency = null;
				if (!statistics[chapter.planet].hasOwnProperty(chapterHistory.position)) {
					exerciseNameExtracted = null;
					activity = null;
					const currentExercise = cabriService.exercices.getExercise(Number(chapterHistory.idExercice));
					if (currentExercise) {
						exerciseNameExtracted = cabriService.exercices.extractor[String(currentExercise.id)]?.title;
						activity = cabriService.activities.find(currentActivity => {
							return Number(currentActivity.id) === Number(currentExercise.gabarit);
						});
						competency = currentExercise.competencesValidees;
					}

					statistics[chapter.planet][chapterHistory.position] = {
						idExercice: Number(chapterHistory.idExercice),
						nameExercice: exerciseNameExtracted,
						thumbnailActivity: activity?.customThumbnail,
						nameActivity: activity?.name,
						shooting: bestResultByExercise[chapterHistory.idExercice]?.shooting,
						star: bestResultByExercise[chapterHistory.idExercice]?.star,
						moon: bestResultByExercise[chapterHistory.idExercice]?.moon,
						averageGoodAnswer: bestResultByExercise[chapterHistory.idExercice]?.averageGoodAnswer,
						competencies : competency
					};
				}
			});
		});

		// the journey to be done
		const currentChapter = this.getCurrentChapter();
		const lastNotDoneExercise = cabriService.currentExercice;
		if (lastNotDoneExercise) {
			exerciseNameExtracted = cabriService.exercices.extractor[String(lastNotDoneExercise.id)]?.title;
			activity = cabriService.activities.find(currentActivity => {
				return Number(currentActivity.id) === Number(lastNotDoneExercise.gabarit);
			});

			if (!statistics[currentChapter.planet]) {
				statistics[currentChapter.planet] = {};
			}
			statistics[currentChapter.planet][currentPosition] = {
				idExercice: Number(lastNotDoneExercise.id),
				nameExercice: exerciseNameExtracted,
				thumbnailActivity: activity?.customThumbnail,
				nameActivity: activity?.name,
				shooting: bestResultByExercise[lastNotDoneExercise.id]?.shooting,
				star: bestResultByExercise[lastNotDoneExercise.id]?.star,
				moon: bestResultByExercise[lastNotDoneExercise.id]?.moon,
				averageGoodAnswer: bestResultByExercise[lastNotDoneExercise.id]?.averageGoodAnswer,
				competencies : lastNotDoneExercise.competencesValidees
			};
		}

		return statistics;
	}
}
