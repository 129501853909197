import { Injectable, NgZone } from "@angular/core";
import { BaseService } from "./base.service";
import { Story } from "../models/story";
import { HttpClient } from "@angular/common/http";
import { Student } from "../models/student";
import { Chapter, exoState } from "../models/chapter";
import { ExerciceChapterHistory } from "../models/exercice-chapter-history";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class StoryService extends BaseService {
	public environment: { production: boolean; activityVersion: number; storyVersion: number; kidaia: boolean };
	constructor(private http: HttpClient, public router: Router, private ngzone: NgZone) {
		super();
		this.environment = environment;
	}

	/**
	 * Get all storys with chapters and exercices result for one user
	 * @param idUser trallalere id user
	 */
	getStorys(idUser: string): Promise<Story[]> {
		const formData = new FormData();
		formData.append("action", "getStorys");
		formData.append("id_user", idUser);
		if (!this.environment.production) {
			formData.append("dev", "true");
		}
		formData.append("version", this.environment.storyVersion.toString());
		const remapStorys: Story[] = [];
		const storysObs = this.http.post<Story[]>(this.postUrl, formData).pipe(
			map(storys => {
				storys.forEach(story => {
					const remapchapters: Chapter[] = [];
					story.chapters.forEach(chapter => {
						const remapExercicesChapterHistory: ExerciceChapterHistory[] = [];
						chapter.exercicesChapterHistory.forEach(exercicesChapterHistory => {
							exercicesChapterHistory.position = Number(exercicesChapterHistory.position);
							exercicesChapterHistory.bilan = (exercicesChapterHistory.bilan as unknown) === "1";
							exercicesChapterHistory.results.forEach(result => {
								result.star = Number(result.star);
								result.shooting = Number(result.shooting);
								result.moon = Number(result.moon);
							});
							remapExercicesChapterHistory.push(Object.assign(new ExerciceChapterHistory(), exercicesChapterHistory));
						});
						chapter.exercicesChapterHistory = remapExercicesChapterHistory;
						chapter.minimumToWin = Number(chapter.minimumToWin);
						chapter.maxExercices = Number(chapter.maxExercices);
						chapter.unlocked = (chapter.unlocked as unknown) === "1";
						chapter.hasExercices = (chapter.hasExercices as unknown) === "1";
						// chapter.systemName =
						remapchapters.push(Object.assign(new Chapter(), chapter));
					});
					story.chapters = remapchapters;
					story.order = Number(story.order);
					remapStorys.push(Object.assign(new Story(), story));
				});
				return remapStorys;
			})
		);
		return lastValueFrom(storysObs);
	}

	private saveExercice(exercice: ExerciceChapterHistory, student: Student): Promise<string> {
		const formData = new FormData();
		formData.append("action", "saveKidaiaExercice");
		formData.append("id_user", student.id);
		formData.append("id_chapter", exercice.chapterId);
		formData.append("id_exercice", exercice.idExercice);
		formData.append("bilan", exercice.bilan?"1":"0");
		formData.append("position", exercice.position.toString());
		const saveExerciceObs = this.http.post<string>(this.postUrl, formData);
		return lastValueFrom(saveExerciceObs);
	}

	/**
	 * Lauch activity with the exercice in position
	 */
	launchExercice(chapter: Chapter, position: number, bilan = false, journeyId = ""): Promise<void> {
		return this.ngzone.run(() => {
			return new Promise<void>((resolve, reject) => {
				// console.log("Angular zone :"+ NgZone.isInAngularZone());

				if (position <= chapter.maxExercices && position > 0) {
					const exercice = chapter.exercicesChapterHistory.find(element => element.position === position);
					if (exercice) {
						this.router.navigateByUrl("/gabarits", {
							state: {
								launchExercice: { exo: exercice, chapter, position }
							}
						});
						resolve();
					} else {
						this.router.navigateByUrl("/gabarits", {
							state: {
								launchExercice: { chapter, position, bilan , journeyId}
							}
						});
						resolve();
					}
				} else {
					this.router.navigateByUrl("/gabarits", {
						state: {
							launchExercice: { chapter, position: chapter.exercicesChapterHistory.length + 1 ,bilan ,journeyId}
						}
					});
					resolve();
				}
			});
		});
	}
	/**
	 * check chapter unlock 0 no, 1 yes, 2 already unlock
	 */
	checkUnlockNextChapter(chapter: Chapter, story: Story, student: Student): number {
		// all exercice have been done
		// check if all exercice have status validated (done with enough star won)
		if (chapter.maxExercices <= chapter.exercicesChapterHistory.length) {
			const nextChapter = story.getNextChapter(chapter);
			if (
				!nextChapter.unlocked &&
				chapter.exercicesChapterHistory.every(exo => {
					return exo.position > chapter.maxExercices || chapter.getExoStatus(exo.position) === exoState.validated;
				})
			) {
				nextChapter.unlocked = true;
				this.saveChapterUnlock(nextChapter, student);
				return 1;
			} else {
				if (nextChapter.unlocked) {
					return 2;
				} else {
					return 0;
				}
			}
		} else {
			return 0;
		}
	}

	/**
	 * choose next page after an activity is done
	 */
	goToNextAfterActivity(chapter: Chapter, position: number, haveUnlock: number = 0) {
		// all exercice have been done
		if (chapter.maxExercices === chapter.exercicesChapterHistory.length && haveUnlock !== 2) {
			// check if all exercice have status validated (done with enough star won)
			if (haveUnlock === 1) {
				// we can go on conclusion
				if (chapter.conclusionWin) {
					this.router.navigateByUrl("/narration", {
						state: {
							sceneToPlay: chapter.conclusionWin,
							position: 0
						}
					});
				} else {
					this.router.navigateByUrl("/map");
				}
			} else {
				if (chapter.conclusionFail) {
					this.router.navigateByUrl("/narration", {
						state: {
							sceneToPlay: chapter.conclusionFail,
							position
						}
					});
				} else {
					this.router.navigateByUrl("/map");
				}
			}
		} else {
			if (position === chapter.maxExercices) {
				if (chapter.conclusionWin) {
					this.router.navigateByUrl("/narration", {
						state: {
							sceneToPlay: chapter.conclusionWin,
							position: 0
						}
					});
				} else {
					this.router.navigateByUrl("/map");
				}
			} else if (chapter.interlude && position < chapter.maxExercices) {
				this.router.navigateByUrl("/map", {
					state: {
						sceneToPlay: chapter.interlude,
						position
					}
				});
			} else {
				this.router.navigateByUrl("/map");
			}
		}
	}

	/**
	 * Create a new exercice for a position save on db and return her id who is a prerequisite to add result
	 * @param position the free position of the exercice
	 * @param idExerciceChapter the id of the exercice choose by ai
	 */
	async createExercice(chapter: Chapter, position: number, student: Student, idExerciceChapter: string, bilan = false): Promise<ExerciceChapterHistory> {
		const exercice = new ExerciceChapterHistory();
		exercice.chapterId = chapter.id;
		exercice.position = position;
		exercice.results = new Array();
		exercice.idExercice = idExerciceChapter;
		exercice.bilan = bilan;
		exercice.id = await this.saveExercice(exercice, student);
		chapter.exercicesChapterHistory.push(exercice);
		return exercice;
	}

	/**
	 * save and exercice result on db
	 */
	private saveExerciceResult(exercice: ExerciceChapterHistory, shooting: number = 0, star: number = 0, moon: number = 0) {
		const formData = new FormData();
		formData.append("action", "saveKidaiaExerciceResult");
		formData.append("id_chapter_exercice", exercice.id);
		formData.append("shooting", shooting.toString());
		formData.append("star", star.toString());
		formData.append("moon", moon.toString());
		const saveExerciceObs = this.http.post<string>(this.postUrl, formData);
		return lastValueFrom(saveExerciceObs);
	}

	/**
	 * add exercice result and return promise when fullfill
	 */
	addExerciceResult(exercice: ExerciceChapterHistory, shooting: number = 0, star: number = 0, moon: number = 0): Promise<void> {
		return new Promise((resolve, reject) => {
			this.saveExerciceResult(exercice, shooting, star, moon)
				.then(() => {
					exercice.results.push({ shooting, star, moon });
					resolve();
				})
				.catch(error => reject(error.message));
		});
	}

	/**
	 * Save the unlocked status for and user/chapter
	 * @return the chapter_user id
	 */
	saveChapterUnlock(chapter: Chapter, student: Student): Promise<string> {
		const formData = new FormData();
		formData.append("action", "saveChapterUser");
		formData.append("id_user", student.id);
		formData.append("id_chapter", chapter.id.toString());
		formData.append("unlocked", chapter.unlocked ? "1" : "0");
		const saveChapter = this.http.post<string>(this.postUrl, formData);
		return lastValueFrom(saveChapter);
	}
}
