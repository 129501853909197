import { trigger, state, style, transition, animate } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { flipCards } from "src/app/models/custom-angular-animations";
import { CardData } from "src/app/pages/memory/memory.page";

@Component({
	selector: "app-game-card",
	templateUrl: "./game-card.component.html",
	styleUrls: ["./game-card.component.scss"],
	animations: [flipCards]
})
export class GameCardComponent implements OnInit {
	@Input() data: CardData;
	@Input() showText?= false;

	@Output() cardClicked = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}
}
