import { ExerciceChapterHistory } from "./exercice-chapter-history";

export enum exoState {
	validated,
	notValidated,
	unlocked,
	locked
}

export enum systemName {
	cassiopee = "Cassiopée",
	olympe = "Olympe",
	etape3 = "etape3",
	etape4 = "etape4",
	etape5 = "etape5",
	tellurique = "Telluriques",
	enfant = "Enfants",
	aeriens = "Aériens",
	galaxie7 = "galaxie7",
	galaxie8 = "galaxie8",
	galaxie9 = "galaxie9",
	galaxie10 = "galaxie10"
}

export class Chapter {
	id: string;
	name: string;
	hasExercices: boolean;
	maxExercices: number;
	introduction: string;
	interlude: string;
	conclusionWin: string;
	conclusionFail: string;
	cinematic: string;
	minimumToWin: number;
	planet: string;
	background: string;
	unlocked: boolean;
	exercicesChapterHistory: ExerciceChapterHistory[];
	systemName: string;
	order: number;
	getTotalStar(): number {
		if (this.exercicesChapterHistory) {
			let total = 0;
			this.exercicesChapterHistory.forEach(element => (total += element.maxStarsWon().star));
			return total;
		} else return 0;
	}

	getCurrentExerciseByPosition(level: number) {
		return this.exercicesChapterHistory.find(exo => exo.position === level);
	}

	getExoStatus(position: number) {
		//position where we have historic
		if (this.exercicesChapterHistory.length >= position) {
			const exo = this.exercicesChapterHistory.find(exo => exo.position === position);
			//for exo from bilan always validated
			if (exo.bilan){
				return exoState.validated;
			}
			const resultExo = exo.maxStarsWon();
			//or we parse result to verify if we have a result over the the minimal ratio
			if (resultExo.total !== 0 && resultExo.ratio >= this.minimumToWin / 10) {
				return exoState.validated;
			} else {
				return exoState.notValidated;
			}
			// next position after a validated exercice -> unlocked
		} else if (this.exercicesChapterHistory.length + 1 === position) {
			return exoState.unlocked;
		} else {
			// else is locked
			return exoState.locked;
		}
	}
}
