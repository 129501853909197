import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { BaseService } from "./base.service";
import { lastValueFrom, Observable, of, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { shareReplay, tap, map, catchError } from "rxjs/operators";
import { Classroom } from "../models/classroom";
import { Student, Role } from "../models/student";
import { KidaiaSso } from "../models/user";
import { addSeconds, format, isBefore, parse } from "date-fns";
import { AppLanguage } from "../models/enums/enum-list";
import { BearerToken, ClassService, GradeClasse } from "./class.service";
import { Buffer } from "buffer";
import { NetworkService } from "./network.service";

@Injectable({
	providedIn: "root"
})
export class TralalereService extends BaseService implements ClassService {
	authBearer: string;
	private teacherInformationObs: Observable<any>;
	private listClasseTeacherObs: Observable<any>;
	private kidaiaSsoObs: Observable<KidaiaSso>;
	private tokenObs: Observable<BearerToken>;
	classes: GradeClasse[];
	source: string;
	isAren = false;
	isBeneylu = false;
	isMathador = false;
	loggedFromHome = false;
	homeStudent: any;
	constructor(private http: HttpClient, @Inject(LOCALE_ID) public locale: string,public networkService:NetworkService) {
		super();
		(window as any).trala = this;
	}

	public disconnectUser(): Observable<any> {
		return new Observable<any>(subscriber => {
			subscriber.next(null);
			subscriber.complete();
		});
	}

	public registerTeacherAccount(email: string, password: string, name: string, firstName: string): Observable<any> {
		return new Observable(subscriber => {
			this.http
				.post("https://lms-mathia.api.tralalere.com/api/user-registration", {
					email: email,
					password: password,
					label: name,
					you_are: "teacher",
					firstName: firstName
				})
				.subscribe({
					next: data => {
						console.log(data);
						this.http
							.get<any>("https://lms-mathia.api.tralalere.com/api/login-token", {
								headers: {
									Authorization: "Basic " + btoa(email + ":" + password)
								}
							})
							.subscribe(data2 => {
								console.log("accesstoken", data2);
								localStorage.setItem("id_token_trala", data2.access_token);
								this.http
									.post("https://lms-mathia.api.tralalere.com/api/groups", {
										label: "Classe démo",
										type: "2",
										archived: false,
										projects: [],
										level: "CP",
										color: null,
										schoolyear_term: "",
										parents: []
									})
									.subscribe((result: any) => {
										subscriber.next(result);
										subscriber.complete();
									});
							});
					},
					error: data => {
						subscriber.error(data);
						subscriber.complete();
					}
				});
		});
	}

	public getAssignationList(idProf: string): Observable<any> {
		return this.http.get<any>(this.postTralalereUrl + "/assignations-list?filter[assignator]=" + idProf);
	}

	/**
	 * request a rest transaction token for tralalere
	 * if only login specified the loggin is the authBearer token
	 */
	login(login?: string, password?: string): Observable<BearerToken> {
		if (!this.tokenObs || this.isLoggedOut) {
			let headerDict;
			if (login && password) {
				this.authBearer = "Basic " + btoa(login + ":" + password);
				headerDict = {
					Authorization: this.authBearer
				};
			}
			if (login && !password) {
				this.authBearer = "Basic " + login;
				headerDict = {
					Authorization: this.authBearer
				};
			} else if (this.authBearer) {
				headerDict = {
					Authorization: this.authBearer
				};
			} else {
				// TODO remove security breach
				headerDict = {
					Authorization: environment.bearerToken
				};
			}

			const requestOptions = {
				headers: new HttpHeaders(headerDict)
			};

			this.tokenObs = this.http
				.get<BearerToken>(this.postTralalereUrl + "/login-token", requestOptions)
				.pipe(
					catchError(async err => {
						this.tralaError = true;
						return err;
					})
				)
				.pipe(
					tap(res => {
						if (res.error) {
							throw throwError(() => res);
						} else {
							this.setSession(res);
						}
					}),
					shareReplay()
				);
		}
		return this.tokenObs;
	}

	public setSession(authResult: BearerToken) {
		const expiresAt = addSeconds(new Date(), Number(authResult.expires_in));

		localStorage.setItem("id_token_trala", authResult.access_token);
		localStorage.setItem("expires_at_trala", format(expiresAt, "T"));
	}
	/**
	 *  return tralalere token for auth
	 *  @returns string
	 */
	public getSessionToken(): Promise<string> {
		return new Promise((resolve, reject) => {
			if (this.isLoggedIn()) {
				resolve(localStorage.getItem("id_token_trala"));
			} else {
				lastValueFrom(this.login()).then(
					data => {
						resolve(data.access_token);
					},
					error => {
						reject(error);
					}
				);
			}
		});
	}

	logout() {
		localStorage.removeItem("id_token_trala");
		localStorage.removeItem("expires_at_trala");
		localStorage.removeItem("id_token_student");
		localStorage.removeItem("expires_at_student");
		localStorage.removeItem("codemaison");

		this.loggedFromHome = false;
		this.homeStudent = null;
	}

	public isLoggedIn(): boolean {
		return isBefore(new Date(), this.getExpirationTralalere());
	}

	public isLoggedInStudent(): boolean {
		return isBefore(new Date(), this.getExpirationStudent());
	}

	isLoggedOut() {
		return !this.isLoggedIn();
	}

	getExpirationTralalere(): Date {
		const expiration = localStorage.getItem("expires_at_trala");
		const expiresAt = parse(expiration, "T", new Date());
		return expiresAt;
	}

	getExpirationStudent(): Date {
		const expiration = localStorage.getItem("expires_at_student");
		const expiresAt = parse(expiration, "T", new Date());
		return expiresAt;
	}

	private getGroupDetail(codeClass: string): Observable<any> {
		if (this.loggedFromHome && this.homeStudent) {
			// filter students to allow only logged in one
			if(this.networkService.connectedStatus){
				return new Observable<any>(observer => {
					this.http.get<any>(this.postTralalereUrl + "/groups-detail/" + codeClass).subscribe({
						next: data => {
							data.data[0].members = data.data[0].members.filter(m => m.uid === this.homeStudent);
							observer.next(data);
						},
						error: err => {
						}
					});
				});
			}else{
				return this.http.get<any>(this.postTralalereUrl + "/groups-detail/" + codeClass);
			}
		} else {
			return this.http.get<any>(this.postTralalereUrl + "/groups-detail/" + codeClass);
		}
	}

	/**
	 * Get a classe from tralalere with an array of Student
	 * @return Classroom
	 */
	getClasseFromGroupDetail(codeClass: string): Observable<Classroom> {
		return this.getGroupDetail(codeClass)
			.pipe(
				catchError(err => {
					return throwError(err);
				})
			)
			.pipe(
				map(data => {
					if (data?.data && Array.isArray(data.data)) {
						const outputClasse = new Classroom(data.data[0].uid, data.data[0].code, data.data[0].label, data.data[0].level);
						const grade = data.data[0].level;
						data.data[0].members.forEach(element => {
							if (element.role === Role.student) {
								const student = new Student(element.uid, outputClasse, element.label);
								student.parentalConsent = element.parentalConsent === 1;
								student.level = grade;
								outputClasse.students.push(student);
							}
						});
						return outputClasse;
					}
				})
			);
	}

	/**
	 * Get teacher/Parent account information raw data from tralalere
	 */
	getTeacherInformation(): Promise<any> {
		if (!this.teacherInformationObs) {
			this.teacherInformationObs = this.http.get<any>(this.postTralalereUrl + "/users/me").pipe(
				shareReplay(),
				map(data => {
					return data.data[0];
				})
			);
		}
		return lastValueFrom(this.teacherInformationObs);
	}

	getListClasseTeacher(): Promise<GradeClasse[]> {
		if (!this.listClasseTeacherObs) {
			this.listClasseTeacherObs = this.http
				.get<any>(this.postTralalereUrl + "/groups")
				.pipe(
					catchError(() => {
						return of(null);
					})
				)
				.pipe(
					shareReplay(),
					map(data => {
						if (data) {
							const classes = new Array();
							data.data.forEach(classe => {
								classes.push({ classroomId: classe.id, grade: classe.level, label: classe.label });
							});
							if (this.locale === AppLanguage.EN && environment.kidaia) {
								classes.forEach(classe => {
									if (classe.label === "CP") {
										classe.label = "Age 5";
									} else if (classe.label === "CE1") {
										classe.label = "Age 6";
									} else if (classe.label === "CE2") {
										classe.label = "Age 7";
									} else if (classe.label === "CM1") {
										classe.label = "Age 8";
									} else if (classe.label === "CM2") {
										classe.label = "Age 9";
									}
								});
							}
							this.classes = classes;
							return classes;
						}
					})
				);
		}
		return lastValueFrom<GradeClasse[]>(this.listClasseTeacherObs);
	}

	/**
	 * Create Student tralalere use classe from student if classe exist or first classe from parent account
	 * @param student use name and parental consent and picture classe not ready actually
	 */
	createStudent(student: Student): Promise<void> {
		return new Promise((resolve, reject) => {
			// retrieve parent/teacher information for mail region and classe
			this.getTeacherInformation()
				.then(me => {
					const postData = {
						label: student.name,
						contact_email: me.email,
						parentalConsent: student.parentalConsent ? "1" : "0",
						groups: [student.classe && student.classe.id ? student.classe.id : me.groups[0]],
						you_are: "teen",
						role: 6,
						find_us: "other",
						picture: student.picture,
						newsletter: false,
						password: this.getLearnerPassword(),
						region: me.region && me.region.id ? me.region.id : null
					};
					// create a learner
					this.http
						.post<any>(this.postTralalereUrl + "/learners/", postData)
						.pipe(map(data => {}))
						.subscribe({
							next: data => {
								resolve();
							},
							error: error => {
								reject(error);
							}
						});
				})
				.catch(error => reject(error));
		});
	}

	/**
	 * Delete student tralalere use id of The Student
	 */
	deleteStudent(student: Student): Promise<void> {
		return new Promise((resolve, reject) => {
			this.http
				.delete<any>(this.postTralalereUrl + "/learners/" + student.id)
				.pipe(map(data => {}))
				.subscribe(
					data => {
						resolve();
					},
					error => {
						reject(error);
					}
				);
		});
	}

	/**
	 * Update Student information update name and parental consent
	 */
	updateStudent(student: Student): Promise<void> {
		return new Promise((resolve, reject) => {
			const postData = {
				label: student.name,
				parentalConsent: student.parentalConsent ? "1" : "0",
				groups: [student.classe.id]
			};
			this.http
				.patch<any>(this.postTralalereUrl + "/learners/" + student.id, postData)
				.pipe(map(data => {}))
				.subscribe({
					next: data => {
						resolve();
					},
					error: error => {
						reject(error);
					}
				});
		});
	}

	/**
	 * Get Kidaia sso information
	 */
	getKidaiaSso(): Promise<KidaiaSso> {
		if (!this.kidaiaSsoObs) {
			this.kidaiaSsoObs = this.http
				.post<KidaiaSso>(this.postApiKidaiaLoggedIn + "?action=getKidaiaInfo", null, {
					withCredentials: true
				})
				.pipe(shareReplay());
		}
		return lastValueFrom(this.kidaiaSsoObs);
	}

	/**
	 * Get the password from tokenBearer
	 */
	private getLearnerPassword(): string {
		const decode = atob(this.authBearer.slice(6));
		const splited = decode.split(":");
		return splited.length > 1 ? splited[1] : null;
	}

	public verifPassword(formData: FormData) {
		return this.http.post(this.postApiKidaia + "?action=verifyLogin", formData);
	}

	public redirectionError() {
		(window as any).open(`${this.siteKidaia}/login`, "_self");
	}

	public loginCodeMaison(code: string) {
		return new Promise<string>(async (resolve, reject) => {
			// login to tralalere
			await this.getSessionToken();

			// save main token
			const idToken = localStorage.getItem("id_token_trala");

			// get access-token from code maison
			const auth = btoa(code + ":" + code);
			this.http
				.get<any>(this.postTralalereUrl + "/login-token", {
					headers: {
						Authorization: "Basic " + auth,
						"Content-Type": "application/json"
					}
				})
				.subscribe({
					next: data2 => {

						// set student token
						localStorage.setItem("id_token_trala", data2.access_token);
						localStorage.setItem("id_token_student", data2.access_token);
						localStorage.setItem("codemaison", code);

						// get student info (groups)
						this.http.get(this.postTralalereUrl + "/users/me").subscribe({
							next: (result: any) => {
								// store user
								this.homeStudent = result.data[0].id;
								this.loggedFromHome = true;

								// find classe in groups
								const groups = result.data[0].groups;
								this.http
									.get(this.postTralalereUrl + "/groups-detail/" + groups.join(","))
									.subscribe({
										next: (groupsDetails: any) => {
											const classe = groupsDetails.data.find(g => g.type === "2");
											localStorage.setItem("id_token_trala", idToken);
											resolve(classe.code);
										},
										error: e => {
											localStorage.setItem("id_token_trala", idToken);
											reject(e);
										}
									});
							},
							error: e => {
								localStorage.setItem("id_token_trala", idToken);
								reject(e);
							}
						});
					},
					error: e => {
						localStorage.setItem("id_token_trala", idToken);
						localStorage.removeItem("codemaison");
						reject(e);
					}
				});
		});
	}
}
