<div [ngClass]="globalService.blueLightFilter? 'blueLightFilter' : 'noBlueLightFilter'"></div>
<app-progress-bar [progress]="this.tempProgress" [cd]="changeD" [adobeAnimate]="environment.kidaia && !environment.ose"
    [showSplash]="showSplash" [adobeReadyObs]="startingService.adobeReadyObs"
    [progressBarObs]="globalService.globalLoadingEvent" [loaderStatus]="globalService.globalLoading"></app-progress-bar>

<!-- NEW SETTINGS STORY COMPONENT: -->
<!-- <app-activity-settings class="storySettings"
    [ngClass]="{'settingsHidden': !globalService.storySettingOpened && globalService.resizeEventRunning}"
    (launchStoryActivityToolbar)="launchStoryActivityEmitter()" (switchSettingParam)="switchSettingsParams($event)"
    [changeD]="changeD"></app-activity-settings> -->

<app-confetti-canvas #canvasConfettiComponent></app-confetti-canvas>
<app-image-viewer *ngIf="environment.kidaia" [imageURL]="globalService.imageViewerURL" [gallery]="globalService.imageViewerGallery"
    [open]="globalService.imageViewerOpen" (close)="globalService.imageViewerOpen = false"></app-image-viewer>

<ion-app class="backgroundStars ion-app">
    <!-- NEW SETTINGS COMPONENT -->
    <app-settings class="settingsComponent"
    [ngClass]="{'settingsHidden': !globalService.displayVoiceSettingsStatus && globalService.resizeEventRunning}"
    (launchStoryActivityToolbar)="launchStoryActivityEmitter()" [changeDetector]="changeD"></app-settings>
    <!-- OSE SPEECH BUBBLE -->
    <app-speech-bubble #bubbleComponent *ngIf="environment.ose || environment.kidaia"
        [ngClass]="{'leftMenuDisplayed' : globalService.leftMenuStatus}"></app-speech-bubble>
    <!-- CABRI -->
    <div id="CabriMainWrapper">
        <div style="font-family: andika_basicregular; position: absolute; left: -500px; top: 0px">
            get andika loaded
        </div>
        <span style="display:none;" id="ruler"></span>
        <div id="maindiv1" style="opacity:1; height:100%; width: 100%;">
            <div id="vue-the-express-toolbar"></div>
            <div class="hidden" id="calcdisplaymenubg">
                <div id="calcdisplaymenu">
                    Clear
                </div>
            </div>
            <div id="canvasholder1" style="width: 100%; height: 100%; position: fixed; top: 0; left: 0;"
                class="canvasholder">
                <canvas id="renderCanvas1" style="width: 100%; height: 100%;"></canvas>
                <div id="vue-components"></div>
                <div id="vue-cabri-objects"></div>
                <div id="page-div">
                    <div id="vue-cabri-objects-percentage"></div>
                </div>
            </div>
            <div id="vue-visual-keyboard"></div>
            <div id="vue-the-bottom-toolbar"></div>
        </div>
    </div>
    <!-- /CABRI -->
    <ion-router-outlet id="main" [animated]="false"
        [ngClass]="{'opacityNone' : globalService.globalLoading || showSplash}">
    </ion-router-outlet>
</ion-app>