import { Actor } from "./lrs/actores";
import { XapiObject, XObjectType } from "./lrs/xapiobject";
import { LrsService } from "../services/lrs.service";
import { CabriDataService } from "../services/cabri-data.service";
import { GlobalService } from "../services/global.service";
import { AccountService } from "../services/account.service";
import { LrsUtils } from "./lrs/lrsUtils";
import { Journey } from "./journey";
import { NetworkService, ConnectionStatus } from "../services/network.service";
import { Status } from "./proposed-activity";
import { Result } from "./lrs/result";
import { XapiContext, XapiExtensions } from "./lrs/xapicontext";
import { LmsService } from "../services/lms.service";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
// import { Statement } from "./lrs/statement";
import { Phrase } from "./phrase";
import { CastelGrid } from "./castel-grid";
import { LrsVerbs, XapiVerbs } from "./lrs/xapiverbs";
import { Student } from "./student";
import { XApiResult } from "./lrs/xapiresult";
import { Statement } from "./statement";

export enum StatementTypes {
	instanciation = "instanciation",
	reponse = "reponse",
	achever = "achever",
	outside = "outside"
}

export enum StatementState {
	exercise = "exercise",
	journey = "journey",
	question = "question"
}

export enum StatementObjectProgression {
	start = "start",
	response = "response",
	completed = "completed"
}

export class LrsStatement {
	public actor = new Array<Actor>();
	public object: XapiObject;
	public verb: any;
	public context: XapiContext;
	public result: Result;
	public type: StatementTypes;
	public state: StatementState;
	public timeStamp: string;
	public stored: string;

	public durationOfJourney: string;
	public durationOfActivity: string;

	public statement: Statement[] = new Array();

	public lrsService: LrsService;
	public lmsService: LmsService;
	public cabriService: CabriDataService;
	public globalService: GlobalService;
	public accountService: AccountService;
	public networkService: NetworkService;
	public http: HttpClient;

	userIsAutenticated = false;
	public cabriActivity = true;

	public environment;

	requirements: {
		cabriService: CabriDataService;
		lmsService: LmsService;
		globalService: GlobalService;
		accountService: AccountService;
		lrsService: LrsService;
		http: HttpClient;
	};
	verbRequirement;

	constructor(requirements, cabri = true) {
		/** Initialisation du LRS */
		this.cabriService = requirements.cabriService;
		this.globalService = requirements.globalService;
		this.lrsService = requirements.lrsService;
		this.accountService = requirements.accountService;
		this.lmsService = requirements.lmsService;
		this.http = requirements.http;
		this.networkService = requirements.networkService;
		this.environment = environment;
		this.cabriActivity = cabri;
	}

	/**
	 * set actor in response mod
	 */
	public setActor(actorRequirement: any) {
		let currentActor: Actor;
		if (this.cabriActivity) {
			if (Array.isArray(actorRequirement)) {
				actorRequirement.forEach(student => {
					currentActor = this._setActorModel(student.id);
					this.actor.push(currentActor);
				});
			} else {
				currentActor = this._setActorModel(actorRequirement.id);
				this.actor.push(currentActor);
			}
		} else {
			currentActor = this._setActorModel(actorRequirement);
			this.actor.push(currentActor);
		}
		return this.actor;
	}

	/**
	 * Define actor Model
	 * @param student
	 */
	private _setActorModel(actorName: string): Actor {
		return {
			account: {
				homePage: "https://xapi.mathia.education",
				name: actorName
			}
		};
	}

	/**
	 * Define Verb based on state (need of second parameter for verb based on response state)
	 */
	public async setVerb(state: StatementState, verb?: string): Promise<any> {
		return new Promise(async resolve => {
			if (this.cabriActivity) {
				if (state === StatementState.exercise) {
					// exercise
					this.verb = XapiVerbs.get(LrsVerbs.initialized);
				} else if (state === StatementState.journey) {
					// journey
					if (this.lmsService.currentUserJourney.resume) {
						this.verb = XapiVerbs.get(LrsVerbs.resume);
					} else {
						this.verb = XapiVerbs.get(LrsVerbs.initialized);
					}
				} else if (state === StatementState.question) {
					// question
					this.verb = XapiVerbs.get(verb);
				}
			} else {
				this.verb = XapiVerbs.get(verb);
			}
			resolve(this.verb);
		});
	}

	public setObject(progression: StatementObjectProgression, objectJourneyParam = false) {
		if (this.cabriActivity) {
			if (progression === StatementObjectProgression.start) {
				return this._setObjectOnStart(objectJourneyParam);
			} else if (progression === StatementObjectProgression.response) {
				return this._setObjectOnResponse();
			} else if (progression === StatementObjectProgression.completed) {
				return this._setObjectOnComplete(objectJourneyParam);
			}
		} else {
			if (progression === StatementObjectProgression.start) {
				return this._setObjectReadingOnStart();
			}
		}
	}

	/**
	 * Result of statement based on progression type
	 *
	 * @param progression
	 * @param verb
	 */
	public setResult(verb: string) {
		if (verb === "completed") {
			// end of activity
			this.result = new Result(true, this.durationOfJourney ? this.durationOfJourney : this.durationOfActivity);
		} else {
			// on response
			const iso8601 = LrsUtils.duration8601(LrsUtils.responseTime);
			if (verb === "finished") {
				this.result = this.verb.display["en-US"].startsWith("passed") ? new Result(true, iso8601) : new Result(false, iso8601);
			} else {
				this.result = verb.startsWith("passed") ? new Result(true, iso8601) : new Result(false, iso8601);
			}
		}
		return this.result;
	}

	/**
	 * timestap set for offline and generation of traces for trala
	 */
	public setTimestamp(timestamp: string) {
		this.timeStamp = timestamp;
	}

	public setStored(stored: string) {
		this.stored = stored;
	}

	/**
	 * Object executed at the end of the activity. The last statement send
	 */
	private _setObjectOnComplete(objectJourneyParam) {
		let objectParams;
		const currentUser = this.accountService.team.find(member => {
			return Number(member.id) === Number(this.actor[0].account.name);
		});

		const perTotalGoodAnswer =
			((currentUser.awardsCurrent.shooting + currentUser.awardsCurrent.normal) / currentUser.awardsCurrent.total) * 100;
		const perTotalWrongAnswer = (currentUser.awardsCurrent.moons / currentUser.awardsCurrent.total) * 100;
		this.context.extensions["https://xapi.mathia.education/pourcentage_bonnes_reponses"] = perTotalGoodAnswer;
		this.context.extensions["https://xapi.mathia.education/pourcentage_mauvaises_reponses"] = perTotalWrongAnswer;
		if (objectJourneyParam) {
			// journey type
			objectParams = LrsUtils.getJourneyObjectParams(this.lmsService);
			if (!this.lmsService.currentUserJourney.resume) {
				const durationJourneyWithsecond = LrsUtils.timeStampConversion(LrsUtils.durationStartJourney);
				this.durationOfJourney = LrsUtils.duration8601(durationJourneyWithsecond);
			} else {
				const duration = this.durationTotalTrainingJourneyResume();
				if (duration) {
					const dateObj = new Date(Date.now() - duration * 1000).getTime();
					const durationJourneyWithsecond = LrsUtils.timeStampConversion(dateObj);
					this.durationOfJourney = LrsUtils.duration8601(durationJourneyWithsecond);
				}
			}
		} else {
			//exercise type
			objectParams = LrsUtils.getExerciseObjectParams(this.cabriService);
			const durationWithsecond = LrsUtils.timeStampConversion(this.lrsService.durationStartActivity);
			this.durationOfActivity = LrsUtils.duration8601(durationWithsecond);
		}

		this.context.extensions["https://xapi.mathia.education/durée_activité"] =
			this.durationOfJourney !== null ? this.durationOfJourney : this.durationOfActivity;

		const adaptative = LrsUtils.getExercisesAdaptativeInfos2(this.cabriService);
		this.object = {
			id: `https://xapi.mathia.education/${objectParams.type}/id/${objectParams.id}`,
			definition: {
				name: {
					"fr-FR": `${objectParams.name}`
				},
				description: {
					"fr-FR": `${objectParams.description}`
				},
				extensions: {
					[XapiExtensions.competences]: adaptative.competences,
					[XapiExtensions.erreursType]: adaptative.erreursTypes,
					[XapiExtensions.prerequis]: adaptative.prerequis,
					[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
					[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
				},
				type: objectParams.definitionType
			},
			objectType: "Activity"
		};
	}

	/**
	 * Object executed on student response.From second statement until the before last statement
	 */
	private _setObjectOnResponse() {
		this._updateResponseParams(StatementObjectProgression.response);
		const adaptative = LrsUtils.getExercisesAdaptativeInfos2(this.cabriService);
		let { definitionName, definitionDescription } = this._setDefinitionParams();
		// // Define context object
		this.object = {
			id: `https://xapi.mathia.education/question/id/${this.cabriService.currentExercice.id}`,
			definition: {
				name: {
					"en-US": definitionName
				},
				description: {
					"en-US": definitionDescription
				},
				extensions: {
					[XapiExtensions.competences]: adaptative.competences,
					[XapiExtensions.erreursType]: adaptative.erreursTypes,
					[XapiExtensions.prerequis]: adaptative.prerequis,
					[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
					[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
				},
				type: "https://xapi.mathia.education/objectType/question"
			},
			objectType: "Activity"
		};

		return this.object;
	}

	/**
	 * Define object definition params
	 */
	public _setDefinitionParams() {
		const typeOfActivity = LrsUtils.detectCurrentActivityNameById(+this.cabriService.currentActivity.id);
		let definitionName;

		if (!LrsUtils.currentOperation) {
			definitionName = `${this.cabriService.currentActivity.name} `;
		} else {
			if (typeOfActivity == "jeu_de_l'heure") {
				definitionName = `${LrsUtils.currentOperation} `;
			} else if (typeOfActivity == "jeu_de_kim") {
				definitionName = "jeu de kim";
			} else {
				definitionName = `Opération ${LrsUtils.currentOperation} `;
			}
		}

		return {
			definitionName,
			definitionDescription: `${LrsUtils.currentOperation}`
		};
	}

	public _updateResponseParams(progression: StatementObjectProgression.response) {
		if (progression === StatementObjectProgression.response) {
			let modeOperatoire = LrsUtils.getModeOperatoire(this.cabriService.currentExercice);
			// Gestion d'un id pour récupérer l'"historique" de toutes les événments sur une question
			LrsUtils.currentId = LrsUtils.generateUniqueId();
			this.context.contextActivities = this._getContextActivity();
			this.context.extensions[XapiExtensions.modeOperatoire] = modeOperatoire;
			this.context.extensions[XapiExtensions.reponse] = this.cabriService.participantAnswer;
			this.context.extensions[XapiExtensions.hid] = LrsUtils.currentId;
			this.context.extensions[XapiExtensions.resume] = this.lmsService.currentUserJourney?.resume;
			this.context.extensions[XapiExtensions.formatHeure] = LrsUtils.formatHeure ? LrsUtils.formatHeure + "h" : undefined;
			this.context.extensions[XapiExtensions.modeReponse] = LrsUtils.responseMode;
		}
	}

	/**
	 * Context activity on response
	 */
	private _getContextActivity() {
		let textHexadecimal = LrsUtils.toHex(this.cabriService.currentActivity.name);
		return {
			parent: [
				{
					id: `https://xapi.mathia.education/id/${textHexadecimal}`,
					objectType: "Activity"
				}
			]
		};
	}

	/**
	 * Object executed on start.First statement executed when the page is reloaded
	 *
	 * @param objectJourneyParam
	 */
	private _setObjectOnStart(objectJourneyParam = false) {
		let objectParams;
		if (objectJourneyParam) {
			objectParams = LrsUtils.getJourneyObjectParams(this.lmsService);
		} else {
			objectParams = LrsUtils.getExerciseObjectParams(this.cabriService);
		}

		const adaptative = LrsUtils.getExercisesAdaptativeInfos2(this.cabriService);
		LrsUtils.idsession = LrsUtils.defineExerciseSessionId(this.lmsService, this.cabriService, this.accountService);
		if (this.lmsService.currentUserJourney) {
			// journey requirements
			LrsUtils.assignationId = this.lmsService.currentUserJourney.assignationId;
			if (this.networkService.connectedStatus) {
				LrsUtils.parcoursId = this.lmsService.currentUserJourney.id;
			}
			// journey exercise step
			LrsUtils.jounreyExerciseStep = this.lmsService.currentUserJourney.exercises.find(ex => {
				return ex.status !== Status.done;
			})?.step;
			if (this.lmsService.currentUserJourney.idSession) {
				// journey session ID
				LrsUtils.idJourneySession = this.lmsService.currentUserJourney.idSession;
			} else {
				this.lmsService.currentUserJourney.idSession = LrsUtils.idJourneySession = LrsUtils.generateUniqueSessionId(
					this.accountService,
					false,
					this.lmsService
				);
			}
		}

		this.object = {
			id: `https://xapi.mathia.education/${objectParams.type}/id/${objectParams.id}`,
			definition: {
				name: {
					"fr-FR": `${objectParams.name}`
				},
				description: {
					"fr-FR": `${objectParams.description}`
				},
				extensions: {
					[XapiExtensions.competences]: adaptative.competences,
					[XapiExtensions.erreursType]: adaptative.erreursTypes,
					[XapiExtensions.prerequis]: adaptative.prerequis,
					[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
					[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
				},
				type: objectParams.definitionType
			},
			objectType: "Activity"
		};

		return this.object;
	}

	/**
	 * Context to be executed without exception on all type of statements (start,response,completion)
	 */
	public setContext(): XapiContext {
		// cabri
		let teamInfos = this.getTeamInfos();
		let activity = this.getActivityInfos();
		let exercise = this.getExerciseInfos();
		let modeAffichage = LrsUtils.getModeAffichageLesNombresHorloge(this.cabriService);
		let binome = LrsUtils.checkIfPair(this.accountService, this.cabriService);
		let codeclass = LrsUtils.getCodeClass(this.accountService);
		let modeJeu = LrsUtils.getModeJeu(this.globalService, this.cabriService);
		let { holo, remote } = LrsUtils.checkHoloRemote(this.cabriService.currentActivity);
		let kidaiaDevMode;
		if (this.environment.isKidaia) {
			kidaiaDevMode = !this.environment.production ? true : false;
		}

		this.context = {
			team: !teamInfos.team ? undefined : { member: teamInfos.team },
			extensions: {
				[XapiExtensions.idEquipe]: teamInfos.idTeam,
				[XapiExtensions.activite]: activity.manualName,
				[XapiExtensions.nomActivite]: activity.name,
				[XapiExtensions.idActivite]: activity.id,
				[XapiExtensions.idSession]: LrsUtils.idsession,
				[XapiExtensions.id]: exercise.id,
				[XapiExtensions.nomExercice]: exercise.name,
				[XapiExtensions.kidaia]: this.globalService.isKidaia ? true : false,
				[XapiExtensions.modeAffichage]: modeAffichage,
				[XapiExtensions.finAutomatique]: this.globalService.fastBilanFinished ? true : false,
				[XapiExtensions.controleAdistance]: remote,
				[XapiExtensions.holo]: holo,
				[XapiExtensions.binome]: binome,
				[XapiExtensions.codeclasse]: codeclass,
				[XapiExtensions.modeJeu]: modeJeu,
				[XapiExtensions.typeParcours]: this.lmsService.currentUserJourney?.difficulty,
				[XapiExtensions.recommandation]: this.lmsService.currentUserJourney?.recommandation,
				[XapiExtensions.kidaiadevmode]: kidaiaDevMode,
				[XapiExtensions.assignationId]: LrsUtils.assignationId,
				[XapiExtensions.parcoursEtape]: LrsUtils.jounreyExerciseStep,
				[XapiExtensions.parcoursid]: LrsUtils.parcoursId,
				[XapiExtensions.parcoursSessionId]: LrsUtils.idJourneySession,
				[XapiExtensions.story]: this.cabriService.currentActivity?.story ? true : false
			}
		};

		return this.context;
	}

	public async send() {
		return new Promise((resolve, reject) => {
			this.lrsService.userAutenticated.subscribe(autenticated => {
				if (autenticated) {
					this.userIsAutenticated = true;
					// send request if user is autenticated
					this.statement = this._getStatement;
					const encodedClientBasicAuth = localStorage.getItem("clientBasicAuth");
					this.statement.forEach(statement => {
						this.lrsService.sendRequest(statement).subscribe({
							next: request => {
								resolve(statement);
							},
							error: err => {
								console.error("error send statement on start", err);
								this.offlineStatementSave(statement);
								resolve(statement);
							}
						});
					});
				} else {
					this.userIsAutenticated = false;
					reject();
				}
			});
		});
	}

	offlineStatementSave(currentStatement) {
		const currentMoment = LrsUtils.getLrsTimestampFormat();
		currentStatement.timestamp = currentMoment;
		currentStatement.stored = currentMoment;
		this.lrsService.offlineStatementsStore.push(currentStatement);
		localStorage.setItem("statement", JSON.stringify(this.lrsService.offlineStatementsStore));
	}

	get isJourneyHaveState() {
		return (
			this.lmsService.currentUserJourney &&
			(this.lmsService.currentUserJourney.start || this.lmsService.currentUserJourney.resume) &&
			!this.lmsService.currentUserJourney.reload
		);
	}

	/**
	 * Total trainging duration of journey after the resume
	 */
	durationResumedTrainingJourney(sessionAskedQuestions) {
		if (sessionAskedQuestions && sessionAskedQuestions.allAskedQuestions.length > 0) {
			let journeyAskedQuestions = new Array();
			journeyAskedQuestions = sessionAskedQuestions.allAskedQuestions;
			// Group journeys maked in 3 minutes
			const training = journeyAskedQuestions.reduce((r, o, i, { [i - 1]: last }) => {
				let nextTimestamp;
				let lastTimestamp;
				if (o && o.timestamp) {
					nextTimestamp = new Date(o.timestamp);
				}
				if (last && last.timestamp) {
					lastTimestamp = new Date(last.timestamp);
				}
				if (!last || nextTimestamp - lastTimestamp > 3 * 60000) {
					r.push([o]);
				} else {
					r[r.length - 1].push(o);
				}
				return r;
			}, []);

			// Calculate total duration of journey by substraction of different timestamps
			let totalDuration = 0;
			for (const j in training) {
				// Only one timestamp
				if (training[j].length === 1) {
					totalDuration += Number(training[j][0].vTimer);
				} else {
					// Multiple timestamps
					training[j].forEach((item, index) => {
						if (training[j][index + 1]) {
							const next: any = new Date(training[j][index + 1].timestamp);
							const before: any = new Date(training[j][index].timestamp);
							totalDuration += (next - before) / 1000;
						}
					});
				}
			}
			if (typeof totalDuration === "number") {
				if (totalDuration < 0) {
					return null;
				} else {
					return Math.round(totalDuration);
				}
			}
		}
		return null;
	}

	getTeamInfos() {
		let idTeam = LrsUtils.getIdTeam(this.cabriService, this.accountService);
		let team = LrsUtils.getTeam(this.accountService);
		return { idTeam, team };
	}

	/**
	 * Duration of resume journey in training mod
	 */
	durationTotalTrainingJourneyResume() {
		const startedJourney = LrsUtils.durationStartJourney / 1000;
		const currentDate = new Date().getTime() / 1000;
		const asSeconds = currentDate - startedJourney;
		const totalFinalDuration = this.lmsService.currentUserJourney.duration + asSeconds;
		return Math.floor(totalFinalDuration);
	}

	public getExerciseInfos(): { id: number; name: string } {
		let id = LrsUtils.getExerciseId(this.cabriService, this.globalService);
		let name = LrsUtils.getExerciseName(this.cabriService, this.globalService);
		return { id, name };
	}

	public getActivityInfos(): { id: string; name: string; manualName: string } {
		let id = LrsUtils.getActivityId(this.cabriService, this.globalService);
		// same similar name extensions
		let name = LrsUtils.getActivityName(this.cabriService, this.globalService);
		let manualName = LrsUtils.detectCurrentActivityNameById(+this.cabriService.currentActivity.id);
		return { id, name, manualName };
	}

	private get _getStatement(): Statement[] {
		const statement: Statement[] = new Array();
		// this.actor.forEach((actor: Actor) => {
		// 	const currentStatement: Statement = {
		// 		actor: actor,
		// 		verb: this.verb,
		// 		object: this.object,
		// 		result: this.result,
		// 		timestamp: this.timeStamp,
		// 		stored: this.stored,
		// 		context: this.context
		// 	};
		// 	statement.push(currentStatement);
		// });
		return statement;
	}

	/**
	 * Generate a new or recover an existing journey session id according journey state (resume)
	 */
	defineJourneySessionId() {
		if (this.networkService.connectedStatus) {
			if (this.lmsService.currentUserJourney.idSession) {
				return this.lmsService.currentUserJourney.idSession;
			} else {
				return LrsUtils.generateUniqueSessionId(this.accountService, false, this.lmsService);
			}
		}
	}

	/**
	 * Get current journey exercise step which has not be validated yet
	 */
	getJourneyExerciseStep() {
		const currentExercise = this.lmsService.currentUserJourney.exercises.find(ex => {
			return ex.status !== Status.done;
		});

		if (currentExercise) {
			return currentExercise.step;
		}
	}

	/**
	 * Journey id
	 */
	get journeyId() {
		if (this.networkService.connectedStatus) {
			return this.lmsService.currentUserJourney.id;
		}
	}

	/**
	 * Journey duration
	 */

	get journeyStartDuration() {
		// durationStartJourney
		return new Date().getTime();
	}

	calculateJourneyFinallDuration() {
		const durationJourneyWithsecond = LrsUtils.timeStampConversion(this.journeyStartDuration);
		return LrsUtils.duration8601(durationJourneyWithsecond);
	}

	/**
	 * Verify if  journey's all exercises  have been completed
	 */
	get journeyCompleted() {
		return this.lmsService.currentUserJourney && !this.lmsService.currentUserJourney.nextActivityProposed;
	}

	/**
	 * Training total duration when it has been already played
	 * @param currentJourney
	 */

	setResumeTrainingDuration(currentJourney: Journey) {
		let journeyAskedQuestions = new Array();
		journeyAskedQuestions = currentJourney.allAskedQuestions;
		// Group journeys maked in 3 minutes
		const training = journeyAskedQuestions.reduce((r, o, i, { [i - 1]: last }) => {
			let nextTimestamp;
			let lastTimestamp;
			if (o && o.timestamp) {
				nextTimestamp = new Date(o.timestamp);
			}
			if (last && last.timestamp) {
				lastTimestamp = new Date(last.timestamp);
			}
			if (!last || nextTimestamp - lastTimestamp > 3 * 60000) {
				r.push([o]);
			} else {
				r[r.length - 1].push(o);
			}
			return r;
		}, []);

		// Calculate total duration of journey by substraction of different timestamps
		let totalDuration = 0;
		for (const j in training) {
			// Only one timestamp
			if (training[j].length === 1) {
				totalDuration += Number(training[j][0].vTimer);
			} else {
				// Multiple timestamps
				training[j].forEach((item, index) => {
					if (training[j][index + 1]) {
						const next: any = new Date(training[j][index + 1].timestamp);
						const before: any = new Date(training[j][index].timestamp);
						totalDuration += (next - before) / 1000;
					}
				});
			}
		}
		if (typeof totalDuration === "number") {
			if (totalDuration < 0) {
				return null;
			} else {
				return Math.round(totalDuration);
			}
		}
	}

	/**Reading Statement */
	private _setObjectReadingOnStart() {
		const activityChoosed = JSON.parse(localStorage.getItem("activitiesChoosed"));

		if (activityChoosed) {
			this.object = {
				id: `https://xapi.mathia.education/id/${activityChoosed[0].id}`,
				definition: {
					name: {
						"fr-FR": activityChoosed[0].titre
					},
					description: {
						"fr-FR": `La ${activityChoosed[0].titre} avec une difficulté de niveau ${activityChoosed[0].text_difficulty}`
					},
					extensions: {
						[XapiExtensions.difficulty]: activityChoosed[0].text_difficulty
					},
					type: `https://xapi.mathia.education/activity-type/${activityChoosed[0].type}/${activityChoosed[0].category}`
				},
				objectType: "Activity"
			};
		}
	}

	setReadingContext(activityId = null) {
		if (!this.cabriActivity) {
			// lecture
			let codeclass = LrsUtils.getCodeClass(this.accountService);
			this.context = {
				team: undefined,
				contextActivities: activityId
					? {
							parent: [
								{
									id: `https://xapi.mathia.education/activite_${activityId}`
								}
							]
					  }
					: undefined,
				extensions: {
					[XapiExtensions.codeclasse]: codeclass != null && !isNaN(codeclass) ? codeclass : 0,
					[XapiExtensions.nomActivite]: "lecture",
					[XapiExtensions.idActivite]: 2,
					[XapiExtensions.activite]: "lecture"
				}
			};
		}
	}

	public setObjectReadingOnNormal(phrase: Phrase, activityId: string) {
		const activityChoosed = JSON.parse(localStorage.getItem("activitiesChoosed"));
		if (activityChoosed) {
			this.object = {
				id: `https://xapi.mathia.education/activite_${String(activityId)}_question_${phrase.id}`,
				definition: {
					name: {
						"fr-FR": phrase.display
					},
					description: {
						"fr-FR": phrase.display
					},
					type: "https://xapi.mathia.education/objectType/question"
				},
				objectType: "Activity"
			};
		}
	}

	/** Random traces for trala*/

	/**
	 * Generate random operation with its appropriate response according to the verb type
	 */
	generateRandomResponseOperation(verb): { response: string; currentOperation: string } {
		const variables = this.cabriService.currentActivity.variables;
		let response: string;
		let currentOperation = null;
		if (Number(this.cabriService.currentActivity.id) === 1 || Number(this.cabriService.currentActivity.id) === 7) {
			if (Number(variables.operation) === 1) {
				currentOperation = String(variables.nb1 + " + " + variables.nb2);
			} else if (Number(variables.operation) === 2) {
				currentOperation = String(variables.nb1 + " * " + variables.nb2);
			} else if (Number(variables.operation) === 3) {
				currentOperation = String(variables.nb1 + " - " + variables.nb2);
			}
			// Bonnes réponses
			response = eval(currentOperation);
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = response + 1;
			}
		} else if (Number(this.cabriService.currentActivity.id) === 3) {
			if (Number(variables.operation) === 1) {
				currentOperation = String(variables.nb1 + " + " + variables.pas);
			} else {
				currentOperation = String(variables.nb1 + " - " + variables.pas);
			}
			// Bonnes réponses
			response = eval(currentOperation);

			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = response + 2;
			}
		} else if (Number(this.cabriService.currentActivity.id) === 5) {
			const randomHour = this._getHour();
			currentOperation = randomHour.goodResult;
			// Bonnes réponses
			response = randomHour.goodResult;
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = randomHour.multiChoices[0];
			}
		} else if (Number(this.cabriService.currentActivity.id) === 6) {
			const castelGrid = new CastelGrid();
			// Définir nombre de lignes et de colonnes
			castelGrid._nbRows = Number(variables["v-nbRow"]);
			castelGrid._nbCols = Number(variables["v-nbCol"]);
			if (Number(variables.operation) === 1) {
				castelGrid.mode = "addition";
			} else if (Number(variables.operation) === 2) {
				castelGrid.mode = "multiplication";
			} else if (Number(variables.operation) === 3) {
				castelGrid.mode = "soustraction";
			} else if (Number(variables.operation) === 4) {
				castelGrid.mode = "addition-classic";
			}
			// L'ensemble des résultats possibles au cours de la session
			castelGrid.generateUndisplayedNumbers(Number(variables["v-hidingLevel"]));
			const nbHiddenNumbers = Number(variables["v-nbHidden"]);
			const difficultyLevel = Number(variables["v-difficulty"]);
			const hiddenNumbers = castelGrid.generateHiddenNumbers(nbHiddenNumbers, difficultyLevel);
			const cellId = hiddenNumbers[Math.floor(Math.random() * hiddenNumbers.length)];

			// La cellule correspondante
			const currentCell = castelGrid.getCellById(Number(cellId));
			let operation = "";
			operation += castelGrid.cells[0][currentCell.col].value;
			if (castelGrid.mode === "addition" || castelGrid.mode === "addition-classic") {
				operation += " + ";
			} else if (castelGrid.mode === "multiplication") {
				operation += " * ";
			} else if (castelGrid.mode === "soustraction") {
				operation += " - ";
			}
			operation += castelGrid.cells[0][currentCell.row].value;
			currentOperation = operation;
			// Bonnes réponses
			response = eval(operation);
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				response = response + 2;
			}
		} else if (Number(this.cabriService.currentActivity.id) === 4) {
			let response = Math.floor(Math.random() * this.cabriService.currentActivity.variables["v-solides"].length);
			// Mauvaises réponses
			if (verb === "failed" || verb === "failed-on-first-attempt") {
				let searchWrongAnswer;
				do {
					searchWrongAnswer = Math.floor(Math.random() * this.cabriService.currentActivity.variables["v-solides"].length);
				} while (response == searchWrongAnswer);
				response = searchWrongAnswer;
			}
		}
		return { response, currentOperation };
	}

	/**
	 * Generate hour
	 */
	private _getHour() {
		let possibleMinutes = new Array();
		let minHour = 1;
		let maxHour = 24;
		if (this.cabriService.currentActivity.variables["v-difficulty"]) {
			if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 1) {
				possibleMinutes = [0];
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 2) {
				possibleMinutes = [0, 30];
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 3) {
				possibleMinutes = [0, 15, 30, 45];
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 4) {
				for (let i = 0; i < 24; i += 5) {
					possibleMinutes.push(i);
				}
			} else if (Number(this.cabriService.currentActivity.variables["v-difficulty"]) === 5) {
				for (let i = 0; i < 24; i++) {
					possibleMinutes.push(i);
				}
			}
		} else if (this.cabriService.currentActivity.variables["v-AmPm"]) {
			if (Boolean(this.cabriService.currentActivity.variables["v-AmPm"])) {
				minHour = 1;
				maxHour = 24;
			} else {
				minHour = 1;
				maxHour = 12;
			}
		}

		const date = LrsUtils.generateHour(possibleMinutes, maxHour, minHour);
		const dateFormatted = LrsUtils.formattingResult(date);
		return LrsUtils.createMultiChoice(dateFormatted, possibleMinutes, maxHour, minHour);
	}

	/**
	 * Verb based on percentage
	 */
	verbBasedOnPercentage(goodAnswers, numberOfQuestions, cursorMod = null) {
		const wrongAnswers = numberOfQuestions - goodAnswers;
		const isActivityWithHelp =
			Number(this.cabriService.currentActivity.id) === 1 ||
			Number(this.cabriService.currentActivity.id) === 7 ||
			Number(this.cabriService.currentActivity.id) === 4 ||
			Number(this.cabriService.currentActivity.id) === 5 ||
			Number(this.cabriService.currentActivity.id) === 6 ||
			Number(this.cabriService.currentActivity.id) === 10;

		const failedVerbToFilter = new Array();
		const passedVerbToFilter = new Array();

		if (cursorMod === "2" || !isActivityWithHelp || wrongAnswers === 0) {
			failedVerbToFilter.push("failed");
			passedVerbToFilter.push("passed");
		} else {
			failedVerbToFilter.push("failed", "failed-on-first-attempt");
			passedVerbToFilter.push("passed", "passed-with-help");
		}

		let failds = Object.keys(XapiVerbs.verbs).filter(verb => {
			return failedVerbToFilter.some(verbToFilter => {
				return verb === verbToFilter;
			});
		});
		let passed = Object.keys(XapiVerbs.verbs).filter(verb => {
			return passedVerbToFilter.some(verbToFilter => {
				return verb === verbToFilter;
			});
		});

		const finalFailds = new Array();
		const finalPassed = new Array();

		for (let i = 0; i < wrongAnswers; i++) {
			failds = failds.sort(() => {
				return 0.5 - Math.random();
			});
			finalFailds.push(failds[0]);
		}

		for (let i = 0; i < goodAnswers; i++) {
			passed = passed.sort(() => {
				return 0.5 - Math.random();
			});

			finalPassed.push(passed[0]);
		}
		let concatVerbs = finalFailds.concat(finalPassed).sort(() => {
			return 0.5 - Math.random();
		});

		concatVerbs.forEach((verb, index) => {
			const isPassedVerb = passed.some(passedVerb => passedVerb === verb);
			if (index === concatVerbs.length - 1) {
				concatVerbs[index] = isPassedVerb ? "passed" : "failed";
			} else {
				if (concatVerbs[index - 1]) {
					if (isPassedVerb) {
						if (verb === "passed-with-help") {
							if (concatVerbs[index - 1] === "passed" || concatVerbs[index - 1] === "failed-on-first-attempt") {
								concatVerbs[index] = "passed-with-help";
							} else {
								concatVerbs[index] = "passed";
							}
						} else {
							if (concatVerbs[index - 1] === "failed-on-first-attempt") {
								concatVerbs[index] = "passed-with-help";
							} else {
								concatVerbs[index] = "passed";
							}
						}
					} else {
						if (verb === "failed-on-first-attempt") {
							if (
								concatVerbs[index - 1] === "failed" ||
								concatVerbs[index - 1] === "passed" ||
								concatVerbs[index - 1] === "passed-with-help"
							) {
								concatVerbs[index] = "failed-on-first-attempt";
							} else {
								concatVerbs[index] = "failed";
							}
						} else {
							if (
								concatVerbs[index - 1] === "passed" ||
								concatVerbs[index - 1] === "failed" ||
								concatVerbs[index - 1] === "passed-with-help"
							) {
								concatVerbs[index] = "failed-on-first-attempt";
							} else {
								concatVerbs[index] = "failed";
							}
						}
					}
				} else {
					// first verb of array
					if (isPassedVerb) {
						concatVerbs[index] = "passed";
					} else {
						concatVerbs[index] = "failed-on-first-attempt";
					}
				}
			}
		});

		const finalVerbs = new Array();

		if (cursorMod === "2") {
			concatVerbs = concatVerbs.sort((a, b) => {
				if (a === "passed") {
					return -1;
				} else {
					return 1;
				}
			});

			const anyFailedExist = concatVerbs.some(verb => {
				return verb === "failed";
			});
			concatVerbs = concatVerbs.filter((verb, index) => {
				return verb === "passed";
			});
			if (anyFailedExist) {
				concatVerbs.push("failed");
			}
		}
		concatVerbs.forEach(verb => {
			finalVerbs.push({ verb, finished: LrsVerbs.finished });
		});

		return finalVerbs;
	}

	/**
	 * Get random activity param
	 */
	getRandomItem(array) {
		const randomNumber = Math.floor(Math.random() * array.length);
		return array[randomNumber];
	}
}
