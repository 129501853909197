import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { AccountService } from "./account.service";
import { CabriDataService } from "./cabri-data.service";
import { Student } from "../models/student";
import { Classroom } from "../models/classroom";
import { LrsService } from "./lrs.service";
import { PlayTTSService } from "./play-tts.service";
import { LrsUtils } from "../models/lrs/lrsUtils";
import { NetworkService } from "./network.service";
import { BehaviorSubject, lastValueFrom, throwError } from "rxjs";
import { Platform } from "@ionic/angular";
import { catchError, retry } from "rxjs/operators";
import { GlobalService } from "./global.service";
import { LmsService } from "./lms.service";
import { ConnectionStatusUpdaterService } from "./connection-status-updater.service";
import { LocalStorageService, StorageKey } from "./local-storage-service";
import { BaseService } from "./base.service";
import { ClassService } from "./class.service";
import { AppUtils } from "../app-utils";
declare var window: {
	location: any;
};
@Injectable({
	providedIn: "root"
})
export class RgpdGuard extends BaseService implements CanActivate {
	rgpd: any;
	accepted: boolean;
	rgpdUpdated = false;
	notDisplayRGPD = false;
	currentVersion: number;
	permissionStorageAsked = false;
	public currentUrl: any;
	public currentState: RouterStateSnapshot;
	public redirectionUrl: any;
	constructor(
		public http: HttpClient,
		public accountService: AccountService,
		public router: Router,
		public activatedRoute: ActivatedRoute,
		public cabriService: CabriDataService,
		public lrsService: LrsService,
		public networkService: NetworkService,
		private playTTS: PlayTTSService,
		private pfm: Platform,
		public classService: ClassService,
		public globalService: GlobalService,
		public lmsService: LmsService,
		public connectionUpdater: ConnectionStatusUpdaterService,
		public localStorageService: LocalStorageService
	) {
		super();
		if (localStorage.getItem("version")) {
			this.currentVersion = Number(localStorage.getItem("version"));
		} else {
			this.currentVersion = 0;
		}
		this.playTTS.killSpeech();
		this.currentUrl = window.location.href;
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		return new Promise(resolve => {
			this.currentState = state;
			if (!this.accountService.checkedUserRGPDState) {
				return this.http.get(`${this.postUrl}?action=app_mathia_rgpd_json`).subscribe(
					(data: any) => {
						const rgpdParsed = JSON.parse(data);
						this.rgpd = rgpdParsed;
						this.rgpdNewVersion(resolve);
						this.acceptedRGPD();
						this.accountService.checkedUserRGPDState = true;
					},
					err => {
						this.pfm.ready().then(() => {
							this.notDisplayRGPD = true;
							this.accountService.checkedUserRGPDState = true;
							resolve(true);
						});
					}
				);
			} else {
				console.log("no verification");
				resolve(true);
			}
		});
	}

	/** RGPD WITH DATE COMPARISON */
	rgpdNewVersion(resolve?) {
		if (!localStorage.getItem("version") && this.rgpdAccepted) {
			localStorage.setItem("version", this.rgpd.versionInfo.version);
			this.notDisplayRGPD = true;
			this.rgpdUpdated = false;
			this.currentVersion = this.rgpd.versionInfo.version;
		} else {
			if (new Date(this.rgpd.versionInfo.version).getTime() > new Date(this.currentVersion).getTime() && this.rgpdAccepted) {
				localStorage.setItem("version", this.rgpd.versionInfo.version);
				this.rgpdUpdated = true;
				this.currentVersion = this.rgpd.versionInfo.version;
				localStorage.removeItem("rgpd_accepted");
				this.notDisplayRGPD = false;
				if (!this.currentState.url.startsWith("/home") && !this.globalService.isKidaia) {
					this._redirectToHome();
				}
				resolve(true);
			} else if (!this.rgpdAccepted) {
				this.rgpdUpdated = true;
				this.notDisplayRGPD = false;
				this.currentVersion = this.rgpd.versionInfo.version;
				const url = new URL(this.currentUrl);
				const urlValues = this.urlIntoValues(url);

				this.globalService.checkIframeIntegration(urlValues, this.classService);

				this.cabriService.getAllActivitiesAsync().then(async () => {
					this.demandUserJourney(urlValues, resolve);
					if (urlValues.aid) {
						await this.cabriService.setActivityId(urlValues.aid);
					}
					if (urlValues.codeclasse && urlValues.uid && urlValues.token ) {
						// if (this.accountService.team.length === 0) {
							const newStudent = new Student(
								urlValues.uid,
								new Classroom(urlValues.codeclasse, urlValues.codeclasse, null),
								urlValues.prenom,
								true,
								false,
								1
							);
							this.accountService.team = [newStudent];
							localStorage.setItem("codeclasse", String(urlValues.codeclasse))
							localStorage.setItem("team", JSON.stringify(this.accountService.team))
							this.accountService.updateUserData(urlValues.uid);
							this.accountService.recoverStudentsInfos();
							if(urlValues.codeclasse){
								this.lrsService.autenticateEnterCodeClass(urlValues.codeclasse);
							}
						// }
						LrsUtils.assignationId = urlValues.assignation_id;
					} else if (this.globalService.isBeneylu) {
						if (this.accountService.team.length === 0) {
							const randomKey = AppUtils.createGuid();
							const newStudent = new Student(
								randomKey,
								new Classroom(randomKey, randomKey, null),
								urlValues.prenom ? urlValues.prenom : "petit astronaute",
								true,
								false,
								1
							);
							this.accountService.team = [newStudent];
							this.lrsService.autenticateEnterCodeClass(randomKey);
						}
					}  else if (this.globalService.isMathador) {
						if (this.accountService.team.length === 0) {
							urlValues.codeclasse = AppUtils.createGuid();
							const newStudent = new Student(
								urlValues.uid,
								new Classroom(urlValues.codeclasse, urlValues.codeclasse, null),
								urlValues.prenom,
								true,
								false,
								1
							);
							this.accountService.team = [newStudent];
							this.lrsService.autenticateEnterCodeClass(urlValues.codeclasse);
						}
					}
					if(this.globalService.inIframe){
						this.accepted = true;
						localStorage.setItem("rgpd_accepted", String(this.accepted));
					}
					if (urlValues.aid || (urlValues.codeclasse && urlValues.uid && urlValues.assignation_id)) {
						this.cabriService.toCabri = true;
						this.router.navigateByUrl(this.cabriService.currentActivity.routingPath);
					} else if(!this.globalService.inIframe){
						this.notDisplayRGPD = false;
						this.rgpdUpdated = true;
						// async vérifier rgpd à nouveau
						if (!this.currentState.url.startsWith("/home") && !this.rgpdAccepted && !this.globalService.isKidaia) {
							this._redirectToHome();
						}
					}
					resolve(true);
				});
			} else {
				this.notDisplayRGPD = true;
				this.rgpdUpdated = false;
				resolve(true);
			}
		}
		this.pfm.ready().then(() => {
			if (!this.connectionUpdater.rgpdUpdated) {
				this.localStorageService.set(StorageKey.rgpd, this.rgpd);
				this.connectionUpdater.rgpdUpdated = true;
			}
		});
	}

	public settingsConf() {}

	private _isBabylonPage() {
		return this.currentState.url === "/map" || this.currentState.url === "/accueil" || this.currentState.url === "/narration";
	}

	/**
	 * Need to accept the rgpd so redirect to the home page and pass or not params throught according conditions
	 */
	private _redirectToHome() {
		if (!this.accountService.user.codeClasse) {
			this.router.navigate(["/home"], { queryParams: { redirectTo: "/starting" } });
		} else if (this._isBabylonPage()) {
			this.router.navigate(["/home"], { queryParams: { redirectTo: "/activity-participants" } });
		} else {
			this.router.navigate(["/home"], { queryParams: { redirectTo: this.currentState.url } });
		}
	}

	/**
	 * Event handled when RGPD is accepted from home page
	 */
	acceptedRGPD() {
		if (this.rgpd.politiqueDeConfidentialite.checked) {
			this.globalService.showSettingParams.next("launch_wizard");
			this.globalService.displayVoiceSettingsStatus = true;
			this.globalService.displayVoiceSettingsAnimate = false;
			this.currentUrl = window.location.href;
			const url = new URL(this.currentUrl);

			if (!url.searchParams.get("redirectTo")) {
				this.notDisplayRGPD = true;
			}
			const redirectTo = url.searchParams.get("redirectTo");
			this.accepted = true;
			this.rgpdUpdated = false;
			localStorage.setItem("version", this.rgpd.versionInfo.version);
			if (redirectTo) {
				this.redirectionUrl = redirectTo;
			} else {
				// redirect only when before user losted connection
				if (this.cabriService.activities.length === 0) {
					this.redirectionUrl = "/starting";
				}
			}
			localStorage.setItem("rgpd_accepted", String(this.accepted));
			this.accountService.checkedUserRGPDState = true;
		}
	}

	get rgpdAccepted() {
		let result = localStorage.getItem("rgpd_accepted");

		if (result == null || result == undefined) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Get journey through the url
	 */
	async demandUserJourney(urlParamsObj, resolve?) {
		if (
			urlParamsObj.uid &&
			typeof urlParamsObj.uid !== "undefined" &&
			urlParamsObj.assignation_id &&
			typeof urlParamsObj.assignation_id !== "undefined"
		) {
			await this.classService.getSessionToken();
			this.accountService.user.codeClasse = urlParamsObj.codeclasse;
			this.getStudents().then(async (classRoom: Classroom) => {
				if (classRoom.students) {
					const selectedStudent = classRoom.students.find(student => {
						return Number(student.id) === Number(urlParamsObj.uid);
					});
					try {
						await this.accountService.getAssignations(classRoom);
						await this.lmsService.getAllClassJourneys(this.accountService.classroom);
						await this.accountService.getStudentJourneys(selectedStudent.id);
						if (this.lmsService.userJourneysByAssignation.length > 0) {
							// search journey by id
							const userJourneyTargetted = this.lmsService.userJourneysByAssignation.find(userJourney => {
								return Number(userJourney.assignationId) === Number(urlParamsObj.assignation_id);
							});
							if (userJourneyTargetted) {
								if (this.accountService.team.length === 0) {
									this.accountService.team = new Array();
									const newStudent = new Student(
										urlParamsObj.uid,
										new Classroom(urlParamsObj.codeclasse, urlParamsObj.codeclasse, null),
										urlParamsObj.prenom,
										true,
										false,
										1
									);

									this.accountService.team.push(newStudent);
									this.lrsService.autenticateEnterCodeClass(urlParamsObj.codeclasse);
								}
								await this.lmsService.launchJourney(userJourneyTargetted, this.cabriService, this.globalService);
							} else {
								console.error("Assignation id with that journey does not belong to the student");
							}
						}
					} catch (e) {
						console.log("error", e);
					}
				}
			});
			if (resolve) {
				resolve(true);
			}
			return;
		}
	}

	/**
	 * Gte url values
	 */
	urlIntoValues(url) {
		const codeclasse = url.searchParams.get("codeclasse");
		const prenom = url.searchParams.get("prenom");
		const uid = url.searchParams.get("uid");
		const aid = url.searchParams.get("aid");
		const test = url.searchParams.get("test");
		const token = url.searchParams.get("token");
		const org = url.searchParams.get("org");
		const assignationId = parseInt(url.searchParams.get("assignation_id"), 10);
		return { token, codeclasse, prenom, uid, aid, assignation_id: assignationId, test, org };
	}

	getStudents(): Promise<Classroom> {
		return new Promise(resolve => {
			this.classService
				.getClasseFromGroupDetail(this.accountService.user.codeClasse.toString())
				.pipe(
					catchError(async err => {
						await this.classService.getSessionToken();
						return throwError(() => new Error(err));
					}),
					retry(3)
				)
				.subscribe(
					(data: Classroom) => {
						resolve(data);
					},
					error => {}
				);
		});
	}
}
