<ng-container *ngIf="currentSpotGame">
  <div class="title-container">    
  </div>
  <div class="title-wrapper">
    <div class="headerContent" *ngIf="!won">
      <p class="wonTitle" >Trouve les {{totalIntruder}} erreurs présentes dans cette image ({{counterFindIntruder}}/{{totalIntruder}})</p>
      <div class="starsContainer">
        <ng-container *ngFor="let award of awardsStars;let i = index">
          <img class="star-award" [ngClass]="{'isAwarded':award,'isNotAwarded':!award}"  src="/assets/icon/etoile_full.png"/>
        </ng-container>
      </div>
    </div>

    <ion-button class="skip-button" (click)="nextActivity()" *ngIf="!environment.production || accountService.team[0].preview">{{accountService.team[0].preview?"Passer":"Dessin suivant"}}</ion-button>
  </div>

  <div class="svgDisplayedContainer">
    <pinch-zoom class="pinchZoomElem" #pinch [properties]="pZProperties" > 
      <div id="svg-wrapper" [ngClass]="{'isGrabbing':imageMoved}"  #svgWrapperErrors>
      </div>
    </pinch-zoom>
    <div *ngIf="displayTutoHorizontal || displayTutoVertical" class="tutoScrollImg" [ngClass]="{'isHorizontal':displayTutoHorizontal}"></div>
    <div class="controlBtn" *ngIf="this.pinchZoom?.isZoomedIn">
      <ion-icon name="chevron-back-outline" [hidden]="!needGoLeft" class="arrow-wrapper arrow-left" (click)="goHorizontal('left')"></ion-icon>
      <ion-icon name="chevron-back-outline" [hidden]="!needGoRight" class="arrow-wrapper arrow-right" (click)="goHorizontal('right')"></ion-icon> 
        <ion-icon name="chevron-back-outline" [hidden]="!needGoTop" class="arrow-wrapper arrow-top" (click)="goVertical('top')"></ion-icon>
        <ion-icon name="chevron-back-outline" [hidden]="!needGoBottom" class="arrow-wrapper arrow-bottom" (click)="goVertical('bottom')"></ion-icon>
    </div>
</div>

 
</ng-container>
<div id="nav-wrapper" *ngIf="!currentSpotGame && cabriService.spotDifference">
  <nav>
    <ul>
      <ng-container *ngFor="let spotGame of cabriService.spotDifference">
        <li (click)="loadSVG(spotGame.id)">{{spotGame.id}} - {{spotGame.title | titlecase}}</li>
      </ng-container>
    </ul>
  </nav>
</div>