import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Badge } from "src/app/models/badge";
import { AudioStoreService } from "src/app/services/audio-store.service";

@Component({
	selector: "app-reward",
	templateUrl: "./reward.component.html",
	styleUrls: ["./reward.component.scss"]
})
export class RewardComponent implements OnInit, OnDestroy {
	@Input() badges: Badge[];
	currentBadge: Badge;
	index: number;
	timeout: NodeJS.Timeout;

	constructor(public modalCtrl: ModalController, public audioStoreService: AudioStoreService) {}

	ngOnInit() {
		if (this.badges?.length > 0) {
			this.index = 0;
			this.currentBadge = this.badges[this.index];
		}
	}

	click(event: Event) {
		const element = (event.currentTarget as HTMLBaseElement).getElementsByClassName("box-body")[0];
		if (!element.classList.contains("click")) {
			element.classList.add("click");
			this.audioStoreService.loadSuccess();
			this.timeout = setTimeout(() => {
				this.audioStoreService.playSound();
			}, 1000);
		} else {
			element.classList.remove("click");
			if (this.timeout) {
				clearTimeout(this.timeout);
			}
			this.audioStoreService.stopSound();
			this.index++;
			if (this.badges.length > this.index) {
				this.currentBadge = this.badges[this.index];
			} else {
				this.modalCtrl.dismiss();
			}
		}
	}

	ngOnDestroy() {
		if (this.timeout) {
			clearTimeout(this.timeout);
		}
		this.audioStoreService.stopSound();
	}
}
