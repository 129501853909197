import { Param } from "./params/param";

export enum Status {
	done = "done",
	notDone = "notDone",
	eliminated = "eliminated"
}

export class ProposedActivity {
	step: string;
	journeyId?: number;
	activityId: number;
	exerciseId: number;
	name: string;
	level: string;
    params?: Array<Param>;
	status?: Status = Status.notDone;

	constructor(step: string, name: string, level?: string,
				         activityId?: number, exerciseId?: number, params?: Array<Param>, status?: Status ) {
		if (step) {
			this.step = step;
		}
		if (activityId) {
			this.activityId = activityId;
		}

		if (level) {
			this.level = level;
		}
		this.name = name;
		if (exerciseId) {
			this.exerciseId = exerciseId;
		}
		this.params = new Array<Param>();
		if (params) {
			this.params = params;
		}
		this.status = Status.notDone;
		if (status) {
			this.status = status;
		}
	}
}
