export class Badge {
	id: string;
	name: string;
	type: BadgeType;
	picture: string;
	icon: string;
	card: string;
	typeAchievement: BadgeAchievementType;
	competencyRequirement: string[];
	chapterRequirement: string;
	chapterStep: BadgeStepLocation;
	ruleRequirement: string;
	descriptionText: string;
	sttTextAcquired: string;
	unlocked: boolean;
	dateAcquired: Date;
}

export enum BadgeType {
	item = 1,
	picture = 2,
	skill = 3,
	award = 4
}

export enum BadgeAchievementType {
	step = "step",
	competency = "competency",
	rule = "rule",	
}

export enum BadgeStepLocation {
	introduction = 0,
	exercice1 = 1,
	exercice2 = 2,
	exercice3 = 3,
	exercice4 = 4,
	exercice5 = 5,
	conclusion = 6,
    cinematic = 7,
    chapter = 8
}
