<div
  [ngClass]="{'paramsContainer' : !tutorialParamTargetted, 'kidaiaParamsContainer': globalService.environment.kidaia, 'assignationParamContainer' : assignation, 'gabaritParamContainer': !assignation}"
  *ngIf="myParam.hidden == false">

  <ng-container *ngIf="!myParam.hidden && myParam.className == 'SelectParam'">
    <div class="paramWrapper" *ngIf="filteredSelectionList.length > 0 && !quickstartPage"
      [ngClass]="{'tutorialParamWrapper scrollbar-primary' : tutorialParamTargetted}">
      <div class="customLabelContainer"
        [ngClass]="{'customLabelContainerExercises':myParam.exercices,'customLabelContainerExercisesTM':myParam.exercices && !globalService.isDesktop}">
        <div class="customLabel">
          <ng-container *ngIf="globalService.isKidaia && myParam.exercices;else normalParams">
            <p class="customLabelText"><span i18n>Programme {{selectedClass}} </span><span
                *ngIf="myParam.exercices && !this.globalService.exerciseStatisticsOpened && cabriService.exercices?.selectedCategory?.catName">
                - {{cabriService.exercices.selectedCategory.catName}} </span></p>
          </ng-container>

          <ng-template #normalParams>
            <p class="customLabelText">{{myParam.label}} <span
                *ngIf="myParam.exercices && !this.globalService.exerciseStatisticsOpened && cabriService.exercices?.selectedCategory?.catName">
                - {{cabriService.exercices.selectedCategory.catName}} </span></p>
          </ng-template>
        </div>
      </div>

      <div *ngIf="!myParam.exercices && !displayNombreAutrementChoices" class="slidesWrapper">
        <ion-grid style="width:100%" class="modalBody scrollbar-primary">
          <ion-row>
            <ion-col size="1">
              <span class="prevButton" (click)="prevSlide()">&nbsp;</span>
            </ion-col>
            <ion-col size="10" class="ionColSlider">

              <swiper #ionSlides pager="false">
                <ng-template swiperSlide class="slide" *ngFor="let option of filteredSelectionList">
                  <div class="buttonsWrapper">
                    <div class="buttonBorder buttonBorderSlide">
                      <div class="button  elementList modalButtonWithImage selectedItem" #paramSelection>
                        <div class="iconTargetted">
                          <img [src]="option[4]" class="modalImg">
                        </div>
                        <div class="tutorialItemName">{{myParam.exercices? option.name:option[0]}}</div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </swiper>

            </ion-col>
            <ion-col size="1">
              <span class="nextButton" (click)="nextSlide()">&nbsp;</span>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <div *ngIf="displayNombreAutrementChoices">
        <div class="buttonsWrapper nombreAutrementWrapper">
          <ng-container *ngFor="let option of filteredSelectionList;let i=index;">
            <div class="buttonBorder">
              <div [ngClass]="(myParam.value === option[1])? 'selectedItem' : ''"
                class="button elementList modalButtonWithImage" (click)="updateParamValue(i);">
                <div class="iconTargetted">

                  <ion-img [src]="option[4]" class="modalImg"></ion-img>
                </div>
                <div>{{option[0]}}</div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- Exercices -->
      <ion-grid>
        <div class="listWrapper" *ngIf="myParam && myParam.exercices && !displayNombreAutrementChoices">
          <!-- Normal list of exercises -->
          <ion-row class="listWrapperExercisesRow"
            [ngClass]="globalService.exerciseStatisticsOpened ?  'listWrapperExercisesRowCategory' : ''">
            <!--Exercises with category -->
            <div class="eachCompetencies"
              *ngFor="let exerciseByStatus of cabriService.categoryNamesOrdered |keyvalue;let i=index; let last = last"
              [ngClass]="(globalService.isDesktop || (globalService.isTablet && globalService.isLandscape)) && globalService.exerciseStatisticsOpened ? 'eachCompetencies2Rows' : 'eachCompetencies1Row'">
              <app-exercises-statistics-details class="exerciseStatisticsContainer"
                *ngIf="globalService.exerciseStatisticsOpened" [exerciseByStatus]="exerciseByStatus"
                [currentActivity]="currentActivity" (click)="openExerciseCategory(exerciseByStatus.value)">
              </app-exercises-statistics-details>
              <div class="listOfExercises" *ngIf="exerciseByStatus.value.opened">
                <div *ngFor="let item of exerciseByStatus.value.items;let i=index; let last = last"
                  class="exercisesWrapper">
                  <div class="elementListBorder elementListNormal" [ngClass]="{'elementListBorderLastParam' : last}">
                    <ion-row class="modalContentWrapper elementList"
                      [ngClass]="{'modalContentWrapperTablet' : globalService.isTablet, 'modalContentWrapperDesktop':
                      globalService.isDesktop, 'modalContentWrapperMobile': globalService.isMobile, 'selectedItem' : (item.selected)}"
                      (click)="updateExerciseValue($event,item.exercise)">
                      <ion-col size="5"
                        [sizeXl]="!accountService.exerciseStatistics.exercisePerGoodAnswer[item.exercise.id] ? '7' : '5'"
                        [sizeSm]="globalService.isMobile ?  '7' : !accountService.exerciseStatistics.exercisePerGoodAnswer[item.exercise.id] ? '8' : '4'"
                        size-xs="9" class="leftModalPositionWrapper">
                        <p class="ion-text-left"> {{cabriService.exercices.extractor[item.exercise.id].title}} </p>
                      </ion-col>
                      <ion-col size="2" size-xl="2" [sizeSm]="globalService.isMobile ?  '2' : '4'" size-xs="2"
                        class="studentPerGoodAnswerCol"
                        *ngIf="!globalService.isMobile && accountService.exerciseStatistics.exercisePerGoodAnswer[item.exercise.id]">

                        <app-custom-simple-progressbar [mobileLandscape]="globalService.isMobileLandscape"
                          [percent]="accountService.exerciseStatistics.exercisePerGoodAnswer[item.exercise.id].result">
                        </app-custom-simple-progressbar>
                      </ion-col>

                      <ion-col size="5" class="ion-no-padding exerciseContainerCol"
                        [sizeSm]="globalService.isMobile ?  '5' : '4'" size-xs="3" size-xl="5">

                        <ion-row class="exerciseContainer">
                          <ion-col size="8" class="nombreAutrementDisplayModes"
                            *ngIf="(globalService.isMobileLandscape || globalService.isDesktop || globalService.isTablet)">
                            <ion-img *ngIf="item.exercise.displayResult.collection.value"
                              [src]="item.exercise.displayResult.collection.image"></ion-img>
                            <ion-img *ngIf="item.exercise.displayResult.letters.value"
                              [src]="item.exercise.displayResult.letters.image"></ion-img>
                            <ion-img *ngIf="item.exercise.displayResult.fingers.value"
                              [src]="item.exercise.displayResult.fingers.image"></ion-img>
                            <ion-img *ngIf="item.exercise.displayResult.coins.value"
                              [src]="item.exercise.displayResult.coins.image"></ion-img>
                            <ion-img *ngIf="item.exercise.displayResult.dice.value"
                              [src]="item.exercise.displayResult.dice.image"></ion-img>
                          </ion-col>

                          <ion-col
                            [attr.size]="(globalService.isMobileLandscape || globalService.isDesktop || globalService.isTablet) ? '4' : '12'"
                            size="4" class="ion-no-padding">
                            <p>{{cabriService.exercices.extractor[item.exercise.id].id}}</p>
                          </ion-col>
                        </ion-row>

                      </ion-col>

                    </ion-row>

                  </div>
                </div>
              </div>
            </div>
          </ion-row>
        </div>
      </ion-grid>
    </div>
    <ion-row class="globalParamContainer"
      [ngClass]="{'globalParamContainerPortrait' : !globalService.isLandscape, 'globalParamContainerLandscape' : globalService.isLandscape, 'globalParamContainerUseFullSpace':displayFullSpaceGrid, 'globalParamContainerHelp':displayParamToRow}"
      *ngIf="filteredSelectionList.length > 0 && quickstartPage">
      <ion-col size="4" sizeXs="{{displayFullSpaceGrid ? '12' : '4'}}" sizeMd="{{displayFullSpaceGrid ? '12' : '4'}}"
        sizeLg="{{displayFullSpaceGrid ? '12' : '3'}}" sizeXl="{{displayFullSpaceGrid ? '12' : '2'}}"
        class="paramNameWrapper">
        <ng-container *ngIf="displayFullSpaceGrid; else otherCases">
          <p>{{myParam.label}}</p>
        </ng-container>

        <ng-template #otherCases>
          <p *ngIf="myParam.name === 'holo'">Mode <br> holographique</p>
          <p *ngIf="myParam.name === 'input-method'">Mode <br> de réponse</p>
          <p *ngIf="myParam.name === 'remoteHost'">Contrôle <br> à distance</p>
        </ng-template>

      </ion-col>
      <ion-col class="paramValueCol" size="8" sizeXs="{{displayFullSpaceGrid ? '12' : '8'}}"
        sizeMd="{{displayFullSpaceGrid ? '12' : '8'}}" sizeLg="{{displayFullSpaceGrid ? '12' : '8'}}"
        sizeXl="{{displayFullSpaceGrid ? '12' : '10'}}">
        <ion-row class="paramValueContainer">
          <ng-container *ngFor="let option of filteredSelectionList;let i = index;">
            <ion-col sizeXs="3" sizeMd="3" sizeLg="3" sizeXl="3" class="eachParamContainer" [ngClass]="[(myParam.value === option[1]) && globalService.isKidaia ? 'kidaiaParamColSelected' : '',
              (myParam.value === option[1]) && !globalService.isKidaia ? 'mathiaParamColSelected' : '',
              globalService.isKidaia ? 'kidaiaParamCol' : 'mathiaParamCol',
              !globalService.isKidaia && globalService.isDesktop ? 'mathiaParamColDesktop' : '']"
              (click)="updateParamValue(i)">
              <div class="eachParamItem">
                <ion-icon [src]="option[4]" slot="icon-only" class="modalImg" name="add"></ion-icon>
                <!-- <ion-img [src]="option[4]" class="modalImg"></ion-img> -->
                <div class="eachParamItemNameContainer">
                  <p class="eachParamItemText">
                    {{option[0]}}
                  </p>
                </div>
              </div>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-col>

    </ion-row>
  </ng-container>



  <div *ngIf="!myParam.hidden && myParam.className == 'Param'" class="paramWrapper">
    <ion-item>
      <ion-label class="quicksand" position="stacked">{{myParam.label}}</ion-label>
    </ion-item>
  </div>
  <div *ngIf="!myParam.hidden && myParam.className == 'CheckParam'" class="paramWrapper">
    <ion-item>
      <ion-label class="quicksand">{{myParam.label}}</ion-label>
      <ion-icon *ngIf="myParam.tooltip" color="mathiaFontColor" slot="icon-only" name="help-circle-outline"
        class="helpIcon" [tooltip]='myParam.tooltip' placement='top' [click]='true' style="padding-left: 5px;">
      </ion-icon>
      <ion-toggle [(ngModel)]="myParam.value"></ion-toggle>
    </ion-item>
  </div>
  <div *ngIf="!myParam.hidden && myParam.className == 'RangeParam'" class="paramWrapper">
    <ion-item>
      <ion-label class="quicksand">{{myParam.label}}</ion-label>
      <ion-icon *ngIf="myParam.tooltip" color="mathiaFontColor" slot="icon-only" name="help-circle-outline"
        class="helpIcon" [tooltip]='myParam.tooltip' placement='top' [click]='true' style="padding-left: 5px;">
      </ion-icon>
      <ion-range [(ngModel)]="myParam.value" min="{{myParam.min}}" max="{{myParam.max}}" step="{{myParam.step}}"
        snaps="true" color="secondary"></ion-range>
    </ion-item>
  </div>


  <!-- </div> -->