import { Injectable } from "@angular/core";
import { Journey, journeyStatus, JourneyRecommendation, JourneysByStatus, JourneyMode } from "../models/journey";
import { ProposedActivity, Status } from "../models/proposed-activity";
import { catchError, lastValueFrom, ReplaySubject, Subject, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Exercices, Grade } from "../models/exercices";
import { BaseService } from "./base.service";
import { CabriDataService } from "./cabri-data.service";
import { Classroom } from "../models/classroom";
import { AppUtils } from "../app-utils";
import { NetworkService } from "./network.service";
import { LrsService } from "./lrs.service";
import { AccountService } from "./account.service";
import { environment } from "src/environments/environment";
import { JourneyStore } from "../models/journey-store";
import { ExerciseStatistics, StoredLrsStatements } from "../models/gamification-lrs";
import { ExerciseJourneyExtractor } from "../components/journey/journey.component";
import { AssignationStatistics, StudentExerciseStatistics } from "../models/lrs/studentExerciseStatistics";
import { CabriActivity } from "../models/cabri-activity";
import { GlobalService } from "./global.service";
import { LrsUtils } from "../models/lrs/lrsUtils";
import { Platform } from "@ionic/angular";
import { Exercise } from "../models/exercise";
import { ClassService } from "./class.service";
import { Student } from "../models/student";
import { Router } from "@angular/router";

@Injectable({
	providedIn: "root"
})
export class LmsService extends BaseService {
	// generic journeys
	public allJourneys: Journey[];
	// user assignated journeys
	public userJourneysByAssignation = new Array<Journey>();
	// journey selected from modal
	public userJourneyFromParams: Journey;
	// notify when lost connection without refresh
	public allActivitiesLoaded: ReplaySubject<any> = new ReplaySubject(1);
	public modalStudentJourneys = false;
	// journey that is playing
	public currentUserJourney: Journey;
	// journeys ordered by they statuses
	public allJourneysByStatus = new Array<JourneysByStatus>();
	public allJourneysByInProgressStatus = new Array<JourneysByStatus>();
	public journeyState: { [journeyId: string]: Journey[] };
	public lastMadeJourney: Journey;
	public storedJourneyStatementLoad = false;
	public allStartedCompletedJourneys: { [journeyId: string]: Journey[] };
	// all assignated journeys
	public journeysByAssignation: Journey[];
	public _globalJourneys: Journey[] = [];
	public allJourneyLoaded = false;
	public currentUserJourneyId: number;
	public environment;
	public randomRecommandedJourney = false;
	public storedJourney: JourneyStore;
	public startRecommendedJourney = false;
	public exerciseStatistics: ExerciseStatistics;
	public exerciseStatisticsLoaded = new Subject();
	public exerciseStatisticsLoadedSubscription: Subscription;
	public journeyStatistics: AssignationStatistics;
	extractor: ExerciseJourneyExtractor = {};
	exerciseStatisticsDetails: StudentExerciseStatistics;
	alreadyDoneExercises: any;
	public resume: boolean;
	public gamification: StoredLrsStatements;

	constructor(
		private http: HttpClient,
		public platform: Platform,
		private classService: ClassService,
		public networkService: NetworkService,
		public router: Router,
		public globalService: GlobalService
	) {
		super();
		this.environment = environment;
	}

	initGamification(lrsService: LrsService, lmsService: LmsService, cabriService: CabriDataService, accountService: AccountService) {
		this.gamification = new StoredLrsStatements(lrsService, lmsService, cabriService, this.networkService, accountService);
	}

	/**
	 * Filter upstream trala assignation journeys
	 *
	 * @param assignJourney journey recover from trala
	 * @param array filter keep real journeys
	 */

	getTralaAssignationJourneys(assignJourney, array) {
		// all student exercises in trala forma
		const posInd = array.findIndex(exercise => {
			return assignJourney.content.source === exercise.content.source && exercise.content.source != null;
		});

		// push into array if exercise not exist or if it is a journey
		if (posInd === -1 && assignJourney.content.difficulty && assignJourney.content.difficulty.length > 0) {
			array.push(assignJourney);
		}
		return array;
	}

	/**
	 * Filter  bilan journeys from the list when not internet connection
	 */
	filterBilanOffline(journey: Journey[]) {
		const journeys = journey.filter(j => {
			return !j.bilan;
		});

		return journeys;
	}

	// /**
	//  * Update journey coming from the lrs and sync with globalJourneys (delete not existing one)
	//  */
	// updateLrsJourneyList() {
	// 	for (const journeyId in this.allJourneysByStatus[0]) {
	// 		if (journeyId) {
	// 			const result = this.allJourneysByStatus[0][journeyId].items.filter(lmsJourney => {
	// 				return this._globalJourneys.some(globalJ => {
	// 					return lmsJourney.id === globalJ.id;
	// 				});
	// 			});

	// 			// no need of condition
	// 			if (result?.length > 0) {
	// 				this.allJourneysByStatus[0][journeyId].items = result;
	// 			}
	// 		}
	// 	}
	// }

	/**
	 * Only get student statistics displayed in modal
	 */
	getStudentExercisesStatistics(accountService: AccountService, cabriService: CabriDataService, currentActivity: CabriActivity) {
		if (this.exerciseStatistics && currentActivity) {
			this.exerciseStatisticsDetails = new StudentExerciseStatistics(
				this.exerciseStatistics,
				accountService.team[0].id,
				cabriService,
				currentActivity
			);
			accountService.exerciseStatistics.completedExercises = this.exerciseStatisticsDetails.countCompletedExercises;
			accountService.exerciseStatistics.averageRateCorrectAnswers = this.exerciseStatisticsDetails.averageRateCorrectAnswers;
			accountService.exerciseStatistics.exercisePerGoodAnswer = this.exerciseStatisticsDetails.exercisePerGoodAnswer;
			accountService.exerciseStatistics.totalNumberAwardsByActivity = this.exerciseStatisticsDetails.totalNumberAwardsByActivity;
		}
	}

	getAssignationStatistics() {
		if (!this.exerciseStatisticsDetails) {
			this.exerciseStatisticsDetails = new StudentExerciseStatistics(this.exerciseStatistics);
		}

		this.journeyStatistics = this.exerciseStatisticsDetails.getTotalAssignationStatistics(
			this.allJourneysByStatus,
			this.getStatusAsIndex(journeyStatus.completed)
		);
	}

	/**
	 * Get all trala journeys and convert them as local Journey
	 */
	getAllJourneys(cabriService: CabriDataService) {
		return new Promise(async (resolve, reject) => {
			let journeysPromise: Promise<Journey[]>;
			// with connection
			journeysPromise = new Promise(async resolveJourneys => {
				return this.http
					.get<any>(this.postApiMathia + "/getAllJourneys")
					.pipe(
						catchError(async err => {
							console.error("request server journeys crashed", err);
							return await lastValueFrom(this.http.get<any>("/assets/json/journeys.json"));
						})
					)
					.subscribe({
						next: journeys => {
							resolveJourneys(journeys);
						}
					});
			});
			journeysPromise.then(journeys => {
				this._globalJourneys = journeys;
				// filter journeys without exercices
				this._globalJourneys.filter(currentData => {
					return currentData.exercises && Array.isArray(currentData.exercises) && currentData.exercises.length > 0;
				});

				// create ProposedActivity object for each exercise of each journey
				this._globalJourneys.forEach((journey, index, currJourney) => {
					const currentJourney = new Journey(
						cabriService,
						this,
						Number(journey.id),
						journey.educationalLevel,
						null,
						journey.title,
						journey.difficulty,
						false,
						false
					);
					currentJourney.model = journey.model;
					currentJourney.uid = journey.uid;

					if (journey.exercises && Array.isArray(journey.exercises) && journey.exercises.length > 0) {
						let indexCurr = 0;
						journey.exercises.forEach((exId, index2) => {
							// find this exercice
							const exercise = cabriService.exercices.getExercise(exId);
							if (exercise) {
								const instProposedActivity = new ProposedActivity(
									journey.id + "-" + indexCurr,
									exercise.name,
									exercise.classe,
									Number(exercise.gabarit),
									exercise.id,
									new Array()
								);
								currentJourney.exercises.push(instProposedActivity);
								indexCurr++;
							} else {
								console.error("exercice not found :", exId);
							}
						});
					}
					currJourney[index] = currentJourney;
				});
				this.allJourneyLoaded = true;
				resolve(true);
			});
		});
	}

	/**
	 * Filter allowed journeys for a class
	 * @returns Generic and teacher's journeys
	 */
	getAllClassJourneys(classroom: Classroom): Promise<any> {
		return new Promise(async (resolve, reject) => {
			// wait all journey to be ready
			while (!this.allJourneyLoaded) {
				await AppUtils.timeOut(100);
			}
			// filter journeys not owned by teacher
			this.allJourneys = this._globalJourneys.filter((currentData: Journey) => {
				return currentData.model || Number(currentData.uid) === Number(classroom.idProf);
			});

			if (!this.networkService.connectedStatus) {
				this.allJourneys = this.filterBilanOffline(this.allJourneys);
			}

			resolve(this.allJourneys);
		});
	}

	/**
	 * Get all recommendations for a classe or just for one student if studentId set
	 * @param studentId (optional) to get only one recommendation
	 * @return Promise<JourneySuggestion[]>
	 */
	private getAiRecommendations(codeClasse: number | string, studentId?: string): Promise<JourneyRecommendation[]> {
		return new Promise((resolve, reject) => {
			this.classService.getSessionToken().then(
				(token: string) => {
					const searchParams = new URLSearchParams();
					searchParams.append("codeClasse", codeClasse.toString());
					if (studentId) {
						searchParams.append("student", studentId.toString());
					}
					searchParams.append("token", token);

					this.http
						.get<JourneyRecommendation[]>(this.postApiMathia + `/recommendations?${searchParams.toString()}`)
						.pipe(
							catchError(async err => {
								console.error("err while recovering journey recommendation");
								return [null];
							})
						)
						.subscribe({
							next: data => {
								resolve(data);
							}
						});
				},
				error => {
					reject(error);
				}
			);
		});
	}

	/**
	 * Get All journey Recommended by api.mathia for one student.
	 * @return Promise<Journey[]>
	 */
	getAiRecommendation(
		cabriService: CabriDataService,
		accountService: AccountService,
		recommandedLrsJourney: Journey = null,
		resume = false
	): Promise<Journey> {
		return new Promise(async (resolve, reject) => {
			if (!this.allJourneyLoaded) {
				try {
					await this.getAllJourneys(cabriService);
				} catch (error) {
					reject(error);
				}
			}

			const codeClasse = accountService.team[0].classe.id;
			const student = accountService.team[0];
			this.getAiRecommendations(codeClasse, student.id).then(
				async (journeySuggested: JourneyRecommendation[]) => {
					let journey;
					if (!journeySuggested[0] || journeySuggested[0] === null) {
						const startedCompletedJourneyIds = this.getCompletedJourneysIdsFromHistory();
						// choose the journey which is adventure mode if it's had not been yet realized.
						journey = this._globalJourneys.find(
							j =>
								j.isAdventureBilan &&
								j.level === student.level &&
								startedCompletedJourneyIds.every(journeyId => {
									return journeyId !== j.id;
								})
						);
						if (!journey) {
							journey = this.getRandomRecommendedJourney(accountService, cabriService, null, true);
							this.randomRecommandedJourney = true;
						}
					} else {
						if (journeySuggested[0].journeyDifficulty === JourneyMode.bilan) {
							// ai recommended bilan journey
							const startedCompletedJourneyIds = this.getCompletedJourneysIdsFromHistory();
							// choose the journey which is adventure mode if it's had not been yet realized.
							journey = this._globalJourneys.find(
								j =>
									j.isAdventureBilan &&
									j.level === student.level &&
									startedCompletedJourneyIds.every(journeyId => {
										return journeyId !== j.id;
									})
							);
						}
						if (!journey) {
							journey = this._globalJourneys.find(j => j.id === journeySuggested[0].journey);
							const targettedExercises = journey.exercises.map(ex => {
								return cabriService.exercices.getExercise(ex.exerciseId);
							});
							let noCompetenciesToValidate = false;
							if (targettedExercises?.length > 0) {
								noCompetenciesToValidate = targettedExercises.every((ex: Exercise) => {
									return Object.keys(ex.competencesValidees)?.length === 0;
								});
							}

							if (noCompetenciesToValidate) {
								journey = this.getRandomRecommendedJourney(accountService, cabriService);
							}

							if (!recommandedLrsJourney) {
								this.startRecommendedJourney = true;
							} else {
								this.startRecommendedJourney = false;
							}

							if (
								recommandedLrsJourney &&
								recommandedLrsJourney.id === journey.id &&
								resume &&
								!recommandedLrsJourney.bilan
							) {
								const notDoneExercises = recommandedLrsJourney.exercises.filter((ex: ProposedActivity) => {
									return ex.status === Status.notDone;
								});

								if (notDoneExercises.length !== journey.exercises.length) {
									journey.exercises = notDoneExercises;
								}
							} else if (recommandedLrsJourney && recommandedLrsJourney.id !== journey.id && noCompetenciesToValidate) {
								journey = recommandedLrsJourney;

								journey.exercises = journey.exercises.filter((ex: ProposedActivity) => {
									return ex.status === Status.notDone;
								});

								if (journey.exercises.length === 0) {
									journey = this.getRandomRecommendedJourney(accountService, cabriService, journey);
								}
							}
						}
					}
					resolve(journey);
				},
				error => {
					reject(error);
				}
			);
		});
	}

	/**
	 * Get all completed journeys ids from history of statements
	 *
	 * @returns Array of journey ids
	 */
	public getCompletedJourneysIdsFromHistory(): Array<number> {
		const startedCompletedJourneyIds: Array<number> = new Array();
		if (this.allStartedCompletedJourneys && Object.keys(this.allStartedCompletedJourneys).length > 0) {
			for (const journeyId in this.allStartedCompletedJourneys) {
				if (journeyId) {
					const journey = this.allStartedCompletedJourneys[journeyId].find(currJ => {
						return currJ.exercises?.every(exercise => {
							return exercise.status === Status.done;
						});
					});
					if (journey) {
						startedCompletedJourneyIds.push(Number(journeyId));
					}
				}
			}
		}
		return startedCompletedJourneyIds;
	}

	/**
	 * if no any journey to recommend so get random journey according to the student level
	 */
	public getRandomRecommendedJourney(
		accountService: AccountService,
		cabriService: CabriDataService,
		journey?: Journey,
		recommended = false
	) {
		let notYetDoneJourneysByStudentLevel: Journey[];
		this.randomRecommandedJourney = true;

		// for CM1 and CM2 filter CE2 journeys otherwise select student level
		const studentLevel =
			accountService.team[0].level === Grade.cm1 || accountService.team[0].level === Grade.cm2
				? Grade.ce2
				: accountService.team[0].level;

		if (journey) {
			notYetDoneJourneysByStudentLevel = this._globalJourneys.filter(currJ => {
				return currJ.id !== 239 && currJ.id !== 240 && currJ.id !== 241 && currJ.id !== journey.id && currJ.level === studentLevel;
			});
		} else if (recommended) {
			// keep not bilan journeys
			notYetDoneJourneysByStudentLevel = this._globalJourneys.filter(currJ => {
				return currJ.id !== 239 && currJ.id !== 240 && currJ.id !== 241 && currJ.level === studentLevel && !currJ.bilan;
			});
		} else {
			notYetDoneJourneysByStudentLevel = this._globalJourneys.filter(currJ => {
				return currJ.id !== 239 && currJ.id !== 240 && currJ.id !== 241 && currJ.level === studentLevel;
			});
		}

		if (notYetDoneJourneysByStudentLevel?.length > 0) {
			// filter and keep not yet made journeys
			const startedCompletedJourneyIds = this.getCompletedJourneysIdsFromHistory();
			const journeysNotDone = notYetDoneJourneysByStudentLevel.filter(j => {
				return startedCompletedJourneyIds.every(journeyId => {
					return journeyId !== j.id;
				});
			});

			if (journeysNotDone?.length > 0) {
				notYetDoneJourneysByStudentLevel = journeysNotDone;
			}
		}
		if (this.exerciseStatistics) {
			this.exerciseStatisticsDetails = new StudentExerciseStatistics(
				this.exerciseStatistics,
				accountService.team[0]?.id,
				cabriService
			);
			const allDoneExercises = this.exerciseStatisticsDetails.getListOfJourneyExercisesCompleted();
			const restNotDoneExercise = notYetDoneJourneysByStudentLevel.filter(filterJourneys => {
				return filterJourneys.exercises.some(journeyEx => {
					return !allDoneExercises.includes(journeyEx.exerciseId);
				});
			});

			if (restNotDoneExercise?.length > 0) {
				const notDoneExercise = new Array();
				restNotDoneExercise[0].exercises.forEach(ex => {
					const result = !allDoneExercises.includes(ex.exerciseId);
					if (result) {
						notDoneExercise.push(ex);
					}
				});
				if (notDoneExercise?.length > 0 && restNotDoneExercise[0].exercises?.length > 0) {
					restNotDoneExercise[0].exercises = notDoneExercise;
					return restNotDoneExercise[0];
				} else {
					return this.getRandomJourney(notYetDoneJourneysByStudentLevel);
				}
			} else {
				return this.getRandomJourney(notYetDoneJourneysByStudentLevel);
			}
		} else {
			return this.getRandomJourney(notYetDoneJourneysByStudentLevel);
		}
	}

	getRandomJourney(journeys: Journey[]) {
		const randomJourneyInd = Math.floor(Math.random() * journeys.length);
		return this._globalJourneys[randomJourneyInd];
	}

	/**
	 * Add step to exercice
	 */
	addDiagnosticExercisesSteps(allExercises: ProposedActivity[], exercices: Exercices) {
		const exercisesTargetted = new Array();
		let indexStep = 0;

		// Les exercices du parcours correspondant parmis tous les exercices
		for (const journeyExercise of allExercises) {
			const exercise = exercices.getExercise(journeyExercise.exerciseId);
			exercise.step = indexStep;
			exercisesTargetted.push(exercise);
			indexStep++;
		}
		return exercisesTargetted;
	}

	/**
	 * Extract / split title from id
	 */
	extractJourneyIdFromTitle() {
		for (const journeyStatusId in this.allJourneysByStatus[0]) {
			if (this.allJourneysByStatus[0][journeyStatusId]) {
				this.allJourneysByStatus[0][journeyStatusId].items.forEach(j => {
					// for journeys
					if (!this.extractor[j.id]) {
						this.extractor[j.id] = {};
					}
					let title;
					let id;
					if (j.model) {
						const journeyCompletedTitle = j.title.split("-");
						if (journeyCompletedTitle.length === 1) {
							title = journeyCompletedTitle[0];
						} else {
							id = journeyCompletedTitle[0];
							title = "";
							for (let i = 1; i < journeyCompletedTitle.length; i++) {
								title += journeyCompletedTitle[i] + (i < journeyCompletedTitle.length - 1 ? "-" : "");
							}
						}
					} else {
						title = j.title;
					}
					if (title) {
						this.extractor[j.id].title = title.trim();
					}

					if (id) {
						this.extractor[j.id].id = id.trim();
					}
				});
			}
		}
	}

	/**
	 * @todo adapt it for mathia in
	 * Get recommanded journey for current student
	 */
	async getStoryJourney(globalService: GlobalService, cabriService: CabriDataService, accountService: AccountService) {
		try {
			await this.getAllClassJourneys(accountService.classroom);
		} catch (err) {
			console.log("err recover journeys");
		}
		accountService.restoreTeam(accountService.allStudents);
		// Get last recommanded journey
		try {
			const recommendedJourney: any = await this.getNextStoryJourney(cabriService, accountService);
			if (recommendedJourney) {
				// check if journey as to be resume
				let journeyInProgress;
				if (!this.allStartedCompletedJourneys || (recommendedJourney.bilan && !recommendedJourney.isAdventureBilan)) {
					// no stored data or bilan (to be restarted every time)
					const journeyTargetted = this.getJourneyById(recommendedJourney.id);
					if (this.startRecommendedJourney) {
						this.resume = false;
					} else {
						this.resume = journeyTargetted && journeyTargetted.exercises.length !== recommendedJourney.exercises.length;
					}
					await this.defineAiRecommandationJourney(recommendedJourney, cabriService, accountService, globalService);
				} else {
					// Verify where the student did stop last time
					journeyInProgress = await accountService.searchRecommandedJourney(
						this.allStartedCompletedJourneys as any,
						recommendedJourney,
						this._globalJourneys
					);
					if (journeyInProgress) {
						if (!journeyInProgress._id) {
							this.resume = false;
						} else {
							this.resume = true;
							journeyInProgress.idSession = journeyInProgress._id;
						}
						delete journeyInProgress._id;
					} else {
						// recommended journey never done
						this.resume = false;
					}
					await this.defineAiRecommandationJourney(recommendedJourney, cabriService, accountService, globalService);
				}
				if (this.randomRecommandedJourney) {
					this.resume = false;
					this.randomRecommandedJourney = false;
				}
			}
		} catch (err) {
			console.error("err", err);
		}
		this.storedJourneyStatementLoad = false;
	}

	/**
	 * define Journey as model
	 */
	async defineAiRecommandationJourney(
		journeyAi: Journey,
		cabriService: CabriDataService,
		accountService: AccountService,
		globalService: GlobalService
	) {
		accountService.defineAiUserJourneysState(journeyAi);
		this.userJourneyFromParams = journeyAi;
		if (!this.userJourneyFromParams.bilan) {
			const allCompleted = this.checkAllTrainingExercisesDone(this.userJourneyFromParams);
			if (allCompleted) {
				// For journey other than bilan don't resume a journey if all exercises have been completed
				this.resume = false;
			}
			await this.defineTrainingUserJourney(
				this.userJourneyFromParams,
				this.allStartedCompletedJourneys,
				accountService,
				cabriService
			);
			if (globalService.environment.kidaia) {
				cabriService.currentActivity.paramHidden("holo", { "-1": true });
			}
			cabriService.currentActivity.setParamValue("v-cursor-mod", "1");
		} else {
			// await this.defineDiagnosticUserJourney(this.lmsService.userJourneyFromParams,false);
			this.currentUserJourney = this.userJourneyFromParams;
			// console.error("this.userJourneyFromParamsthis.userJourneyFromParams", this.userJourneyFromParams);
			if (!globalService.isKidaia) {
				this.currentUserJourney = accountService.recommendedJourneyModel(this.currentUserJourney);
			}
			// select activity and exercise concerned
			cabriService.currentActivity = this.currentUserJourney.defineJourneySelectedActivity(this.currentUserJourney.exercises[0]);
			if (globalService.environment.kidaia) {
				cabriService.currentActivity.paramHidden("holo", { "-1": true });
			}
			cabriService.currentActivity.setParamValue("v-cursor-mod", "3");
			accountService.countAwards(false, this.currentUserJourney);
		}

		if (globalService.isKidaia) {
			this.currentUserJourney.kidaia = true;
		}
		this.currentUserJourney.recommandation = true;
		// this.currentUserJourney.assignationId = null;
		// this.currentUserJourney.assignation = false;
		this.currentUserJourney.allAskedQuestions = new Array();
		if (this.resume) {
			LrsUtils.resume = true;
			this.currentUserJourney.resume = true;
		} else {
			this.currentUserJourney.start = true;
			this.currentUserJourney.idSession = null;
		}
		// LrsUtils.assignationId = undefined;
	}

	checkAllTrainingExercisesDone(currentJourney: Journey): boolean {
		return currentJourney.exercises.every((ex: ProposedActivity) => {
			return ex.status === Status.done;
		});
	}

	async getNextStoryJourney(cabriService: CabriDataService, accountService: AccountService) {
		this.storedJourneyStatementLoad = false;
		try {
			const resultRecommanded = await this.gamification.getStoredJourneys();
			if (resultRecommanded.recommandedJourney) {
				this.allStartedCompletedJourneys = resultRecommanded.recommandedJourney;
			}

			if (resultRecommanded.recommandedJourneyByStatus) {
				this.allJourneysByInProgressStatus = resultRecommanded.recommandedJourneyByStatus;
			}
		} catch (err) {
			console.error("internet ?", err);
		}
		const recommandedLrsJourney = this._getRecommandedJourney;
		if (recommandedLrsJourney) {
			// already journey in progress
			const searchLrsJourney = this._globalJourneys.find(j => {
				return Number(recommandedLrsJourney.id) === Number(j.id);
			});

			if (searchLrsJourney && !searchLrsJourney.isAdventureBilan && searchLrsJourney.bilan && this.allJourneysByInProgressStatus) {
				this.startRecommendedJourney = true;
				let getDiagnosticJourney = null;
				const notDoneExercises = recommandedLrsJourney.exercises.filter(ex => {
					return ex.status === Status.notDone;
				});

				if (notDoneExercises.length > 0) {
					// keep not done exercises
					recommandedLrsJourney.exercises = notDoneExercises;
					getDiagnosticJourney = await this._verifyRecommandedBilanAvailability(
						recommandedLrsJourney,
						cabriService,
						accountService
					);
				}
				// console.log("diagnosticResult2", diagnosticResult);
				console.log("diagnosticResult2", getDiagnosticJourney);
				// console.log("getDiagnosticJourney", getDiagnosticJourney);
				if (getDiagnosticJourney?.recommendedJourney) {
					this.startRecommendedJourney = false;
					return getDiagnosticJourney.recommendedJourney;
				} else {
					this.startRecommendedJourney = true;
					try {
						return await this.getAiRecommendation(cabriService, accountService, recommandedLrsJourney, false);
					} catch (err) {
						console.error("no any recommendation");
					}
				}
			} else {
				const notDoneExercises = recommandedLrsJourney.exercises.filter(ex => {
					return ex.status === Status.notDone;
				});
				if (notDoneExercises.length > 0) {
					// keep not done exercises
					recommandedLrsJourney.exercises = notDoneExercises;
					this.startRecommendedJourney = false;
					return recommandedLrsJourney;
				} else {
					try {
						return await this.getAiRecommendation(cabriService, accountService, recommandedLrsJourney, true);
					} catch (err) {
						console.error("no any recommendation");
					}
				}
			}
		} else {
			// suggested journey for the first time or when all exercises of journey has been just completed
			try {
				return await this.getAiRecommendation(cabriService, accountService, recommandedLrsJourney, true);
			} catch (err) {
				console.error("no any recommendation");
			}
		}
	}

	async defineTrainingUserJourney(
		currentJourney: Journey,
		journeyTargetted: { [journeyId: string]: Journey[] },
		accountService: AccountService,
		cabriService: CabriDataService
	) {
		return new Promise(async (resolve, reject) => {
			if (currentJourney.id) {
				if (journeyTargetted?.[currentJourney.id]) {
					journeyTargetted[currentJourney.id].find(async journeyState => {
						if (!journeyState.farthest && this.resume) {
							// Reprendre un parcours
							this.currentUserJourney = await currentJourney.getResumedCurrentJourneyTrainingMod(journeyState);
							let currentJourneyExercisePos;
							LrsUtils.resume = true;
							if (journeyState.resumeExerciseProcessing(this.currentUserJourney)) {
								// Reprendre un parcours lorsqu'un exercice est en cours d'être joué
								const lastQuestion =
									this.currentUserJourney.allAskedQuestions[this.currentUserJourney.allAskedQuestions.length - 1];
								currentJourneyExercisePos = this.currentUserJourney.exercises.findIndex((exercise: ProposedActivity) => {
									return exercise.step === lastQuestion.step;
								});
							} else {
								// Reprendre un parcours lorsqu'un exercice a été terminé (Passage à l'exercice suivant)
								currentJourneyExercisePos = this.currentUserJourney.exercises.findIndex((exercise: ProposedActivity) => {
									return exercise.status === Status.notDone;
								});
							}

							if (currentJourneyExercisePos > -1) {
								const duration = this.durationJourney(journeyState);
								if (duration) {
									this.currentUserJourney.duration = duration;
								}

								if (!cabriService.currentActivity) {
									// définir l'activité à partir de l'index de l'exercice du parcours
									cabriService.currentActivity = journeyState.defineJourneySelectedActivity(
										journeyState.exercises[currentJourneyExercisePos]
									);
								}

								const exercise = cabriService.exercices.getExercise(
									journeyState.exercises[currentJourneyExercisePos].exerciseId
								);
								if (exercise) {
									try {
										await cabriService.setActivityId(exercise.id);
										// define exercise default value
									} catch (err) {
										console.error("first journey exercise not found");
										resolve(true);
									}
								}
								accountService.countAwards(true, journeyState);
							} else {
								// sécurité ne doit pas executer ce cas.
								console.error("should not appear : ", journeyState.exercises[0]);
								if (!cabriService.currentActivity) {
									cabriService.currentActivity = journeyState.defineJourneySelectedActivity(journeyState.exercises[0]);
								}
								await cabriService.setActivityId(journeyState.exercises[0].exerciseId);
							}
						} else {
							// Commencer un parcours
							LrsUtils.idsession = undefined;
							this.currentUserJourney = currentJourney.getStartedCurrentJourneyTrainingMod();
							LrsUtils.parcoursId = this.currentUserJourney.id;
							LrsUtils.assignationId = this.currentUserJourney.assignationId;
							LrsUtils.jounreyExerciseStep = this.currentUserJourney.exercises[0].step;
							cabriService.currentActivity = this.currentUserJourney.defineJourneySelectedActivity(
								currentJourney.exercises[0]
							);
							try {
								await cabriService.setActivityId(this.currentUserJourney.exercises[0].exerciseId);
								// define exercise default value
							} catch (err) {
								console.error("first journey exercise not found");
								resolve(true);
							}
							// journey's awards
							accountService.countAwards(false);
							// journey's awards
							resolve(true);
						}
						resolve(true);
					});
				} else {
					// S'il n'y a pas de parcours alors jouer l'exercice
					LrsUtils.resume = false;
					this.currentUserJourney = currentJourney.getStartedCurrentJourneyTrainingMod();
					if (!cabriService.currentActivity) {
						cabriService.currentActivity = this.currentUserJourney.defineJourneySelectedActivity(currentJourney.exercises[0]);
					}
					try {
						await cabriService.setActivityId(this.currentUserJourney.exercises[0].exerciseId);
					} catch (err) {
						console.error("first journey exercise not found");
					}
					// journey's awards
					accountService.countAwards(false);
					resolve(true);
				}
			} else {
				// journey's awards
				accountService.countAwards(false);
				resolve(true);
			}
		});
	}

	/**
	 * Check if there are recommandation bilan journey
	 */
	private get _getRecommandedJourney() {
		if (
			this.allJourneysByInProgressStatus?.length > 0 &&
			this.allJourneysByInProgressStatus[0][this.getStatusAsIndex(journeyStatus.inProgress)]?.items &&
			this.allJourneysByInProgressStatus[0][this.getStatusAsIndex(journeyStatus.inProgress)].items.length > 0 &&
			this.allJourneysByInProgressStatus[0][this.getStatusAsIndex(journeyStatus.inProgress)].items[0].exercises?.length > 0
		) {
			return this.allJourneysByInProgressStatus[0][this.getStatusAsIndex(journeyStatus.inProgress)]?.items[0];
		}
	}

	/**
	 * Filter recommanded bilan if some competencies not mastered yet
	 */
	private _verifyRecommandedBilanAvailability(
		recommendedJourney: Journey,
		cabriService: CabriDataService,
		accountService: AccountService
	) {
		return new Promise(async resolve => {
			const exercises = this.addDiagnosticExercisesSteps(recommendedJourney?.exercises, cabriService.exercices);
			const diagnosticResult: any = await accountService.diagnosticMode(exercises, accountService.team[0].id, false);
			// console.log("diagnosticResult", diagnosticResult);
			if (diagnosticResult && diagnosticResult.hasNextActivity) {
				this.passBilanExercisesToDone(recommendedJourney, diagnosticResult.nextActivity.step);
				const notDoneExercises = recommendedJourney.exercises.filter(ex => {
					return ex.status === Status.notDone;
				});
				if (notDoneExercises.length > 0) {
					recommendedJourney.exercises = notDoneExercises;
					resolve({ recommendedJourney, diagnostic: false });
				} else {
					resolve({ recommendedJourney: null, diagnosticResult });
				}
			} else {
				resolve({ recommendedJourney: null, diagnosticResult });
			}
		});
	}

	/**
	 * Pass status to done of all previous exercises of current exercise that should be done
	 */
	passBilanExercisesToDone(journey: Journey, nextExerciseByStepToDo: number) {
		const pos = journey.exercises.findIndex((ex, index) => {
			return index == nextExerciseByStepToDo;
		});
		if (pos > 0) {
			const finalPos = pos - 1;
			journey.exercises[finalPos].status = Status.done;

			const indexes = journey.exercises
				.map((elm, idx) => {
					if (elm.status === Status.done) {
						return idx;
					}
				})
				.filter(uNdefined => uNdefined !== undefined);

			if (indexes.length > 0) {
				// get last index of an exercise done
				const maxOfIndex = Math.max(...indexes);
				for (let i = 0; i <= maxOfIndex; i++) {
					// change all exercises status to done until the last index of done
					journey.exercises[i].status = Status.done;
				}
			}
		}
	}

	getStatusAsIndex(status: journeyStatus): string {
		let index;
		if (status === journeyStatus.inProgress) {
			index = "1";
		} else if (status === journeyStatus.toDo) {
			index = "2";
		} else if (status === journeyStatus.completed) {
			index = "3";
		} else if (status === journeyStatus.offlineJourneysByAssignation) {
			index = "4";
		} else if (status === journeyStatus.allJourneys) {
			index = "5";
		}
		return index;
	}

	/**
	 * At the end of the activity check if there is an diagnostic to be done
	 */
	getNextDiagnosticExercise(currentExercise, journey?: Journey) {
		if (journey) {
			const exercise = journey.exercises.find((exo: ProposedActivity) => {
				return Number(exo.exerciseId) === Number(currentExercise.id) && exo.status !== Status.done;
			});
			if (exercise) {
				exercise.status = Status.done;
			}
			// get remaining exercises
			return journey.exercises.filter((ex: ProposedActivity) => {
				return ex.status === Status.notDone;
			});
		} else {
			const exercise = this.currentUserJourney.exercises.find((exo: ProposedActivity) => {
				return Number(exo.exerciseId) === Number(currentExercise.id) && exo.status !== Status.done;
			});
			if (exercise) {
				exercise.status = Status.done;
			}
			// get remaining exercises
			return this.currentUserJourney.exercises.filter((ex: ProposedActivity) => {
				return ex.status === Status.notDone;
			});
		}
	}

	/**
	 * At the end of an activity select the next one
	 * @returns null or next activity as Journey
	 */
	getTrainingNextActivity(currentExercice) {
		let result;

		const notDoneExerciseExist = this.currentUserJourney.exercises.some(ex => {
			return ex.status === Status.notDone;
		});

		if (notDoneExerciseExist) {
			const currentExercise = this.currentUserJourney.exercises.find((exercise: ProposedActivity) => {
				return Number(exercise.exerciseId) === Number(currentExercice.id) && exercise.status !== Status.done;
			});

			if (currentExercise) {
				currentExercise.status = Status.done;
			}
			// reset all asked questions
			if (this.currentUserJourney.allAskedQuestions) {
				this.currentUserJourney.allAskedQuestions = new Array();
			}
			// find next exercise
			result = this.currentUserJourney.exercises.find((ex: ProposedActivity) => {
				return ex.status === Status.notDone;
			});

			if (result) {
				this.currentUserJourney.next = true;
			}
		}

		return result;
	}

	/**
	 * Journey total awards
	 */
	countTotalJourneyAwards(countAwards: { moonsCount: number; starsCount: number; shootingCounts: number }) {
		// if (training) {
		let journeyAwards: any = localStorage.getItem("journeyTotalAwards");
		if (journeyAwards) {
			let moons = 0;
			let normal = 0;
			let shootings = 0;
			journeyAwards = JSON.parse(localStorage.getItem("journeyTotalAwards"));
			for (const journeyId in journeyAwards) {
				if (journeyAwards.hasOwnProperty(journeyId)) {
					if (journeyId === "moon") {
						journeyAwards[journeyId] += countAwards.moonsCount;
						moons = journeyAwards[journeyId];
					} else if (journeyId === "normal") {
						journeyAwards[journeyId] += countAwards.starsCount;
						normal = journeyAwards[journeyId];
					} else if (journeyId === "shooting") {
						journeyAwards[journeyId] += countAwards.shootingCounts;
						shootings = journeyAwards[journeyId];
						console.error("shootings", shootings);
					}
				}
			}

			return { moonsCount: moons, normalStarts: normal, shootingStars: shootings };
		}
	}

	/**
	 * Total duration of journey after the resume
	 */
	durationJourney(journey: Journey) {
		if (journey?.allAskedQuestions.length > 0) {
			let journeyAskedQuestions = new Array();
			journeyAskedQuestions = journey.allAskedQuestions;
			// Group journeys maked in 3 minutes
			const training = journeyAskedQuestions.reduce((r, o, i, { [i - 1]: last }) => {
				let nextTimestamp;
				let lastTimestamp;
				if (o && o.timestamp) {
					nextTimestamp = new Date(o.timestamp);
				}
				if (last && last.timestamp) {
					lastTimestamp = new Date(last.timestamp);
				}
				if (!last || nextTimestamp - lastTimestamp > 3 * 60000) {
					r.push([o]);
				} else {
					r[r.length - 1].push(o);
				}
				return r;
			}, []);

			// Calculate total duration of journey by substraction of different timestamps
			let totalDuration = 0;
			for (const j in training) {
				// Only one timestamp
				if (training[j].length === 1) {
					totalDuration += Number(training[j][0].vTimer);
				} else {
					// Multiple timestamps
					training[j].forEach((item, index) => {
						if (training[j][index + 1]) {
							const next: any = new Date(training[j][index + 1].timestamp);
							const before: any = new Date(training[j][index].timestamp);
							totalDuration += (next - before) / 1000;
						}
					});
				}
			}
			if (typeof totalDuration === "number") {
				if (totalDuration < 0) {
					return null;
				} else {
					return Math.round(totalDuration);
				}
			}
		}
		return null;
	}

	getJourneyById(journeyId: number) {
		return this._globalJourneys.find(j => j.id === journeyId);
	}

	async launchJourney(journey: Journey, cabriService: CabriDataService, globalService: GlobalService) {
		this.currentUserJourney = journey;

		const firstExercise = this.currentUserJourney.exercises[0].exerciseId;
		await cabriService.setActivityId(firstExercise);

		cabriService.currentActivity.setParamValue("v-cursor-mod", "1");
		cabriService.currentActivity.buildVariables();
		this.currentUserJourney.resume = false;
		this.currentUserJourney.start = true;
		globalService.onActivityPage = true;
		LrsUtils.resume = false;
		this.router.navigateByUrl(cabriService.currentActivity.routingPath);
	}
}
