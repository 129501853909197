<div class="globalContainer leftMenuBackgrd"
  [ngClass]="{'globalContainerShown': menuStatus && !globalService.resizeEventRunning, 'globalTablet' : globalService.isTablet && !globalService.isLandscape,
'globalContainerMobile': globalService.isMobile && globalService.isLandscape,  'leftMenuBackgrdMathia' : !environment.kidaia && !environment.ose}">

  <div class="menuContainer">

    <div class="buttonsContainer" [ngClass]="{'scrollHidden noFlex': scroll}">

      <ng-container *ngFor="let p of appPages">
        <ng-container
          *ngIf="((!environment.production || !isSet(p.prod) || p.prod == true && !(globalService.isCordova && p.extUrl && !networkStatus)) && !p.hide)">

          <div class="customButton" (click)="btnClick(p.url, p.loading, p.extUrl, p.title)"
            [ngClass]="{'disableMenuButton' : (p.url === '/class-code-entry' && !networkService.connectedStatus) || ((p.url === '/gabarits') && !accountImplService.isRealUserConnected),  'customButtonNoFlex': scroll, 'customButtonMobile': globalService.isMobile && globalService.isLandscape, 'smallText' : !environment.kidaia, 'lockPremium' : p.premium && !accountImplService.user.premium}">
            <div class="btnIconContainer">
              <ion-icon [name]="p.icon" class="btnIcon"></ion-icon>
            </div>
            <div class="btnText" [ngClass]="{'btnTextSmall': !environment.kidaia, 'btnTextMobile': globalService.isMobile}">{{p.title}}</div>
          </div>

        </ng-container>
      </ng-container>
    </div>
  </div>
</div>