import { HttpClient } from "@angular/common/http";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { Actor } from "./actores";
import { LrsUtils } from "./lrsUtils";
import { Student } from "../student";
import { ConnectionStatus, NetworkService } from "src/app/services/network.service";
import { BaseService } from "src/app/services/base.service";
import { Status } from "../proposed-activity";
import { LrsService } from "src/app/services/lrs.service";
import { GlobalService } from "src/app/services/global.service";
import { environment } from "src/environments/environment";
import { LmsService } from "src/app/services/lms.service";
import { LrsVerbs, XapiVerbs } from "./xapiverbs";
import { XapiExtensions } from "./xapicontext";
export class GlobalLrs extends BaseService {
	public currentActivityFinished = 0;
	public idsession: string;
	public idJourneySession: string;
	public idsessionJourney: string;
	public passedWithHelp = false;
	public currentExercise;
	public currentUserResponseTime: number;
	public assignationId: number;
	public cabriService: CabriDataService;
	public networkService: NetworkService;
	public accountService: AccountService;
	public globalService: GlobalService;
	public http: HttpClient;
	public context;
	public actor;
	public actorModel: any;
	lastVerb: any;
	public lrs: LrsService;
	public environment: any;

	public currentActivity;
	public currentTeamInfos;
	public textHexadecimal;
	public lmsService: LmsService;

	constructor(
		lrs: LrsService,
		lmsService: LmsService,
		cabriService: CabriDataService,
		accountService: AccountService,
		globalService: GlobalService,
		networkService: NetworkService
	) {
		super();
		this.cabriService = cabriService;
		this.networkService = networkService;
		this.lrs = lrs;
		this.accountService = accountService;
		this.globalService = globalService;
		this.environment = environment;
		this.lmsService = lmsService;

		this.actorModel = new Actor();
		// this.verbsModel = new Verbs();
		this.context = {};
		this.actor = {};
	}

	/**
	 * Create activity statement on page load
	 */

	public createCabriActivityStatementOnLoad() {
		LrsUtils.remoteHost = null;
		LrsUtils.holo = null;
		this.cabriService.currentActivity.params.forEach(param => {
			if (!param.hidden) {
				if (param.name === "remoteHost") {
					LrsUtils.remoteHost = param.defaultValue[0];
				}
				if (param.name === "holo") {
					LrsUtils.holo = param.defaultValue[0];
				}
			}
		});

		LrsUtils.durationStartActivity = new Date().getTime();

		if (this.lmsService.currentUserJourney && this.lmsService.currentUserJourney.resume) {
			const journeyExercise = this.lmsService.currentUserJourney.allAskedQuestions.find(data => {
				return data.exerciseId === this.cabriService.currentExercice.id;
			});

			if (journeyExercise) {
				LrsUtils.idsession = journeyExercise.id_session;
			} else {
				LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			}
		} else {
			if (!this.lmsService.currentUserJourney || (this.lmsService.currentUserJourney && !this.lmsService.currentUserJourney.resume)) {
				LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			}
		}

		if (this.lmsService.currentUserJourney) {
			if (this.lmsService.currentUserJourney.idSession && this.networkService.connectedStatus) {
				LrsUtils.idJourneySession = this.lmsService.currentUserJourney.idSession;
			} else if (!this.lmsService.currentUserJourney.idSession && this.networkService.connectedStatus) {
				LrsUtils.idJourneySession = LrsUtils.generateUniqueSessionId(this.accountService, false, this.lmsService);
			}

			const currentExercise = this.lmsService.currentUserJourney.exercises.find(ex => {
				return ex.status !== Status.done;
			});

			if (currentExercise) {
				LrsUtils.jounreyExerciseStep = currentExercise.step;
			}

			if (this.lmsService.currentUserJourney.assignationId) {
				LrsUtils.assignationId = this.lmsService.currentUserJourney.assignationId;
			}
			if (this.lmsService.currentUserJourney.idSession) {
				LrsUtils.idJourneySession = this.lmsService.currentUserJourney.idSession;
			} else {
				LrsUtils.idJourneySession = LrsUtils.generateUniqueSessionId(this.accountService, false, this.lmsService);
				this.lmsService.currentUserJourney.idSession = LrsUtils.idJourneySession;
			}

			if (this.networkService.connectedStatus) {
				LrsUtils.parcoursId = this.lmsService.currentUserJourney.id;
			}
		}

		this.updateCabriGlobalModel();
		const teamInfos = this.createCabriModelBasedTeamObj();
		this.currentActivity = this.getCabriActivityChosen();
		const createStatement = new Array();
		// const normalMode = !this.lmsService.currentUserJourney ? true : false;
		let normalMode = true;
		// Définir le contenu de l'object du statement
		if (!this.lmsService.currentUserJourney) {
			normalMode = true;
		} else if (this.lmsService.currentUserJourney) {
			if (LrsUtils.resume || this.lmsService.currentUserJourney.start) {
				LrsUtils.durationStartJourney = new Date().getTime();
			}
			if (this.lmsService.currentUserJourney.start || LrsUtils.resume) {
				normalMode = false;
			} else {
				normalMode = true;
			}
			// this.lmsService.currentUserJourney.start = false;
		}
		const statementObj = LrsUtils.checkStatementObjectType(this, normalMode);
		const adaptative = LrsUtils.getExercisesAdaptativeInfos(this);
		if ((this.accountService.team.length > 1 || this.lmsService.currentUserJourney) && !this.globalService.fastBilanFinished) {
			if (teamInfos.member.length === 1) {
				if (
					this.lmsService.currentUserJourney &&
					(this.lmsService.currentUserJourney.start || LrsUtils.resume) &&
					!this.lmsService.currentUserJourney.reload
				) {
					teamInfos.member.push(teamInfos.member[0]);
				}
			}
			let statementObj;
			let verbsModel;
			teamInfos.member.forEach((data, index) => {
				if (
					this.lmsService.currentUserJourney &&
					(this.lmsService.currentUserJourney.start || LrsUtils.resume) &&
					!this.lmsService.currentUserJourney.reload &&
					index === 0
				) {
					statementObj = LrsUtils.checkStatementObjectType(this, false);
					this.updateCabriGlobalModel();
					const paramVerb = this.lmsService.currentUserJourney && LrsUtils.resume ? "resume" : "initialized";
					LrsUtils.resume = false;
					verbsModel = XapiVerbs.get(paramVerb);
				} else {
					statementObj = LrsUtils.checkStatementObjectType(this, true);
					this.updateCabriGlobalModel();
					verbsModel = XapiVerbs.get(LrsVerbs.initialized);
				}
				createStatement.push({
					actor: {
						account: {
							homePage: "https://xapi.mathia.education",
							name: data.account.name
						}
					},
					verb: verbsModel,
					object: {
						id: `https://xapi.mathia.education/${statementObj.type}/id/${statementObj.id}`,
						definition: {
							name: {
								"fr-FR": `${statementObj.name}`
							},
							description: {
								"fr-FR": `${statementObj.description}`
							},
							extensions: {
								[XapiExtensions.competences]: adaptative.competences,
								[XapiExtensions.erreursType]: adaptative.erreursTypes,
								[XapiExtensions.prerequis]: adaptative.prerequis,
								[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
								[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
							},
							type: statementObj.definitionType
						},
						objectType: "Activity"
					},
					context: this.context
				});
			});
			if (this.lmsService.currentUserJourney) {
				LrsUtils.resume = false;
				this.lmsService.currentUserJourney.start = false;
				this.lmsService.currentUserJourney.reload = false;
			}
			return createStatement;
		} else {
			if (this.lmsService.currentUserJourney) {
				if (!this.globalService.fastBilanFinished) {
					return;
				}
			}
			const verbsModel = XapiVerbs.get(LrsVerbs.initialized);
			this.currentActivity = this.getCabriActivityChosen();
			this.textHexadecimal = LrsUtils.toHex(this.currentActivity.name);

			const statement = {
				actor: this.actor,
				verb: verbsModel,
				object: {
					id: `https://xapi.mathia.education/${statementObj.type}/id/${statementObj.id}`,
					definition: {
						name: {
							"fr-FR": `${statementObj.name}`
						},
						description: {
							"fr-FR": `${statementObj.description}`
						},
						extensions: {
							[XapiExtensions.competences]: adaptative.competences,
							[XapiExtensions.erreursType]: adaptative.erreursTypes,
							[XapiExtensions.prerequis]: adaptative.prerequis,
							[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
							[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
						},
						type: statementObj.definitionType
					},
					objectType: "Activity"
				},
				context: this.context
			};
			return statement;
		}
	}

	/**
	 * Reading activity
	 * @param currentVerb
	 */

	public createReadingActivityStatementOnLoad() {
		const connectSid = localStorage.getItem("connect_sid");
		let codeClasse = Number(localStorage.getItem("codeclasse"));
		const activityChoosed = JSON.parse(localStorage.getItem("activitiesChoosed"));

		if (activityChoosed) {
			this.actorModel.name = connectSid;
			if (codeClasse != null && !isNaN(Number(codeClasse))) {
				this.actorModel.classe = codeClasse;
			} else {
				codeClasse = 0;
			}

			let statement = {
				actor: {
					account: {
						homePage: "https://xapi.mathia.education",
						name: connectSid
					}
				},
				verb: XapiVerbs.get(LrsVerbs.initialized),
				object: {
					id: `https://xapi.mathia.education/id/${activityChoosed[0].id}`,
					definition: {
						name: {
							"fr-FR": activityChoosed[0].titre
						},
						description: {
							"fr-FR": `La ${activityChoosed[0].titre} avec une difficulté de niveau ${activityChoosed[0].text_difficulty}`
						},
						extensions: {
							[XapiExtensions.difficulty]: activityChoosed[0].text_difficulty
						},
						type: `https://xapi.mathia.education/activity-type/${activityChoosed[0].type}/${activityChoosed[0].category}`
					},
					objectType: "Activity"
				},
				context: {
					extensions: {
						[XapiExtensions.codeclasse]: codeClasse != null && !isNaN(codeClasse) ? codeClasse : 0,
						[XapiExtensions.nomActivite]: "lecture",
						[XapiExtensions.idActivite]: 2,
						[XapiExtensions.activite]: "lecture"
					}
				}
			};

			return statement;
		}
	}

	public createCabriStatementOnResponse(verbsModel, response) {
		const globalLrsInfos = this.updateCabriGlobalModel();
		let statementObjectInfo;
		// Définir le contenu de l'object du statement
		const typeOfActivity = this.detectCurrentActivityNameById(this.currentActivity.id);
		const adaptative = LrsUtils.getExercisesAdaptativeInfos(this);
		let objectName;

		if (typeOfActivity == "jeu_de_l'heure") {
			objectName = `${LrsUtils.currentOperation} `;
			globalLrsInfos[XapiExtensions.formatHeure] = LrsUtils.formatHeure + "h";
		} else if (typeOfActivity == "jeu_de_kim") {
			objectName = "jeu de kim";
		} else if (typeOfActivity == "jeu_juste_point") {
			objectName = LrsUtils.currentOperation;
		} else {
			objectName = `Opération ${LrsUtils.currentOperation} `;
		}
		statementObjectInfo = {
			// objectId,
			objectName,
			objectDescription: `${LrsUtils.currentOperation}`
		};
		this.context.extensions[XapiExtensions.modeReponse] = LrsUtils.responseMode;

		if (response) {
			this.context.extensions[XapiExtensions.reponse] = response;
		}

		if (this.lmsService.currentUserJourney && this.lmsService.currentUserJourney.resume) {
			this.context.extensions[XapiExtensions.resume] = true;
		}

		this.actor.account.name = LrsUtils.currentUser.id != null ? LrsUtils.currentUser.id : LrsUtils.currentUser.name;

		const contextActivities = {
			parent: [
				{
					id: `https://xapi.mathia.education/id/${this.textHexadecimal}`,
					objectType: "Activity"
				}
			]
		};

		this.context.contextActivities = contextActivities;
		let result;

		// Durée de la réponse en iso8601
		const iso8601 = LrsUtils.duration8601(LrsUtils.responseTime);
		if (this.lastVerb) {
			if (this.lastVerb === "passed" || this.lastVerb === "passed-with-help" || this.lastVerb === "passed-on-retry") {
				result = {
					success: true,
					duration: iso8601
				};
			} else {
				result = {
					success: false,
					duration: iso8601
				};
			}
		} else {
			result = {
				success: verbsModel === "passed" || verbsModel === "passed-with-help" || verbsModel === "passed-on-retry" ? true : false,
				duration: iso8601
			};
		}

		if (this.cabriService.currentExercice) {
			if (this.cabriService.currentExercice.resultMode === "result") {
				this.context.extensions[XapiExtensions.modeOperatoire] = "en ligne";
			} else if (this.cabriService.currentExercice.resultMode === "nb1" || this.cabriService.currentExercice.resultMode === "nb2") {
				this.context.extensions[XapiExtensions.modeOperatoire] = "à trou";
			}
		}
		LrsUtils.currentId = LrsUtils.generateUniqueId();
		// Gestion d'un id pour récupérer l'"historique" de toutes les événments sur une question
		if (LrsUtils.currentId) {
			this.context.extensions[XapiExtensions.hid] = LrsUtils.currentId;
		}

		// Define context object

		// let type;
		// this.lmsService.currentUserJourney ? type = "journey" : type = "question";

		return {
			actor: this.actor,
			verb: XapiVerbs.get(verbsModel),
			object: {
				id: `https://xapi.mathia.education/question/id/${this.cabriService.currentExercice.id}`,
				definition: {
					name: {
						"en-US": statementObjectInfo.objectName
					},
					description: {
						"en-US": statementObjectInfo.objectDescription
					},
					extensions: {
						[XapiExtensions.competences]: adaptative.competences,
						[XapiExtensions.erreursType]: adaptative.erreursTypes,
						[XapiExtensions.prerequis]: adaptative.prerequis,
						[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
						[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
					},
					type: "https://xapi.mathia.education/objectType/question"
				},
				objectType: "Activity"
			},
			context: this.context,
			result
		};
	}

	public createReadingStatementOnResponse(phrase, activityId, verbId) {
		const connectSid = localStorage.getItem("connect_sid");
		const codeClasse = Number(localStorage.getItem("codeclasse"));
		LrsUtils.passedWithHelpReading = false;
		this.actorModel.name = connectSid;
		this.actorModel.classe = codeClasse;

		const statement = {
			actor: {
				account: {
					homePage: "https://xapi.mathia.education",
					name: connectSid
				}
			},
			verb: XapiVerbs.get(verbId),
			object: {
				id: `https://xapi.mathia.education/activite_${activityId}_question_${phrase.id}`,
				definition: {
					name: {
						"fr-FR": phrase.display
					},
					description: {
						"fr-FR": phrase.display
					},
					type: "https://xapi.mathia.education/objectType/question"
				},
				objectType: "Activity"
			},
			context: {
				extensions: {},
				contextActivities: {
					parent: [
						{
							id: `https://xapi.mathia.education/activite_${activityId}`
						}
					]
				}
			}
		};

		statement.context.extensions[XapiExtensions.nomActivite] = "lecture";
		statement.context.extensions[XapiExtensions.idActivite] = 2;
		statement.context.extensions[XapiExtensions.activite] = "lecture";
		if (codeClasse != null && !isNaN(Number(codeClasse))) {
			statement.context.extensions[XapiExtensions.codeclasse] = codeClasse;
		} else {
			statement.context.extensions[XapiExtensions.codeclasse] = 0;
		}

		return statement;
	}

	createCabriStatementActivity(verbId, forCurrentUser = false) {
		this.updateCabriGlobalModel();
		// const statementObj = LrsUtils.checkStatementObjectType(this, normalMode);
		const adaptative = LrsUtils.getExercisesAdaptativeInfos(this);
		this.currentActivity = this.getCabriActivityChosen();
		const teamInfos = this.createCabriModelBasedTeamObj();

		const createStatement = new Array();
		this.context.extensions[XapiExtensions.modeReponse] = LrsUtils.responseMode;
		this.actor.account.name = LrsUtils.currentUser.id != null ? LrsUtils.currentUser.id : LrsUtils.currentUser.name;
		// Convertir un timestamp en secondes
		const durationWithsecond = LrsUtils.timeStampConversion(LrsUtils.durationStartActivity);
		// Obtenir la durée en iso8601
		const durationOfActivity = LrsUtils.duration8601(durationWithsecond);
		let durationOfJourney;
		// Parcours terminé sans avoir repris
		let journeyStartedCompleted = false;
		let statementObj;

		// Multiples participants qui achèvent un exercice
		if ((this.accountService.team.length > 1 && !forCurrentUser) || this.lmsService.currentUserJourney) {
			let journeyCompleted = false;
			// console.log("this.lmsService.currentUserJourney", this.lmsService.currentUserJourney);
			if (this.lmsService.currentUserJourney && !this.lmsService.currentUserJourney.nextActivityProposed) {
				if (!this.globalService.fastBilanFinished) {
					teamInfos.member.push(teamInfos.member[0]);
				}
				if (!this.lmsService.currentUserJourney.resume) {
					journeyStartedCompleted = true;
					const durationJourneyWithsecond = LrsUtils.timeStampConversion(LrsUtils.durationStartJourney);
					durationOfJourney = LrsUtils.duration8601(durationJourneyWithsecond);
					statementObj = LrsUtils.checkStatementObjectType(this, false);
				} else {
					const duration = this.durationTotalTrainingJourneyResume();
					if (duration) {
						const dateObj = new Date(Date.now() - duration * 1000).getTime();
						const durationJourneyWithsecond = LrsUtils.timeStampConversion(dateObj);
						durationOfJourney = LrsUtils.duration8601(durationJourneyWithsecond);
						statementObj = LrsUtils.checkStatementObjectType(this, false);
					}
				}
				journeyCompleted = true;
			}
			teamInfos.member.forEach((data: any, index: number) => {
				if (journeyCompleted) {
					if (index === 0) {
						statementObj = LrsUtils.checkStatementObjectType(this, false);
						this.updateCabriGlobalModel();
					} else {
						statementObj = LrsUtils.checkStatementObjectType(this, true);
						this.updateCabriGlobalModel();
						journeyStartedCompleted = false;
						journeyCompleted = false;
					}
				} else {
					statementObj = LrsUtils.checkStatementObjectType(this, true);
				}
				// Pourcentage de bonnes / mauvaises réponses et la durée de l'activité du début à la fin en format ISO
				if (data.awardsCurrent?.total > 0) {
					this.updateCabriGlobalModel();
					const perTotalGoodAnswer = ((data.awardsCurrent.shooting + data.awardsCurrent.normal) / data.awardsCurrent.total) * 100;
					const perTotalWrongAnswer = (data.awardsCurrent.moons / data.awardsCurrent.total) * 100;
					this.context.extensions[XapiExtensions.pourcentageBonnesReponses] = perTotalGoodAnswer;
					this.context.extensions[XapiExtensions.pourcentageMauvaisesReponses] = perTotalWrongAnswer;
				}
				if (!journeyStartedCompleted) {
					this.context.extensions[XapiExtensions.dureeActivite] = durationOfActivity;
				} else if (journeyStartedCompleted) {
					this.context.extensions[XapiExtensions.dureeActivite] = durationOfJourney;
				}
				if (
					this.lmsService.currentUserJourney &&
					this.cabriService.currentActivity?.story &&
					this.accountService.user.currentChapter
				) {
					this.context.extensions[XapiExtensions.chapterId] = this.accountService.user.currentChapter.id;
					this.context.extensions[XapiExtensions.chapterPosition] =
						this.accountService.user.currentChapter.exercicesChapterHistory.length;
				}
				createStatement.push({
					actor: {
						account: {
							homePage: "https://xapi.mathia.education",
							name: data.account.name
						}
					},
					verb: XapiVerbs.get(verbId),
					object: {
						id: `https://xapi.mathia.education/${statementObj.type}/id/${statementObj.id}`,
						definition: {
							name: {
								"en-US": `${statementObj.name}`
							},
							description: {
								"en-US": `${statementObj.description}`
							},
							extensions: {
								[XapiExtensions.competences]: adaptative.competences,
								[XapiExtensions.erreursType]: adaptative.erreursTypes,
								[XapiExtensions.prerequis]: adaptative.prerequis,
								[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
								[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
							},
							type: statementObj.definitionType
						},
						objectType: "Activity"
					},
					context: this.context,
					result: {
						success: true,
						duration: durationOfJourney ? durationOfJourney : durationOfActivity
					}
				});
			});
			return createStatement;
		} else {
			statementObj = LrsUtils.checkStatementObjectType(this, true);
			// Un seul participant qui achève un exercice (ex : mode Challenge)
			const currentStatement = {
				actor: this.actor,
				verb: XapiVerbs.get(verbId),
				object: {
					id: `https://xapi.mathia.education/${statementObj.type}/id/${statementObj.id}`,
					definition: {
						name: {
							"en-US": `${statementObj.name}`
						},
						description: {
							"en-US": `${statementObj.description}`
						},
						extensions: {
							[XapiExtensions.competences]: adaptative.competences,
							[XapiExtensions.erreursType]: adaptative.erreursTypes,
							[XapiExtensions.prerequis]: adaptative.prerequis,
							[XapiExtensions.competencesValidees]: adaptative.competenceValidees,
							[XapiExtensions.erreursTypeValidees]: adaptative.erreursTypeValidees
						},
						type: statementObj.definitionType
					},
					objectType: "Activity"
				},
				context: this.context,
				result: {
					success: true,
					duration: durationOfActivity
				}
			};

			if (!this.globalService.fastBilanFinished) {
				// Durée de l'activité du début à la fin en format ISO
				this.context.extensions[XapiExtensions.dureeActivite] = durationOfActivity;
			}
			let currentUserTargetted: Student;
			if (LrsUtils.currentTeam) {
				currentUserTargetted = LrsUtils.currentTeam.find(student => {
					return student.id === LrsUtils.currentUser.id;
				});
			}

			// Pourcentage de bonnes / mauvaises réponses
			if (currentUserTargetted && currentUserTargetted.awardsCurrent?.total > 0) {
				const perTotalGoodAnswer =
					((currentUserTargetted.awardsCurrent.normal + currentUserTargetted.awardsCurrent.shooting) /
						currentUserTargetted.awardsCurrent.total) *
					100;
				const perTotalWrongAnswer = (currentUserTargetted.awardsCurrent.moons / currentUserTargetted.awardsCurrent.total) * 100;

				if (perTotalGoodAnswer) {
					this.context.extensions[XapiExtensions.pourcentageBonnesReponses] = perTotalGoodAnswer;
				}
				if (perTotalWrongAnswer) {
					this.context.extensions[XapiExtensions.pourcentageMauvaisesReponses] = perTotalWrongAnswer;
				}
			}
			return currentStatement;
		}
	}

	/**
	 * Duration of resume journey in training mod
	 */
	durationTotalTrainingJourneyResume() {
		const startedJourney = LrsUtils.durationStartJourney / 1000;
		const currentDate = new Date().getTime() / 1000;
		const asSeconds = currentDate - startedJourney;
		const totalFinalDuration = this.lmsService.currentUserJourney.duration + asSeconds;
		return Math.floor(totalFinalDuration);
	}

	/**
	 * All informations  common to all cabri activities
	 */

	public updateCabriGlobalModel() {
		this.currentActivity = this.getCabriActivityChosen();
		this.currentExercise = this.cabriService.currentExercice;
		const activityName = this.detectCurrentActivityNameById(this.currentActivity.id);
		this.textHexadecimal = LrsUtils.toHex(this.currentActivity.name);
		const teamInfos = this.createCabriModelBasedTeamObj();

		let modeAffichage;
		let member;
		if (this.accountService.team.length > 1) {
			member = teamInfos.member.map(data => {
				return { name: data.name, account: data.account, objectType: data.objectType };
			});
		}

		if (this.cabriService.currentActivity.getParam("v-display-mode")) {
			modeAffichage = this.cabriService.currentActivity.getParam("v-display-mode").value;
			if (modeAffichage === "letters") {
				modeAffichage = "chiffres";
			} else if (modeAffichage === "constellation") {
				modeAffichage = "constellation";
			} else if (modeAffichage === "letters") {
				modeAffichage = "pièces";
			} else if (modeAffichage === "fingers") {
				modeAffichage = "mains";
			} else if (modeAffichage === "dice") {
				modeAffichage = "dé";
			}
		}

		(this.actor = {
			account: {
				homePage: "https://xapi.mathia.education",
				name: LrsUtils.currentUser.id != null ? LrsUtils.currentUser.id : LrsUtils.currentUser.name
			}
		}),
			(this.context = {
				team: this.accountService.team.length === 1 ? undefined : { member },
				extensions: {
					[XapiExtensions.idEquipe]:
						this.cabriService.currentActivity.id === "8" ? this.accountService.team[0].id : teamInfos.idTeam,
					[XapiExtensions.nomActivite]: !this.globalService.fastBilanFinished
						? this.cabriService.currentActivity.name
						: undefined,
					[XapiExtensions.idActivite]: !this.globalService.fastBilanFinished ? this.cabriService.currentActivity.id : undefined,
					[XapiExtensions.idSession]: LrsUtils.idsession,
					[XapiExtensions.activite]: activityName && !this.globalService.fastBilanFinished ? activityName : undefined,
					[XapiExtensions.id]:
						this.currentExercise && this.currentExercise.id && !this.globalService.fastBilanFinished
							? this.currentExercise.id
							: undefined,
					[XapiExtensions.nomExercice]:
						this.currentExercise && this.currentExercise.name && !this.globalService.fastBilanFinished
							? this.currentExercise.name
							: undefined,
					[XapiExtensions.kidaia]: this.globalService.isKidaia ? true : false,
					[XapiExtensions.story]: this.cabriService.currentActivity?.story ? true : false,
					[XapiExtensions.codemaison]:this.environment.production && localStorage.getItem("codemaison") !== null ? true : undefined
				}
			});
		// id chapitre
		// position 12345
		if (modeAffichage || LrsUtils.clockResponseMode) {
			let currentAnswer;
			if (modeAffichage) {
				currentAnswer = modeAffichage;
			} else if (LrsUtils.clockResponseMode) {
				currentAnswer = LrsUtils.clockResponseMode;
			}
			this.context.extensions[XapiExtensions.modeAffichage] = currentAnswer;
		} else {
			this.context.extensions[XapiExtensions.modeAffichage] = undefined;
		}

		if (this.globalService.fastBilanFinished) {
			this.context.extensions[XapiExtensions.finAutomatique] = true;
		}

		if (LrsUtils.remoteHost) {
			this.context.extensions[XapiExtensions.controleAdistance] = LrsUtils.remoteHost;
		}

		if (LrsUtils.holo) {
			this.context.extensions[XapiExtensions.holo] = LrsUtils.holo;
		}

		if (this.lmsService.currentUserJourney) {
			if (this.lmsService.currentUserJourney.difficulty) {
				this.context.extensions[XapiExtensions.typeParcours] = this.lmsService.currentUserJourney.difficulty;
			}

			this.context.extensions[XapiExtensions.recommandation] = this.lmsService.currentUserJourney.recommandation;

			this.context.extensions[XapiExtensions.remediation] = this.lmsService.currentUserJourney.remediation;
		}

		if (this.accountService.team.length > 1 && this.cabriService.currentActivity.id !== "8") {
			this.context.extensions[XapiExtensions.binome] = true;
		} else {
			this.context.extensions[XapiExtensions.binome] = false;
		}

		if (this.accountService.user.codeClasse != null && !isNaN(this.accountService.user.codeClasse)) {
			this.context.extensions[XapiExtensions.codeclasse] = this.accountService.user.codeClasse;
		} else {
			this.context.extensions[XapiExtensions.codeclasse] = 0;
		}

		if (this.globalService.isKidaia && !this.environment.production) {
			this.context.extensions[XapiExtensions.kidaiadevmode] = true;
		}

		if (LrsUtils.assignationId || typeof LrsUtils.assignationId !== "undefined") {
			this.context.extensions[XapiExtensions.assignationId] = LrsUtils.assignationId;
		}

		if (LrsUtils.jounreyExerciseStep || typeof LrsUtils.jounreyExerciseStep !== "undefined") {
			this.context.extensions[XapiExtensions.parcoursEtape] = LrsUtils.jounreyExerciseStep;
		}

		if (LrsUtils.parcoursId || typeof LrsUtils.parcoursId !== "undefined") {
			this.context.extensions[XapiExtensions.parcoursid] = LrsUtils.parcoursId;
		}

		if (LrsUtils.idJourneySession || (typeof LrsUtils.idJourneySession !== "undefined" && this.networkService.connectedStatus)) {
			this.context.extensions[XapiExtensions.parcoursSessionId] = LrsUtils.idJourneySession;
		}

		if (this.globalService.fastBilanFinished) {
			this.context.extensions[XapiExtensions.modeJeu] = "bilan";
		} else {
			if (Number(this.cabriService.currentActivity.variables["v-cursor-mod"]) === 0) {
				this.context.extensions[XapiExtensions.modeJeu] = "découverte";
			} else if (Number(this.cabriService.currentActivity.variables["v-cursor-mod"]) === 1) {
				this.context.extensions[XapiExtensions.modeJeu] = "entraînement";
			} else if (Number(this.cabriService.currentActivity.variables["v-cursor-mod"]) === 2) {
				this.context.extensions[XapiExtensions.modeJeu] = "défi";
			} else if (Number(this.cabriService.currentActivity.variables["v-cursor-mod"]) === 3) {
				this.context.extensions[XapiExtensions.modeJeu] = "bilan";
			}
		}

		return this.context.extensions;
	}

	/**
	 * Model based on team object
	 */

	public createCabriModelBasedTeamObj() {
		const member = new Array();
		let idTeam = "";
		this.accountService.team.forEach((student, idx, array) => {
			member.push({
				name: student.id != null ? student.id : student.name,
				awardsCurrent: student.awardsCurrent,
				account: {
					homePage: "https://xapi.mathia.education",
					name: student.id != null ? student.id : student.name
				},
				objectType: "Agent"
			});
			if (idx === array.length - 1) {
				idTeam += student.id != null ? student.id : student.name;
			} else {
				idTeam += student.id != null ? student.id + "-" : student.name + "-";
			}
		});

		return { idTeam, member };
	}

	/**
	 * Get current activity
	 */

	public getCabriActivityChosen() {
		const currentActivity = this.cabriService.currentActivity;
		return currentActivity;
	}

	detectCurrentActivityNameById(activityId) {
		let activityName;
		activityId = Number(activityId);
		if (activityId === 1) {
			activityName = "activite_calcul_mental";
		} else if (activityId === 3) {
			activityName = "jeu_du_furet";
		} else if (activityId === 4) {
			activityName = "jeu_de_kim";
		} else if (activityId === 7) {
			activityName = "les_nombres_autrement";
		} else if (activityId === 5) {
			activityName = "jeu_de_l'heure";
		} else if (activityId === 6) {
			activityName = "galaxie_des_calculs";
		} else if (activityId === 2) {
			activityName = "lecture";
		} else if (activityId === 8) {
			activityName = "découverte_des_solides";
		} else if (activityId === 10) {
			activityName = "resolution_probleme";
		} else if (activityId === 11) {
			activityName = "jeu_juste_point";
		}

		return activityName;
	}

	getCurrentExercise() {
		let currentExercise;
		this.cabriService.currentActivity.params.find(item => {
			if (item.exercices) {
				if (Array.isArray(item.defaultValue)) {
					currentExercise = item.defaultValue[0];
				} else {
					currentExercise = item.defaultValue;
				}
			}
		});

		return currentExercise;
	}
}
