<ng-container
  *ngIf="Object.keys(accountService.exerciseStatistics.completedExercises).length > 0 && this.networkService.connectedStatus;else dsplaySimpleCards">
  <ion-card class="eachCategory selectedItem ion-no-padding">
    <div class="contentBody">
      <ion-card-header>
        <ion-card-title class="ion-card-title"
          [ngClass]="globalService.isMobile ? 'ion-card-titleM' : 'ion-card-titleDT'">
          <p class="defaultContent defaultColor">
            {{exerciseByStatus.value.catName}}

            <ng-container *ngIf="accountService.exerciseStatistics.completedExercises[exerciseByStatus.value.catName]">
              (
              <span [ngClass]="{'progressedResponse' :
                accountService.exerciseStatistics.completedExercises[exerciseByStatus.value.catName].numberCompleted > 0 && !globalService.lowPerformanceMode
                }">
                {{accountService.exerciseStatistics.completedExercises[exerciseByStatus.value.catName].numberCompleted}}
              </span>
              <span> / </span>
              <span class="" [ngClass]="{'progressedResponse': !globalService.lowPerformanceMode}">
                {{exerciseByStatus.value.items.length}}</span>
              )
            </ng-container>
          </p>
        </ion-card-title>
      </ion-card-header>

      <ion-card-content *ngIf="accountService.exerciseStatistics.completedExercises[exerciseByStatus.value.catName]">
        <div class="statisticContainer progressionContainer">
          <app-custom-progressbar
            [number]="accountService.exerciseStatistics.completedExercises[exerciseByStatus.value.catName].numberCompleted"
            [total]="this.exerciseByStatus.value.items.length" [mascotVisible]="true">
          </app-custom-progressbar>
        </div>

        <ion-row class="footerRowContainer">
          <ion-col size="12" size-xl="6" size-md="12" class="ion-no-padding">
            <div class="statisticContainer">
              <p class="defaultColor"><span i18n>Ma note actuelle</span> :
                {{accountService.exerciseStatistics.averageRateCorrectAnswers[exerciseByStatus.value.catName]}} %</p>
            </div>
          </ion-col>

          <ion-col size="12" size-xl="6" size-md="12"
            class="ion-no-padding starsContainerCol ion-justify-content-xl-end"
            [ngClass]="{'starsContainerColDesktop' : globalService.isDesktop}">
            <div class="statisticContainer starsItems">
              <p class="statisticResponse defaultColor"
                [ngClass]="{'progressedResponse' : accountService.exerciseStatistics.totalNumberAwardsByActivity[exerciseByStatus.value.catName].totalShootings > 0  && !globalService.lowPerformanceMode}">
                {{accountService.exerciseStatistics.totalNumberAwardsByActivity[exerciseByStatus.value.catName].totalShootings}}
              </p>
              <img src="/assets/starboard/results/shooting.png" class="awards" alt="shooting">
            </div>
            <div class=" statisticContainer starsItems">
              <p class="statisticResponse defaultColor"
                [ngClass]="{'progressedResponse' : accountService.exerciseStatistics.totalNumberAwardsByActivity[exerciseByStatus.value.catName].totalNormals > 0 && !globalService.lowPerformanceMode}">
                {{accountService.exerciseStatistics.totalNumberAwardsByActivity[exerciseByStatus.value.catName].totalNormals}}
              </p>
              <img src="/assets/starboard/results/regular.png" class="awards" alt="regular">
            </div>

            <div class="statisticContainer end starsItems">
              <p class="statisticResponse defaultColor"
                [ngClass]="{'progressedResponse' : accountService.exerciseStatistics.totalNumberAwardsByActivity[exerciseByStatus.value.catName].totalMoons > 0 && !globalService.lowPerformanceMode}">
                {{accountService.exerciseStatistics.totalNumberAwardsByActivity[exerciseByStatus.value.catName].totalMoons}}
              </p>
              <img src="/assets/starboard/results/moon.png" class="awards" alt="moon">
            </div>
            <!-- </div> -->
          </ion-col>
        </ion-row>

      </ion-card-content>
    </div>
  </ion-card>
</ng-container>

<ng-template #dsplaySimpleCards>
  <ion-card class="eachCategory selectedItem">
    <ion-card-header>
      <ion-card-title>
        <p class="categoryTitle defaultColor" *ngIf="exerciseByStatus.value.catName === NoCategoryStatus.label" i18n>
          Non classé</p>
        <p class="categoryTitle defaultColor" *ngIf="exerciseByStatus.value.catName !== NoCategoryStatus.label">
          {{exerciseByStatus.value.catName}}</p>
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
    </ion-card-content>
  </ion-card>
</ng-template>