import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { AlertController, Platform } from "@ionic/angular";
import { environment } from "../../environments/environment";
import { AppUtils } from "../app-utils";
import { AppLanguage } from "../models/enums/enum-list";
import { Tutorial } from "../models/tutorial";
import { GlobalService } from "./global.service";

declare var TTS: any;
declare var window: { utterances: any; speechSynthesis: any };
@Injectable({
	providedIn: "root"
})
export class PlayTTSService {
	// tts
	public synth: SpeechSynthesis;
	public useTTSCordova: boolean;
	public voices = [];
	public allVoices: Array<any>;
	public _selectedVoiceCordova: any;
	public selectedVoiceSynth: SpeechSynthesisVoice;
	public defaultRate: number;
	public rate: number;
	public volume: number;
	private _rateRatio: number;
	private _dicteeMode: boolean;
	private _cordovaTTSisPlaying: boolean;
	public dicteemodePlayTTS: boolean;
	public maxRateRatio: number;
	public minRateRatio: number;
	public volumeBeforeMute: number;
	public replacer: string;
	public voiceSaved: any;
	public _menusMuted: boolean;
	public menusMutedPlayTTS: boolean;
	environment: any;
	public rateRatioPlayTTS = false;
	voiceRateTTS = $localize`La vitesse d'élocution a été changée`;
	appLanguage = "FR";
	rateRatioSliderTimeOut: NodeJS.Timeout;
	playTTSSecurity: NodeJS.Timeout;
	// securityTimeout: NodeJS.Timeout;
	globalService: GlobalService;
	public currentTTSPText: string;
	public protectedTTSisPlaying: boolean;
	public playTTSPWaiting: boolean;
	public currentTTSPCallback: any;
	alertTTSSecurity: HTMLIonAlertElement;

	private securityOff = false;

	constructor(private platform: Platform, @Inject(LOCALE_ID) public locale: string, public alertController: AlertController) {
		// global variable to keep in memory
		this.environment = environment;
		this.synth = window.speechSynthesis;
		window.utterances = [];

		this.platform.ready().then(async () => {
			this.rateRatioGetStorageValue();
			this.dicteeModeGetStorageValue();
			this.menusMutedGetStorageValue();
			this.checkVoice();
		});
	}

	/**
	 * List of all offered voices
	 */
	getAndroidOfferedVoices(): Map<string, { name: string; quality: number; filter: boolean; engine: string }> {
		const map = new Map();
		let conversion = {};
		if (this.locale === AppLanguage.EN) {
			conversion = {
				"en-US-language": { name: "Juliet", quality: 4, filter: true, engine: "google" },
				// updated
				"en-us-x-iob-local": { name: "Lily", quality: 4, filter: true, engine: "google" },
				"en-us-x-iob-network": { name: "Lily 2", quality: 4, filter: true, engine: "google" },
				"en-us-x-iog-local": { name: "Olive", quality: 4, filter: true, engine: "google" },
				"en-us-x-iog-network": { name: "Olive 2", quality: 4, filter: true, engine: "google" },
				"en-us-x-iol-local": { name: "William", quality: 4, filter: true, engine: "google" },
				"en-us-x-iol-network": { name: "William 2", quality: 4, filter: true, engine: "google" },
				"en-us-x-iom-local": { name: "JAMES", quality: 4, filter: true, engine: "google" },
				"en-us-x-iom-network": { name: "JAMES 2", quality: 4, filter: true, engine: "google" },
				"en-us-x-sfg-local": { name: "MARY", quality: 4, filter: true, engine: "google" },
				"en-us-x-sfg-network": { name: "MARY 2", quality: 4, filter: true, engine: "google" },
				"en-us-x-tpc-local": { name: "PATRICIA", quality: 4, filter: true, engine: "google" },
				"en-us-x-tpc-network": { name: "PATRICIA 2", quality: 4, filter: true, engine: "google" },
				"en-us-x-tpd-local": { name: "ROBERT", quality: 4, filter: true, engine: "google" },
				"en-us-x-tpd-network": { name: "ROBERT 2", quality: 4, filter: true, engine: "google" },
				"en-us-x-tpf-local": { name: "LINDA", quality: 4, filter: true, engine: "google" },
				"en-us-x-tpf-network": { name: "LINDA 2", quality: 4, filter: true, engine: "google" }
			};
		} else {
			conversion = {
				"fr-FR-language": { name: "Lola", quality: 1, filter: true, engine: "google" },
				// updated
				"fr-fr-x-fra-local": { name: "Lisa", quality: 4, filter: true, engine: "google" },
				"fr-fr-x-frb-network": { name: "Raphaël 2", quality: 4, filter: true, engine: "google" },
				"fr-fr-x-frc-network": { name: "Anna", quality: 4, filter: true, engine: "google" },
				"fr-fr-x-frd-local": { name: "Hugo 2", quality: 4, filter: true, engine: "google" },
				"fr-fr-x-vlf-local": { name: "Emma 2", quality: 4, filter: true, engine: "google" },
				"fr-fr-x-vlf-network": { name: "Juliette", quality: 4, filter: true, engine: "google" },
				"fr-fr-x-vlf#male_1-local": { name: "Louis", quality: 2, filter: true, engine: "google" },
				"fr-fr-x-vlf#male_2-local": { name: "Hugo 1", quality: 2, filter: true, engine: "google" },
				"fr-fr-x-vlf#male_3-local": { name: "Paul", quality: 3, filter: true, engine: "google" },
				"fr-fr-x-vlf#female_1-local": { name: "Alice", quality: 2, filter: true, engine: "google" },
				"fr-fr-x-vlf#female_2-local": { name: "Chloé", quality: 2, filter: true, engine: "google" },
				"fr-fr-x-vlf#female_3-local": { name: "Margot", quality: 2, filter: true, engine: "google" },
				"fr-fr-x-frd-network": { name: "Léo", quality: 3, filter: true, engine: "google" },
				"fr-fr-x-frc-local": { name: "Léa", quality: 3, filter: true, engine: "google" },
				"fr-fr-x-frb-local": { name: "Raphaël 1", quality: 2, filter: true, engine: "google" },
				"fr-fr-x-fra-network": { name: "Emma 1", quality: 3, filter: true, engine: "google" },
				"fr-FR-SMTm00": { name: "Simon", quality: 3, filter: true, engine: "samsung" },
				"fr-FR-SMTf00": { name: "Sarah", quality: 3, filter: true, engine: "samsung" }
				// old
				// "fr-fr-x-vlf-local": { name: "Emma", quality: 2, filter: false, engine: "google" },
				// "fr-fr-x-frb-network": { name: "Raphaël", quality: 3, filter: false, engine: "google" },
				// "fr-fr-x-frc-network": { name: "Anna high", quality: 3, filter: false, engine: "google" },
				// "fr-fr-x-frd-local": { name: "Hugo", quality: 2, filter: false, engine: "google" },
				// "fr-fr-x-fra-local": { name: "Lisa", quality: 2, filter: false, engine: "google" },
			};
		}

		for (const conversionId in conversion) {
			if (conversionId) {
				map.set(conversionId, conversion[conversionId]);
			}
		}

		return map;
	}

	/**
	 * Get default selected voice
	 */
	get getAndroidDefaultSelectedVoice() {
		let selectedVoice;
		if (this.locale === AppLanguage.EN) {
			selectedVoice = this.voices.find(voice => voice.identifier === 379088071);
			this.voices.forEach(element => {
				if (element.name === "Juliet") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Lily") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Lily 2") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Olive") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Olive 2") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "JAMES") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "JAMES 2") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "MARY") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "MARY 2") {
					selectedVoice = element;
				}
			});
		} else {
			selectedVoice = this.voices.find(voice => voice.identifier === "fr-FR-language");
			this.voices.forEach(element => {
				if (element.name === "Emma 2") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Juliette") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Léa") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Lisa") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Raphaël 1") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Emma 1") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Hugo 2") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Anna") {
					selectedVoice = element;
				} else if (!selectedVoice && element.name === "Lola") {
					selectedVoice = element;
				}
			});
		}

		return selectedVoice;
	}

	get isplaying() {
		if (!this.useTTSCordova) {
			return this.synth.speaking;
		} else {
			return this._cordovaTTSisPlaying;
		}
	}

	/**
	 * Starting point of the voice check methods (SYNTH / NATIVE)
	 * @returns promise void
	 */
	checkVoice(): Promise<void> {
		return new Promise(async resolve => {
			this.useTTSCordova =
				typeof TTS !== "undefined" &&
				!this.platform.is("desktop") &&
				!this.platform.is("mobileweb") &&
				!document.URL.startsWith("https://app.mathia.education");
			if (this.useTTSCordova) {
				// get the french voices available on device
				await this.getDefaultCordovaVoicesAsync();
			} else if (this.synth) {
				// Web Synth :
				this.voiceSelectedGetStorageValue();
				await this.setSynthVoice();
				this.voiceRateInitSynth(true);
			}
			resolve();
		});
	}

	// SYNTH VOICE

	/**
	 * Get voices from synth.getVoices() (window.speechSynthesis)
	 * @returns promise <voices: []>
	 */
	getSynthSpeechVoices() {
		return new Promise((resolve, reject) => {
			// security if first calls of getVoices return empty array
			let count = 0;
			const id = setInterval(() => {
				const voices = window.speechSynthesis.getVoices();
				if (voices.length !== 0 || count > 100) {
					resolve(voices);
					clearInterval(id);
				}
				count++;
			}, 100);
		});
	}

	/**
	 * populates synth voices list + filter them to selected language (appLanguage)
	 * if voiceSaved in LS present in list => recover / else => findSynthBestVoice()
	 * @returns promise <void>
	 */
	setSynthVoice(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			if (this.synth) {
				console.log("getSynthSpeechVoices()");
				this.getSynthSpeechVoices().then(async (voices: []) => {
					this.allVoices = voices;
					this.voices = voices;
					if (!environment.production) {
						console.log("not filtered synthVoices = ", this.voices);
					}
					// filter voice list to targetted language:
					if (this.locale === AppLanguage.EN) {
						this.filterSynthVoicesToEN();
					} else {
						this.filterSynthVoicesToFR();
					}
					console.log("voice saved in ls = ", this.voiceSaved);
					// select LS saved voice if present in the list:
					if (
						this.voiceSaved &&
						(this.voiceSaved.lang.includes(this.appLanguage) || this.voiceSaved.lang.includes(this.appLanguage.toLowerCase()))
					) {
						this.selectedVoiceSynth = this.voices.find(voice => voice.voiceURI === this.voiceSaved.voiceURI);
						if (!this.selectedVoiceSynth) {
							// if not present find a new one
							this.findSynthBestVoice();
						}
					} else {
						if (this.voiceSaved) {
							console.log("resetting voiceSave in LS cause app Language changed -> findSynthBestVoice()");
						}
						this.voiceSaved = null;
						this.findSynthBestVoice();
					}
					if (!environment.production) {
						console.log("selectedVoiceSynth = ", this.selectedVoiceSynth);
					}
					resolve();
				});
			}
		});
	}

	/**
	 * tool to search a voice in the this.voices Array
	 * @returns voice
	 */
	searchVoice(voiceUriOrName, name?) {
		let voiceFound;
		if (!name) {
			this.voices.forEach(element => {
				if (element.voiceURI === voiceUriOrName) {
					voiceFound = element;
				}
			});
		} else {
			this.voices.forEach(element => {
				if (element.name === voiceUriOrName) {
					voiceFound = element;
				}
			});
		}
		return voiceFound;
	}

	/**
	 * finds synth best possible voice according to chosen language & save it in LS
	 * @returns null
	 */
	findSynthBestVoice() {
		if (this.locale === AppLanguage.EN) {
			this.selectSynthBestVoiceUS();
		} else {
			this.selectSynthBestVoiceFR();
		}
		if (!this.selectedVoiceSynth) {
			if (this.locale === AppLanguage.EN) {
				this.selectFirstSynthVoiceUS();
			} else {
				this.selectFirstSynthVoiceFR();
			}
		}
		if (this.selectedVoiceSynth) {
			this.voiceSelectedSetStorageValue();
		} else {
			console.error("Found no synth voice !");
		}
	}

	/**
	 * filter french voices
	 * (TODO: update over time?)
	 * @returns null
	 */
	filterSynthVoicesToFR() {
		this.voices = this.voices.filter(
			voice =>
				// includes all fr derivatives (Switzerland, Canada, Belgium):
				voice.lang.includes("fr")
			// for more precise selection:
			// voice.lang === "fr-FR" ||
			// voice.lang === "fr" ||
			// voice.lang === "fr_FR" ||
			// voice.lang === "fra-FRA-f00" ||
			// voice.lang === "fra-FRA-m00"
		);
		if (!environment.production) {
			console.log("this.voices = ", this.voices);
		}
	}

	/**
	 * select best known french voice
	 * (TODO: update over time / different devices)
	 * @returns null
	 */
	selectSynthBestVoiceFR() {
		this.selectedVoiceSynth = this.searchVoice("Google français");
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Denise Online (Natural) - French (France)");
		}
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Julie - French (France)");
		}
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Paul - French (France)");
		}
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Hortense - French (France)");
		}
		// TODO APPLE & others?
	}

	/**
	 * select first matching french voice
	 * @returns null
	 */
	selectFirstSynthVoiceFR() {
		this.voices.forEach(voice => {
			if (voice.lang === "fr-FR" || voice.lang === "fr_FR" || voice.lang === "fra-FRA-f00" || voice.lang === "fra-FRA-m00") {
				this.selectedVoiceSynth = voice;
			}
		});
		if (!this.selectedVoiceSynth) {
			this.voices.forEach(voice => {
				if (voice.lang.includes("fr")) {
					this.selectedVoiceSynth = voice;
				}
			});
		}
		console.log("this.selectedVoiceSynth = ", this.selectedVoiceSynth);
	}

	setSynthVoiceRateRatioFr() {
		if (this.selectedVoiceSynth) {
			this.defaultRate = 1;
			if (this.selectedVoiceSynth.voiceURI === "Google français") {
				this.minRateRatio = 0.8;
				this.maxRateRatio = 1.3;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Denise Online (Natural) - French (France)") {
				this.minRateRatio = 0.8;
				this.maxRateRatio = 1.3;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Julie - French (France)") {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.3;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Paul - French (France)") {
				this.minRateRatio = 0.6;
				this.maxRateRatio = 1.5;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Hortense - French (France)") {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.5;
			} else if (this.selectedVoiceSynth.name.includes("Google")) {
				this.minRateRatio = 0.8;
				this.maxRateRatio = 1.2;
			} else if (this.selectedVoiceSynth.name.includes("Microsoft")) {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.5;
			} else if (this.selectedVoiceSynth.voiceURI.includes("com.apple.")) {
				this.minRateRatio = 0.9;
				this.maxRateRatio = 1.1;
			} else {
				this.minRateRatio = 0.5;
				this.maxRateRatio = 1.5;
			}
		}
		// TODO APPLE & others?
	}

	/**
	 * filter english voices
	 * @returns null
	 */
	filterSynthVoicesToEN() {
		this.voices = this.voices.filter(
			voice => voice.lang.includes("en")
			// voice.lang === "en-US"
			// || voice.lang === "en-GB"
			// || voice.lang === "en_US"
			// || voice.lang === "en_GB"
			// || voice.lang === "en"
			// || voice.lang === "en-AU"
		);
		if (!environment.production) {
			console.log("this.voices filtered = ", this.voices);
		}
	}

	/**
	 * select best known english voice & setup min/max rateRatios accordingly
	 * (TODO: update over time / different devices)
	 * @returns null
	 */
	selectSynthBestVoiceUS() {
		this.selectedVoiceSynth = this.searchVoice("Google US English");
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Jenny Online (Natural) - English (United States)");
		}
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Aria Online (Natural) - English (United States)");
		}
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Mark - English (United States)");
		}
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Zira - English (United States)");
		}
		if (!this.selectedVoiceSynth) {
			this.selectedVoiceSynth = this.searchVoice("Microsoft Zira Desktop - English (United States)", true);
		}
		// TODO APPLE & others?
	}

	/**
	 * select first matching english voice
	 * @returns null
	 */
	selectFirstSynthVoiceUS() {
		this.voices.forEach(element => {
			if (element.lang === AppLanguage.EN || element.lang.includes("en") || element.lang.includes("US")) {
				this.selectedVoiceSynth = element;
			}
		});

		this.voices.forEach(voice => {
			if (voice.lang === AppLanguage.EN || voice.lang.includes("US")) {
				this.selectedVoiceSynth = voice;
			}
		});
		if (!this.selectedVoiceSynth) {
			this.voices.forEach(voice => {
				if (voice.lang.includes("en")) {
					this.selectedVoiceSynth = voice;
				}
			});
		}
		console.log("this.selectedVoiceSynth = ", this.selectedVoiceSynth);
	}

	setSynthVoiceRateRatioEn() {
		this.defaultRate = 1;
		if (this.selectedVoiceSynth){
			if (this.selectedVoiceSynth.voiceURI === "Google US English") {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.2;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Jenny Online (Natural) - English (United States)") {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.3;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Aria Online (Natural) - English (United States)") {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.3;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Mark - English (United States)") {
				this.minRateRatio = 0.6;
				this.maxRateRatio = 1.5;
			} else if (this.selectedVoiceSynth.voiceURI === "Microsoft Zira - English (United States)") {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.5;
			} else if (this.selectedVoiceSynth.name === "Microsoft Zira Desktop - English (United States)") {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.5;
			} else if (this.selectedVoiceSynth.name.includes("Google")) {
				this.minRateRatio = 0.7;
				this.maxRateRatio = 1.3;
			} else if (this.selectedVoiceSynth.name.includes("Microsoft")) {
				this.minRateRatio = 0.6;
				this.maxRateRatio = 1.5;
			} else if (this.selectedVoiceSynth.voiceURI.includes("com.apple.")) {
				this.minRateRatio = 0.9;
				this.maxRateRatio = 1.1;
			} else {
				this.minRateRatio = 0.6;
				this.maxRateRatio = 1.5;
			}
		}
		// TODO APPLE & others?
	}

	/**
	 * change voice from settings component & resets rate
	 * @returns null
	 */
	changeVoice() {
		if (this.synth) {
			if (!this.environment.production) {
				console.log("selectedVoiceSynth = ", this.selectedVoiceSynth);
			}
			this.voiceRateInitSynth(false, true);
		}
		this.voiceSelectedSetStorageValue();
	}

	/**
	 * set voice rate according to LS saved rate if any & voice type then save it in LS
	 * @returns null
	 */
	voiceRateInitSynth(getLSValue: boolean, voiceChanged = false) {
		// default dictee mode replacer.
		this.replacer = " , ";

		if (getLSValue) {
			const storedRate = localStorage.getItem("rate");
			if ((storedRate && storedRate !== "NaN") || storedRate !== undefined) {
				this.rate = parseFloat(storedRate);
			} else {
				this.defaultRate = 1;
				this.rate = this.defaultRate;
				localStorage.setItem("rate", String(this.rate));
			}
			// remove (see init) ?
			this.rateRatioGetStorageValue();
		}
		if (!this.defaultRate || voiceChanged) {
			if (this.locale === AppLanguage.FR) {
				this.setSynthVoiceRateRatioFr();
			} else if (this.locale === AppLanguage.EN) {
				this.setSynthVoiceRateRatioEn();
			}
		}
		if (voiceChanged) {
			this.rate = this._rateRatio = this.defaultRate;
		}
		if (this._rateRatio < this.minRateRatio || this._rateRatio > this.maxRateRatio) {
			this.rate = this._rateRatio = this.defaultRate;
			if (!this.environment.production) {
				console.log("rate reset to default cause of voice change");
			}
		}
		if (this.rate) {
			this.rate = this.roundRate(this.rate);
		} else {
			this.rate = this.defaultRate;
		}
		localStorage.setItem("rate", String(this.rate));
		if (!environment.production) {
			console.log("voice rate set to " + this.rate);
		}
	}

	// CORDOVA VOICE

	async getDefaultCordovaVoicesAsync() {
		return new Promise<void>(resolve => {
			if (this.locale === AppLanguage.EN) {
				TTS.getVoices(AppLanguage.EN).then(voices => {
					this.allVoices = voices;
					this.voices = voices;
					// remap voices array for android
					if (this.platform.is("android")) {
						this.voices = this.remapVoiceList(this.voices);
					}
					// remap voices array for ios
					if (this.platform.is("ios")) {
						this.voices = this.remapIosVoiceList(this.voices);
					}
					console.log("this.voices after remap = ", this.voices);
					// check voice save in localstorage else choose first one
					this.voiceSelectedGetStorageValue().then(() => {
						// TTS Rate
						this.voiceRateInitCordova();
						resolve();
					});
				});
			} else {
				TTS.getVoices("fr-FR").then(voices => {
					this.voices = voices;
					console.log("this.voices before remap = ", this.voices);
					// remap voices array for android
					if (this.platform.is("android")) {
						this.voices = this.remapVoiceList(this.voices);
					}
					// remap voices array for ios
					if (this.platform.is("ios")) {
						this.voices = this.remapIosVoiceList(this.voices);
					}
					console.log("this.voices after remap = ", this.voices);
					// check voice save in localstorage else choose first one
					this.voiceSelectedGetStorageValue().then(() => {
						// TTS Rate
						this.voiceRateInitCordova();
						resolve();
					});
				});
			}
		});
	}

	// rate
	voiceRateInitCordova() {
		this.defaultRate = this.platform.is("android") ? 1.55 : 1.05;
		// ios :
		this.minRateRatio = 0.8;
		this.maxRateRatio = 1.1;
		this.replacer = " , ";
		// google :
		// console.log('voiceRateInitCordova() -- this._selectedVoiceCordova.engine condition enter');
		if (this._selectedVoiceCordova.engine === "google") {
			this.replacer = " \n ";
			this.minRateRatio = 0.6;
			this.maxRateRatio = 1.3;
			this.defaultRate = 1.4;
			console.log("(google) minRR = " + this.minRateRatio + " maxRR = " + this.maxRateRatio + " defaultRate = " + this.defaultRate);
		} else if (this._selectedVoiceCordova.engine === "samsung") {
			this.replacer = " , ";
			this.minRateRatio = 0.8;
			this.maxRateRatio = 1.2;
			this.defaultRate = 1.3;
			// console.log('this.min/max rate ratio set to ' + this.minRateRatio + ' and ' + this.maxRateRatio + ' (samsung) @ init');
		}
		this.rateRatioGetStorageValue();
		this.rate = this.defaultRate * this._rateRatio;
		this.rate = this.roundRate(this.rate);
		localStorage.setItem("rate", String(this.rate));
		console.log("this rate set to " + this.rate + " on voiceRateInitCordova() end --+ stored in LS");
	}

	remapVoiceList(voices): any[] {
		const finalVoice: any[] = new Array();
		const conversion = this.getAndroidOfferedVoices();
		voices.sort((a, b) => (a.name <= b.name ? -1 : 1));
		voices.forEach(voice => {
			// if (conversion[voice.name] && conversion[voice.name].filter) {
			if (conversion.get(voice.name)) {
				const defaultName = this.locale === AppLanguage.EN ? "en-US-language" : "fr-FR-language";
				if (voice.name === defaultName) {
					voice.default = true;
				}
				voice.quality = conversion.get(voice.name).quality;
				voice.engine = conversion.get(voice.name).engine;
				voice.name = conversion.get(voice.name).name;

				if (voice.default) {
					finalVoice.unshift(voice);
				} else {
					finalVoice.push(voice);
				}
			} else if (!conversion.get(voice.name)) {
				finalVoice.push(voice);
			}
		});
		finalVoice.sort();
		return finalVoice;
	}

	remapIosVoiceList(voices): any[] {
		const finalVoice: any[] = new Array();
		const conversion = {
			"com.apple.ttsbundle.siri_female_fr-FR_compact": { name: "Marie", quality: 2, filter: true, engine: "ios" },
			"com.apple.ttsbundle.siri_male_fr-FR_compact": { name: "Daniel", quality: 2, filter: true, engine: "ios" },
			"com.apple.ttsbundle.Audrey-compact": { name: "Audrey", quality: 2, filter: true, engine: "ios" },
			"com.apple.ttsbundle.Audrey-premium": { name: "Audrey améliorée", quality: 3, filter: true, engine: "ios" },
			"com.apple.ttsbundle.Thomas-compact": { name: "Thomas", quality: 2, filter: true, engine: "ios" },
			"com.apple.ttsbundle.Thomas-premium": { name: "Thomas amélioré", quality: 3, filter: true, engine: "ios" },
			"com.apple.ttsbundle.Aurelie-compact": { name: "Aurélie", quality: 2, filter: true, engine: "ios" },
			"com.apple.ttsbundle.Aurelie-premium": { name: "Aurélie améliorée", quality: 3, filter: true, engine: "ios" }
		};
		voices.sort((a, b) => (a.name <= b.name ? -1 : 1));
		voices.forEach(voice => {
			if (conversion[voice.identifier] && conversion[voice.identifier].filter) {
				if (voice.identifier === "com.apple.ttsbundle.siri_female_fr-FR_compact") {
					voice.default = true;
				}
				voice.quality = conversion[voice.identifier].quality;
				voice.name = conversion[voice.identifier].name;
				if (voice.default) {
					finalVoice.unshift(voice);
				} else {
					finalVoice.push(voice);
				}
			} else if (!conversion[voice.identifier]) {
				finalVoice.push(voice);
			}
		});
		finalVoice.sort();
		return finalVoice;
	}

	async checkVoiceSelected() {
		if (this.selectedVoiceCordova && this.selectedVoiceCordova.identifier) {
			return true;
		} else {
			await this.getDefaultCordovaVoicesAsync();
			return true;
		}
	}

	// CORE

	async playTTS(playtext: string, callback: any = null, dicteeMode: boolean = false, security = true): Promise<any> {
		// security
		playtext = String(playtext);
		playtext = this.sanitize(playtext);
		if (dicteeMode === true && this.dicteeMode === true) {
			// dictee mode use ponctuation to slow down speech
			playtext = playtext.replace(/ /g, this.replacer);
		}
		if (this.synth && !this.useTTSCordova) {
			// browser
			if (this.isplaying) {
				// kill previous speech
				console.log("CALLING SYNTH killSpeech() on playTTS() START");
				await this.killSpeech();
			}
			console.log(this.selectedVoiceSynth);
			if (this.selectedVoiceSynth) {
				// create speech object
				const utterThis = new SpeechSynthesisUtterance(playtext);
				utterThis.voice = this.selectedVoiceSynth;
				utterThis.lang = utterThis.voice.lang;
				utterThis.volume = this.volume;

				// set rate
				const rate = this.roundRate(this.rate);
				if (rate) {
					utterThis.rate = rate;
				}

				// save variable to prevent deletion by garbage collector (iOS)
				window.utterances = [];
				window.utterances.push(utterThis);
				// manage events to end promise at end of speech or on error
				return new Promise<any>((resolve, reject) => {
					// end event
					utterThis.addEventListener(
						"end",
						event => {
							console.log("playTTS() END");
							resolve(true);
							if (callback) {
								callback();
							}
						},
						true
					);

					utterThis.onstart = () => {
						this.clearPlayTTSSecurity();
					};

					// start event
					utterThis.addEventListener("start", event => { });

					// error event
					utterThis.addEventListener(
						"error",
						event => {
							console.log("playTTS() ERROR : " + JSON.stringify(event));
							resolve(event);
						},
						true
					);

					// speak
					try {
						this.clearPlayTTSSecurity();
						if (playtext !== " " && playtext !== "") {
							this.setPlayTTSSecurity(playtext, callback, dicteeMode, security, resolve);
						}
						this.synth.speak(utterThis);
					} catch (e) {
						resolve(true);
					}
				});
			} else {
				return new Promise<void>((resolve, reject) => {
					resolve();
				});
			}
		} else {
			// natif
			try {

				if (TTS) {
					// make sur voice is ready
					await this.checkVoiceSelected();

					// kill previous speech
					if (this.isplaying) {
						console.log("SPEECH KILLED ON PLAYTTS() START");
						this.killSpeech();
					}
					this._cordovaTTSisPlaying = true;
					// create speech object
					let ttsSpeak;
					if (this._selectedVoiceCordova?.identifier) {
						ttsSpeak = TTS.speak({
							text: playtext,
							rate: this.rate,
							volume: this.volume,
							identifier: this._selectedVoiceCordova.identifier
						})
							.then(() => {
								this._cordovaTTSisPlaying = false;
								console.log("Success");
							})
							.catch((reason: any) => {
								this._cordovaTTSisPlaying = false;
								console.log("TTS catch error", reason);
							});
					} else {
						console.log("no selectedVoiceCordova in playTTS");
						ttsSpeak = new Promise<any>((resolve, reject) => {
							resolve(true);
						});
					}
					return ttsSpeak;
				}
			} catch (e) {
				return new Promise<void>((resolve, reject) => {
					resolve();
				});
			}
		}
	}

	setPlayTTSSecurity(playtext: string, callback: any = null, dicteeMode: boolean = false, security = true, resolve) {
		if (!this.securityOff) {
			this.playTTSSecurity = setTimeout(async () => {
				console.log("*** relaunching playTTS ***");
				// this.synth.speak(utterThis);
				if (security && !this.isplaying) {
					this.alertTTSSecurity = await this.alertController.create({
						cssClass: "waitUserActionAlert",
						backdropDismiss: false,
						mode: "ios",
						message: $localize`Activer la synthèse vocale`,
						buttons: [
							{
								text: $localize`OK`,
								role: "confirm",
								cssClass: "startButtonCabri",
								handler: async () => {
									await this.playTTS($localize`OK`, null, false, false);
									this.playTTS(playtext, callback, dicteeMode, false);
								}
							}
						]
					});
					await this.alertTTSSecurity.present();
				} else {
					if (this.isplaying) {
						// ok, remove tag security off
						this.securityOff = false;
					} else if (security === false) {
						// ko, set tag security off
						this.securityOff = true;
					}
					resolve();
				}
			}, 5000);
		} else {
			resolve();
		}
	}

	clearPlayTTSSecurity() {
		this.securityOff = false;
		if (this.playTTSSecurity) {
			clearTimeout(this.playTTSSecurity);
			this.playTTSSecurity = null;
		}
		this.alertTTSSecurity?.dismiss();
	}

	sanitize(text: string) {
		const replacements = {
			" CE1": " C E 1",
			" CE2": " C E 2",
			"CE1 ": "C E 1 ",
			"CE2 ": "C E 2 ",
			CM1: "C M 1",
			CM2: "C M 2",
			explorer: "exploré",
			"km (kilomètre)": "kilomètre",
			" km ": "kilomètre",
			" km.": "kilomètre.",
			"km³(kilomètre cube)": "kilomètre cube",
			"CO2 (dioxyde de carbone)": "dioxyde de carbone",
			"CO2 (le dioxyde de carbone)": "dioxyde de carbone",
			CO2: "C O 2",
			" g de CO2": "gramme de CO2",
			" kg de CO2": "kilo de CO2",
			" av\\.": " avant",
			JC: "Jésus-Christ",
			" XIIe ": " douzième ",
			" XIIIe ": " treizième ",
			" XIVe ": " quatorzième ",
			" Salies-": " Salisse ",
			" plus de goût": " pluce de goût",
			"\\.\\.\\." : "…",
			",,," : "…",
			", , ,": "…",
			"Le Gave ": "Le Gâve ",
			"×": "fois",
			"÷": "diviser par"
		};
		const replacementsIOSSafari = {
			"([\\w])-([\\w])": "$1 $2",
			quizz: "couiz",
			"comme les pesticides": "comme les pessticides",
			AOC: "A O C",
			AOP: "A O P",
			kidaia: "kida ya",
			" III([ ,.])": " 3$1",
			" IV([ ,.])": "  4$1",
			bayonne: "baïonne",
			Phébus: "Phébusse",
			Fébus: "Phébusse",
			puzzle: "peuzeule",
		};

		const replacementsMicrosoft = {
			Et: { text: "et", insensitive: false },
			Cosinus: "Cossinus",
			"Profites-en": "Profite zan",
			"Profitons-en": "Profiton zan",
			Alaya: "Halaya",
			Toutatis: "Toutatice",
			"le bouton plus": "le bouton pluce",
			"en plus": "en pluce",
			" Gave ": " Gav ",
			"nutriments?": "nutriman",
			kidaia: "kidaaya"
		};

		const replacementsMicrosoftDenise = {
			"t'y": "t'hi",
			" y ": " hi "
		};
		// replacements for all speech synthesis system
		text = this.replaceCustom(replacements, text);

		// replacements for iOS Safari speech synthesis system
		if (this.platform.is("ios") && !this.useTTSCordova) {
			text = this.replaceCustom(replacementsIOSSafari, text);
		}

		// replacements for Microsoft speech synthesis system
		if (this.selectedVoiceSynth?.name.match(/Microsoft/gi)) {
			text = this.replaceCustom(replacementsMicrosoft, text);
		}

		// replacements for Microsoft speech synthesis Denise
		if (this.selectedVoiceSynth?.name.match(/Microsoft/gi) && this.selectedVoiceSynth.name.match(/Denise/gi)) {
			text = this.replaceCustom(replacementsMicrosoftDenise, text);
		}
		return text;
	}

	replaceCustom(replacements, text) {
		for (const key in replacements) {
			if (replacements.hasOwnProperty(key)) {
				let insensitive = true;
				if (replacements[key].text) {
					insensitive = replacements[key].insensitive;
					replacements[key] = replacements[key].text;
				}
				while (text.match(new RegExp(key, insensitive ? "gi" : "g"))) {
					text = text.replace(new RegExp(key, insensitive ? "gi" : "g"), replacements[key]);
				}
				// TODO test on ios => no need while?
				// text = text.replace(new RegExp(key, insensitive ? "gi" : "g"), replacements[key]);
			}
		}
		return text;
	}

	roundRate(x) {
		let round: number;
		round = x * 100;
		round = Math.round(round);
		round = round / 100;

		if (isFinite(round)) {
			return round;
		} else {
			console.log("infinite tts rate value !", round);
			return round;
		}
	}

	killSpeech(callback = null): Promise<any> {
		this.clearPlayTTSSecurity();
		if (!this.useTTSCordova && this.synth && typeof this.synth !== undefined && this.synth.speaking) {
			// if browser is playing, kill it
			this.synth.cancel();
			console.log("killSpeech synth.cancel()");
			return new Promise<void>(async (resolve, reject) => {
				await AppUtils.timeOut(200);
				resolve();
			});
		} else if (typeof TTS !== "undefined" && this.isplaying) {
			this._cordovaTTSisPlaying = false;
			// if natif is playing
			if (this.platform.is("ios")) {
				return TTS.speak("")
					.then(() => {
						console.log("%cTTS KILLSPEECH() IOS DONE");
						if (callback) {
							callback();
						}
					})
					.catch((reason: any) => {
						console.log("%cTTS KILLSPEECH() IOS ERROR : " + reason);
					});
			} else {
				const promise = new Promise((resolve, reject) => {
					console.log("%cTTS KILLSPEECH() CORDOVA DONE");
					resolve(true);
				});
				TTS.stop();
				if (callback) {
					callback();
				}
				return promise;
			}
		} else {
			return Promise.resolve(false);
		}
	}

	// Getters and Setters -->

	// VOICE
	voiceSelectedSetStorageValue() {
		if (this.useTTSCordova) {
			if (this._selectedVoiceCordova) {
				this.voiceSaved = this._selectedVoiceCordova;
				localStorage.setItem(this.localStorageVoiceName, JSON.stringify(this.voiceSaved));
				// console.log(
				// 	"TTS _voiceSelected written in LS + TTS voiceSave ----> " +
				// 		JSON.parse(localStorage.getItem("voiceSelected"))
				// );
			}
		} else if (this.synth && this.selectedVoiceSynth) {
			// console.error("saving this.selectedVoiceSynth = ", this.selectedVoiceSynth);
			const voiceSave = {
				name: this.selectedVoiceSynth.name,
				voiceURI: this.selectedVoiceSynth.voiceURI,
				lang: this.selectedVoiceSynth.lang,
				localService: this.selectedVoiceSynth.localService
			};
			// console.error("as voiceSave = ", voiceSave);
			localStorage.setItem(this.localStorageVoiceName, JSON.stringify(voiceSave));
			console.log("TTS selectedVoiceSynth written in LS + TTS voiceSave ----> " + this.selectedVoiceSynth.voiceURI);
			// console.log("TTS selectedVoiceSynth written in LS + TTS voiceSave ----> ", this.voiceSave);
		}
	}

	/**
	 * Name of voice saved in local storage
	 */
	get localStorageVoiceName(): string {
		let storageString = "";
		if (this.locale === AppLanguage.EN) {
			storageString = "voiceSelectedEn";
		} else {
			storageString = "voiceSelected";
		}
		return storageString;
	}

	async voiceSelectedGetStorageValue(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.useTTSCordova = typeof TTS !== "undefined" && !this.platform.is("desktop") ? true : false;
			if (this.useTTSCordova) {
				// CORDOVA
				const selectedVoiceCordova = localStorage.getItem(this.localStorageVoiceName);
				if (selectedVoiceCordova && selectedVoiceCordova !== "undefined") {
					// console.log('selectedVoiceCordova in LS ENTERED');
					try {
						this.voiceSaved = JSON.parse(selectedVoiceCordova);
					} catch (e) {
						this.voiceSaved = null;
						console.log("json parse voice save error", e);
					}
				}
				if (this.voiceSaved && this.voiceSaved.identifier) {
					this._selectedVoiceCordova = this.voices.find(voice => voice.identifier === this.voiceSaved.identifier);
				}
				if (this._selectedVoiceCordova) {
					resolve(true);
				} else {
					// console.log('no selectedVoiceCordova in LS : ');
					// this._selectedVoiceCordova = this.voices.find(voice => voice.identifier === "fr-FR-language");
					this._selectedVoiceCordova = this.getAndroidDefaultSelectedVoice;
					if (this._selectedVoiceCordova === undefined || this.selectedVoiceCordova === null) {
						this._selectedVoiceCordova = this.voices[0];
					}
					this.voiceSaved = this._selectedVoiceCordova;
					localStorage.setItem(this.localStorageVoiceName, JSON.stringify(this.voiceSaved));
					resolve(true);
				}
			} else if (this.synth) {
				// SYNTH
				if (localStorage.getItem(this.localStorageVoiceName)) {
					const save = JSON.parse(localStorage.getItem(this.localStorageVoiceName));
					if ((save && typeof save === "string") || save instanceof String) {
						this.voiceSaved = null;
						// console.error("resetting voiceSave in LS cause old format");
						localStorage.setItem(this.localStorageVoiceName, null);
					} else if (save) {
						this.voiceSaved = save;
						// console.error("got voiceSaved from LS = ", this.voiceSaved + " type of = " + typeof this.voiceSaved);
					} else {
						// console.error("no voice saved in LS");
					}
					// if (!this.environment.production) {
					// 	console.log("selectedVoiceSynth FROM LS : ", this.selectedVoiceSynth);
					// }
					resolve(true);
				} else {
					if (!this.environment.production) {
						console.log("No voice selected in LS");
					}
					resolve(false);
				}
			}
		});
	}
	set selectedVoiceCordova(voice: any) {
		this._selectedVoiceCordova = voice;
	}
	get selectedVoiceCordova() {
		return this._selectedVoiceCordova;
	}

	// RATE RATIO
	set rateRatio(value: number) {
		if (this.rateRatioSliderTimeOut) {
			clearTimeout(this.rateRatioSliderTimeOut);
		}
		this.rateRatioSliderTimeOut = setTimeout(() => {
			this._rateRatio = value;
			this.rate = this.defaultRate * this._rateRatio;
			this.rate = this.roundRate(this.rate);
			if (!this.environment.production) {
				console.log("Synth rate set to " + this.rate + " (rateRatio = " + this._rateRatio + ")");
			}
			if (this.rateRatioPlayTTS) {
				this.playTTS(this.voiceRateTTS);
			}
		}, 500);
	}
	get rateRatio(): number {
		return this._rateRatio;
	}
	rateRatioGetStorageValue() {
		if (localStorage.getItem("ttsRateRatio") && localStorage.getItem("ttsRateRatio") !== "NaN") {
			this._rateRatio = parseFloat(localStorage.getItem("ttsRateRatio"));
			//   console.log('rateRatio on ttsService updated from storage  : ' + this._rateRatio);
		} else {
			this._rateRatio = 1;
			localStorage.setItem("ttsRateRatio", String(this._rateRatio));
			console.log("ttsRateRatio imported from LocalStor by TTS service : " + this._rateRatio);
		}
	}
	rateRatioSetStorageValue() {
		localStorage.setItem("ttsRateRatio", String(this._rateRatio));
		localStorage.setItem("rate", String(this.rate));
		// console.log('TTSRateRatio written in LocalStor by TTS service : ' + this._rateRatio);
		// console.log('TTSrate written in LocalStor by TTS service : ' + this.rate);
	}

	// DICTEE MODE
	set dicteeMode(value: boolean) {
		if (value === true) {
			this._dicteeMode = true;
			if (this.dicteemodePlayTTS === true) {
				this.playTTS($localize`le mode dictée est activé`, null, false);
			}
		} else {
			this._dicteeMode = false;
			if (this.dicteemodePlayTTS === true) {
				this.playTTS($localize`le mode dictée est désactivé`, null, false);
			}
		}
	}
	get dicteeMode(): boolean {
		return this._dicteeMode;
	}
	dicteeModeGetStorageValue() {
		if (localStorage.getItem("dicteeModeState")) {
			this._dicteeMode = localStorage.getItem("dicteeModeState") as any;
			console.log("dicteeModeState in TTSservice updated from storage : " + this._dicteeMode);
		} else if (!localStorage.getItem("dicteeModeState")) {
			this._dicteeMode = false;
			console.log("dicteeModeState in TTSservice updated from service (no storage data) : " + this._dicteeMode);
		}
	}
	dicteeModeSetStorageValue() {
		localStorage.setItem("dicteeModeState", this._dicteeMode as any);
		console.log("dicteeModeState written in LocalStor by TTS service : " + this._dicteeMode);
	}
	dicteeHelp() {
		// console.log('string replacer : ' + this.replacer);
		this.playTTS(
			$localize`Le mode dictée accentue la séparation entre les mots sur la page d'enregistrement ` +
			$localize` Par exemple ` +
			this.replacer +
			$localize`2 plus 2 égale 4` +
			this.replacer +
			$localize`donnera. 2` +
			this.replacer +
			$localize` plus` +
			this.replacer +
			$localize` 2` +
			this.replacer +
			$localize` égale` +
			this.replacer +
			$localize` 4`,
			null,
			false
		);
	}

	// MUTE MENUS MODE (only in Mathia)
	set menusMuted(value: boolean) {
		if (value === true) {
			this._menusMuted = true;
			if (this.menusMutedPlayTTS === true) {
				this.playTTS($localize`le mode menus silencieux est activé`, null, false);
			}
		} else {
			this._menusMuted = false;
			if (this.menusMutedPlayTTS === true) {
				this.playTTS($localize`le mode menus silencieux est désactivé`, null, false);
			}
		}
	}
	get menusMuted(): boolean {
		return this._menusMuted;
	}
	menusMutedGetStorageValue() {
		if (localStorage.getItem("menusMutedState")) {
			this._menusMuted = JSON.parse(localStorage.getItem("menusMutedState"));
			console.log("menusMutedState in TTSservice updated from storage : " + this._menusMuted);
		} else if (!localStorage.getItem("menusMutedState")) {
			this._menusMuted = false;
			console.log("menusMutedState in TTSservice updated from service (no storage data) : " + this._menusMuted);
		}
	}
	menusMutedSetStorageValue() {
		localStorage.setItem("menusMutedState", String(this._menusMuted));
		console.log("menusMutedState written in LocalStor by TTS service : " + this._menusMuted);
	}

	/**
	 * Play TTS with Protection System against interruption
	 * @param element TTS text
	 * @param callback inject playTTS.then() content here (will be saved in case of interrupting outside event)
	 * TODO: handle multiple outside events through protection param
	 */
	playTTSEventProtected(element: any, callback: any = null, dicteemode: boolean = false, fromToolbar = false): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.currentTTSPText = element;
			this.protectedTTSisPlaying = true;
			// console.log("%cglobalService.protectedTTSisPlaying = " + this.protectedTTSisPlaying, "background: pink; color: black;");
			this.currentTTSPCallback = callback;
			let text;
			if (element instanceof Tutorial) {
				// console.log("%cplayTTSProtected ENTER with tutorial phrase : " + element.phrase, "background: pink; color: black;");
				// console.log("object = ", element);
				if (element.phraseTTS) {
					text = element.phraseTTS;
				} else {
					text = element.phrase;
				}
			} else if (element) {
				// console.log("%cplayTTSProtected ENTER with : " + element, "background: pink; color: black;");
				text = element;
			}
			if (dicteemode) {
				dicteemode = this.dicteeMode;
			}
			this.playTTS(text, null, dicteemode).then(() => {
				if ((!this.globalService.toolbarMenuOpened.status || fromToolbar) && !this.globalService.appIdle) {
					this.playTTSPWaiting = false;
					this.currentTTSPText = null;
					// console.log('%cglobalService.playTTSPWaiting set to = ' + this.playTTSPWaiting, 'background: pink; color: black;');
					this.protectedTTSisPlaying = false;
					// console.log("%cglobalService.protectedTTSisPlaying set to = " + this.protectedTTSisPlaying, "background: pink; color: black;");
					callback = this.currentTTSPCallback;
					if (callback && !this.globalService.killTTSEP) {
						callback();
					}
					this.currentTTSPCallback = null;
					this.globalService.killTTSEP = false;
					resolve();
				}
			});
		});
	}
}
