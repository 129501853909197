import { Injectable } from "@angular/core";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { fromEvent, merge, Observable, of, ReplaySubject } from "rxjs";
import { Platform, AlertController, ToastController } from "@ionic/angular";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
export enum ConnectionStatus {
	Online,
	Offline
}

@Injectable({
	providedIn: "root"
})
export class NetworkService {
	// static status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject(ConnectionStatus.Online);
	public status: ReplaySubject<ConnectionStatus> = new ReplaySubject(1);
	public connectedStatus: boolean;
	public environment: any;
	private toast: ToastController;
	public errorOccured: boolean;

	constructor(
		private network: Network,
		private plt: Platform,
		public alertController: AlertController,
		public toastController: ToastController
	) {
		this.plt.ready().then(() => {
			console.log("NETWORK SERVICE INIT");
			const status = this.network.type !== "none" ? ConnectionStatus.Online : ConnectionStatus.Offline;
			this.updateNetworkStatus(status);
			this.initializeNetworkEvents();
		});
		this.environment = environment;
	}

	public initializeNetworkEvents() {
		if (this.plt.is("cordova")) {
			this.network.onConnect().subscribe(status => {
				this.updateNetworkStatus(ConnectionStatus.Online);
				this.connectionActions();
			});
			this.network.onDisconnect().subscribe(status => {
				this.updateNetworkStatus(ConnectionStatus.Offline);
				this.connectionActions();
			});
		} else {
			merge(of(null), fromEvent(window, "online"), fromEvent(window, "offline"))
				.pipe(
					map(() => {
						return navigator.onLine;
					})
				)
				.subscribe(async connected => {
					this.updateNetworkStatus(connected ? ConnectionStatus.Online : ConnectionStatus.Offline);
					this.connectionActions();
				});
		}
	}

	connectionActions() {
		if (!this.connectedStatus) {
			let text: string;
			if (this.environment.ose || this.environment.kidaia) {
				text = $localize`Pas de connexion internet, veuillez-vous reconnecter.`;
			} else {
				text = $localize`Pas de connexion internet, veuillez-vous reconnecter pour utiliser toutes les fonctionnalités de Mathia.`;
			}
			this.presentToast(text);
		} else {
			if (this.toast) {
				this.toastController.dismiss();
			}
		}
	}

	async presentToast(message) {
		const toast = await this.toastController.create({
			message,
			position: "bottom",
			duration: 2500,
			cssClass: "custom-toast-internet"
		});

		await toast.present();
	}

	public updateNetworkStatus(status: ConnectionStatus, updateStatus: boolean = true) {
		this.connectedStatus = status === ConnectionStatus.Online;
		if (updateStatus) {
			this.status.next(status);
		}
	}

	public onNetworkChange(): Observable<ConnectionStatus> {
		return this.status.asObservable();
	}
}
