import { NgModule, ErrorHandler, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouteReuseStrategy } from "@angular/router";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { IonicModule, IonicRouteStrategy, Platform } from "@ionic/angular";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { Media } from "@awesome-cordova-plugins/media/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { AccountService } from "./services/account.service";
import { AccountImplService } from "./services/account.Impl.service";
import { PlayTTSService } from "./services/play-tts.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { TokenInterceptorService } from "./services/token-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SocketIoModule, SocketIoConfig, Socket } from "ngx-socket-io";
import { SttService } from "./services/stt.service";
import { TypeofPipe } from "./models/params/typeof.pipe";
import { CabriDataService } from "./services/cabri-data.service";
import { ComponentsModule } from "./components/components.module";
import { Insomnia } from "@awesome-cordova-plugins/insomnia/ngx";
import { GlobalService } from "./services/global.service";
import { LrsService } from "./services/lrs.service";
import { SocketSttOne, SocketSttTwo, SocketRemoteOne, SocketAR1 } from "./services/web-socket.service";
import { RemoteService } from "./services/remote.service";
import { NetworkService } from "./services/network.service";
import { Network } from "@awesome-cordova-plugins/network/ngx";
import { IonicStorageModule } from "@ionic/storage-angular";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { Drivers } from "@ionic/storage";
import { OpenNativeSettings } from "@awesome-cordova-plugins/open-native-settings/ngx";
import { GlobalErrorHandler } from "./errors/global-error-handler";
import { StoryService } from "./services/story.service";
import { WaitingOperationGuard } from "./auth-guard.guard";
import { ClassService } from "./services/class.service";
import { environment } from "src/environments/environment";
import { TralalereService } from "./services/tralalere.service";
import { OpenSteamService } from "./services/open-steam.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Device } from "@awesome-cordova-plugins/device/ngx";
import { BabylonGameBasePageDirective } from "./page/base/babylon-game-base-page.directive";
import { ServiceWorkerModule } from "@angular/service-worker";
const config: SocketIoConfig = { url: "https://ws2.mathia.education", options: { reconnectionAttempts: 5 } };
@NgModule({
	declarations: [AppComponent, TypeofPipe, BabylonGameBasePageDirective],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		HttpClientJsonpModule,
		IonicModule.forRoot({ animated: true }),
		IonicStorageModule.forRoot({
			name: "__mydb",
			driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
		}),
		AppRoutingModule,
		FormsModule,
		SocketIoModule,
		ComponentsModule,
		ReactiveFormsModule,
		NgbModule,
		/*ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000"
		})*/
	],
	providers: [
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		SocketSttOne,
		SocketSttTwo,
		SocketRemoteOne,
		SocketAR1,
		File,
		Media,
		Platform,
		StatusBar,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: AccountService, useClass: AccountImplService },
		{ provide: ClassService, useClass: environment.ose ? OpenSteamService : TralalereService },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
		PlayTTSService,
		SttService,
		Insomnia,
		ScreenOrientation,
		CabriDataService,
		AndroidPermissions,
		OpenNativeSettings,
		LrsService,
		NetworkService,
		RemoteService,
		GlobalService,
		Network,
		StoryService,
		Device,
		WaitingOperationGuard
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
