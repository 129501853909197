import { HttpClient } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { Subscription } from "rxjs";
import { AppUtils } from "src/app/app-utils";
import { LrsUtils } from "src/app/models/lrs/lrsUtils";
import { XapiContext, XapiExtensions } from "src/app/models/lrs/xapicontext";
import { XapiObject, XObjectType } from "src/app/models/lrs/xapiobject";
import { XApiResult } from "src/app/models/lrs/xapiresult";
import { LrsVerbs } from "src/app/models/lrs/xapiverbs";
import { OseActivity } from "src/app/models/ose-activities";
import { OseExerciceType } from "src/app/models/ose2-journey";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { Statement } from "src/app/models/statement";
import { AccountService } from "src/app/services/account.service";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { GlobalService } from "src/app/services/global.service";
import { LrsService } from "src/app/services/lrs.service";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { environment } from "src/environments/environment";
export class Coloring {
	id: number;
	image: string;
	title: string;
	feedback: string;
	consigne:string;
}
@Component({
	selector: "app-coloring",
	templateUrl: "./coloring.component.html",
	styleUrls: ["./coloring.component.scss"]
})
export class ColoringComponent implements OnInit, OnDestroy {
	@ViewChild("svgElement") svgElement;
	@ViewChild("pinch") pinchZoom: PinchZoomComponent;
	@Input() scenarioBubble: ScenarioOseBubble;
	public currentSVGIndex = 0;
	public counterItemsToColor = 0;
	public totalItemsColored: number;
	public currentColoring: Coloring;
	public won: boolean;
	pZProperties;
	svg: SVGElement;
	windowResizedSubscription: Subscription;
	public deltaDistance = 50;
	public startX;
	public startY;
	public endStatement: Statement[];
	environment;
	skip = false;
	public defaultConsigne = $localize`Colorie cette image en cliquant sur tous ses éléments`;
	public consigne 
	constructor(
		public http: HttpClient,
		public globalService: GlobalService,
		public cabriService: CabriDataService,
		public accountService: AccountService,
		public lrs: LrsService,
		public oseJourneyService: OseJourneyService,
		public router: Router
	) {
		this.environment = environment;
		this.pZProperties = {
			zoomControlScale: 2,
			limitPan: true,
			wheelZoomFactor: 0.3,
			backgroundColor: "transparent",
			disableZoomControl:"never",
			"double-tap":false

		};
		this.oseJourneyService.launchExercise.subscribe({
			next: (data: OseActivity) => {
				if (data.type === OseExerciceType.coloriage) {
					this.loadSVG(data.id);
				}
			}
		});
	}

	async loadColoring(){
		await this.cabriService.getColoring();
		this.loadSVG(this.oseJourneyService.getCurrentExerciseId());
	}

	async ngOnInit() {
		// await this.cabriService.getColoring();
		// this.loadSVG(this.oseJourneyService.getCurrentExerciseId());
	}

	mouseDown(event) {
		this.startX = event.pageX;
		this.startY = event.pageY;
	}

	updateSvgPosition() {
		if (this.svg) {
			this.svg.style.height = this.svgElement.nativeElement.offsetHeight + "px";
			this.svg.style.width = this.svgElement.nativeElement.offsetWidth + "px";
		}
	}

	public initializeGame() {
		this.won = false;
		this.skip = false;
		this.counterItemsToColor = 0;
		this.consigne;
	}

	async loadSVG(id) {
		this.globalService.setGlobalLoading(true);
		if (id) {
			this.initializeGame();
			this.currentColoring = this.cabriService.coloring.find(c => Number(c.id) === Number(id));
			this.cabriService.currentOseActivity = { id: this.currentColoring.id, title: 
			this.currentColoring.title, type: OseExerciceType.coloriage };
			this.http.get(this.currentColoring.image as any, { responseType: "text" }).subscribe({
				next: async data => {
					if (this.pinchZoom.isZoomedIn) {
						this.pinchZoom.toggleZoom();
					}
					this.svgElement.nativeElement.innerHTML = data;
					this.svg = this.svgElement.nativeElement.querySelector("svg");
					// capture coords when mouse down
					this.svg.addEventListener("mousedown", this.mouseDown.bind(this));
					await this.svgReady();
					// set position of svg
					this.updateSvgPosition();
					// handle autorized svg objects
					const pathes = this.svgElement.nativeElement.querySelectorAll("path, rect, polygon, circle, ellipse");
					this.totalItemsColored = pathes.length;
					pathes.forEach(p => {
						p.saveFill = p.style.fill;
						p.classList.add("noColorized")
						p.addEventListener("mouseup", e => {
							if (this.isSingleClick(e)) {
								// check need display skip button
								this.colorizeSelectedElement(e);
								this.skip = this.perGivenAnswer > 50;
								this.checkEnd();
							}
						});
					});
					this.globalService.setGlobalLoading(false);
					const statement = this.startActivityStatement();
					if (statement) {
						this.lrs.send(statement);
					}
					this.consigne = this.currentColoring.consigne || this.defaultConsigne;
					this.scenarioBubble.readCustomText(this.consigne,true);
				}
			});
		} else {
			if (this.environment.production) {
				this.router.navigateByUrl("/territoire");
			} else {
				this.globalService.setGlobalLoading(false);
			}
		}
	}

	/**
	 * Differentiate single click from double click(zoom or click)
	 * @param e Event
	 * @returns boolean
	 */
	public isSingleClick(e):boolean{
		const diffX = Math.abs(e.pageX - this.startX);
		const diffY = Math.abs(e.pageY - this.startY);
		return diffX < this.deltaDistance && diffY < this.deltaDistance;
	}

	/**
	 * Colorize svg element
	 * @param e svgElement
	 */
	colorizeSelectedElement(e){
		e.target.classList.remove("noColorized")
		e.target.classList.add("colorized")
		// check need display skip button
		if (e.target.style.stroke !== "none") {
			this.counterItemsToColor++;
		}
		e.target.style.fill = e.target.saveFill;
	}

	public get perGivenAnswer() {
		return (this.counterItemsToColor / this.totalItemsColored) * 100;
	}

	async checkEnd() {
		if (this.counterItemsToColor === this.totalItemsColored) {
			this.endStatement = this.endActivityStatement();
			if (this.pinchZoom?.isZoomedIn) {
				this.pinchZoom.toggleZoom();
			}
			await this.displayFeedBackMessage();
		}
	}

	public async displayFeedBackMessage() {
		this.won = true;
		this.globalService.confettiCanvasComponent.launchConfetti();
		if (this.scenarioBubble) {
			await this.scenarioBubble.launchFeedbackWithEnd(this.currentColoring.feedback,true);
		}
	}

	svgReady() {
		return new Promise<void>(resolve => {
			AppUtils.timeOut(50).then(async () => {
				let counter = 0;
				while (this.svgElement.nativeElement.offsetWidth === 0 && counter < 50) {
					await AppUtils.timeOut(10);
					counter++;
				}
				resolve();
			});
		});
	}
	public async nextDrawing() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
		this.endStatement = this.endActivityStatement();
		if (this.oseJourneyService.currentJourney) {
			this.lrs
				.send(this.endStatement)
				.then(() => {
					this.oseJourneyService.exerciseEnd.next();
				})
				.catch(error => {
					console.error("error last statement not send", error);
					this.oseJourneyService.exerciseEnd.next(true);
				});
		} else {
			this.currentSVGIndex++;
			if (this.currentSVGIndex === this.cabriService.coloring.length) {
				this.currentSVGIndex = 0;
			}
			this.loadSVG(this.cabriService.coloring[this.currentSVGIndex].id);
		}
	}

	/**
	 * Statement object created at the beginning of the activity.
	 * @returns Statement[] contains one or two elements depending if it's a journey(ex + journey) or a simple exercise(1)
	 */
	startActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();
			LrsUtils.idsession = LrsUtils.generateUniqueSessionId(this.accountService, true);
			LrsUtils.currentUserResponseTime = Date.now();
			const object = new XapiObject(
				`${LrsUtils.statementUrl}/exercise/${String(this.currentColoring.id)}`,
				this.currentColoring.title,
				this.currentColoring.title,
				XObjectType.exercise
			);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions()
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.initialized, object, context);
			const journeyStatement = this.lrs.startJourneyStatement();
			if (journeyStatement) {
				statements.push(journeyStatement);
			}
			statements.push(exerciseStatement);
			return statements;
		}
	}

	/**
	 * Statement object created at the end of the activity
	 * @returns Statement[] contains one or two elements depending if it's the last exercise of journey (ex + journey) or a simple exercise(1)
	 */

	endActivityStatement(): Statement[] {
		if (this.oseJourneyService.currentJourney) {
			const statements = new Array();

			const object = new XapiObject(
				`${LrsUtils.statementUrl}/${String(this.currentColoring.id)}`,
				this.currentColoring.title,
				this.currentColoring.title,
				XObjectType.exercise
			);
			const activityDuration = LrsUtils.duration8601(LrsUtils.timeStampConversion(LrsUtils.currentUserResponseTime));
			const result = new XApiResult(LrsVerbs.completed, activityDuration);
			const context = new XapiContext(this.accountService.team, {
				...this.lrs.globalActivityExtensions(),
				[XapiExtensions.dureeActivite]: activityDuration
			});

			const exerciseStatement = new Statement(this.accountService.team[0]?.id, LrsVerbs.completed, object, context, result);
			const endJourneyStatement = this.lrs.endJourneyStatement();
			if (endJourneyStatement) {
				statements.push(endJourneyStatement);
			}

			statements.push(exerciseStatement);
			return statements;
		}
	}

	async ngOnDestroy() {
		await this.scenarioBubble.skipMathiaSpeechSequence(true);
	}
}
