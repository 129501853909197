import { Injectable, ChangeDetectorRef } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { BaseService } from "./base.service";
import { HostListener, Component } from "@angular/core";
import { Observable, fromEvent } from "rxjs";
import { Platform } from "@ionic/angular";
import { SafariService } from "./safari.service";
import { SocketSttTwo, SocketSttOne } from "./web-socket.service";
import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions/ngx";
import { AppUtils } from "../app-utils";
import { GlobalService } from "./global.service";
import { NetworkService } from "./network.service";
import { environment } from "src/environments/environment";
import { ClassService } from "./class.service";

// Cordova audioInput plugin
declare let audioinput: any;
declare var window: any;
@Injectable({
	providedIn: "root"
})
export class SttService extends BaseService {
	audioContextDestination: AudioDestinationNode;
	environment;
	constructor(
		private socket1: SocketSttOne,
		private socket2: SocketSttTwo,
		private platform: Platform,
		public safariService: SafariService,
		public androidPermissions: AndroidPermissions,
		public globalService: GlobalService,
		public networkService: NetworkService,
		public classService: ClassService
	) {
		super();
		this.environment = environment;
		this.state = {
			connected: false,
			recording: false,
			recognitionOutput: [],
			rasaRecognitionOuput: []
		};
		this.socket = socket1;
		// check URL params
		window.params = new URLSearchParams(window.location.search);
		const urlParams = window.params ? Array.from(window.params) : [];
		const urlParamsObj = AppUtils.objectify(urlParams);
		this.globalService.checkIframeIntegration(urlParamsObj, classService);
		this.cd = null;
		if (!environment.ose && !this.globalService.isBeneylu && !this.environment.ose) {
			this.init();
			this.platform.ready().then(() => {
				this.useSTTCordova =
					typeof audioinput !== "undefined" &&
					!this.platform.is("desktop") &&
					!this.platform.is("mobileweb") &&
					!document.URL.startsWith("https://app.mathia.education") &&
					!document.URL.startsWith("https://dev.mathia.education") &&
					!document.URL.startsWith("https://dev.kidaia.com") &&
					!document.URL.startsWith("https://app.kidaia.com") &&
					!document.URL.startsWith("https://preprod.app.mathia.education");

				if (!this.platform.is("desktop") && !this.platform.is("mobileweb") && this.useSTTCordova) {
					this.getCordovaAudioPermission();
				} else {
					const success = stream => {
						if (stream) {
							stream.getTracks()[0].stop();
						}
					};
					if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
						navigator.mediaDevices
							.getUserMedia({
								video: false,
								audio: true
							})
							.then(success);
					} else {
						if ((navigator as any).getUserMedia){
							(navigator as any).getUserMedia(
								{
									video: false,
									audio: true
								},
								success,
								null
							);
						}
					}
				}
			});
		}
		this.micCheckDone = false;
	}
	public state: any;
	public recognition: Observable<any>;
	public rasaRecognition: Observable<any>;
	public audioContext: AudioContext;
	private mediaStream: MediaStream;
	public mediaStreamSource: MediaStreamAudioSourceNode;
	public processor: AudioWorkletNode;
	private cd: ChangeDetectorRef;
	private rasa;
	public gainNode;
	public haveGain = false;
	public callbackMediastream: any;

	// microphone:
	public noMicrophone: boolean;
	public micCheckDone: boolean;
	public socket: Socket;

	// Cordova audioInput param
	captureCfg;
	useSTTCordova: boolean;
	lastParam: { rasa: boolean; context: any };
	wasRecording: boolean;

	private sourceMicVolume;

	public setChangeDetector(cd: ChangeDetectorRef) {
		this.cd = cd;
	}

	public switchSttServer() {
		this.socket.removeAllListeners();
		this.socket = this.socket === this.socket1 ? this.socket2 : this.socket1;
		this.socket.connect();
		this.init();
		if (this.cd) {
			this.cd.detectChanges();
		}
	}

	/**
	 * Start Recording stt from mic
	 * the service.status give information on ws connection and recording state
	 * recognition result are send too state.recognitionOuput and observeable recognition
	 * if rasa enable result are send to an observeable rasaRecognition
	 * @param [rasa] boolean active rasa
	 * @param [context] rasa context optionnal
	 */
	async startRecording(rasa = false, context = null) {
		this.lastParam = { rasa, context };
		if (!this.networkService.connectedStatus) {
			// no connection so not activate stt
			if (this.state?.recording) {
				this.state.recording = false;
			}
			if (this.cd) {
				this.cd.detectChanges();
			}
		} else {
			if (rasa) {
				this.rasa = { activate: true, context };
			} else {
				this.rasa = { activate: false, context };
			}
			if (!this.state.recording) {
				this.state.recording = true;
				if (this.cd) {
					this.cd.detectChanges();
				}

				// cordova plugin switch
				if (typeof audioinput !== "undefined" && this.useSTTCordova) {
					audioinput.start(this.captureCfg);
				} else {
					await this.startMicrophone();
				}
			}
		}
	}

	/**
	 * Stop service stt
	 */
	async stopRecording() {
		if (this.state.recording) {
			this.state.recording = false;
			if (this.state.connected) {
				if (!this.state.connected) {
					this.socket.connect();
				}
				this.socket.emit("stream-reset");
			}
			if (this.cd) {
				this.cd.detectChanges();
			}
			// cordova plugin switch
			if (typeof audioinput !== "undefined" && this.useSTTCordova) {
				audioinput.stop();
			} else {
				await this.stopMicrophone();
			}
		}
	}

	requestAndroidPermissions() {
		this.androidPermissions
			.requestPermissions([
				this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE,
				this.androidPermissions.PERMISSION.MODIFY_AUDIO_SETTINGS,
				this.androidPermissions.PERMISSION.INTERNET,
				this.androidPermissions.PERMISSION.WAKE_LOCK
			])
			.then(() => {
				console.log("then stt");
			})
			.catch(err => {
				console.log("catch stt", err);
			});
	}

	getCordovaAudioPermission() {
		console.log("%c getCordovaAudioPermission entered", "background: red; color: green");
		console.log("%c audioinput : " + audioinput, "background: red; color: green");
		if (typeof audioinput !== "undefined" && this.useSTTCordova) {
			console.log("%c getCordovaAudioPermission condition entered", "background: red; color: green");
			this.captureCfg = {
				sampleRate: 16000,
				bufferSize: 512,
				channels: 1,
				format: audioinput.FORMAT.PCM_16BIT,
				audioSourceType: audioinput.AUDIOSOURCE_TYPE.DEFAULT,
				normalize: false
			};

			audioinput.initialize(this.captureCfg, () => {
				audioinput.checkMicrophonePermission(hasPermission => {
					if (hasPermission) {
						console.log("We already have permission to record.");
						// Listen to audioinput events
						window.addEventListener(
							"audioinput",
							(event: any) => {
								if (!this.state.connected) {
									this.socket.connect();
								}
								this.socket.emit("stream-data", event.data.buffer, this.rasa);
							},
							false
						);
						window.addEventListener(
							"audioinputerror",
							(error: any) => {
								// try{
								// 	audioinput.stop();
								// 	audioinput.initialize(this.captureCfg, () => {
								// 		audioinput.checkMicrophonePermission(hasPermission2 => {
								// 			audioinput.start(this.captureCfg);
								// 		});
								// 	});
								// } catch(e) {
								// }
								// alert("onAudioInputErrorCordova event recieved: " + JSON.stringify(error));
							},
							false
						);
					} else {
						// Ask the user for permission to access the microphone
						audioinput.getMicrophonePermission((hasPermission2, message) => {
							this.platform.ready().then(() => {
								if (this.platform.is("android")) {
									this.requestAndroidPermissions();
								}
							});
							if (hasPermission2) {
								console.log("User granted us permission to record.");
								window.addEventListener(
									"audioinput",
									(event: any) => {
										if (!this.state.connected) {
											this.socket.connect();
										}
										this.socket.emit("stream-data", event.data, this.rasa);
									},
									false
								);
								window.addEventListener(
									"audioinputerror",
									(error: any) => {
										alert("onAudioInputErrorCordova event recieved: " + JSON.stringify(error));
									},
									false
								);
							} else {
								console.warn("User denied permission to record.");
							}
						});
					}
				});
			});
		}
	}

	/**
	 * Force reset stream on server deleting past unprocessed buffer
	 */
	resetStream() {
		if (this.state.recording) {
			if (this.state.connected) {
				if (!this.state.connected) {
					this.socket.connect();
				}
				this.socket.emit("stream-reset");
			}
		}
	}

	restartRecording() {
		this.stopRecording();
		this.startRecording(this.lastParam.rasa, this.lastParam.context);
		this.wasRecording = false;
	}

	// init socket webservice
	private init() {
		let recognitionCount = 0;

		this.socket.on("connect", () => {
			console.log("socket connected");
			this.state.connected = true;
			if (this.wasRecording || (this.state && this.state.recording)) {
				this.restartRecording();
			}
		});

		this.socket.on("disconnect", () => {
			console.log("socket disconnected");
			this.state.connected = false;
			if (this.state && this.state.recording) {
				this.wasRecording = true;
			}
			this.stopRecording();
		});

		this.socket.on("recognize", results => {
			console.log("recognized:", results.text);
			results.id = recognitionCount++;
			this.state.recognitionOutput.unshift(results);
		});

		this.socket.on("rasaRecognize", results => {
			// raw
			console.log("rasaRecognize raw : " + JSON.stringify(results, null, 4));

			results.id = recognitionCount++;
			this.state.rasaRecognitionOuput.unshift(results);
		});

		this.recognition = this.socket.fromEvent("recognize");
		this.rasaRecognition = this.socket.fromEvent("rasaRecognize");
	}

	/**
	 * Create AudioWorkletProcessorNode
	 */
	private createAudioProcessorNode(audioContext: AudioContext, audioSource) {
		this.processor = new AudioWorkletNode(audioContext, "recorder-worklet");

		// Handle worketAudio message
		this.processor.port.onmessage = e => {
			// console.log('event audio',e)
			if (e.data.eventType === "data") {
				const audioData = e.data.audioBuffer;
				if (this.state.connected) {
					// console.log('connectteeedd')
					// Send data buffer to ws socket
					if (!this.state.connected) {
						this.socket.connect();
					}
					this.socket.emit("stream-data", e.data.audioBuffer.buffer, this.rasa);
				}
				if (this.callbackMediastream) {
					this.callbackMediastream(e.data.audioBuffer);
				}
			}
			if (e.data.eventType === "stop") {
				console.log("audioworklet have stopped");
			}
		};

		this.processor.connect(audioContext.destination);
	}
	/**
	 * launch record query right mic access, create processor node and mic input stream
	 */
	private startMicrophone() {
		return new Promise<void>(async resolve => {
			if (
				(!this.audioContext || this.globalService.isIos || this.safariService.onSafari("Safari")) &&
				!this.globalService.isBeneylu &&
				!this.environment.ose
			) {
				this.audioContext = new AudioContext();
				this.audioContextDestination = this.audioContext.destination;
				this.audioContext.audioWorklet
					.addModule("../../assets/js/recorderWorkletProcessor.js")
					.then(() => {
						const success = (stream: MediaStream) => {
							this.noMicrophone = false;
							this.micCheckDone = true;
							console.log("%c started recording", "background: red; color: yellow;");
							this.mediaStream = stream;
							this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
							this.createAudioProcessorNode(this.audioContext, this.mediaStreamSource);
							this.mediaStreamSource.connect(this.processor);
							resolve();
						};

						const fail = e => {
							console.error("startMicrophone recording failure", e);
							console.log(e.name + ": " + e.message);
							this.noMicrophone = true;
							this.micCheckDone = true;
							resolve();
						};
						if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
							// console.error("Media Constraint", navigator.mediaDevices.getSupportedConstraints());
							navigator.mediaDevices
								.getUserMedia({
									video: false,
									audio: true
								})
								.then(success)
								.catch(fail);
						} else {
							(navigator as any).getUserMedia(
								{
									video: false,
									audio: true
								},
								success,
								fail
							);
						}
					})
					.catch(error => {
						this.noMicrophone = true;
						console.error("startMicrophone catch error", error);
						resolve();
					});
			} else {
				if (this.globalService.isChrome && this.globalService.isMobile && !this.globalService.isBeneylu && !this.environment.ose) {
					const userMediaPromise = new Promise<void>(resolve2 => {
						const success = (stream: MediaStream) => {
							this.noMicrophone = false;
							this.micCheckDone = true;
							console.log("%c started recording", "background: red; color: yellow;");
							this.mediaStream = stream;
							this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
							this.processor.connect(this.audioContext.destination);
							this.mediaStreamSource.connect(this.processor);
							resolve2();
						};

						const fail = e => {
							console.error("startMicrophone recording failure", e);
							console.log(e.name + ": " + e.message);
							this.noMicrophone = true;
							this.micCheckDone = true;
							resolve2();
						};
						if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
							navigator.mediaDevices
								.getUserMedia({
									video: false,
									audio: true
								})
								.then(success)
								.catch(fail);
						} else {
							(navigator as any).getUserMedia(
								{
									video: false,
									audio: true
								},
								success,
								fail
							);
						}
					});
					await userMediaPromise;
				}
				await this.audioContext.resume();
				resolve();
			}
		});
	}

	/**
	 * Close record and destroy worklet mediastream associated
	 */
	private killProcessor() {
		if (this.processor) {
			this.processor.port.postMessage({
				eventType: "stop"
			});
		}
	}

	/**
	 * Close record
	 */
	private async stopMicrophone() {
		if (this.globalService.isIos || this.safariService.onSafari("Safari")) {
			this.killProcessor();
			if (this.mediaStream) {
				this.mediaStream.getTracks()[0].stop();
				delete this.mediaStream;
			}
			if (this.mediaStreamSource) {
				this.mediaStreamSource.disconnect();
				delete this.mediaStreamSource;
			}
			if (this.processor) {
				this.processor.disconnect();
				delete this.processor;
			}
			if (this.audioContext) {
				await this.audioContext.close();
				delete this.audioContext;
				await AppUtils.timeOut(1000);
			}
		} else if (this.audioContext) {
			if (this.globalService.isChrome && this.globalService.isMobile) {
				if (this.mediaStream) {
					this.mediaStream.getTracks()[0].stop();
					delete this.mediaStream;
				}
				if (this.mediaStreamSource) {
					this.mediaStreamSource.disconnect();
					delete this.mediaStreamSource;
				}
			}
			await this.audioContext.suspend();
		}
	}

	/**
	 * Start recognition with rasa activated
	 */
	public startRecordingWRasa(context = null) {
		if (!context) {
			context = { locale: this.globalService.locale };
		} else {
			context.locale = this.globalService.locale;
		}
		this.startRecording(true, context);
	}

	/**
	 * Stop recognition with rasa activated
	 */
	public async stopRecordingWRasa() {
		await this.stopRecording();

		console.log("RASA recording stopped");
	}
}
