import { Injectable } from "@angular/core";
import { User } from "../models/user";
import { ReplaySubject, Subject } from "rxjs";
import { Classroom } from "../models/classroom";
import { Student } from "../models/student";
import { Journey } from "../models/journey";
import { BaseService } from "./base.service";
import { LrsService } from "./lrs.service";
import { ProposedActivity } from "../models/proposed-activity";
import { UrlTree } from "@angular/router";

export enum userAlertActions {
	removeStudent = "removeStudent",
	switchStudent = "switchStudent",
	changeParam = "changeParam"
}

@Injectable({
	providedIn: "root"
})
export abstract class AccountService extends BaseService {
	user: User;
	public isUserLoaded: ReplaySubject<boolean>;
	studentsClassLoaded: boolean;
	classroom: Classroom;
	allStudents: Student[] = new Array();
	public team: Student[];
	public teamsCopy: Student[];
	teams;
	maxKidaiaAccount: number;
	urlBeforeRedirection: UrlTree;
	public exerciseStatistics = {
		completedExercises: {},
		averageRateCorrectAnswers: {},
		exercisePerGoodAnswer: {},
		totalNumberAwardsByActivity: {}
	};
	studentsStatisticsRecovered: boolean;
	public journeyTeamShootingStarsCount = new Array<string>();
	public journeyTeamNormalStarsCount = new Array<string>();
	public journeyTeamMoonsCount = new Array<string>();
	public checkedUserRGPDState = false;
	public loadGabaritStudentInfos;
	public logReadyPromise: Promise<void>;
	abstract initJourneyTeamTotalAwards();
	abstract setCabriService(cabriService);
	abstract checkUserPermission(value?: string): boolean;
	abstract initializeUserStatistics();
	abstract get isRealUserConnected(): boolean;
	abstract get isRealClassConnected(): boolean;
	abstract checkUserConnected(): boolean;
	abstract getAssignations(classRoom: Classroom): Promise<Journey[]>;
	abstract restoreGroups();
	abstract restoreTeam(allStudents: Student[]);
	abstract setStudentSlot(student: Student, indexStudent?);
	abstract getStudentsAndJourneys(): Promise<void>;
	abstract getKidaiaStudents();
	abstract getStudentStatistics(studentJourneysPromise?: Promise<void>);
	abstract updateUserData(studentId: string): Promise<void>;
	abstract getJourneys();
	abstract getStudentJourneys(studentId: string | number, falseTraces?: boolean): Promise<void>;
	abstract recoverStudentsInfos();
	abstract diagnosticMode(allJourneyExercises: Journey[], idEleve, onStart?: boolean);
	abstract aiRecommandedAssignationId(journey: Journey);
	abstract searchRecommandedJourney(journeysEnum: { [key: string]: Journey[] }, aiSelectedJourney: Journey, _globalJourneys: Journey[]);
	abstract defineAiUserJourneysState(aiJourney: Journey);
	abstract recommendedJourneyModel(journey: Journey);
	abstract setLrsService(lrsService: LrsService);
	abstract defineDefaultSelectedJourney(checkJourneyState?: () => void);
	abstract disconnectUser();
	abstract getNextActivity();
	abstract get isUserMakingExercise(): boolean;
	abstract setNextActivity();
	abstract saveUserJourney(launch: boolean, nextActivity?: ProposedActivity);
	abstract markDiagnosticExerciseDone(currentJourney: Journey);
	abstract removeStoryJourney();
	abstract getRecoveredJourney(): Journey;
	abstract countAwards(resume, currentJourney?: Journey);
	abstract trainingMode(countTotalAwards: boolean);
	abstract allowSupervision();
	abstract switchKidaiaCurrentUser(student: Student, $event?)
	abstract getStudentsAndJourneysLoggedIn(): Promise<void>
	abstract isClassLoggedIn(): boolean;
	abstract canAccessPremium(urlType : 'video'|'aventure'|'logbook' ,callback : () => void);
	abstract canAccessPremiumOse(urlType : 'ose' ,callback : () => void);
}
