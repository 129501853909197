import { ScenarioOse } from "src/app/models/scenario-ose";
import { ChangeDetectorRef } from "@angular/core";
import { AccountService } from "../services/account.service";
import { GlobalService } from "../services/global.service";
import { ScenarioPhrase } from "./scenario-phrase";
import { PlayTTSService } from "../services/play-tts.service";
import { OseJourneyService } from "../services/ose-journeys.service";
import { Details } from "../page/territoire/territoire.page";

export class ScenarioOseBubble extends ScenarioOse {
	constructor(
		accountService: AccountService,
		globalService: GlobalService,
		oseJourneyService: OseJourneyService,
		cd: ChangeDetectorRef,
		public ttsService: PlayTTSService,
		public bubblePage?: any
	) {
		super(accountService, globalService, oseJourneyService, globalService.speechBubbleComponent, cd, ttsService);
	}

	/**
	 * overload for globalBubble // do not trigger when another runMathiaSpeech() is running as it messes with sequences !
	 */
	async runMathiaSpeech(speechSequence, globalBubble = false) {
		if (globalBubble) {
			this.globalService.speechBubbleComponent?.displayGlobalBubble(true);
		}
		await super.runMathiaSpeech(speechSequence);
		if (globalBubble) {
			this.globalService.speechBubbleComponent?.displayGlobalBubble(false);
		}
	}

	/**
	 * read a text through runMathiaSpeech() to be handled as it (pausable by events, display through tts bubble)
	 * @param consigne text to read / display
	 */
	async readCustomText(consigne: string, globalBubble = false) {
		const speechSequence: Array<any> = [new ScenarioPhrase([consigne])];
		await this.runMathiaSpeech(speechSequence, globalBubble);
	}

	/**
	 * Journey scenario
	 */
	async launchJourneyFeedback() {
		const speechSequences = new Array();
		if (!this.bubblePage.oseJourneyService.currentJourney.parentId) {
			this.bubblePage.isCategoryListOpen = true;
			// fin de séquence découverte
			if (this.bubblePage.oseJourneyService.currentJourney.feedback) {
				// feedback defined in wordpress
				await this.launchFeedbackWithEnd(this.bubblePage.oseJourneyService.currentJourney.feedback);
			} else {
				// feedback not defined so read the default's one
				let firstTextRead: string;
				const title = this.bubblePage.oseJourneyService.currentJourney.title;
				if (this.bubblePage.oseJourneyService.isNewJourneyCompleted()) {
					firstTextRead = $localize`Bravo, tu as terminé la séquence suivante : ${title} pour la première fois.`;
				} else {
					firstTextRead = $localize`Bravo, tu as terminé la séquence suivante : ${title}.`;
				}
				speechSequences.push(new ScenarioPhrase([firstTextRead]));
			}

			if(this.bubblePage.oseJourneyService.currentJourney?.quizz){
				const quizzToDo = this.bubblePage.oseJourneyService.currentJourney.quizz.filter(quizz => {
					return !quizz.completedQuizz;
				});
				this.bubblePage.openDetails(Details.quizz);
				if (quizzToDo.length > 0) {
					// Journey with quizz to do
					let text;
					if (quizzToDo.length === 1) {
						text = $localize`Teste ton niveau d'écolo avec le dernier quiz`;
					} else {
						text = $localize`Teste ton niveau d'écolo avec un des ${quizzToDo.length} quiz qu'il te reste à faire`;
					}
					speechSequences.push(
						new ScenarioPhrase([text], async () => {
							await this.bubblePage.animHighlightQuizToDo();
						})
					);
				} else {
					speechSequences.push(new ScenarioPhrase([$localize`Tu peux refaire un quiz ou découvrir un nouveau point d'intérêt.`]));
					await this.bubblePage.animHighlightQuizToDo();
				}
			}
			await this.runMathiaSpeech(speechSequences, true);
		} else {
			// fin de quiz
			const quizzJourney = this.bubblePage.oseJourneyService.journeys.find(journey => {
				return Number(journey.id) === Number(this.bubblePage.oseJourneyService.currentJourney.parentId);
			});
			const quizzToDo = quizzJourney.quizz.filter(quizz => {
				return !quizz.completedQuizz;
			});
			if (quizzToDo.length > 0) {
				speechSequences.push(
					new ScenarioPhrase([$localize`Bravo, passe au niveau supérieur en cliquant sur "je me teste"`], async () => {
						await this.bubblePage.animHighlightQuizToDo();
					})
				);
			} else {
				speechSequences.push(
					new ScenarioPhrase([
						$localize`Bravo, tu as gagné les trois étoiles. Repars à l'aventure pour découvrir un nouveau point d'intérêt`
					])
				);
			}

			await this.runMathiaSpeech(speechSequences, true);
		}
	}

	/**
	 * skip the scenario sequence on skip button click or manual call from anywhere
	 * @param data
	 */
	async skipMathiaSpeechSequence(data: any) {
		if (this.ttsService.protectedTTSisPlaying) {
			const callback = this.ttsService.currentTTSPCallback;
			if (Array.isArray(data)) {
				this.skipSequence = data[0];
				await this.ttsService.killSpeech(callback);
				data[1]();
			} else {
				this.skipSequence = data;
				await this.ttsService.killSpeech(callback);
			}
		}
	}

	// territoire page intro
	async welcomeInTerritory() {
		const speechSequence: Array<any> = [
			new ScenarioPhrase([$localize`Bienvenue dans le Béarn !`]),
			new ScenarioPhrase([$localize`Explore ce territoire en cliquant sur les points lumineux !`])
		];
		await this.runMathiaSpeech(speechSequence, true);
	}
}
