import { Injectable } from "@angular/core";
import { Socket } from "ngx-socket-io";
import { SemVer } from "semver";
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';


@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketSttOne extends Socket {
	constructor(platform : Platform) {
		let url = "192.168.1.66:4100";
		const opt = { reconnectionAttempts: 5, withCredentials: true, autoConnect: true };
		if (environment.kidaia){
			url = "192.168.1.66:4100";
		}
		if (platform.is('cordova') && platform.is('ios')){
			opt['transports']=['websocket'];
		}
		//patch for old safari with socket problem we force a route on endpoint service directly no balancing
		// use livrary semver for true version compare
		try {
			const regex = /^(?:(?!chrome|android).)*(?:Version\/([\d|.]*)).*safari/i;
			let m: RegExpExecArray;
			if ((m = regex.exec(navigator.userAgent)) !== null) {
				if (m[1]) {
					let version: SemVer = new SemVer(m[1]);
					if (version.compare("14.0.3") < 0) {

						url = "https://ws3.mathia.education";
						if (environment.kidaia){
							url = "https://ws3.kidaia.com";
						}
					}
				}
			}
		} catch (error) {

		}
		super({url: url, options:  opt});

	}
}

@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketSttTwo extends Socket {
	constructor(platform : Platform) {
		let url = "https://ws2.mathia.education";
		//let url = null;
		const opt = { reconnectionAttempts: 1, withCredentials: true , autoConnect: false};
		if (platform.is('cordova') && platform.is('ios')){
			opt['transports']=['websocket'];
		}
		if (environment.kidaia){
			url = "https://none";
			opt.reconnectionAttempts=0;
			opt.autoConnect= false;
		}
		super({ url: url, options: opt });
	}
}

@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketRemoteOne extends Socket {
	constructor() {
		let url = "https://ws2.rc.mathia.education";
		const opt = { reconnectionAttempts: 5, withCredentials: true, autoConnect: true };
		if (environment.kidaia){
			url = "https://none";
			opt.reconnectionAttempts=0;
			opt.autoConnect= false;
		}
		super({ url: url, options: opt });
	}
}

@Injectable({
	providedIn: "root" // <--provides this service in the root ModuleInjector
})
export class SocketAR1 extends Socket {
	constructor() {
		let url = "https://ws.ar.gpu.kidaia.com/test";
		const opt = { reconnectionAttempts: 5, withCredentials: true, autoConnect: true };
		super({ url: url, options: opt });
	}
}
