<div id="fiche-container">

    <div id="small-loader" *ngIf="globalService.smallLoaderStatus">
        <img id="loaderGIF" src="/assets/babylon/loading_low_ose.gif" alt="chargement">
    </div>

    <link rel="stylesheet" id="elementor-icons-shared-0-css"
        href="https://kidaia.com/wp-content/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css?ver=5.15.3"
        type="text/css" media="all">
    <link rel="stylesheet" id="wp-block-library-css"
        href="https://kidaia.com/wp-includes/css/dist/block-library/style.min.css" type="text/css" media="all">
    <link rel="stylesheet" id="dashicons-css" href="https://kidaia.com/wp-includes/css/dashicons.min.css?ver=6.0.1"
        type="text/css" media="all">
    <link rel="stylesheet" id="theme-kidaia-css" href="https://kidaia.com/wp-content/themes/theme_kidaia/style.css"
        type="text/css" media="all">

    <div #customStyle>

    </div>
    <div *ngIf="fiche && !environment.production" id="next-fiche">
        <!-- <button (click)="updateFiguresHeight()" >🔄</button> -->
        <button (click)="prevFiche()">prev</button><button (click)="nextFiche()">next</button> <button
            (click)="updateFiguresHeight()">🔄</button> {{fiche.id}} - {{fiche.title}}
        <a href="https://kidaia.com/wp-admin/post.php?post={{fiche.id}}&action=edit" target="_blank"
            style="color: var(--osegreen)">éditer</a>
    </div>
    <div *ngIf="(swiper?.activeIndex > 0 && fiche) " id="previous" (click)="previousPage()">← Précédent</div>
    <swiper #ionSlides [hidden]="!fiche && !currentFiche?.isEtape" [style.opacity]="globalService.smallLoaderStatus ? 0 : 1"
        class="fiche-{{fiche?fiche.id:'not-loaded'}}"  [resizeObserver]="true"  (slideChangeTransitionEnd)="slideTouchTransitionEnd($event)" (init)="swiperInitliazed($event)" (slideChange)="onSwiperChange();" 
        (resize)="swiperResizeEvent();">
        <ng-container *ngIf="fiche">
            <ng-container *ngFor="let page of fiche.pages">
                <ng-template swiperSlide>
                    <div class="page-container--fiche scrollbar-primary" [innerHTML]="page.innerHTML | safeHtml"
                        [style.opacity]="globalService.smallLoaderStatus ? 0 : 1"></div>
                </ng-template>
            </ng-container>
        </ng-container>


        <ng-container *ngIf="currentFiche?.isEtape">
            <ng-template swiperSlide>
                <div class="conclusion-end-seq page-container--fiche scrollbar-primary"
                    [style.opacity]="globalService.smallLoaderStatus ? 0 : 1">
                    <div class="congratulationWrapper" *ngIf="currentFiche.title === FicheEtapes.titleConclusion">
                        <h1 class="conclusion-end-seq__title">
                           <span *ngIf="totalSeqStep">Félicitations ! Tu as terminé la {{totalSeqStep}} étape de ce parcours !</span> 
                            <span *ngIf="!totalSeqStep">Félicitations ! Tu as terminé cette étape de ce parcours !</span> 
                        </h1>
                        <h2 *ngIf="totalExRemaining">
                            Encore {{totalExRemaining}}
                            <ng-container *ngIf="totalExRemaining === 'une'">étape</ng-container>
                            <ng-container *ngIf="totalExRemaining !== 'une'">étapes</ng-container>
                            sur {{totalExToDo}} pour terminer la séquence.
                        </h2>
                    </div>

                    <div class="congratulationWrapper" *ngIf="currentFiche.title === FicheEtapes.titleNextSeq">
                        <h1 class="conclusion-end-seq__title">Félicitations ! Tu as terminé 
                            <ng-container *ngIf="oseJourneyService.currentJourney.selectedCategory === Details.discovering">la séquence atelier</ng-container>
                            <ng-container *ngIf="oseJourneyService.currentJourney.selectedCategory === Details.learning">la séquence d'apprentissage</ng-container>
                        </h1>
                    </div>
                    <div class="conclusion-end-seq__choice">
                        <h2 class="choiceContinue" i18n>
                            Veux-tu poursuivre ?
                        </h2>
                        <div id="btnBlocksConclusion">
                            <div class="back-btn ">
                                <div id="btnQuit" class="btn" (click)="oseJourneyService.quitJourney()">
                                    <div class="icon"><img src="/assets/icon/backPlay.png"></div>
                                    <div class="text">Non - Retour à la carte</div>
                                </div>
                            </div>

                            <div id="buttonContinue" class="back-btn">
                                <div id="btnContinue" class="btn" (click)="continueActivity()">
                                    <div class="text">Oui - Direction la prochaine 
                                        <span *ngIf="currentFiche.title === FicheEtapes.titleConclusion">étape</span>
                                        <span *ngIf="currentFiche.title === FicheEtapes.titleNextSeq">séquence</span>
                                    </div>
                                    <div class="icon"><img src="/assets/icon/nextPlay.png"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </ng-container>

    </swiper>
    <div class="mascotte" *ngIf="displayMascotte">
        <div class="mascotte__img"></div>
    </div>
    <div id="oseCloseButton" (click)="oseJourneyService.quitJourney()" *ngIf="oseJourneyService.isPlaying && !standAlone">
        <div id="oseCloseButton-global__img"></div>
    </div>
    <div class="oseNextButton" (click)="nextPage($event)" *ngIf="fiche && !feedback"
        [ngClass]="{'pulsingBtnNext' : animateNextButton && !vimeoIsPlaying}">
        <img src="/assets/icon/play-ose.svg" />
    </div>
    <div class="osePrevButton" (click)="prevPage($event)" *ngIf="fiche && !feedback && !(oseJourneyService?.currentJourney?.currentExerciseIndex === 0)">
        <img src="/assets/icon/play-ose.svg"/>
    </div>
    <div class="feedback-skip-btn" (click)="nextPage($event)" *ngIf="fiche && feedback && !nextButtonClicked"
        [ngClass]="{'pulsingBtnNext' : !readingFeedback}">
        <p class="feedback-skip-btn__text">{{readingFeedback ? "Passer" : "Continuer"}}</p>
    </div>
    <div id="nav-wrapper" *ngIf="fiches && !fiche">
        <nav>
            <ul>
                <ng-container *ngFor="let fiche of fiches">
                    <li (click)="loadFiche(fiche.id)">{{fiche.id}} - {{fiche.title}}</li>
                </ng-container>
            </ul>
        </nav>
    </div>
</div>