import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AlertController, IonButton } from "@ionic/angular";
import { versions } from "process";
import { AccountService } from "src/app/services/account.service";
import { AnalyticService } from "src/app/services/analytic.service";
import { ClassService } from "src/app/services/class.service";
import { GlobalService } from "src/app/services/global.service";
import { WpSidService } from "src/app/services/wp-sid.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-feedbackc",
	templateUrl: "./feedback.component.html",
	styleUrls: ["./feedback.component.scss"]
})
export class FeedbackComponent implements OnInit {
	constructor(
		public analyticService: AnalyticService,
		public wpSIdService: WpSidService,
		public globalService: GlobalService,
		public accountService: AccountService,
		public alertController: AlertController,
		public classService: ClassService
	) {}

	typeFeedbackList = [
		{ key: 19, value: $localize`Signaler un problème` },
		{ key: 21, value: $localize`Avis et suggestion` }
	];
	typeFeedback: string;
	@ViewChild(IonButton) ionButton: IonButton;

	form: FormGroup<{
		title: FormControl<string | null>;
		userRam: FormControl<string | null>;
		userModel: FormControl<string | null>;
		mail: FormControl<string | null>;
		content: FormControl<string | null>;
		note: FormControl<number | null>;
		typeFeedback: FormControl<number | null>;
		date: FormControl<Date | null>;
	}>;

	ngOnInit() {
		this.form = new FormGroup({
			title: new FormControl<string | null>("", Validators.required),
			content: new FormControl<string | null>("", Validators.required),
			mail: new FormControl<string | null>("", [Validators.email]),
			userRam: new FormControl<string | null>(""),
			userModel: new FormControl<string | null>(""),
			typeFeedback: new FormControl<number | null>(this.typeFeedbackList[0].key, Validators.required),
			note: new FormControl<number | null>(3),
			date: new FormControl<Date | null>(new Date())
		});
	}

	onSubmit(event: Event) {
		this.ionButton["el"].innerText = $localize`Envoi en cours`;
		this.ionButton.disabled = true;
		try {
			let data = this.form.value;
			let dataMachine = {
				userAgent: navigator.userAgent,
				sessionToken: this.wpSIdService.connectSid,
				ram: (navigator as any).deviceMemory,
				performance: this.globalService.lowPerformanceMode,
				classe: this.accountService.user.codeClasse,
				app: environment.ose ? "ose" : environment.kidaia ? "kidaia" : "mathia",
				version: versions.revision + "@" + versions.branch + ":" + versions.version,
				userId: this.accountService.user.studentId
			};
			let fullData = { ...data, ...dataMachine, ...globalThis.device };

			this.analyticService.sendFeedback(fullData).subscribe({
				next: res => {
					this.presentAlert();
					this.ngOnInit();
					this.form.value.typeFeedback = this.typeFeedbackList[0].key;
					this.ionButton["el"].innerText = $localize`Envoyer`;
				},
				error: error => {
					this.ngOnInit();
					this.form.value.typeFeedback = this.typeFeedbackList[0].key;
					this.ionButton["el"].innerText = $localize`Envoyer`;
				}
			});
		} catch (error) {
			this.ngOnInit();
			this.form.value.typeFeedback = this.typeFeedbackList[0].key;
			this.ionButton["el"].innerText = $localize`Envoyer`;
		}
	}

	async presentAlert() {
		const alert = await this.alertController.create({
			message: $localize`Le message a bien été reçu merci pour votre retour.`,
			buttons: ["OK"],
			cssClass : "alertFeedback"
		});

		await alert.present();
	}
}
