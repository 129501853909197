export const environment = {
  production: false,
  activityVersion: 13,
  storyVersion:3,
  kidaia: true,
  bearerToken : null,
  ose: false,
  autolog : false,
  login : null,
  password : null
};