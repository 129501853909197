<div class="card" (click)="cardClicked.emit()" [@cardFlip]="data.state" [ngClass]="data.state">
  <div class="back">
  </div>
    <div class="front">
      <ng-container *ngIf="!data.displayText">
        <!-- <div class="img" *ngIf="data.imageId">
            <img [src]="'https://source.unsplash.com/' + data.imageId + '/400x400'">
          </div> -->
        <div class="img" [ngClass]="{'textWon': showText}" *ngIf="data.imageId">
          <img [src]="data.imageId">
          <div>
            <p>{{data.text}}</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="data.displayText">
        <div class="text">
          <p>{{data.text}}</p>
        </div>
      </ng-container>
    </div>
</div>