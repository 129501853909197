import { PlayTTSService } from "src/app/services/play-tts.service";
import { ToolbarBasePage } from "src/app/page/cabri/toolbar.base.page";
import { AudioService } from "./../../services/audio.service";
import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalService } from "src/app/services/global.service";
import { HttpClient } from "@angular/common/http";
import { OseJourneyService } from "src/app/services/ose-journeys.service";
import { POI } from "src/app/models/poi";
import { OseMap } from "src/app/models/ose-maps";
import { Ose2Journey, OseExerciceType, QuizzStatus } from "src/app/models/ose2-journey";
import { ScenarioNavigationOse } from "src/app/models/scenario-navigation-ose";
import { ModalStateName, oseModalPoiJourney } from "src/app/models/custom-angular-animations";
import { AccountService } from "src/app/services/account.service";
import { OseStatsService } from "src/app/services/ose-stats.service";
import { forkJoin, lastValueFrom, ReplaySubject, Subscription } from "rxjs";
import { CabriDataService } from "src/app/services/cabri-data.service";
import { AppUtils } from "src/app/app-utils";
import { ScenarioOseBubble } from "src/app/models/scenario-ose-bubble";
import { FicheComponent, FicheEtapes } from "src/app/components/fiche/fiche.component";
import { LrsService } from "src/app/services/lrs.service";
import { LrsVerbs, XapiVerbs } from "src/app/models/lrs/xapiverbs";
import { JourneysStatus } from "src/app/models/lrs/ose-stats-models";
import { Statement } from "src/app/models/statement";
import { XapiExtensions } from "src/app/models/lrs/xapicontext";
import { ConfettiOptions } from "src/app/models/confetti-canvas";
import { ScenarioOse } from "src/app/models/scenario-ose";
import { OseActivity } from "src/app/models/ose-activities";
import Swiper, { SwiperOptions } from "swiper";
import { SwiperComponent } from "swiper/angular";
import { PinchZoomComponent } from "@meddv/ngx-pinch-zoom";
import { once } from "events";

export enum ActivityType {
	journey,
	map,
	fiche
}

export enum Details {
	quizz = "quizz",
	learning = "learning",
	discovering = "discovering"
}

export class Status {
	public svg: any;
	image: HTMLBaseElement;
	constructor(svgElementText?: string) {
		if (svgElementText) {
			this.svg = new DOMParser().parseFromString(svgElementText, "image/svg+xml")?.documentElement;
			this.image = this.svg?.querySelector("image");
		}
	}
	/**
	 * Replace image src by instance
	 * @param poiElement HTMLBaseElement
	 */
	replaceStatus(poiElement: HTMLBaseElement, attributeName: string) {
		if (this.image) {
			poiElement.setAttribute("xlink:href", this.image.getAttribute(attributeName));
		} else {
			poiElement.setAttribute("xlink:href", attributeName);
		}
	}
}

@Component({
	selector: "app-territoire",
	templateUrl: "./territoire.page.html",
	styleUrls: ["./territoire.page.scss"],
	animations: [oseModalPoiJourney]
})
export class TerritoirePage extends ToolbarBasePage implements OnInit {
	@ViewChild("cardActivity", { static: false }) cardActivity: ElementRef;
	@ViewChildren("quizzContainer", { read: ElementRef }) quizzContainer: QueryList<ElementRef>;
	@ViewChild("svgWrapper", { static: false }) svgWrapper: ElementRef;
	@ViewChildren("uncompletedQuizzStars", { read: ElementRef }) uncompletedQuizzStars: QueryList<ElementRef>;
	@ViewChild("ficheComponent", { static: false }) ficheComponent: FicheComponent;
	@ViewChild("ionSlides", { static: false }) slideElement: SwiperComponent;
	@ViewChild("pinch") pinchZoom: PinchZoomComponent;
	subpartInProgress:OseActivity;

	currentPOIElement: HTMLElement;
	listFichePOIElements = new Array();
	prevPOIElement: HTMLElement;
	territoirePOIElements: HTMLBaseElement[] = new Array();
	territoireMarkerGroupElements: HTMLBaseElement[] = new Array();
	listPOI: Array<POI>;

	public activitiesDOM: HTMLBaseElement[] = new Array<HTMLBaseElement>();
	public selectedActivities: Ose2Journey[] | OseMap[] = new Array();
	modalState: string;
	goToRightPosition = "0%";
	animationRunning = false;

	clickEventListeners = [];
	first = true;
	selectedPOIType: ActivityType;
	public displayTerritoire = true;
	scenarioNav: ScenarioNavigationOse;
	private introTTSPlayed: boolean;

	public initialStatus: Status;
	public onGoingStatus: Status;
	public completedStatus: Status;
	groupsElements: HTMLBaseElement[] = new Array();
	groupImages: HTMLBaseElement[] = new Array();
	newStatementSendSubscription: Subscription;
	competenciesOpen = false;
	displayQuizChoice = false;
	allQuizCompleted = false;
	isJourneyDisplayed = false;
	canJourneyResume = false;

	totalCompletedExercises: Array<OseActivity>;
	totalProgressionExercisesToDo: Array<OseActivity>;
	scenarioOseBubble: ScenarioOseBubble;
	swiperInitEnd: Promise<void>;
	initSwiperPromise: Promise<unknown>;

	alreadyCompletedJourney:boolean
	initSwiperPromiseResolve: () => void;

	isCategoryListOpen:boolean;

	get swiper() {
		return this.slideElement?.swiperRef;
	}

	public get Details() {
		return Details;
	}
	sliderOptions: SwiperOptions = {
		loop: false,
		rewind: true,
		threshold: 30,
		slidesPerView: 1,
		on: {
			init: () => {
				this.initSwiperPromiseResolve();
			}
		}
	};
	currentTerritoire = "Le Béarn";
	public journeysTitles = [];
	detailsOpened = false;
	disableValidateSelectionEx = false;

	public get QuizzStatus() {
		return QuizzStatus;
	}

	public get OseExerciceType() {
		return OseExerciceType;
	}

	constructor(
		public renderer: Renderer2,
		public router: Router,
		public elRef: ElementRef,
		public globalService: GlobalService,
		public oseJourneyService: OseJourneyService,
		public oseStats: OseStatsService,
		public http: HttpClient,
		public accountService: AccountService,
		public audioService: AudioService,
		public cd: ChangeDetectorRef,
		public ttsService: PlayTTSService,
		public cabriService: CabriDataService,
		public playTTS: PlayTTSService,
		public lrsService: LrsService
	) {
		super(globalService, ttsService, cd, router);
		this.scenarioNav = new ScenarioNavigationOse(
			this.accountService,
			this.globalService,
			this.oseJourneyService,
			this,
			this.cd,
			playTTS
		);
		this.scenarioOseBubble = new ScenarioOseBubble(
			this.accountService,
			this.globalService,
			this.oseJourneyService,
			this.cd,
			playTTS,
			this
		);
		this.scenario = new ScenarioOse(this.accountService, this.globalService, this.oseJourneyService, this, this.cd, ttsService);

		this.initSwiperPromise = new Promise<void>(resolve => {
			this.initSwiperPromiseResolve = resolve;
		});
	}

	ionViewWillEnter() {
		super.ionViewWillEnter();
		this.globalService.setGlobalLoading(true);
		this.listFichePOIElements = new Array();
	}

	async ionViewDidEnter() {
		this.first = false;
		this.oseJourneyService.isPlaying = false;
		this.detailsOpened = false;
		await this.oseJourneyService.journeysLoaded();
		this.oseJourneyService.prevCompletedJourneys = this.oseJourneyService.currentCompletedJourneys;
		this.oseJourneyService.initializeOseStats();
		await this.cabriService.getAllQuizz();
		await lastValueFrom(this.accountService.isUserLoaded);
		if (!this.accountService.team || this.accountService.team?.length === 0) {
			this.router.navigateByUrl("/activity-participants");
		}
		try {
			await Promise.all([
				this.oseStats.completedJourneys(),
				this.oseStats.completedExercises(),
				this.oseStats.sequencesProgression(),
				this.cabriService.getAllTerritoires()
			]);
			this.setCompletedJourneysStatus();
			this.oseJourneyService.initializeStatuses();
			this.setJourneyExercisesStatuses();
		} catch (err) {
			console.error("some stats include errors");
		}
		await this.cabriService.getAllFiches();
		this.addFicheData();
		await this.globalService.waitLandscapeMode();
		if (this.oseJourneyService.currentMap) {
			await this.skipAllScenarioSpeechSequences();
			await this.loadMapPage();
		} else {
			await this.loadTerritoireMap();
		}
		// back from journey end
		if (this.oseJourneyService.newCompletion) {
			// launch tts feedback before
			// Call it before opening of modal in order to keep parentId property of current journey
			// to differentiate the quizz from the journey
			this.scenarioOseBubble.launchJourneyFeedback();
			// open journey modal
			this.openMAPModal(this.oseJourneyService.currentJourney);
		}

		await this.initSwiperPromise;
	}

	addFicheData(){
		this.oseJourneyService.journeys.forEach((j) => {
			j.exercises.forEach((currEx,index,currArray) => {
				if(currEx.type === OseExerciceType.fiche){
					const fiche = this.cabriService.fiches.find((f) => Number(f.id) === Number(currEx.id))
					if(fiche){
						currArray[index].titre_sommaire = fiche.titre_sommaire;
					}
				}
			})
			let lastSubpart = null;
			j.exercises.forEach( (e, index) => {

				// define table of content
				if(e.isEtape || e.type !== OseExerciceType.fiche){
					// steps and games are not displayed
					e.displayInModal = false;
				} else {
					if(index > 0){
						// check if title is different from last fiche's title
						const previousFicheIndex = this.getPreviousFiche(j.exercises, index);
						e.displayInModal = j.exercises[previousFicheIndex].titre_sommaire !== e.titre_sommaire;
					} else {
						// first element is always displayed
						e.displayInModal = true;
					}
					if(e.displayInModal){
						// new subpart
						e.subpart= {
							hasGame: false,
							hasQuiz: false,
							hasVideo: false,
						}
						lastSubpart = e;
					}
				}
				// check content of each subpart
				if(lastSubpart){
					if(e.hasvideo){
						lastSubpart.hasSubpart = lastSubpart.subpart.hasVideo = true;
					}
					if(e.type === OseExerciceType.quizz){
						lastSubpart.hasSubpart  = lastSubpart.subpart.hasQuiz = true;
					}
					if(e.type !== OseExerciceType.fiche && e.type !== OseExerciceType.quizz){
						lastSubpart.hasSubpart = lastSubpart.subpart.hasGame = true;
					}
				}
				// debug table of content
				// if(!e.isEtape) {
					// console.log(j.title, e.title, e.titre_sommaire, e.displayInModal);
				// }
			})
		})

	}

	private getPreviousFiche(exercises, currentIndex){
		for(let i = currentIndex - 1; i > -1; i--){
			if(!exercises[i].isEtape && exercises[i].type === OseExerciceType.fiche){
				return i;
			}
		}
		return 0;
	}
	async ngOnInit() {
	}

	launchSelectedExoSeq() {
		// total exerciseRemaing/done
		const exercises = this.oseJourneyService.currentJourney.exercises;

		const exercisesSelectedToDo = exercises.filter(ex => {
			return ex.selected;
		});

		exercises.forEach((ex, index, currArr) => {
			const needDo = exercisesSelectedToDo.find(exToDo => {
				return exToDo.id === ex.id;
			});
			if (!needDo) {
				currArr[index].completed = true;
			} else {
				currArr[index].completed = false;
			}
		});
		this.goNextActivity(this.oseJourneyService.currentJourney, true);
	}

	/**
	 * Find svg element to trigger modal
	 * @param journey Ose2Journey
	 */
	openMAPModal(journey: Ose2Journey) {
		const journeyId = journey.parentId ? String(journey.parentId) : String(journey.id);
		let journeyHtmlElement: HTMLElement;
		// find journey group
		journeyHtmlElement = this.svgWrapper.nativeElement.querySelector(`#journey-${journeyId}-image`);
		if (journeyHtmlElement) {
			this.selectMAP({ target: journeyHtmlElement });
		} else {
			// find journey POI
			journeyHtmlElement = this.territoirePOIElements.find(mapElement => {
				return mapElement.id === `journey-${journeyId}`;
			});

			if (journeyHtmlElement) {
				this.selectPOI({ target: journeyHtmlElement });
			}
		}
		if (!journeyHtmlElement) {
			this.oseJourneyService.newCompletion = false;
		}
	}

	/**
	 * selected exercises that should be done starting from the selected index and filters 
	 * them based on index is greater than or equal to the selected index.
	 * @param selectedEx Ose activity
	 * @param index number
	 */
	async startSelectedActivityFromIndex(selectedEx:OseActivity, index:number) {
		let categoryExercises = this.oseJourneyService.currentJourney.getSelectedCategory();
		categoryExercises = categoryExercises.filter((catEx, catIndex) => index <= catIndex);
		const checkedSelectedExIndexes = this.oseJourneyService.currentJourney.
								  exercises.map((ex,index) =>ex.selected ? index : "").filter(String)
		// Save temp exercise completed state
		this.oseJourneyService.currentJourney.exercises.forEach((ex) => {
			ex.selected = categoryExercises.some(catEx => catEx.id === ex.id);
		});
		const title = selectedEx.titre_sommaire || selectedEx.title;
		const confirmAnswer = await this.globalService.simpleAlert(
			`Souhaites-tu débuter à partir de l'exercice suivant : ` + `</br> ${title}`,
			[
				{ text: $localize`Valider`, value: true },
				{ text: $localize`Annuler`, value: false }
			],
			"alertConfirm"
		);

		if (confirmAnswer) {
			// change the state of exercises  based on whether they need to be completed or not
			this.oseJourneyService.currentJourney.exercises.forEach((ex, index, currArray) => {
				const toDo = categoryExercises.some((catEx) => catEx.id === ex.id)
				currArray[index].completed = !toDo;
			});
			this.oseJourneyService.currentJourney.createDynamicNextSeqEtape();
			this.goNextActivity(this.oseJourneyService.currentJourney, true);
		} else {
			// resetting the selected exercises to their previous state.
			this.oseJourneyService.currentJourney.exercises.forEach((ex, index) => {
				ex.selected = checkedSelectedExIndexes.some((indexes) => indexes === index);
			});
		}
	}

	/**
	 * Start doing category exercises based status => preview(only checked exercises) or student(all included)
	 */
	startJourneyWithSelectedExercises() {
		let exercisesToDo;
		this.oseJourneyService.currentJourney.currentCategoryExercise = this.oseJourneyService.currentJourney.getSelectedCategory();

		if (this.accountService.team[0].preview) {
			exercisesToDo = this.oseJourneyService.currentJourney.exercises.filter(ex => ex.selected);
			if (exercisesToDo.length === 0) {
				exercisesToDo = this.oseJourneyService.currentJourney.currentCategoryExercise;
			}
		}

		this.oseJourneyService.currentJourney.exercises.forEach((exercise, i, currArr) => {
			let toDo: boolean;
			if (this.accountService.team[0].preview) {
				toDo = exercisesToDo.some(exToDo => exToDo.id === exercise.id);
			} else {
				toDo = true;
			}

			const isCurrentEx = this.oseJourneyService.currentJourney.
								currentCategoryExercise.some(currEx => currEx.id === exercise.id);

			if (isCurrentEx) {
				currArr[i].completed = toDo ? false : true;
			} else {
				currArr[i].completed = true;
			}
		});

		this.oseJourneyService.currentJourney.createDynamicNextSeqEtape();
		this.goNextActivity(this.oseJourneyService.currentJourney, true);
	}

	async animHighlightQuizToDo() {
			const animateQuizzDoneStars = async (quizz) => {
				let quizzStars = Array.from(quizz.last.nativeElement.querySelectorAll(".isCompleted.notAwarded"));
				if(quizzStars.length == 0){
					quizzStars = Array.from(quizz.last.nativeElement.querySelectorAll(".isCompleted.awarded"));
				}
				for (let index = 0; index <= quizzStars.length - 1; index++) {
					const star = quizzStars[index] as any;
					this.renderer.addClass(star, "highlightRemaining");
					await once(star, "animationend");
					this.renderer.removeClass(star, "highlightRemaining");
				}
			}
			if (this.quizzContainer?.last) {
				// if the Dom element is loaded not need to subscribe
				animateQuizzDoneStars(this.quizzContainer);
			} else {
				this.quizzContainer.changes.subscribe(async (quizz) => {
					animateQuizzDoneStars(quizz);
				});
			}
	}

	async loadTerritoireMapSVG() {
		return new Promise<void>(resolve => {
			const territoire = this.http.get("/assets/territoire/bearn.svg", { responseType: "text" });
			const initialized = this.http.get("/assets/territoire/status/initialized.svg", { responseType: "text" });
			const ongoing = this.http.get("/assets/territoire/status/ongoing.svg", { responseType: "text" });
			const completed = this.http.get("/assets/territoire/status/completed.svg", { responseType: "text" });
			forkJoin([territoire, initialized, ongoing, completed]).subscribe(results => {
				this.initialStatus = new Status(results[1]);
				this.onGoingStatus = new Status(results[2]);
				this.completedStatus = new Status(results[3]);
				this.svgWrapper.nativeElement.innerHTML = results[0];
				const svg = this.svgWrapper.nativeElement.querySelector("svg");
				this.setSvgStylesLocal(svg);
				this.setSwiperSwiping(true);
				resolve();
			});
		});
	}

	async loadMapSvg() {
		return new Promise<void>(resolve => {
			// this.oseJourneyService.currentMap = {
			// 	id: 8530,
			// 	intro: "<!-- wp:paragraph -->\n<p>Bienvenue à la chambre d'agriculture ! Ici nous allons voir ensemble ce qu'est l'alimentation responsable. Et surtout les bonnes habitudes à prendre pour limiter notre impact sur la planète. </p>\n<!-- /wp:paragraph -->\n\n<!-- wp:paragraph -->\n<p>Clique sur les points de couleur pour accéder aux différentes activités.</p>\n<!-- /wp:paragraph -->\n\n<!-- wp:paragraph -->\n<p>C'est parti !</p>\n<!-- /wp:paragraph -->",
			// 	title: "Chambre d'agriculture",
			// 	city: "Pau",
			// 	background: "https://kidaia.com/wp-content/uploads/2022/07/territoire-agriculture.png",
			// 	competencies: [
			// 		{
			// 			id: 91,
			// 			name: "Produits locaux/circuits courts"
			// 		},
			// 		{
			// 			id: 86,
			// 			name: "Produits de saison"
			// 		}
			// 	],
			// 	svgUrl: "https://kidaia.com/wp-content/uploads/2022/07/mapAgriculture.svg",
			// 	isFiche: false
			// };
			const url = (this.oseJourneyService.currentMap as OseMap).svgUrl;
			// const url = "/assets/territoire/map-agriculture.svg";
			this.http.get(url, { responseType: "text" }).subscribe({
				next: async data => {
					this.svgWrapper.nativeElement.innerHTML = data;
					const svg = this.svgWrapper.nativeElement.querySelector("svg");
					svg.setAttribute("preserveAspectRatio", "xMidYMid slice");
					this.setSvgStylesLocal(svg);
					this.setSwiperSwiping(false);
					resolve();
				}
			});
		});
	}

	public setSwiperSwiping(enable: boolean) {
		if (enable) {
			this.slideElement?.updateParameter("allowTouchMove", true);
			this.slideElement?.updateParameter("noSwiping", false);
		} else {
			this.slideElement?.updateParameter("allowTouchMove", false);
			this.slideElement?.updateParameter("noSwipingClass", "swiper-slide");
			this.slideElement?.updateParameter("noSwiping", true);
		}
	}

	/**
	 * Prevent the CSS properties of a locally embedded SVG to affecting other pages
	 * @param svg svg element
	 */
	public setSvgStylesLocal(svg) {
		const style = svg.querySelector("defs > style");
		const stylesSplitted = [];
		let currentCharacter = "";
		for (const character of style.innerHTML) {
			if (character === "}") {
				currentCharacter += character;
				stylesSplitted.push(`#${svg.id} ${currentCharacter}`);
				currentCharacter = "";
			} else {
				currentCharacter += character;
			}
		}
		const finalStylesCss = stylesSplitted.join("");
		style.innerHTML = finalStylesCss;
	}

	/**
	 * Read tts intro Bearn map
	 */
	async readBearnTTSIntro() {
		if (!this.introTTSPlayed) {
			this.introTTSPlayed = true;
			await this.scenarioOseBubble.welcomeInTerritory();
		}
	}

	/**
	 * Read tts intro for each map. If user discovering map for the first time globalBubble is displayed
	 */
	public async readMapTTSIntro() {
		if (!this.oseJourneyService.newCompletion) {
			// read intro
			await this.scenarioOseBubble.readHTML((this.oseJourneyService.currentMap as OseMap).intro, true);
		}
	}

	async loadTerritoireMap() {
		// map loaded
		await this.loadTerritoireMapSVG();
		this.displayTerritoire = true;
		try {
			this.listPOI = await this.oseJourneyService.getAllPOI();
		} catch (err) {
			console.error("POI error");
		}
		// from svg select id's images of journey/poi
		this.territoirePOIElements = Array.from(this.svgWrapper.nativeElement.querySelectorAll("svg image, svg g, svg use")).filter(
			(htmlElement: HTMLBaseElement) => {
				return (
					htmlElement.id?.startsWith("poi") ||
					htmlElement.id?.startsWith("journey") ||
					(htmlElement.id?.startsWith("fiche") && !htmlElement.id?.includes("marker"))
				);
			}
		) as HTMLBaseElement[];

		this.territoirePOIElements.forEach(poiElement => {
			poiElement.addEventListener("click", this.selectPOI.bind(this));
		});

		this.territoireMarkerGroupElements = Array.from(this.svgWrapper.nativeElement.querySelectorAll("svg g")).filter(
			(htmlElement: HTMLBaseElement) => {
				return htmlElement.id?.includes("marker");
			}
		) as HTMLBaseElement[];

		this.territoireMarkerGroupElements.forEach(poiElement => {
			poiElement.addEventListener("click", this.selectPOI.bind(this));
		});

		this.hideHighlightImages();
		this.setJourneyStatuses(this.territoirePOIElements, false);
		this.globalService.setGlobalLoading(false);
		await this.globalService.unlockAudioAndTTSIfPageReloaded(true);
		this.readBearnTTSIntro();
	}

	/**
	 * Hide highlight images displayed by default
	 */
	hideHighlightImages() {
		if (this.displayTerritoire) {
			this.territoirePOIElements.forEach(element => {
				const imageGlow =
					(element.id.startsWith("fiche-") || element.id.startsWith("poi-")) &&
					element.id.endsWith("image") &&
					element.tagName === "image";
				if (imageGlow) {
					this.renderer.setStyle(element, "pointer-events", "none");
					this.renderer.setStyle(element, "opacity", "0");
				}
			});
		} else {
			this.groupImages.forEach(groupElement => {
				this.renderer.setStyle(groupElement, "opacity", "1");
			});
		}
	}

	/**
	 * Set poi statuses based on journey or exercise progression
	 * @param poiJourneysElements poi HTML elements
	 * @param isJourneyType if true the status will be based on all started or completed journeys otherwise it will be depend on all exercises
	 */

	setJourneyStatuses(poiJourneysElements: HTMLBaseElement[], isJourneyType: boolean) {
		let validatedItems;
		let onGoingItems;
		if (isJourneyType) {
			// search from all journeys (e.g Map (Chambre d'agriculture) )
			validatedItems = this.oseJourneyService.currentCompletedJourneys;
			onGoingItems = this.oseJourneyService.onGoingJourneys;
		} else {
			// search from all exercises (e.g Territoire-> Fiche )
			validatedItems = this.oseJourneyService.completedExercises;
			onGoingItems = this.oseJourneyService.onGoingExercises;
		}

		/**
		 * Change poi's marker style to display none
		 * @param poiId fiche id
		 * @param progressionStatus progression status including 3 possibilites
		 */
		const displayMarkerElementByProgressionStatus = (poiId: string, progressionStatus: string) => {
			this.territoireMarkerGroupElements.forEach((markerGroupElement: any) => {
				// group containing three images by status (initialized/ongoing/completed)
				if (markerGroupElement?.id === `fiche-${poiId}-marker`) {
					markerGroupElement.childNodes.forEach(children => {
						// Group childrens
						if (children?.id !== `fiche-${poiId}-marker-${progressionStatus}`) {
							this.renderer.setStyle(children, "display", "none");
							this.renderer.removeAttribute(children, children.dataset.displayed);
						} else {
							children.dataset.displayed = true;
							this.renderer.setStyle(children, "display", "block");
						}
					});
				}
			});
		};

		poiJourneysElements.forEach(journeyElem => {
			if (!journeyElem.id?.endsWith("image")) {
				// console.log("journeyElem",journeyElem)
				const poi = journeyElem.id.split("-")?.[1];
				if (poi) {
					let currJourney = this.oseJourneyService.journeys.find(currJ => currJ.id === Number(poi));

					if (!currJourney) {
						// Create nasty journey, in the case where journey doesn't come from server
						currJourney = new Ose2Journey({
							id: Number(poi)
						});
					}

					if (currJourney) {
						const validated = currJourney.hasJourneyStatusMatch(validatedItems);
						if (validated) {
							// validated
							if (this.displayTerritoire) {
								// not display
								displayMarkerElementByProgressionStatus(poi, "completed");
								// replace href of poi image source directly
								this.completedStatus.replaceStatus(journeyElem, "xlink:href");
							} else {
								// replace href id
								new Status().replaceStatus(journeyElem, "#completed");
								if (!journeyElem.id.includes("completed")) {
									// not display completed poi's on map
									this.renderer.setStyle(journeyElem, "display", "none");
								}
							}
						} else {
							const onGoing = currJourney.hasJourneyStatusMatch(onGoingItems);
							if (onGoing) {
								if (this.displayTerritoire) {
									displayMarkerElementByProgressionStatus(poi, "ongoing");
									// replace href of poi image source directly
									this.onGoingStatus.replaceStatus(journeyElem, "xlink:href");
								} else {
									// replace href id
									new Status().replaceStatus(journeyElem, "#ongoing");
									if (!journeyElem.id.includes("ongoing")) {
										// not display ongoing poi's on map
										this.renderer.setStyle(journeyElem, "display", "none");
									}
								}
							} else {
								if (this.displayTerritoire) {
									displayMarkerElementByProgressionStatus(poi, "initialized");
								} else {
									if (!journeyElem.id.includes("initialized")) {
										// not display initialized poi's on map
										this.renderer.setStyle(journeyElem, "display", "none");
									}
								}
							}
						}
					}
				}
			}
		});
	}

	onAnimationStart($event: AnimationEvent) {
		if (!this.first) {
			this.animationRunning = true;
		}
	}

	closeModal() {
		if (this.selectedActivities[0]?.isFiche) {
			this.listFichePOIElements.push(this.currentPOIElement);
		}
		this.selectedActivities = new Array();
		this.setFichePOIStatus();
		this.ficheComponent?.sendJourneyEndLrs();
		this.modalState = ModalStateName.close;
		// this.oseJourneyService.currentJourney.selectedCategory = null;
		this.detailsOpened = false;
		this.hideHighlightImages();
	}

	/**
	 *  change the status of the POI fiche of HTML Element according to its reading status asynchronously
	 * @param started boolean
	 */
	setFichePOIStatus() {
		if (this.displayTerritoire && this.oseJourneyService.currentJourney?.isFiche) {
			this.lrsService.newStatementSend = new ReplaySubject(1);
			this.newStatementSendSubscription = this.lrsService.newStatementSend.subscribe((statementSent: Statement) => {
				if (statementSent && statementSent.context?.extensions?.[XapiExtensions.id]) {
					const idExercise = statementSent.context.extensions[XapiExtensions.id];

					let arrayStatus: Array<JourneysStatus>;
					let hasNewStatus = false;
					const countCurrentPOIid = this.listFichePOIElements.filter(elem => {
						return elem.id === "fiche-" + idExercise;
					});
					if (countCurrentPOIid.length === 1) {
						arrayStatus = this.oseJourneyService.onGoingExercises;
						// verify if current fiche is not  included already in completed array before adding it
						const allStatuses = this.oseJourneyService.completedExercises.concat(this.oseJourneyService.onGoingExercises);
						hasNewStatus = !allStatuses.some(completed => {
							return completed.id === Number(idExercise);
						});
					} else {
						arrayStatus = this.oseJourneyService.completedExercises;
						hasNewStatus = !arrayStatus.some(completed => {
							return completed.id === Number(idExercise);
						});
					}

					if (this.prevPOIElement) {
						// update all last opened statuses to completed except the current one
						this.listFichePOIElements.forEach(listPOIElement => {
							let currElemId;
							let isCurrentPOIFiche = true;
							const listPOIId = Number(listPOIElement.id.split("-")[1]);
							const currentElement = this.currentPOIElement?.id.split("-");
							if (currentElement) {
								currElemId = Number(currentElement[1]);
								if (currentElement[0] !== "fiche") {
									isCurrentPOIFiche = false;
								}
							}
							if (!isCurrentPOIFiche || (Number(idExercise) !== listPOIId && listPOIId !== currElemId)) {
								const alreadyExist = this.oseJourneyService.completedExercises.some(journey => {
									return journey.id === listPOIId;
								});
								if (!alreadyExist) {
									this.oseJourneyService.completedExercises.push({
										id: listPOIId,
										status: XapiVerbs.get(LrsVerbs.completed).id
									});
									hasNewStatus = true;
								}
							}
						});
					}
					if (hasNewStatus) {
						// there is a new status so update une current one
						const poiElement = this.territoirePOIElements.find(element => {
							return element.id === "fiche-" + idExercise;
						}) as any;

						let poiMarkerElement;
						this.territoireMarkerGroupElements.forEach(groupElement => {
							if (groupElement?.id === `fiche-${idExercise}-marker`) {
								// this.render
								Array.from(groupElement.childNodes).forEach((groupChild: any) => {
									if (groupChild?.dataset?.displayed) {
										poiMarkerElement = groupChild;
									}
								});
							}
						});

						// POI/MARKER image changed when fiche progression changed(not view/view in progress/viewed)
						const changePoiMarkerImageByStatus = (
							svgTargetted: HTMLBaseElement,
							svgTargettedParentItems: Array<HTMLBaseElement>
						) => {
							this.renderer.setStyle(svgTargetted, "display", "none");
							arrayStatus.push({
								id: Number(idExercise),
								status:
									countCurrentPOIid.length === 1
										? XapiVerbs.get(LrsVerbs.initialized).id
										: XapiVerbs.get(LrsVerbs.completed).id
							});
							// changing status
							this.setJourneyStatuses(svgTargettedParentItems, false);
						};

						changePoiMarkerImageByStatus(poiElement, this.territoirePOIElements);
						this.renderer.setStyle(poiElement, "display", "block");
						changePoiMarkerImageByStatus(poiMarkerElement, this.territoireMarkerGroupElements);
					}
				}
			});
		}
	}

	openCategories(){
		this.isCategoryListOpen = !this.isCategoryListOpen;
		this.detailsOpened = false;
		this.oseJourneyService.currentJourney.selectedCategory = null;
	}

	async goNextActivity(activity: Ose2Journey | OseMap, resume: boolean) {
		switch (this.selectedPOIType) {
			case ActivityType.journey:
				// journey POI
				this.oseJourneyService.currentJourney.resume = resume;
				if (!resume) {
					this.oseJourneyService.currentJourney.setAllExercisesStatuses(false);
				}
				// start of journey
				this.oseJourneyService.launchNextJourneyExercise();
				if (this.displayTerritoire) {
					this.oseJourneyService.currentMap = null;
				}
				break;
			case ActivityType.map:
				this.hideHighlightImages();
				this.oseJourneyService.currentMap = activity;
				this.loadMapPage();
				break;
			case ActivityType.fiche:
				this.closeModal();
				break;
			default:
				throw new Error("poi type not recognized");
		}
		this.modalState = ModalStateName.close;
		this.currentPOIElement = null;
	}

	async onAnimationEnd($event: AnimationEvent) {
		if (!this.first) {
			if (this.modalState === ModalStateName.show) {
				this.checkModalType();
				this.modalState = ModalStateName.hide;
			} else if (this.modalState === ModalStateName.close) {
				this.renderer.removeClass(this.cardActivity.nativeElement, "card-one-poi");
				this.renderer.addClass(this.cardActivity.nativeElement, "card-reduce");
				this.currentPOIElement = null;
			} else if (this.modalState === ModalStateName.hide) {
				if (this.oseJourneyService.newCompletion) {
					const confettiOptions: ConfettiOptions = {
						particleCount: 250,
						spread: 70,
						startVelocity: 30
					};
					this.globalService.confettiCanvasComponent
						?.launchConfetti(this.cardActivity.nativeElement, confettiOptions)
						.then(() => {
							this.oseJourneyService.newCompletion = false;
						});
				}
			}

			if (this.globalService.isSafari) {
				// hack for svg element resize bug
				const svgElement = this.svgWrapper.nativeElement.querySelector("svg");
				if (svgElement) {
					svgElement.style.opacity = 0.99;
					this.cd.detectChanges();
					await AppUtils.timeOut(100);
					svgElement.style.opacity = 1;
					this.cd.detectChanges();
				}
			}
		}
		this.animationRunning = false;
	}

	/**
	 * Grab top most svg's group element of current svg element
	 * @param domElement HTMLElement
	 * @returns SvgElement
	 */
	getClosestGroup(domElement: HTMLElement) {
		let groupParent;
		let counterGrabTop = 0;

		while ((!groupParent && counterGrabTop <= 10) || (groupParent?.parentElement && groupParent.tagName !== "g")) {
			counterGrabTop++;
			groupParent = domElement.parentElement;
		}
		return groupParent;
	}

	async selectPOI($event: { target: HTMLElement; offsetX?: number; offsetY?: number }) {
		this.displayQuizChoice = false;
		this.competenciesOpen = false;
		this.allQuizCompleted = false;
		this.isJourneyDisplayed = false;

		if (!this.animationRunning) {
			this.cd.detectChanges();
			let targetPOI = $event.target;
			if (targetPOI.tagName === "path") {
				targetPOI = this.getClosestGroup(targetPOI);
			}
			if (this.currentPOIElement) {
				this.prevPOIElement = this.currentPOIElement;
				if (this.prevPOIElement.id.includes("fiche")) {
					this.setFichePOIStatus();
					this.ficheComponent?.sendJourneyEndLrs();
				}
			}
			// prevent click on same element
			if (targetPOI && (!this.currentPOIElement || targetPOI.id !== this.currentPOIElement?.id)) {
				// remove css class on previous poi
				// set current poi
				const poiData = targetPOI.id.split("-");
				this.currentPOIElement = this.svgWrapper.nativeElement.querySelector(`#${poiData[0]}-${poiData[1]}`);
				this.hideHighlightImages();
				switch (poiData[0]) {
					case "journey":
						this.selectedPOIType = ActivityType.journey;
						this.oseJourneyService.currentJourney = this.oseJourneyService.getById(Number(poiData[1]));
						break;
					case "poi":
						this.selectedPOIType = ActivityType.map;
						this.oseJourneyService.currentJourney = null;
						break;
					case "fiche":
						this.selectedPOIType = ActivityType.fiche;
						this.highlightSelectedFichePOIImage(poiData[1]);
						this.oseJourneyService.currentJourney = new Ose2Journey(
							{
								id: poiData[1],
								title: "",
								exerciseIds: [poiData[1]],
								competencies: []
							},
							this.oseJourneyService.oseCompetencies,
							this.cabriService.fiches as any
						);
						this.oseJourneyService.currentJourney.started = this.oseJourneyService.currentJourney.isFiche = true;
						this.scenarioNav.previousTitleRead = null;
						this.listFichePOIElements.push(this.currentPOIElement);
						this.setFichePOIStatus();
						break;
					default:
						throw new Error("poi type not recognized");
				}
				this.isJourneyDisplayed = this.selectedPOIType === ActivityType.journey;
				this.openModal();
				// animation
				this.renderer.removeClass(this.cardActivity.nativeElement, "card-reduce");
				this.first = false;
			}
		}
	}

	/**
	 * Open modal based on its status
	 */
	openModal() {
		this.modalState = ModalStateName.show;
	}

	/**
	 * Highlight associated fich image of poi
	 * @param ficheId string
	 */
	highlightSelectedFichePOIImage(ficheId: string) {
		const associatedFicheImg = Array.from(this.currentPOIElement.parentNode.childNodes).find((elem: HTMLBaseElement) => {
			return elem.id === `fiche-${ficheId}-image`;
		});
		if (associatedFicheImg) {
			this.renderer.setStyle(associatedFicheImg, "transition", "opacity 0.3s linear");
			this.renderer.setStyle(associatedFicheImg, "opacity", "1");
		}
	}

	async customOnResize(): Promise<void> {
		return new Promise(async resolve => {
			super.customOnResize();
			this.checkNeedResizeModal();
			resolve();
		});
	}

	checkNeedResizeModal() {
		if (this.cardActivity?.nativeElement) {
			if (
				Array.isArray(this.selectedActivities) &&
				(this.selectedActivities.length > 1 || (this.selectedActivities[0]?.isFiche && window.innerWidth <= 1200))
			) {
				// more than one poi/journey
				this.renderer.removeClass(this.cardActivity.nativeElement, "card-one-poi");
				this.renderer.addClass(this.cardActivity.nativeElement, "card-multiple-poi");
				this.getModalFullWidth();
			} else if (Array.isArray(this.selectedActivities)) {
				this.renderer.removeClass(this.cardActivity.nativeElement, "card-multiple-poi");
				this.renderer.addClass(this.cardActivity.nativeElement, "card-one-poi");
				this.goToRightPosition = "0%";
			}
		}
	}

	checkModalType() {
		if (this.displayTerritoire) {
			this.selectedActivities = this.getCurrentPOIActivities();
		} else {
			this.selectedActivities = [this.oseJourneyService.currentJourney];
			if (this.oseJourneyService.currentJourney.quizz) {
				this.allQuizCompleted = this.oseJourneyService.currentJourney.quizz.every(q => q.completedQuizz);
				console.log(this.allQuizCompleted);
			}
		}
		this.renderer.removeClass(this.cardActivity.nativeElement, "card-reduce");
		this.customOnResize();
	}

	setCompletedJourneysStatus() {
		this.oseJourneyService.journeys.forEach(journey => {
			journey.completed = journey.hasJourneyStatusMatch(this.oseJourneyService.currentCompletedJourneys);
			if (journey.quizz?.length > 0) {
				journey.setQuizzCompletionStatuses(this.oseJourneyService.completedExercises);
			}
		});
	}

	/**
	 * Set journey exercises in order to resume
	 */
	setJourneyExercisesStatuses() {
		this.oseJourneyService.sequencesTraces.forEach(journeyTracesProgression => {
			this.oseJourneyService.journeys.forEach((journey, index2, currJourney) => {
				if (journey.id === journeyTracesProgression.parcoursId) {
					const exToResume = journeyTracesProgression.exercises_progressions.filter(progressEx => {
						return progressEx.completed;
					});

					if (exToResume?.length > 0) {
						const lastExCompleted = exToResume[exToResume.length - 1];
						const index = journey.exercises.findIndex(journeyEx => {
							return Number(journeyEx.id) === Number(lastExCompleted.id);
						});
						if (index > -1) {
							for (let i = 0; i <= index; i++) {
								currJourney[index2].exercises[i].completed = true;
							}
						}
					}
				}
			});
		});

		// resumed activity is conclusion(fiche)
		this.oseJourneyService.journeys.forEach(j => {
			j.exercises.forEach((ex, indexEx) => {
				ex.selected = false;
				ex.alreadyCompleted = this.oseJourneyService.completedExercises.some(completedEx => {
					return completedEx.id === ex.id;
				});
				if (ex.title === FicheEtapes.titleNextSeq) {
					// supprimer l'activité de type catégorie(fiche) à l'arrivée sur la page.
					j.exercises.splice(indexEx, 1);
				}
			});
			const firstExToDo = j.exercises.find(ex => {
				return ex.completed === false;
			});

			// completed to not resume that one
			if (firstExToDo?.isEtape) {
				firstExToDo.completed = true;
			}
		});
	}

	openDetails(type) {
		if (type !== null && type === this.oseJourneyService.currentJourney.selectedCategory) {
			this.oseJourneyService.currentJourney.selectedCategory = null;
			this.oseJourneyService.currentJourney.currentCategoryExercise = new Array();
		} else {
			this.oseJourneyService.currentJourney.selectedCategory = type;
			this.oseJourneyService.currentJourney.currentCategoryExercise = this.oseJourneyService.currentJourney.getSelectedCategory();
			this.oseJourneyService.currentJourney.currentCategoryExercise.forEach((ex) => {
				if(ex.isworkshop === "1"){
					ex.icon = "assets/icon/chisel.svg";
				}else if(ex.type === OseExerciceType.fiche){
					ex.icon = "assets/icon/book.svg";
				}else if(ex.type === OseExerciceType.quizz){
					ex.icon = "assets/icon/quiz.svg";
				}else{
					ex.icon = "assets/icon/game.svg";
				}
			})
		}
		console.log("currentJourney.currentCategoryExercise",this.oseJourneyService.currentJourney.currentCategoryExercise)
		this.detailsOpened = this.oseJourneyService.currentJourney.selectedCategory ? true : false;
	}

	/**
	 * Get POI map(s)
	 */
	getCurrentPOIActivities(): Ose2Journey[] | OseMap[] {
		const poiId = this.currentPOIElement.id.split("-")?.[1];
		if (poiId) {
			if (this.oseJourneyService.currentJourney) {
				return Array(this.oseJourneyService.currentJourney);
			} else {
				return this.listPOI.find(poi => {
					return poi.id === Number(poiId);
				})?.maps;
			}
		}
	}

	/**
	 * Determine width of modal when items (poi/journeys ) are more than one
	 */
	getModalFullWidth() {
		const modalSizeAsPerc = 80;
		const modalCenteredPos = 50;
		this.cardActivity.nativeElement.style.setProperty("--modalFullSize", String(modalSizeAsPerc) + "%");
		this.goToRightPosition = modalCenteredPos - modalSizeAsPerc / 2 + "%";
	}
	/**
	 * Map activity
	 */
	async loadMapPage() {
		this.globalService.setSmallLoading(true);
		this.selectedActivities = new Array();
		this.renderer.removeClass(this.cardActivity.nativeElement, "card-reduce");
		// map loaded
		await this.loadMapSvg();
		this.displayTerritoire = false;
		if (this.pinchZoom?.isZoomedIn) {
			this.pinchZoom.toggleZoom();
		}
		// all maps are journey's type
		this.selectedPOIType = ActivityType.journey;
		this.isJourneyDisplayed = true;
		this.activitiesDOM = Array.from(this.svgWrapper.nativeElement.querySelectorAll("svg image,svg defs,svg g, svg use"));
		this.groupsElements = new Array();
		const poiElements = new Array();
		this.groupImages = new Array();
		this.activitiesDOM.forEach(mapElement => {
			if (mapElement.tagName === "g" && mapElement.id.startsWith("journey-")) {
				let addSvgElement = true;
				const journeyId = mapElement?.id?.split("-");
				if (journeyId?.length > 0) {
					const poiElement = this.activitiesDOM.find(mapElement => {
						return mapElement?.id === `${journeyId[0]}-${journeyId[1]}-poi`;
					}) as any;

					if (poiElement?.style?.display === "none") {
						mapElement.style.opacity = "0";
						addSvgElement = false;
					}
				}
				if (addSvgElement) {
					this.groupsElements.push(mapElement);
					if (mapElement?.id?.endsWith("-image")) {
						this.groupImages.push(mapElement);
					}
					mapElement.childNodes.forEach((groupItem: HTMLBaseElement) => {
						if (groupItem.tagName === "image") {
							// add images tags
							if (groupItem.nextElementSibling) {
								// draw elements are at the same level than image. Make image no clickable
								const tagNames = ["g", "polygon", "polyline", "path", "rect"];
								const containSvgDrawElement = tagNames.includes(groupItem.nextElementSibling.tagName);
								if (containSvgDrawElement) {
									this.renderer.setStyle(groupItem, "pointer-events", "none");
								}
							}
						}
						if (groupItem.tagName === "use" || groupItem.tagName === "image") {
							poiElements.push(groupItem);
						}
					});
				}
			}
		});

		this.groupsElements.forEach(activityElem => {
			activityElem.addEventListener("click", this.selectMAP.bind(this));
		});
		this.setJourneyStatuses(poiElements, true);
		this.globalService.setSmallLoading(false);
		this.globalService.setGlobalLoading(false);
		this.readMapTTSIntro();
	}

	/**
	 * Skip all instances scenarios
	 */
	async skipAllScenarioSpeechSequences() {
		await this.scenarioNav?.skipMathiaSpeechSequence(true);
		await this.scenarioOseBubble?.skipMathiaSpeechSequence(true);
	}

	async goBackTerritoire() {
		this.scenarioOseBubble?.skipMathiaSpeechSequence(true);
		this.selectedActivities = new Array();
		this.globalService.setGlobalLoading(true);
		await this.loadTerritoireMap();
		this.detailsOpened = false;
	}

	/**
	 * Return the last subpart element that is currently in progress
	 * @returns OseActivity
	 */
	public getLatestSubPartInProgress():OseActivity{
		const currentExerciseIndex = this.oseJourneyService.currentJourney.exercises.findIndex(ex => !ex.completed);
		let subPartFiche;
		for (let i = currentExerciseIndex; i >= 0; i--) {
			const currentExercise = this.oseJourneyService.currentJourney.exercises[currentExerciseIndex];
			if(currentExercise.hasSubpart){
				subPartFiche = currentExercise;
				break;
			}
			// Loop code goes here
		}
		return subPartFiche;
	}

	/**
	 * Returns the HTMLBaseElement of the selected POI map element.
	 * @param selectedPoiSvgElement HtmlBaseElement
	 */
	public getSelectedPoiMap(selectedPoiElement:HTMLBaseElement):HTMLBaseElement{
		let currentImgSelected;
		if (selectedPoiElement.id.includes("poi")) {
			// poi group is clicked
			currentImgSelected = Array.from(selectedPoiElement.parentElement.childNodes).find((currElement: any) => {
				return currElement && currElement.id?.includes("image");
			}) as HTMLBaseElement;
		} else {
			// image group is clicked
			currentImgSelected = selectedPoiElement;
		}
		return currentImgSelected;
	}

	/**
	 * Select map journey
	 */
	selectMAP($event: { target: HTMLElement }) {
		if (!this.animationRunning) {
			this.detailsOpened = false;
			// reset modal parameters
			const targettedActivity = this.getClosestGroup($event.target);
			let targettedIdReg = targettedActivity.id.match(/(\d+)/);
			let currentPOIIdReg = this.currentPOIElement?.id.match(/(\d+)/) as any;
			if (!targettedIdReg) {
				targettedIdReg = [];
			}
			if (!currentPOIIdReg) {
				currentPOIIdReg = [];
			}
			// prevent click on same element
			if (targettedActivity && (!this.currentPOIElement || targettedIdReg[0] !== currentPOIIdReg[0])) {
				const idElement = targettedActivity.id.split("-");

				this.totalProgressionExercisesToDo = this.totalCompletedExercises = null;
				this.competenciesOpen = false;
				this.displayQuizChoice = false;
				this.allQuizCompleted = false;
				this.isJourneyDisplayed = true;
				this.oseJourneyService.currentJourney = this.oseJourneyService.getById(Number(idElement[1]));
				this.oseJourneyService.currentJourney.selectedCategory = null;
				this.oseJourneyService.currentJourney.currentCategoryExercise = new Array();
				// this.oseJourneyService.currentJourney = this.oseJourneyService.getById(24331);
				this.getSeqCategories();
				this.alreadyCompletedJourney = this.oseJourneyService.currentCompletedJourneys.some((compJ) => {compJ.id === this.oseJourneyService.currentJourney.id})
				const notDoneExercises = this.oseJourneyService.currentJourney.exercises.filter(ex => !ex.completed);
				this.canJourneyResume = notDoneExercises?.length > 0 ? 
										notDoneExercises?.length !== this.oseJourneyService.currentJourney.exercises?.length
										: false; 
				this.subpartInProgress = this.getLatestSubPartInProgress();
				// title //
				this.modalState = ModalStateName.hide;
				this.cd.detectChanges();
				this.currentPOIElement = targettedActivity;
				if (targettedActivity?.childNodes) {
					// get group image element
					let currentImageSelected = this.getSelectedPoiMap(targettedActivity);
					if (currentImageSelected) {
						this.groupImages.forEach(groupElement => {
							if (groupElement?.id.includes("image")) {
								let id = currentImageSelected.id;
								this.renderer.setStyle(groupElement, "opacity", id !== groupElement.id ? "0" : "1");
							}
						});
					}
				}

				this.resizeCardMaxHeight();
				if (this.oseJourneyService.currentJourney.quizz) {
					this.allQuizCompleted = this.oseJourneyService.currentJourney.quizz.every(q => q.completedQuizz);
				}
				this.openModal();
				// ratio total ex/remaining
				this.totalProgressionExercisesToDo = this.oseJourneyService.currentJourney.getTotalExercisesToDo(false);
				if(this.canJourneyResume){
					this.totalCompletedExercises = this.totalProgressionExercisesToDo.filter(ex => ex.completed)
					this.totalProgressionExercisesToDo.forEach((ex, index) => {
						if (index === this.totalProgressionExercisesToDo.length - 1 && ex instanceof OseActivity) {
							// add additional item for progression if last activity is fiche and has no video
							this.totalCompletedExercises.push(new OseActivity());
						}
					});
				}else{
					this.totalCompletedExercises = new Array()
				}
			}
		}
	}

	getSeqCategories() {
		const { workshops, learnings, evaluations } = this.oseJourneyService.currentJourney.initializeCategoryExercises(this.cabriService.quizz);
		this.oseJourneyService.currentJourney.workshops = workshops;
		this.oseJourneyService.currentJourney.learnings = learnings;
		this.oseJourneyService.currentJourney.evaluations = evaluations;
	}

	/**
	 * Resize card height in order to take full window height
	 */
	resizeCardMaxHeight() {
		AppUtils.timeOut(50).then(async () => {
			let counter = 0;
			while (this.cardActivity.nativeElement.offsetTop === 0 && counter < 50) {
				await AppUtils.timeOut(10);
				counter++;
			}
			this.cardActivity.nativeElement.style.maxHeight =
				window.innerHeight - this.globalService.toolbarHeight - this.cardActivity.nativeElement.offsetTop - 15 + "px";
		});
	}

	ionViewWillLeave() {
		super.ionViewWillLeave();
		this.first = true;
		this.selectedActivities = null;
		this.currentPOIElement = null;
		this.oseJourneyService.prevCompletedJourneys = null;
		this.skipAllScenarioSpeechSequences();
		this.newStatementSendSubscription?.unsubscribe();
	}

	/**
	 * Launch journey's quizz
	 */
	async launchQuizz(quizzId, journey) {
		const quizz = (await this.cabriService.getAllQuizz()).find(qzz => Number(qzz.id) === Number(quizzId));
		this.oseJourneyService.currentJourney = new Ose2Journey(
			{
				id: quizzId,
				title: quizz.title,
				exerciseIds: [quizzId],
				competencies: [],
				parentId: journey?.id
			},
			this.oseJourneyService.oseCompetencies,
			this.cabriService.quizz
		);
		this.oseJourneyService.currentJourney.exercises.forEach(ex => {
			ex.type = OseExerciceType.quizz;
			ex.completed = false;
		});
		this.oseJourneyService.launchNextJourneyExercise();
	}


	grimoire($event) {
		if (this.isJourneyDisplayed) {
			$event.stopPropagation();
			this.competenciesOpen = !this.competenciesOpen;
		}
	}

	onSwiperChange(swiper: Swiper) {
		if (this.swiper) {
			const i = this.slideElement.swiperRef.realIndex;
			this.currentTerritoire = [{ title: "Le Béarn" }, ...this.cabriService.territoires][i].title + (i > 0 ? " (verrouillé)" : "");
			if (this.pinchZoom.isZoomedIn) {
				this.pinchZoom.toggleZoom();
			}
		}
	}

	nextSlide() {
		this.setSwiperSpeed(this.cabriService.territoires.length);
		this.slideElement.swiperRef.slideNext();
	}

	prevSlide() {
		this.setSwiperSpeed(0);
		this.slideElement.swiperRef.slidePrev();
	}

	setSwiperSpeed(index: number) {
		const i = this.slideElement.swiperRef.activeIndex;
		if (i === index) {
			this.slideElement?.updateParameter("speed", 0);
		} else {
			if (this.swiper.params.speed !== 300) {
				this.slideElement?.updateParameter("speed", 300);
			}
		}
	}
}
