import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";
import { GlobalService } from "./global.service";
import { environment } from "src/environments/environment";
import { int } from "babylon4.1";
import { string } from "@tensorflow/tfjs";
import { map } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class AnalyticService extends BaseService {
	constructor(private http: HttpClient) {
		super();
		(window as any).ana = this;
	}

	sendAnalytics(value: string, params = {}) {
		params["Prod"] = environment.production ? 1 : 0;
		params["app"] = environment.ose ? "ose" : environment.kidaia ? "kidaia" : "mathia";

		const formData = new FormData();
		formData.append("action", "app_mathia_analytics_event");
		formData.append("event", value);
		if (params) {
			formData.append("params", JSON.stringify(params));
		}
		this.http.post<any>(this.postUrl, formData).subscribe({
			error: err => console.error(err)
		});
	}

	getNavigationStat(
		dateStart?: string,
		dateEnd?: string,
		app?: "kidaia" | "mathia" | "ose",
		prod?: 0 | 1,
		deepLimit?: number,
		threshold?: number,
		deepMode = false
	) {
		const formData = new FormData();
		formData.append("action", "analytic_navigation");
		formData.append("deepLengthMode", deepMode ? "1" : "0");
		if (prod) {
			formData.append("prod", prod.toString());
		}
		if (dateStart) {
			formData.append("date_start", dateStart);
		}
		if (dateEnd) {
			formData.append("date_end", dateEnd);
		}
		if (deepLimit) {
			formData.append("deepLimit", deepLimit.toString());
		}
		if (threshold) {
			formData.append("threshold", threshold.toString());
		}
		if (app) {
			formData.append("app", app);
		}
		return this.http.post<{ ordered: { from: string; to: string; flow: number }[]; raw: { from: string; to: string; flow: number }[] }>(
			this.postUrl,
			formData
		);
	}

	getNavigationBilanStat(
		dateStart?: string,
		dateEnd?: string,
		app?: "kidaia" | "mathia" | "ose",
		prod?: 0 | 1,
		deepLimit?: number,
		threshold?: number,
		deepMode = false
	) {
		const formData = new FormData();
		formData.append("action", "analytic_navigation_bilan");
		formData.append("deepLengthMode", deepMode ? "1" : "0");
		if (prod) {
			formData.append("prod", prod.toString());
		}
		if (dateStart) {
			formData.append("date_start", dateStart);
		}
		if (dateEnd) {
			formData.append("date_end", dateEnd);
		}
		if (deepLimit) {
			formData.append("deepLimit", deepLimit.toString());
		}
		if (threshold) {
			formData.append("threshold", threshold.toString());
		}
		if (app) {
			formData.append("app", app);
		}
		return this.http.post<
			{
				from: string;
				to: string;
				flow: number;
			}[][]
		>(this.postUrl, formData);
	}
	/**
	 * 
	 * @param params {type?: string; notNullId?: boolean; userId?: string; dateStart?: string; dateEnd?: string,params: {key : value}}
	 * on params key can use # to concat the operation sign like this 'key@like':value
	 * @returns 
	 */
	getAnalyticEvent(params: { type?: string; notNullId?: boolean; userId?: string; dateStart?: string; dateEnd?: string; params: any }) {
		const formData = new FormData();
		formData.append("action", "analytic_search");
		formData.append("params", JSON.stringify(params));

		return this.http.post<{ user_id: string; event: string; date: string; param: any }[]>(this.postUrl, formData).subscribe(result => {
			console.log(result);
		});
	}

	sendFeedback(param: any) {
		const formData = new FormData();
		formData.append("action", "feedback");
		formData.append("params", JSON.stringify(param));
		return this.http.post(this.postUrl, formData);
	}
}
