<!-- APP SETTINGS Kidaia V2-->

<div *ngIf="globalService.allowSettingsCompToDisplay" class="globalContainer backgroundSettings"
    [ngClass]="{'globalContainerShown': globalService.displayVoiceSettingsStatus,'animateOpening':globalService.displayVoiceSettingsAnimate, 'globalContainerLandscape': globalService.isLandscape}">

    <!-- REUSED TEMPLATES -->

    <!-- close button when help is handled -->
    <ng-template #reUseCloseHelpButton>
        <div class="closeButtonHelpContainer" (click)="handleHelp()">
            <ion-icon class="closeButtonHelp" name="close-outline">
            </ion-icon>
        </div>
    </ng-template>

    <!-- top wizard system -->
    <ng-template #reUseWizard let-title="title" let-iconSrc="iconSrc" , let-menu="menu" , let-img="img">
        <div class="step-wizard-item" [ngClass]="{'selectedParam':menuTabItemSelected.menu === menu }">
            <div class="step-wizard-item_description">
                <div id="icon-{{menu}}" class="progress-count" (click)="selectedTabHelp(menu)">
                    <ion-icon size="normal" slot="icon-only" [src]="iconSrc" [alt]="title" *ngIf="iconSrc"></ion-icon>
                    <ng-container *ngIf="menu === MenuTabItems.assistant;else otherMenus">
                        <ion-img *ngIf="!globalService.isOse && !globalService.isPageOse" size="normal" slot="icon-only" [alt]="title" [src]="'/assets/mathia/mascotte3d/mascotte3d.png'"></ion-img>
                        <ion-img *ngIf="globalService.isOse || globalService.isPageOse" size="normal" slot="icon-only" [alt]="title" [src]="'/assets/mascotte/bebee.png'"></ion-img>
                    </ng-container>
                    <ng-template #otherMenus>
                        <ion-img size="normal" slot="icon-only" [src]="img" [alt]="title" *ngIf="img"></ion-img>
                    </ng-template>
                </div>
                <span class="progress-label">{{title}}</span>
            </div>
        </div>
    </ng-template>

    <ng-template #reUseSkip  let-help="help">
        <!-- skipNextTuto -->
        <div class="topdown-wrapper">
            <div class="next-btn" (click)="nextTab()" *ngIf="!help">
                <p class="feedback-skip-btn__text">
                    <span i18n>Suivant</span>
                </p>
            </div>
            <div class="help-btn" [hidden]="!globalService.isMobile || !menuTabItemSelected.hasHelp" (click)="handleHelp()">
                <p class="feedback-skip-btn__text">
                    <span i18n *ngIf="getHelp">Réglages</span>
                    <span i18n *ngIf="!getHelp">Aide</span>
                </p>
            </div>
        </div>
    </ng-template>

    <ng-template #reUseMascotte>
        <div class="mascotteContainer">
            <ion-img *ngIf="!globalService.isOse && !globalService.isPageOse" [src]="'/assets/mathia/mascotte3d/mascotte3d.png'" class="mascotte"></ion-img>
            <ion-img *ngIf="globalService.isOse || globalService.isPageOse" [src]="'/assets/mascotte/bebee.png'" class="mascotte"></ion-img>
        </div>
    </ng-template>


    <div [ngClass]="{'tutoFullContainer': isTuto && getHelp}"></div>

    <!-- close icon -->
    <div id="closeMenuSettingsMathia" *ngIf="!globalService.isKidaia"
        [ngClass]="[globalService.displayVoiceSettingsStatus ? 'pointer closeIconMathia iconDefaultMenuOpened' : 'opacityNone']"
        (click)="closeSettings()">
    </div>
    <div id="closeMenuSettingsKidaia" *ngIf="globalService.isKidaia"
        [ngClass]="[globalService.displayVoiceSettingsStatus ? 'pointer closeIconKidaia' : 'opacityNone', globalService.progressBarStatus ? 'kidaiaBigIconSlotProgressBarMenu' : 'kidaiaBigIconSlotMenu']"
        (click)="closeSettings()">
    </div>
    <div class="step-wizard-container">
        <div class="step-wizard-container-items" #stepWizardItems>
            <ng-container *ngFor="let item of wizardTabItems; let index = index">
                <ng-container *ngIf="globalService.isLandscape || index < 3">
                    <ng-container [ngTemplateOutlet]="reUseWizard"
                        [ngTemplateOutletContext]="{ title: item.title,iconSrc:item.iconSrc,menu:item.menu,img:item.img}">
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <div class="step-wizard-container-items" #stepWizardItems>
            <ng-container *ngFor="let item of wizardTabItems; let index = index">
                <ng-container *ngIf="!globalService.isLandscape && index >= 3">
                    <ng-container [ngTemplateOutlet]="reUseWizard"
                        [ngTemplateOutletContext]="{ title: item.title,iconSrc:item.iconSrc,menu:item.menu,img:item.img}">
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <div class="settingsContainer"
        [ngClass]="{'settingsContainerHelp':getHelp, 'settingsContainerPortrait': !globalService.isLandscape, 'settingsContainerLandscape': globalService.isLandscape, 'settingsContainerPortraitMobile' : !globalService.isLandscape && globalService.isMobile, 'settingsContainerMobileLandscape' : globalService.isMobile && globalService.isLandscape}">
        <!-- ASSISTANT -->
        <div id="assistant-tab" class="eachTabParamsContainer audioContainer"
            [hidden]="menuTabItemSelected.menu !== MenuTabItems.assistant">
            <!-- ASSISTANT PARAMS -->
            <div class="dividedContainer devidedContainerParams" #paramsContainer>
                <div class="configContainer">
                    <ng-template [ngTemplateOutlet]="reUseMascotte"></ng-template>
                    <!-- <img src="/assets/mathia/mascotte3d/mascotte3d.png" /> -->
                    <div #assistantInit class="configContainer_content">
                        <h2 class="configContainer_content_title" i18n>Bienvenue dans l'assistant de configuration ({{versions.version}})</h2>
                        <p i18n>Nous allons ensemble parcourir les différents paramètres pour une configuration optimale
                        </p>
                        <nav>
                            <ion-button class="microTestBtn configBtn"
                                (click)="validateConfigStatus(WizzardConfig.start)" i18n>
                                Démarrer la configuration
                            </ion-button>
                            <!-- <ion-button class="microTestBtn configBtn" (click)="validateConfigStatus(WizzardConfig.later)">
                                <p>Redemander plus tard</p>
                            </ion-button> -->
                            <ion-button *ngIf="commonErrors.length === 0" class="microTestBtn configBtn"
                                (click)="validateConfigStatus(WizzardConfig.auto)" i18n>
                                Configuration automatique
                            </ion-button>
                        </nav>
                    </div>
                </div>
            </div>
            <!-- no help for first page-->
            <!-- <div class="dividedContainer devidedContainerAudio"
                [ngClass]="{'dividedContainerHelp' :!isTuto, 'dividedContainerTuto':isTuto}" *ngIf="getHelp">
        </div> -->
        </div>
        <div id="audio-tab" class="eachTabParamsContainer audioContainer"
            [hidden]="menuTabItemSelected.menu !== MenuTabItems.audio">

            <!-- AUDIO PARAMS -->
            <div class="dividedContainer devidedContainerParams" #paramsContainer>
                <div class="dividedContainer2">
                    <div class="dividedContainer3">
                        <h2 i18n>Volumes</h2>
                        <div class="settingWrapper">
                            <div class="iconAndLabelWrapper"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <!-- volume icon -->
                                <ion-button slot="start" fill="clear" class="buttonKid"
                                    *ngIf="!globalService.muteSounds" (click)="toggleMuteSounds(true)">
                                    <ion-icon color="kidaiaIcon" size="large" slot="icon-only" name="chatbubbles">
                                    </ion-icon>
                                </ion-button>
                                <!-- mute icon -->
                                <ion-button slot="start" *ngIf="globalService.muteSounds"
                                    (click)="toggleMuteSounds(false)" fill="clear" class="buttonKid">
                                    <ion-icon color="kidIconColor" size="large" slot="icon-only" name="volume-mute">
                                    </ion-icon>
                                </ion-button>
                                <label class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}">
                                    {{SettingName.volumeVoix}}
                                </label>
                            </div>
                            <!-- Range -->
                            <div class="itemCustom rangeItem"
                                [ngClass]="{'itemCustomMobile': globalService.isMobile, 'rangeItemPortraitMobile': !globalService.isLandscape}"
                                lines="none">
                                <ion-range class="rangeCustom" [(ngModel)]="globalService.soundsVolume" min="0.0001"
                                    max="1" step="0.001" (ionKnobMoveEnd)="sendAnalytic($event)">
                                </ion-range>
                            </div>
                        </div>
                        <div class="settingWrapper">
                            <div class="iconAndLabelWrapper"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid" *ngIf="!globalService.muteFxs"
                                    (click)="toggleMuteFxs(true)">
                                    <ion-icon color="kidaiaIcon" size="large" slot="icon-only" name="volume-high">
                                    </ion-icon>
                                </ion-button>
                                <ion-button slot="start" *ngIf="globalService.muteFxs" (click)="toggleMuteFxs(false)"
                                    fill="clear" class="buttonKid">
                                    <ion-icon color="kidIconColor" size="large" slot="icon-only" name="volume-mute">
                                    </ion-icon>
                                </ion-button>
                                    <label class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile, 'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}">{{SettingName.volumeSons}}</label>
                            </div>
                            <div class="itemCustom rangeItem"
                                [ngClass]="{'itemCustomMobile': globalService.isMobile, 'rangeItemPortraitMobile': !globalService.isLandscape}"
                                lines="none">
                                <ion-range class="rangeCustom" [(ngModel)]="globalService.fxsVolume" min="0.0001"
                                    max="1" step="0.001" (ionKnobMoveEnd)="sendAnalytic($event)">
                                </ion-range>
                            </div>
                        </div>


                        <!-- MUSIC VOLUME BABYLON + MISC MATHIA-->
                        <div class="settingWrapper">
                            <div class="iconAndLabelWrapper"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <!-- volume icon -->
                                <ion-button slot="start" fill="clear" class="buttonKid"
                                    *ngIf="!globalService.muteMusicVolume" (click)="toggleMuteMusic(true)">
                                    <ion-icon color="kidaiaIcon" size="large" slot="icon-only" name="musical-notes">
                                    </ion-icon>
                                </ion-button>
                                <!-- mute icon -->
                                <ion-button slot="start" *ngIf="globalService.muteMusicVolume"
                                    (click)="toggleMuteMusic(false)" fill="clear" class="buttonKid">
                                    <ion-icon color="kidIconColor" size="large" slot="icon-only" name="ban">
                                    </ion-icon>
                                </ion-button>
                                <!-- label -->
                                <label class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}">{{SettingName.volumeMusique}}</label>
                            </div>
                            <!-- Range -->
                            <div class="itemCustom rangeItem"
                                [ngClass]="{'itemCustomMobile': globalService.isMobile, 'rangeItemPortraitMobile': !globalService.isLandscape}"
                                lines="none">
                                <ion-range class="rangeCustom" [(ngModel)]="globalService.musicVolumeSliderValue"
                                    min="-2.18" max="0.69" step="0.01" (ionKnobMoveEnd)="sendAnalytic($event)">
                                </ion-range>
                            </div>
                        </div>

                        <h2 i18n>Synthèse vocale</h2>

                        <!-- VOICES LIST-->
                        <div id="voice-list" class="settingWrapper"
                            [ngClass]="{hasError: (commonErrors.includes(SettingsError.noVoice) || commonErrors.includes(SettingsError.samsungVoice))}">
                            <div class="iconAndLabelWrapper"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid">
                                    <ion-icon size="normal" slot="icon-only" name="mic">
                                    </ion-icon>
                                </ion-button>
                                <label class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}"
                                    i18n>Voix</label>
                            </div>

                            <div [ngClass]="{'itemCustomMobile': globalService.isMobile, 'rangeItemPortraitMobile': !globalService.isLandscape}"
                                lines="none" *ngIf="!ttsService.useTTSCordova && ttsService.synth">
                                <select [(ngModel)]="ttsService.selectedVoiceSynth"
                                    (change)="changeVoice();sendAnalytic($event);"
                                    class="selectedVoice fontFam settingsMenuItemsBgColor"
                                    [ngClass]="{'selectedVoiceMobile': globalService.isMobile}">
                                    <option *ngFor="let voice of ttsService.voices" [ngValue]="voice"
                                        class="fontFamOptionSelect">
                                        <div class="voiceName">
                                            {{voice.name}}
                                        </div>

                                        <ng-template #enligne i18n>
                                            #en ligne
                                        </ng-template>

                                    </option>
                                </select>
                            </div>

                            <ion-item class="itemCustom rangeItem" lines="none" *ngIf="ttsService.useTTSCordova">
                                <select [(ngModel)]="ttsService._selectedVoiceCordova"
                                    (change)="changeVoice();sendAnalytic($event);"
                                    class="selectedVoice fontFam settingsMenuItemsBgColor">
                                    <option *ngFor="let voice of ttsService.voices" [ngValue]="voice"
                                        class="fontFamOptionSelect">
                                        <span>{{voice.name}}</span>
                                        <span *ngFor="let item of arrayOne(voice.quality); let i = index;">⭐</span>
                                        <ng-container *ngIf="voice.localService || globalService.isIos;else enligne"
                                            i18n>
                                            #hors ligne
                                        </ng-container>
                                        <ng-template #enligne i18n>
                                            #en ligne
                                        </ng-template>
                                    </option>
                                </select>
                            </ion-item>
                            <div class="error-wrapper">
                                <div *ngIf="commonErrors.includes(SettingsError.noVoice)">
                                    <p>⚠️ <span i18n>Aucune voix n'est disponible.</span> <a *ngIf="!globalService.inIframe"
                                        (click)="openLink(environment.kidaia?'https://kidaia.com/configurer-la-synthese-vocale-kidaia/':'https://mathia.education/configurer-la-synthese-vocale-mathia/')"><span i18n>Consultez
                                            la page suivante pour plus d'informations</span></a></p>
                                    <p><small *ngIf="globalService.isAndroid" i18n>Dans les paramètres de votre appareil,
                                            rechercher "Synthèse vocale"</small></p>
                                </div>
                                <div *ngIf="commonErrors.includes(SettingsError.samsungVoice)">
                                    <p>⚠️ <span i18n>Sur samsung, vous pouvez choisir d'installer des voix francaises ou
                                        basculer sur la synthèse vocale google.</span><a *ngIf="!globalService.inIframe"
                                        (click)="openLink(environment.kidaia?'https://kidaia.com/configurer-la-synthese-vocale-kidaia/':'https://mathia.education/configurer-la-synthese-vocale-mathia/')"><span i18n>Consultez
                                        la page suivante pour plus d'informations</span></a></p>
                                </div>
                            </div>
                        </div>

                        <!-- VOICE SPEED -->
                        <div class="settingWrapper" *ngIf="ttsService.useTTSCordova || ttsService.synth">
                            <!-- EDIT VOICE RATE RATIO SYNTH + CORDOVA -->
                            <div class="iconAndLabelWrapper" [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid">
                                    <ion-icon size="normal" slot="icon-only" name="speedometer">
                                    </ion-icon>
                                </ion-button>
                                <label class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}">{{SettingName.vitesseVoix}}</label>
                            </div>
                            <!-- range -->
                            <div class="itemCustom rangeItem"
                                [ngClass]="{'itemCustomMobile': globalService.isMobile, 'rangeItemPortraitMobile': !globalService.isLandscape}"
                                lines="none">
                                <ion-range class="rangeCustom" [min]="ttsService.minRateRatio"
                                    [max]="ttsService.maxRateRatio" step="0.1" [(ngModel)]="ttsService.rateRatio">
                                </ion-range>
                            </div>
                        </div>


                        <div class="settingWrapper settingWrapperAnalyser"
                            [ngClass]="{hasError: (commonErrors.includes(SettingsError.noMicrophone) || commonErrors.includes(SettingsError.noMicPermission))}">
                            <div class="analyserViewWrapper">
                                <div class="iconAndLabelWrapper"
                                    [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                    <ion-button class="microTestBtn labelKid ion-no-margin" (click)="manageVoiceTesting()">

                                        <p
                                            *ngIf="!sttService?.state?.recording || !sttService.micCheckDone || sttService.noMicrophone;else enableStopRecording">
                                            {{SettingName.testDuMicro}}
                                        </p>
                                        <ng-template #enableStopRecording>
                                            <p i18n>Annuler le test</p>
                                        </ng-template>
                                        <!--  -->
                                    </ion-button>
                                </div>
                                <div class="analyserViewContainer ">
                                    <div class="rangeContainer" style="width:100%"
                                        [ngClass]="{'rangeProgressionCordova':globalService.isCordova, 'rangeContainerPlaying' : sttService.state.recording, 'rangeContainerStop': !sttService.state.recording}">
                                        <div class="rangeProgression"
                                            [style.transform]="'translateX('+volumeAudioVisualization+'%)'">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="sttService.state.recording">
                                <div class="rasaTextContainer rangeItem"
                                    *ngIf="sttService.state.recognitionOutput?.length > 0 && sttService.state.recognitionOutput[0].text;else notYetRasaRecognized">
                                    <p class="ion-text-uppercase">
                                        <i>{{sttService.state.recognitionOutput[0].text}}</i>
                                    </p>
                                </div>

                                <ng-template #notYetRasaRecognized>
                                    <div class="rasaTextContainer rangeItem">
                                        <p *ngIf="sttService.micCheckDone && !sttService.noMicrophone"
                                            class="ion-text-uppercase" i18n>Dis quelque chose</p>
                                        <p *ngIf="sttService.micCheckDone && sttService.noMicrophone" i18n>On dirait
                                            que tu
                                            n'as
                                            pas
                                            de micro branché.</p>
                                    </div>
                                </ng-template>

                            </ng-container>
                            <div class="error-wrapper">
                                <div *ngIf="commonErrors.includes(SettingsError.noMicrophone) || commonErrors.includes(SettingsError.noMicPermission)">
                                    <p>⚠️ <span i18n>Problème de micro détecté <ng-container *ngIf="commonErrors.includes(SettingsError.noMicPermission)">(permission refusée)</ng-container></span>. <a
                                        *ngIf="!globalService.inIframe" (click)="openLink(environment.kidaia?'https://kidaia.com/configurer-son-microphone-sur-kidaia/':'https://mathia.education/configurer-son-microphone-sur-mathia/')"><span i18n>Consultez
                                            la page suivante pour plus d'informations</span></a></p>
                                </div>
                            </div>
                        </div>

                        <ng-template [ngTemplateOutlet]="reUseSkip"></ng-template>
                    </div>
                </div>
            </div>

            <!-- AUDIO HELP -->
            <div class="dividedContainer devidedContainerAudio"
                [ngClass]="{'dividedContainerHelp' :!isTuto, 'dividedContainerTuto':isTuto}" *ngIf="getHelp">
                <div class="dividedContainer2">
                    <ng-template *ngIf="globalService.isMobile" [ngTemplateOutlet]="reUseCloseHelpButton"></ng-template>

                    <ng-container *ngIf="isTuto">
                        <!-- Mascotte -->
                        <ng-template [ngTemplateOutlet]="reUseMascotte"></ng-template>
                    </ng-container>
                    <ng-container *ngIf="!isTuto">
                        <div class="helpContainer">
                            <div class="helpContainer2">
                                <div class="eachHelpContainer" *ngFor="let help of wizardTabItems[1].helpPhrases">
                                    <div class="helpContainer__title">
                                        <!-- <ion-button fill="outline" slot="start" fill="end" class="tutoSTTParam"
                                        (click)="readSTTParamExplanation(help.label,help.description)">
                                        <ion-icon name="volume-high-outline"></ion-icon>
                                    </ion-button> -->
                                        <ion-icon [size]="help.icon.size" slot="icon-only"
                                            [name]="help.icon.name?help.icon.name:''"
                                            [src]="help.icon.src?help.icon.src:''">
                                        </ion-icon>
                                        <p>{{help.label}}</p>
                                    </div>
                                    <div class="helpContainer__body">
                                        <p>{{help.description}} 
                                            <a *ngIf="help.label === 'Voix' && !globalService.inIframe"
                                                (click)="openLink(environment.kidaia?'https://kidaia.com/configurer-la-synthese-vocale-kidaia/':'https://mathia.education/configurer-la-synthese-vocale-mathia/')">
                                                <span i18n>Si vous avez des problèmes avec la synthèse vocale, cliquez ici pour plus d'informations.</span>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template [ngTemplateOutlet]="reUseSkip" [ngTemplateOutletContext]="{help:true}"></ng-template>
                </div>
            </div>
        </div>
        <!-- ACCESSIBILITY -->
        <div id="accessibility-tab" class="eachTabParamsContainer graphicContainer"
            [hidden]="menuTabItemSelected.menu !== MenuTabItems.graphic">
            <!-- GRAPHIC PARAMS -->
            <div class="dividedContainer devidedContainerParams devidedContainerGraph" #paramsContainer>
                <div class="dividedContainer2">
                    <div class="dividedContainer3">
                        <h2 i18n>Accessibilité</h2>
                        <div class="settingWrapper ">
                            <div class="iconAndLabelWrapperToggle"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid">
                                    <ion-icon size="normal" slot="icon-only" name="contrast"></ion-icon>
                                </ion-button>
                                <div class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}"
                                    i18n>
                                    Filtre lumière bleue</div>
                            </div>

                            <div class="itemCustom" lines="none" class="toggleItem"
                                [ngClass]="{'toggleItemMobile itemCustomMobile': globalService.isMobile}">
                                <ion-toggle class="toggleCustom"
                                    [ngClass]="{'toggleCustomMobile': globalService.isMobile}"
                                    (ionChange)="sendAnalytic($event)" name="apple"
                                    [(ngModel)]="globalService.blueLightFilter"
                                    [checked]="globalService.blueLightFilter">
                                </ion-toggle>
                            </div>
                        </div>

                        <div class="settingWrapper settingWrapperBL" *ngIf="!globalService.webGl1"
                            [ngClass]="{hasError: (commonErrors.includes(SettingsError.lowRAM) || commonErrors.includes(SettingsError.veryLowRAM))}">
                            <div class="iconAndLabelWrapperToggle"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid">
                                    <ion-icon size="normal" slot="icon-only"
                                        src="/assets/icon/icon-turtle.svg"></ion-icon>
                                </ion-button>
                                <div class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}"
                                    i18n>
                                    Mode faible performance
                                </div>
                            </div>

                            <div class="itemCustom" lines="none" class="toggleItem"
                                [ngClass]="{'toggleItemMobile itemCustomMobile': globalService.isMobile}">
                                <ion-toggle class="toggleCustom"
                                    [ngClass]="{'toggleCustomMobile': globalService.isMobile}"
                                    (ionChange)="sendAnalytic($event)" name="apple"
                                    [(ngModel)]="globalService.lowPerformanceMode"
                                    [checked]="globalService.lowPerformanceMode">
                                </ion-toggle>
                            </div>
                            <div class="error-wrapper">
                                <div *ngIf="commonErrors.includes(SettingsError.lowRAM)">
                                    <p i18n>⚠️ Nous vous conseillons d'activer ce mode si vous rencontrer des problèmes
                                        durant
                                        l'usage de l'application</p>
                                </div>
                                <div *ngIf="commonErrors.includes(SettingsError.veryLowRAM)">
                                    <p i18n>⚠️ Nous avons activé ce mode par défaut pour les appareils disposant de moins
                                        de 3Go
                                        de RAM</p>
                                </div>
                            </div>
                        </div>

                        <!-- mode dictee (off for now)-->
                        <!--
                        <div *ngIf="!globalService.isKidaia" class="settingWrapper settingWrapperBL">
                            <div class="iconAndLabelWrapperToggle" [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid">
                                    <ion-icon size="normal" slot="icon-only" name="hourglass"></ion-icon>
                                </ion-button>
                                <div class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}"
                                    i18n>Mode dictée</div>
                                <ion-button color="secondary" slot="end" fill="clear" (click)="dicteeHelp()"
                                    class="dicteeButton">
                                    <ion-icon slot="icon-only" name="help-circle-outline" class="helpIcon"></ion-icon>
                                </ion-button>
                            </div>

                            <div class="toggleItem" lines="none"
                                [ngClass]="{'toggleItemMobile itemCustomMobile': globalService.isMobile}">
                                <ion-toggle class="toggleCustom" [ngClass]="{'toggleCustomMobile': globalService.isMobile}"
                                    (ionChange)="sendAnalytic($event)" slot="end" name="apple"
                                    [(ngModel)]="ttsService.dicteeMode" [checked]="ttsService.dicteeMode">
                                </ion-toggle>
                            </div>
                        </div>
                        -->
                        <!-- MUTE MENUS MODE -->
                        <div *ngIf="!globalService.isKidaia" class="settingWrapper settingWrapperBL">
                            <div class="iconAndLabelWrapperToggle"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid">
                                    <ion-icon size="normal" slot="icon-only" name="chatbubbles"></ion-icon>
                                </ion-button>
                                <div class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile} " i18n>Menu silencieux
                                </div>
                            </div>

                            <div class="toggleItem" lines="none"
                                [ngClass]="{'toggleItemMobile itemCustomMobile': globalService.isMobile}">
                                <ion-toggle class="toggleCustom"
                                    [ngClass]="{'toggleCustomMobile': globalService.isMobile}" name="apple"
                                    [(ngModel)]="ttsService.menusMuted" [checked]="ttsService.menusMuted"
                                    (ionChange)="sendAnalytic($event)"></ion-toggle>
                            </div>
                        </div>

                        <div class="settingWrapper settingWrapperBL">
                            <div class="iconAndLabelWrapperToggle"
                                [ngClass]="{'iconAndLabelMarginL': !globalService.isMobile}">
                                <ion-button slot="start" fill="clear" class="buttonKid">
                                    <ion-icon size="normal" slot="icon-only" name="eye"></ion-icon>
                                </ion-button>
                                <div class="fontFam settingsMenuItemsBgColor labelKid"
                                    [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}"
                                    i18n>Mode dyslexique</div>
                            </div>

                            <div class="toggleItem" lines="none"
                                [ngClass]="{'toggleItemMobile itemCustomMobile': globalService.isMobile}">
                                <ion-toggle class="toggleCustom"
                                    [ngClass]="{'toggleCustomMobile': globalService.isMobile}"
                                    (ionChange)="sendAnalytic($event)" slot="end" name="apple"
                                    [(ngModel)]="globalService.dyslexicMode" [checked]="globalService.dyslexicMode">
                                </ion-toggle>
                            </div>
                        </div>

                        <div *ngIf="!globalService.isKidaia && accountImplService.user.codeClasse 
                                        && cabriService.holoMode !== '-1' && cabriService.holoMode !== '1'
                                        && !globalService.inIframe"
                            class="logoutContainer" (click)="disconnectUser()">
                            <div class="fontFam settingsMenuItemsBgColor labelKid labelDisconnect"
                                [ngClass]="{'labelKidMobile': globalService.isMobile,  'labelKidMobileDys': globalService.dyslexicMode && globalService.isMobile, 'dyslexicFontHeight': globalService.dyslexicMode}"
                                i18n>Déconnexion</div>
                            <div class="logoutWrapper">
                                <ion-icon size="large" name="log-out" class="ion-icon-logout" class="logoutColor">
                                </ion-icon>
                            </div>
                        </div>
                        <ng-template [ngTemplateOutlet]="reUseSkip"></ng-template>
                    </div>
                </div>
            </div>


            <!-- ACCESIBILITY HELP -->
            <div class="dividedContainer devidedContainerGraphic dividedContainerHelp" *ngIf="getHelp">
                <div class="dividedContainer2">
                    
                    <ng-template *ngIf="globalService.isMobile" [ngTemplateOutlet]="reUseCloseHelpButton"></ng-template>
                    <div class="helpContainer">
                        <div class="helpContainer2">
                            <div class="eachHelpContainer" *ngFor="let help of wizardTabItems[2].helpPhrases">
                                <div class="helpContainer__title">
                                    <!-- <ion-button fill="outline" slot="start" fill="end" class="tutoSTTParam"
                                        (click)="readSTTParamExplanation(help.label,help.description)">
                                        <ion-icon name="volume-high-outline"></ion-icon>
                                    </ion-button> -->
                                    <ion-icon [size]="help.icon.size" slot="icon-only"
                                        [name]="help.icon.name?help.icon.name:''"
                                        [src]="help.icon.src?help.icon.src:''">
                                    </ion-icon>
                                    <p>{{help.label}}</p>
                                </div>
                                <div class="helpContainer__body">
                                    <p>{{help.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ng-template [ngTemplateOutlet]="reUseSkip" [ngTemplateOutletContext]="{help:true}"></ng-template>
                </div>
            </div>
        </div>

        <!-- GENERAL PARAMS -->
        <div id="activityParams-tab" class="eachTabParamsContainer activityParametersContainer"
            [hidden]="menuTabItemSelected.menu !== MenuTabItems.parametres">
            <!-- GENERAL PARAMETERS -->
            <div class="dividedContainer devidedContainerParams dividedContainerSettings" #paramsContainer>

                <div class="eachCategoryContainer eachCategoryContainer_settings"
                    [hidden]="menuTabItemSelected.menu !== MenuTabItems.parametres">
                    <app-activity-settings #activitySettings class="storySettings"
                        (launchStoryActivityToolbar)="launchStoryActivityEmitter()" [getHelp]="getHelp"
                        (switchSettingParam)="switchSettingsParams($event)" [changeD]="changeD"
                        [firstTimeParamSelection]="firstTimeParamSelection"
                        (closeSettingsFromActivitySettings)="closeSettings()"></app-activity-settings>
                </div>
                <ng-template [ngTemplateOutlet]="reUseSkip"></ng-template>
            </div>


            <!-- GENERAL PARAMETERS HELP -->
            <div class="dividedContainer devidedContainerParameters dividedContainerHelp" *ngIf="getHelp">
                <div class="dividedContainer2">
                    <ng-template *ngIf="globalService.isMobile" [ngTemplateOutlet]="reUseCloseHelpButton"></ng-template>
                    <div class="helpContainer"
                        *ngIf="menuTabItemSelected.menu === MenuTabItems.parametres && menuTabItemSelected.helpPhrases">
                        <div class="helpContainer2">
                            <ng-container *ngFor="let paramTuto of wizardTabItems[3].helpPhrases;let last = last">
                                <div class="eachHelpContainer" [ngClass]="{'divider': !last}" *ngIf="paramTuto && paramTuto.phrases?.length>0">
                                    <div class="helpContainer__title">
                                        <!-- <ion-button fill="outline" slot="start" fill="end" class="tutoSTTParam"
                                            (click)="readSTTParamExplanation(paramTuto.value.label,paramTuto.value.phrases)">
                                            <ion-icon name="volume-high-outline">
                                            </ion-icon>
                                        </ion-button> -->
                                        <h2 class="paramActivityParamTitle">{{paramTuto.label}}</h2>
                                    </div>
                                    <div class="helpContainer__body">
                                        <ng-container *ngFor="let phrase of paramTuto.phrases">
                                            <div class="helpContainer__body_images">
                                                <div class="eachActivityParamContainer">
                                                    <ion-icon class="helpIconActivitySettings" [src]="phrase.img"
                                                        [alt]="paramTuto.label"></ion-icon>
                                                    <p class="helpTextActivitySettings">{{phrase.text}}</p>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <ng-template [ngTemplateOutlet]="reUseSkip" [ngTemplateOutletContext]="{help:true}"></ng-template>
                </div>
            </div>
        </div>

        <!-- LIENS UTILES -->
        <div id="links-tab" class="eachTabParamsContainer activityParametersContainer"
            [hidden]="menuTabItemSelected.menu !== MenuTabItems.liensUtiles">
            <!-- GENERAL PARAMETERS -->
            <div class="dividedContainer devidedContainerParams">
                <div class="dividedContainer2">
                    <ng-container *ngIf="!environment.kidaia">
                        <ul>
                            <li>
                                <a target="_system" (click)="openLink('https://mathia.education/')">
                                    <h2 i18n><ion-icon name="globe-outline"></ion-icon> Site compagnon</h2>
                                    <p i18n>Point d'entrée pour tout savoir sur le projet Mathia.
                                        <ion-icon name="exit-outline" title="Ouvrir la page"></ion-icon>
                                    </p>
                                </a>
                            </li>
                            <li>
                                <a target="_system" (click)="openLink('https://mathia.education/ressources/')">
                                    <h2 i18n><ion-icon name="globe-outline"></ion-icon> Ressources pégagogiques</h2>
                                    <p i18n>Livret de mise en route, catalogue d'exercices ou référentiel de compétences,
                                        retrouvez-y toutes les ressources pour enseignants.
                                        <ion-icon name="exit-outline" title="Ouvrir la page"></ion-icon>
                                    </p>
                                </a>
                            </li>
                            <li>
                                <a target="_system" (click)="openLink('https://mathia.education/webinaire/')">
                                    <h2 i18n><ion-icon name="globe-outline"></ion-icon> Webinaire Mathia</h2>
                                    <p i18n>Inscrivez-vous au prochain webinaire pour une présentation complète de la solution et
                                        poser toutes vos questions.
                                        <ion-icon name="exit-outline" title="Ouvrir la page"></ion-icon>
                                    </p>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="environment.kidaia">
                        <ul>
                            <li>
                                <h2 i18n><ion-icon name="globe-outline"></ion-icon> <a target="_system" href="https://kidaia.com/">Site officiel Kidaia</a></h2>
                                <p i18n>Point d'entrée pour tout savoir sur Kidaia.</p>
                            </li>
                            <li>
                                <h2 i18n><ion-icon name="globe-outline"></ion-icon> <a target="_system" href="https://kidaia.com/activites-a-imprimer/">Activités à imprimer</a></h2>
                                <p i18n>De nombreuses activités gratuites à imprimer pour jouer en famille tout en consolidant ses acquis en mathématiques</p>
                            </li>
                            <li>
                                <h2 i18n><ion-icon name="globe-outline"></ion-icon> <a target="_system" href="https://kidaia.com/kidaia-environnement/">Kidaia Environnement</a></h2>
                                <p i18n>Faire naître l’enthousiasme pour l’écologie et le développement durable</p>
                            </li>
                            <li>
                                <h2 i18n><ion-icon name="logo-instagram"></ion-icon> <a target="_system" href="https://www.instagram.com/kidaia_fr/">Suivez-nous sur Instagram</a></h2>
                                <p i18n>Activités, conseils, nouveautés et bonne humeur</p>
                            </li>
                        </ul>
                    </ng-container>
                </div>
                <ng-template [ngTemplateOutlet]="reUseSkip"></ng-template>
            </div>
        </div>


        <!-- FEEDBACK PARAMS -->
        <div id="feedback-tab" class="eachTabParamsContainer activityParametersContainer"
            [hidden]="menuTabItemSelected.menu !== MenuTabItems.feedback">
            <!-- GENERAL PARAMETERS -->
            <div class="dividedContainer devidedContainerParams">
                <div class="dividedContainer2">
                    <app-feedbackc></app-feedbackc>
                </div>
                <ng-template [ngTemplateOutlet]="reUseSkip"></ng-template>
            </div>
        </div>


        <app-custom-alert *ngIf="displayChangeParamAlert" (alertMessageAnswer)="alertResponse($event)"
            [alertAction]="userAlertActions.changeParam" [alertTitle]="alertTitle"></app-custom-alert>


        <!-- TEMP SWITCH STT SERVER -->
        
    </div>
    <div *ngIf="globalService.config && !globalService.inIframe" class="linksContainer" [ngClass]="{'linksContainerPortrait': !globalService.isLandscape && !globalService.isDesktop, 
    'linksContainerMobile': globalService.isMobile, 'linksContainerTablet': globalService.isTablet}">
        <div [ngClass]="[!globalService.isLandscape ? 'linksPortrait' : 'linksLandscape']">
            <div class="linksP1">
                <a target="_system" *ngIf="globalService.config?.politiqueconfidentialite" class="link privacy"
                    (click)="openLink(globalService.config.politiqueconfidentialite)" i18n>Confidentialité</a>
                <a target="_system" *ngIf="globalService.config?.cgu" class="link cgu"
                    (click)="openLink(globalService.config.cgu)" i18n>CGU</a>
            </div>
            <div class="linksP2" [ngClass]="[globalService.config?.accessibilite ? 'linksP2' : 'linksP2noAccess']">
                <a target="_system" *ngIf="globalService.config?.mentionslegales" class="link legal"
                    [ngClass]="{'linkWide' : !(globalService.config?.accessibilite)}"
                    (click)="openLink(globalService.config.mentionslegales)" i18n>Mentions légales</a>
                <a target="_system" *ngIf="globalService.config?.accessibilite" class="link accessibility"
                    (click)="openLink(globalService.config.accessibilite)" i18n>Accessibilité</a>
            </div>
        </div>
    </div>
</div>